export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO';
export const UPDATE_COVER_PHOTO = 'UPDATE_COVER_PHOTO';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_REFRESH_TOKEN = 'SET_USER_REFRESH_TOKEN';
export const UPDATE_USER_PRIVACY = 'UPDATE_USERS_PRIVACY';
export const UPDATE_NOTIF_COUNT = 'UPDATE_NOTIF_COUNT';
export const CLEAR_NOTIF_COUNT = 'CLEAR_NOTIF_COUNT';
export const NOTIFICATION_SUBSCRIBE = 'NOTIFICATION_SUBSCRIBE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOADED_PROFILE = 'LOADED_PROFILE';
export const STEP_UP = 'STEP_UP';
export const STEP_DOWN = 'STEP_DOWN';
export const THREAD_SUBSCRIBE = 'THREAD_SUBSCRIBE';
export const SIDEBAR_CONNECTION_TYPE_CHANGE = 'SIDEBAR_CONNECTION_TYPE_CHANGE';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const ACCEPT_SIDEBAR_REQUEST = 'ACCEPT_SIDEBAR_REQUEST';
export const ADD_SIDEBAR_CONNECTION = 'ADD_SIDEBAR_CONNECTION';
export const CONNECTIONS_TOOLTIP_CLOSE = 'CONNECTIONS_TOOLTIP_CLOSE';
export const CONNECTIONS_TOOLTIP_OPEN = 'CONNECTIONS_TOOLTIP_OPEN';
export const CLEAR_LOADED_PROFILE = 'CLEAR_LOADED_PROFILE';
export const USER_DECEASED = 'USER_DECEASED';
export const TRUSTED_PERSON_USER_DECEASED = 'TRUSTED_PERSON_USER_DECEASED';
export const USER_REVIVED = 'USER_REVIVED';
export const TWO_FA_PROFILE_UPDATE = 'TWO_FA_PROFILE_UPDATE';
export const TOGGLE_MOBILE_PROFILE = 'TOGGLE_MOBILE_PROFILE';
export const CLOSE_MOBILE_PROFILE = 'CLOSE_MOBILE_PROFILE';
export const DELETE_FUNERAL_PLAN = 'DELETE_FUNERAL_PLAN';
export const SET_FUNERAL_PHONE = 'SET_FUNERAL_PHONE';

export const onDeleteFuneralPlan = () => {
    return (dispatch) => {
        dispatch({
            type: DELETE_FUNERAL_PLAN,
        });
    };
};

// dispatch chagnes
export const setUser = (user) => {
    return {
        type: SET_USER,
        user: user,
    };
};

export const updateUser = (profile) => {
    return {
        type: UPDATE_USER,
        profile: profile,
    };
};

export const updateUserPhoto = (profilePhoto) => {
    return {
        type: UPDATE_USER_PHOTO,
        profilePhoto: profilePhoto,
    };
};

export const updateUserCover = (coverPhoto, isUser) => {
    return {
        type: UPDATE_COVER_PHOTO,
        coverPhoto: coverPhoto,
        isUser: isUser,
    };
};

export const setToken = (token) => {
    return {
        type: SET_USER_TOKEN,
        token: token,
    };
};

export const setRefreshToken = (refreshToken) => {
    return {
        type: SET_USER_REFRESH_TOKEN,
        refreshToken: refreshToken,
    };
};

export const loadProfile = (payload) => {
    return {
        type: LOADED_PROFILE,
        loadedprofile: payload,
    };
};

// dispatch actions
export const onSetUser = (payload) => {
    return (dispatch) => {
        dispatch(setUser(payload));
    };
};

export const onLoadedProfile = (payload) => {
    return (dispatch) => {
        dispatch(loadProfile(payload));
    };
};

export const onUpdateUser = (payload) => {
    return (dispatch) => {
        dispatch(updateUser(payload));
    };
};

export const onUpdateUserPhoto = (payload) => {
    return (dispatch) => {
        dispatch(updateUserPhoto(payload));
    };
};

export const onUpdateUserCover = (payload, isUser) => {
    return (dispatch) => {
        dispatch(updateUserCover(payload, isUser));
    };
};

export const onSetToken = (payload) => {
    return (dispatch) => {
        dispatch(setToken(payload));
    };
};

export const onSetRefreshToken = (payload) => {
    return (dispatch) => {
        dispatch(setRefreshToken(payload));
    };
};

export const onUpdateUserPrivacy = (payload) => {
    return (dispatch) => {
        dispatch(updateUserPrivacy(payload));
    };
};

export const updateUserPrivacy = (privacy) => {
    return {
        type: UPDATE_USER_PRIVACY,
        privacy: privacy,
    };
};

export const onUpdateNotifCount = (payload) => {
    return (dispatch) => {
        dispatch(updateNotifCount(payload));
    };
};

export const updateNotifCount = (notifReadCount) => {
    return {
        type: UPDATE_NOTIF_COUNT,
        notifReadCount: notifReadCount,
    };
};

export const onClearNotifCount = () => {
    return (dispatch) => {
        dispatch(clearNotifCount());
    };
};

export const clearNotifCount = () => {
    return {
        type: CLEAR_NOTIF_COUNT,
    };
};

export const onNotificationCountSubscribe = (payload) => {
    return (dispatch) => {
        dispatch(notificationCountSubscribe(payload));
    };
};

export const notificationCountSubscribe = (notifCount) => {
    return {
        type: NOTIFICATION_SUBSCRIBE,
        notifCount: notifCount,
    };
};

export const onUnreadThreadCountSubscribe = (payload) => {
    return (dispatch) => {
        dispatch(threadCountSubscribe(payload));
    };
};

export const threadCountSubscribe = (threadCount) => {
    return {
        type: THREAD_SUBSCRIBE,
        threadCount: threadCount,
    };
};

export const onLogoutUser = () => {
    return (dispatch) => {
        dispatch(logoutUser());
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};

export const stepUp = () => {
    return {
        type: STEP_UP,
    };
};

export const onStepUp = () => {
    return (dispatch) => {
        dispatch(stepUp());
    };
};

export const stepDown = () => {
    return {
        type: STEP_DOWN,
    };
};

export const onStepDown = () => {
    return (dispatch) => {
        dispatch(stepDown());
    };
};

export const onSidebarConnectionTypeChange = (payload) => {
    return (dispatch) => {
        dispatch(siderbarConnectionTypeChange(payload));
    };
};

export const siderbarConnectionTypeChange = (payload) => {
    return {
        type: SIDEBAR_CONNECTION_TYPE_CHANGE,
        userId: payload.userId,
        connectionType: payload.type,
    };
};

export const onRemoveConnection = () => {
    return (dispatch) => {
        dispatch(removeConnection());
    };
};

export const removeConnection = () => {
    return {
        type: REMOVE_CONNECTION,
    };
};

export const onAddConnection = (payload) => {
    return (dispatch) => {
        dispatch(addConnection(payload));
    };
};

export const addConnection = (payload) => {
    return {
        type: ADD_SIDEBAR_CONNECTION,
        connection: payload,
    };
};

export const onClearLoadedProfile = () => {
    return (dispatch) => {
        dispatch(clearLoadedProfile());
    };
};

export const clearLoadedProfile = () => {
    return {
        type: CLEAR_LOADED_PROFILE,
    };
};

export const onAcceptRequest = (payload) => {
    return (dispatch) => {
        dispatch(acceptRequest(payload));
    };
};

export const acceptRequest = (payload) => {
    return {
        type: ACCEPT_SIDEBAR_REQUEST,
        payload: payload,
    };
};

export const onConnectionsTooltipClose = () => {
    return (dispatch) => {
        dispatch(connectionsTooltipClose());
    };
};

export const connectionsTooltipClose = () => {
    return { type: CONNECTIONS_TOOLTIP_CLOSE };
};

export const onConnectionsTooltipOpen = () => {
    return (dispatch) => {
        dispatch(connectionsTooltipOpen());
    };
};

export const connectionsTooltipOpen = () => {
    return { type: CONNECTIONS_TOOLTIP_OPEN };
};

export const onUserDeceased = (payload) => {
    return (dispatch) => {
        dispatch(userDeceased(payload));
    };
};

export const userDeceased = (payload) => {
    return {
        type: USER_DECEASED,
        profileDeceased: payload,
    };
};

export const onTrustedDeceased = (payload) => {
    return (dispatch) => {
        dispatch(trustedDeceased(payload));
    };
};

export const trustedDeceased = (payload) => {
    return {
        type: TRUSTED_PERSON_USER_DECEASED,
        trustedProfileDeceased: payload,
    };
};

export const onRevive = (payload) => {
    return (dispatch) => {
        dispatch(revive(payload));
    };
};

export const revive = (payload) => {
    return {
        type: USER_REVIVED,
        revivedProfile: payload,
    };
};

export const onUpdateTwoFaProfile = (payload) => {
    return (dispatch) => {
        dispatch(updateTwoFaProfile(payload));
    };
};

export const updateTwoFaProfile = (payload) => {
    return {
        type: TWO_FA_PROFILE_UPDATE,
        profile: payload,
    };
};

export const onToggleMobileProfile = () => {
    return (dispatch) => {
        dispatch(toggleMobileProfile());
    };
};

export const toggleMobileProfile = () => {
    return {
        type: TOGGLE_MOBILE_PROFILE,
    };
};

export const onCloseMobileProfile = () => {
    return (dispatch) => {
        dispatch(closeMobileProfile());
    };
};

export const closeMobileProfile = () => {
    return {
        type: CLOSE_MOBILE_PROFILE,
    };
};

export const onSetFuneralPhone = (phone) => {
    return {
        type: SET_FUNERAL_PHONE,
        funeralPhone: phone,
    };
};
