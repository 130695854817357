import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentFlowHeader from '../../PayentFlowHeader/PaymentFlowHeader';

class StepTrack extends Component {
    state = {
        simple: false,
    };

    componentDidMount() {
        if (window.location.href.includes('simple')) {
            this.setState({
                simple: true,
            });
        }
    }

    render() {
        return (
            <PaymentFlowHeader
                showStepTrack={true}
                currentStep={this.props.currentStep}
                totalStep={this.props.totalStep}
                simplePlan={this.state.simple}
            />
            // <div
            //     className={`${
            //         this.state.simple
            //             ? 'c-funeral__nav__steps c-funeral__nav__steps--simple'
            //             : 'c-funeral__nav__steps'
            //     }`}
            // >
            //     <ul
            //         className={`o-flex-inline ${
            //             this.state.simple ? 'c-funeral__nav__steps--simple' : ''
            //         }`}
            //     >
            //         <li
            //             className={`${
            //                 this.props.totalStep >= 0 &&
            //                 this.props.currentStep !== 0
            //                     ? 'done'
            //                     : ''
            //             } ${this.props.currentStep === 0 ? 'done active' : ''}`}
            //         >
            //             Contact
            //         </li>
            //         {!this.state.simple ? (
            //             <li
            //                 className={`${
            //                     this.props.totalStep >= 1 &&
            //                     this.props.currentStep !== 1
            //                         ? 'done'
            //                         : ''
            //                 } ${
            //                     this.props.currentStep === 1
            //                         ? 'done active'
            //                         : ''
            //                 }`}
            //             >
            //                 Options
            //             </li>
            //         ) : null}
            //         {!this.state.simple ? (
            //             <li
            //                 className={`${
            //                     this.props.totalStep >= 2 &&
            //                     this.props.currentStep !== 2
            //                         ? 'done'
            //                         : ''
            //                 } ${
            //                     this.props.currentStep === 2
            //                         ? 'done active'
            //                         : ''
            //                 }`}
            //             >
            //                 Wishes
            //             </li>
            //         ) : null}
            //         <li
            //             className={`${
            //                 this.props.totalStep >= 3 &&
            //                 this.props.currentStep !== 3
            //                     ? 'done'
            //                     : ''
            //             } ${
            //                 this.props.currentStep === 3 ? 'done active' : ''
            //             } personal-step`}
            //         >
            //             Personal
            //         </li>
            //         <li
            //             className={`${
            //                 this.props.totalStep >= 4 &&
            //                 this.props.currentStep !== 4
            //                     ? 'done'
            //                     : ''
            //             } ${this.props.currentStep === 4 ? 'done active' : ''}`}
            //         >
            //             Summary
            //         </li>
            //     </ul>
            // </div>
        );
    }
}

const mapStateToProps = (state) => ({
    totalStep: state.funeralPaymentReducer.totalStep,
    currentStep: state.funeralPaymentReducer.currentStep,
});

export default connect(mapStateToProps)(StepTrack);
