export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';

//dispatch changes
export const setCurrentRoute = (route) => {
    return {
        type: SET_CURRENT_ROUTE,
        route: route,
    };
};
//dispatch actions
export const onSetCurrentRoute = (payload) => {
    return (dispatch) => {
        dispatch(setCurrentRoute(payload));
    };
};
