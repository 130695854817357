import 'react-confirm-alert/src/react-confirm-alert.css';

import * as connActionTypes from '../../../../store/actions/connectionActions';
import * as notifActionTypes from '../../../../store/actions/notificationActions';

import React, { Component } from 'react';

import ApiService from '../../../../service/api.service';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import imagePlaceholder from '../../../../assets/images/profile-placeholder.png';
import pastDateFormater from '../../../../service/past_date_formater.service';

class Notification extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        dropdownHovered: false,
        dropdownShown: false,
        checkedValue: 0,
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //handle accept request
    handleAcceptRequest = (data) => {
        this._isMounted &&
            ApiService.acceptRequest(
                data,
                this.props.notification.connection.id
            )
                .then((conn) => {
                    this._isMounted &&
                        this.setState({
                            dropdownShown: false,
                            dropdownHovered: false,
                        });
                    //update connection with connection status
                    this.props.onAcceptConnection(
                        this.props.notification.id,
                        conn.data.status
                    );
                    this.props.onFilterRequestedConnectionsAccept(conn.data);
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    //handle radio change on add new connection
    handleRadioChange = (type) => {
        const data = {
            status: 2,
            type: parseInt(type),
        };
        //if trusted person
        if (type === 1) {
            confirmAlert({
                customUI: ({ onClose }) => (
                    <div className="popup-content popup-content--smaller text-center">
                        <div className="c-popup">
                            <div className="c-popup__header">
                                <p className="margin-reset font-18 font-bold mt-29 font-333 opacity-8 lh-28">
                                    Please confirm you would like to make{' '}
                                    <span className="font-secondary font-bold">
                                        {this.props.notification.source.name}
                                    </span>{' '}
                                    a Trusted Person.
                                </p>
                            </div>
                            <h2 className="font-bold font-18 clearfix font-333 opacity-8 lh-28">
                                You are selecting{' '}
                                <span className="font-secondary font-bold">
                                    {this.props.notification.source.name}
                                </span>{' '}
                                to be a Trusted Person for your Aura account. It
                                is an important role with many responsibilities.{' '}
                                <span className="o-d-block">
                                    <a
                                        href="https://www.aura.life/guides/the-role-of-a-trusted-person"
                                        className="link-underline-hover-background font-orange"
                                    >
                                        Learn more about this role here
                                    </a>
                                </span>
                            </h2>
                            <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                <button
                                    className="btn btn--full btn--size-width-180"
                                    onClick={() => {
                                        this.handleAcceptRequest(data);
                                        onClose();
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn--ghost-purple btn--size-width-180"
                                    onClick={() => {
                                        this.setState({ checkedValue: 0 });
                                        onClose();
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                ),
            });
        } else {
            this.handleAcceptRequest(data);
        }
    };

    // hide connections dropdown
    hideConnectionOptions = (event) => {
        if (
            !event.currentTarget.contains(event.relatedTarget) &&
            !this.state.hovered
        ) {
            this._isMounted &&
                this.setState({
                    dropdownShown: false,
                    dropdownHovered: false,
                });
        }
    };

    //decline connection
    handleDecline = () => {
        this._isMounted &&
            ApiService.declineRequest(
                this.props.notification.connection.id
            ).then(() => {
                this.props.onFilterRequestedConnectionsDecline(
                    this.props.notification.connection.id
                );
                this.props.onDecline(this.props.notification.id);
            });
    };

    // show connections dropdown
    showConnectionOptions = () => {
        this._isMounted &&
            this.setState((prevState) => ({
                dropdownShown: !prevState.dropdownShown,
                radioValue: this.props.connectionType,
            }));
    };

    onDropdownMouseEnter = () => {
        this._isMounted && this.setState({ dropdownHovered: true });
    };
    onDropdownMouseLeave = () => {
        this._isMounted && this.setState({ dropdownHovered: false });
    };

    //close notifications after redirecting to profile
    handleNotificationsClose = () => {
        this.props.onNotificationToggle();
        setTimeout(() => {
            window.location.reload();
        }, 200);
    };

    render() {
        let requestConnectionNotification;
        let requestTrustedPersonNotification;
        let acceptTrustedPersonNotification;
        let acceptFriendsFamilyNotification;
        let reminderPendingConnection;
        let reminderNoConnection;
        let lifeStoryEditor;
        let lifeStoryPublished;
        let lifeStoryUpdated;
        let sectionUpdated;
        if (this.props.notification.notificationType.code === 2) {
            requestConnectionNotification = (
                <div className="c-notification__body c-user-list">
                    <p>
                        {this.props.notification.connection.status === 1 ? (
                            <span>
                                <Link
                                    onClick={this.handleNotificationsClose}
                                    to={`/life-story/${this.props.notification.source.profile.slug}`}
                                >
                                    {this.props.notification.source.name}
                                </Link>{' '}
                                has requested to connect
                            </span>
                        ) : null}

                        {this.props.notification.connection.status === 2 ? (
                            <span>
                                You are now connected with{' '}
                                <Link
                                    onClick={this.handleNotificationsClose}
                                    to={`/life-story/${this.props.notification.source.profile.slug}`}
                                >
                                    {this.props.notification.source.name}
                                </Link>
                            </span>
                        ) : null}
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                    {this.props.notification.connection.status === 1 ? (
                        <div className="o-flex mt-20 c-user-list__user-status">
                            <button
                                className="btn-action btn-action--decline btn btn--faded-red btn--no-border"
                                id={`decline-${this.props.notification.id}`}
                                onClick={() => this.handleDecline()}
                            >
                                Decline
                            </button>
                            <div
                                onBlur={($event) =>
                                    this.hideConnectionOptions($event)
                                }
                                tabIndex="0"
                                className="position-relative"
                            >
                                <button
                                    onClick={() => this.showConnectionOptions()}
                                    className="btn-action btn-action--connect btn--no-border btn--with-icon-right o-flex o-flex-middle o-flex-center"
                                >
                                    Connect
                                    <i className="icon-arrow-down-1 font-10"></i>
                                </button>
                                <div
                                    onMouseEnter={this.onDropdownMouseEnter}
                                    onMouseLeave={this.onDropdownMouseLeave}
                                    className={`c-dropdown-menu c-dropdown-menu--left c-dropdown-menu--mobile-button connection-popup ${
                                        this.state.dropdownShown ? 'open' : ''
                                    }`}
                                >
                                    <span className="font-16 font-333 opacity-9 o-d-block c-dropdown-menu__title">
                                        Connect as your:
                                    </span>
                                    <ul className="margin-reset">
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`trusted-person--${this.props.notification.id}`}
                                                type="radio"
                                                name="connection-type"
                                                checked={
                                                    this.state.checkedValue ===
                                                    1
                                                }
                                                onChange={() =>
                                                    this.handleRadioChange(1)
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`trusted-person--${this.props.notification.id}`}
                                            >
                                                <i className="icon-trusted-person font-orange font-20"></i>
                                                <span className="font-333 opacity-8 font-18">
                                                    Trusted Person
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`family-member--${this.props.notification.id}`}
                                                type="radio"
                                                name="connection-type"
                                                onChange={() =>
                                                    this.handleRadioChange(3)
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`family-member--${this.props.notification.id}`}
                                            >
                                                <i className="icon-family font-333 font-20"></i>
                                                <span className="font-333 opacity-8 font-18">
                                                    Family
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`friend--${this.props.notification.id}`}
                                                type="radio"
                                                name="connection-type"
                                                onChange={() =>
                                                    this.handleRadioChange(2)
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`friend--${this.props.notification.id}`}
                                            >
                                                <i className="icon-friends font-333 font-20"></i>
                                                <span className="font-333 opacity-8 font-18">
                                                    Friend
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        }

        if (
            this.props.notification.notificationType.code === 3 &&
            this.props.notification.connection.type === 1
        ) {
            acceptTrustedPersonNotification = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>{' '}
                        has accepted your connection request and added you as a{' '}
                        <span className="font-green">Trusted Person</span>
                    </p>
                    <p>
                        <span className="float-left">
                            <i className="icon-error c-notification-warning"></i>
                        </span>
                        This is a very important role, with many
                        responsibilities.{' '}
                        <span className="o-d-block">
                            <a
                                href="https://www.aura.life/guides/the-role-of-a-trusted-person"
                                className="link-underline-hover-background font-orange"
                            >
                                Learn more about this role here
                            </a>
                        </span>
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (
            this.props.notification.notificationType.code === 3 &&
            (this.props.notification.connection.type === 2 ||
                this.props.notification.connection.type === 3)
        ) {
            acceptFriendsFamilyNotification = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            <span>{this.props.notification.source.name}</span>
                        </Link>{' '}
                        has accepted your connection request
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (
            this.props.notification.notificationType.code === 4 &&
            this.props.notification.connection.type === 1
        ) {
            requestTrustedPersonNotification = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>
                        <span> has added you as a </span>
                        <span className="font-green">Trusted Person</span>
                    </p>
                    <p>
                        <span className="float-left">
                            <i className="icon-error c-notification-warning"></i>
                        </span>
                        This is a very important role, with many
                        responsibilities.{' '}
                        <span className="o-d-block">
                            <a
                                href="https://www.aura.life/guides/the-role-of-a-trusted-person"
                                className="link-underline-hover-background font-orange"
                            >
                                Learn more about this role here
                            </a>
                        </span>
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (
            this.props.notification.notificationType.code === 4 &&
            (this.props.notification.connection.type === 2 ||
                this.props.notification.connection.type === 3)
        ) {
            acceptFriendsFamilyNotification = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>{' '}
                        has accepted your connection request
                    </p>
                    <span className="font-18 font-333 opacity-8">
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (this.props.notification.notificationType.code === 10) {
            lifeStoryEditor = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>{' '}
                        has added you as an Editor on their Life Story.
                    </p>
                    <p>
                        Help{' '}
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>{' '}
                        to create a rich timeline of their life's memories by{' '}
                        <br />
                        clicking{' '}
                        <Link
                            className="link-underline-hover-background font-orange"
                            onClick={this.handleNotificationsClose}
                            to={`/aura/${this.props.notification.source.profile.slug}/life-story`}
                        >
                            here
                        </Link>
                        .
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (this.props.notification.notificationType.code === 11) {
            lifeStoryPublished = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>{' '}
                        has published their Life Story on Aura and would like
                        you to see it by <br />
                        clicking{' '}
                        <Link
                            className="link-underline-hover-background font-orange"
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            here
                        </Link>
                        .
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (this.props.notification.notificationType.code === 12) {
            lifeStoryUpdated = (
                <div className="c-notification__body">
                    <p>
                        {
                            <Link
                                onClick={this.handleNotificationsClose}
                                to={`/life-story/${this.props.notification.source.profile.slug}`}
                            >
                                {this.props.notification.source.name}
                            </Link>
                        }{' '}
                        has updated their Life Story. See their updates{' '}
                        <Link
                            className="link-underline-hover-background font-orange"
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            here
                        </Link>
                        .
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        if (this.props.notification.notificationType.code === 13) {
            sectionUpdated = (
                <div className="c-notification__body">
                    <p>
                        <Link
                            onClick={this.handleNotificationsClose}
                            to={`/life-story/${this.props.notification.source.profile.slug}`}
                        >
                            {this.props.notification.source.name}
                        </Link>{' '}
                        has shared important infomation with you on Aura. View
                        it{' '}
                        <Link
                            className="link-underline-hover-background font-orange"
                            onClick={this.handleNotificationsClose}
                            to={`/aura/${
                                this.props.notification.source.profile.slug
                            }/${
                                this.props.notification.userWish
                                    ? 'wishes'
                                    : 'practical-information'
                            }`}
                        >
                            here
                        </Link>
                        .
                    </p>
                    <span>
                        {pastDateFormater.formatDate(
                            this.props.notification.createdAt
                        )}
                    </span>
                </div>
            );
        }

        return (
            <div
                className={`c-notification o-flex position-relative ${
                    this.props.notification.new ? 'c-notification__new' : ''
                }`}
            >
                {this.props.notification.new ? (
                    <div className="c-notification--new"></div>
                ) : null}
                <div
                    className={`c-notification__image border-radius-3 position-relative overflow-both-hidden ${
                        this.props.notification.source.profile.dateOfDeath
                            ? 'c-deceased-indicator'
                            : null
                    }`}
                >
                    <Link
                        onClick={this.handleNotificationsClose}
                        to={`/life-story/${this.props.notification.source.profile.slug}`}
                    >
                        <img
                            className="position-absolute width-full height-full"
                            src={
                                this.props.notification.source.profile.photo
                                    ? this.props.notification.source.profile
                                          .photo.url
                                    : imagePlaceholder
                            }
                            alt="profile"
                        ></img>
                    </Link>
                </div>

                {requestConnectionNotification}
                {requestTrustedPersonNotification}
                {acceptTrustedPersonNotification}
                {acceptFriendsFamilyNotification}
                {reminderPendingConnection}
                {reminderNoConnection}
                {lifeStoryEditor}
                {lifeStoryPublished}
                {lifeStoryUpdated}
                {sectionUpdated}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onDecline: (notificationId) =>
            dispatch(notifActionTypes.onDecline(notificationId)),
        onAcceptConnection: (notifId, connectionStatus) =>
            dispatch(
                notifActionTypes.onAcceptConnection({
                    notifId,
                    connectionStatus,
                })
            ),
        onFilterRequestedConnectionsAccept: (connection) =>
            dispatch(connActionTypes.onAcceptRequest(connection)),
        onFilterRequestedConnectionsDecline: (connId) =>
            dispatch(connActionTypes.onDeclineRequest(connId)),
        onUpdateConnectionNumber: (updateType) =>
            dispatch(connActionTypes.onUpdateConnectionNumber(updateType)),
        onNotificationToggle: () =>
            dispatch(notifActionTypes.onToggleNotifications()),
    };
};

export default connect(null, mapDispatchToProps)(Notification);
