import React, { Component } from 'react';
import { connect } from 'react-redux';
import imageLogo from '../../assets/images/logo-without-slogan.svg';
import NumberFormater from '../../service/number_formater.service';
import triangle from '../../assets/images/funeral-payment/navigation/triangle.svg';
class PaymentFlowHeader extends Component {
    state = {
        disagree: false,
    };

    render() {
        return (
            <div className="c-payment-flow-header">
                <a class="c-payment-flow-header__logo" href="/">
                    <img src={imageLogo} alt="logo" />
                </a>
                {this.props.showStepTrack && (
                    <div className="c-payment-flow-header__progress-wrapper">
                        <p>
                            Step{' '}
                            {this.props.currentStep === 0
                                ? '1'
                                : this.props.currentStep === 3 &&
                                  this.props.simplePlan
                                ? '2'
                                : this.props.currentStep === 4 &&
                                  this.props.simplePlan
                                ? '3'
                                : this.props.currentStep === 1
                                ? '2'
                                : this.props.currentStep === 2
                                ? '3'
                                : this.props.currentStep === 3
                                ? '4'
                                : this.props.currentStep === 4
                                ? '5'
                                : ''}
                            /{this.props.simplePlan ? '4' : '6'}{' '}
                        </p>
                        <div className="c-payment-flow-header__progress-bar-wrapper">
                            <div
                                className={`c-payment-flow-header__progress-bar ${
                                    this.props.simplePlan &&
                                    this.props.currentStep === 0
                                        ? 'c-payment-flow-header__progress-bar--simple-step-1'
                                        : ''
                                } ${
                                    this.props.simplePlan &&
                                    this.props.currentStep === 3
                                        ? 'c-payment-flow-header__progress-bar--simple-step-2'
                                        : ''
                                } ${
                                    this.props.simplePlan &&
                                    this.props.currentStep === 4
                                        ? 'c-payment-flow-header__progress-bar--simple-step-3'
                                        : ''
                                } ${
                                    !this.props.simplePlan &&
                                    this.props.currentStep === 0
                                        ? 'c-payment-flow-header__progress-bar--step-1'
                                        : ''
                                } ${
                                    !this.props.simplePlan &&
                                    this.props.currentStep === 1
                                        ? 'c-payment-flow-header__progress-bar--step-2'
                                        : ''
                                }${
                                    !this.props.simplePlan &&
                                    this.props.currentStep === 2
                                        ? 'c-payment-flow-header__progress-bar--step-3'
                                        : ''
                                }${
                                    !this.props.simplePlan &&
                                    this.props.currentStep === 3
                                        ? 'c-payment-flow-header__progress-bar--step-4'
                                        : ''
                                }${
                                    !this.props.simplePlan &&
                                    this.props.currentStep === 4
                                        ? 'c-payment-flow-header__progress-bar--step-5'
                                        : ''
                                }`}
                            ></div>
                        </div>
                    </div>
                )}

                {/* Infinity number */}
                <div className="c-payment-flow-header__callus">
                    <p>Prefer to speak with us? Call us:</p>
                    <a href="tel:+448008085723"> 0800 808 5723</a>
                    <p>Mon - Fri: 9:00am - 5:30pm</p>
                </div>
                {/* This below is ammount for mobile devices */}
                {this.props.currentStep >= 0 && (
                    <div className="c-funeral__nav--amount">
                        <span>Total cost</span>
                        <p>£{NumberFormater.formatNumber(this.props.amount)}</p>
                        <img src={triangle} alt="triangle"></img>
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    amount: state.funeralPaymentReducer.total,
    currentStep: state.funeralPaymentReducer.currentStep,
});

export default connect(mapStateToProps)(PaymentFlowHeader);
