import React, { Component } from 'react';
import DateFormater from '../../../service/date_formater.service';
import ReactHtmlParser from 'react-html-parser';
import NumberFormater from '../../../service/number_formater.service';
import { Link } from 'react-router-dom';
export default class OrderHistoryItem extends Component {
    render() {
       


        return (
            <div className="c-order-history__item">
                <h4>
                    Policy number{' '}
                    {this.props.item.orderItems[0].userFuneralPlan.id}
                </h4>
                <div className="c-order-history__item--date o-flex o-flex-between">
                
                   
                    <span>
                        {DateFormater.formatDate(this.props.item.createdAt)}
                    </span>
                    {this.props.item.transactions &&
                    this.props.item.transactions[0] ? (
                        <p>
                            Paid with ****{' '}
                            {this.props.item.transactions[0].last4}
                        </p>
                    ) : null}
                </div>
                <Link
                    to={`/purchase/${this.props.item.uuid}`}
                    className="c-order-history__item--box o-flex position-relative"
                >
                    <img
                        src={
                            this.props.item.orderItems[0].userFuneralPlan
                                .planOption.image
                        }
                        alt="item-placeholder"
                    ></img>
                    <div className="c-order-history__item--desc o-flex o-flex-column">
               
                    {this.props.item.orderItems[0].userFuneralPlan.status === null ?
                    <div  style={{
                        marginBottom: `20px`,
                        display: 'block',
                    }}></div> :
                    <div className="c-order-history__item--cancel">Canceled</div>
                        }
                        <h3>
                            {ReactHtmlParser(
                                this.props.item.orderItems[0].userFuneralPlan
                                    .plan.title
                                    ? this.props.item.orderItems[0]
                                          .userFuneralPlan.plan.title
                                    : ''
                            )}
                        </h3>
                        <span>
                            {
                                this.props.item.orderItems[0].userFuneralPlan
                                    .planOption.title
                            }
                        </span>
                        <p>
                            {ReactHtmlParser(
                                this.props.item.orderItems[0].userFuneralPlan
                                    .planOption.description
                            )}
                        </p>
                    </div>
                    <div className="c-order-history__item--amount o-flex o-flex-column o-flex-middle position-relative">
                        <span style={{marginTop: '30px'}}>Total cost</span>
                        <span>
                            £
                            {NumberFormater.formatNumber(
                                this.props.item.orderItems[0].price
                            )}
                        </span>
                        <span>
                            {this.props.item.orderItems[0].userFuneralPlan
                                .rate === 1
                                ? 'One Payment'
                                : this.props.item.orderItems[0].userFuneralPlan
                                      .rate === 24
                                ? '24 Payments'
                                : '12 Payments'}
                        </span>
                    </div>
                </Link>
            </div>
        );
    }
}
