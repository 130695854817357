import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Component } from 'react';

import Slider from 'react-slick';
import classes from './SlickSlider.module.scss';

export default class SlickSliderComparison extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 5000,
            lazyLoad: true,
        };

        return (
            <div className={classes.Slick}>
                <Slider {...settings}>
                    {this.props.img1 && (
                        <div>
                            <div
                                className="background-image"
                                style={{
                                    backgroundImage: `url(${this.props.img1})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        </div>
                    )}
                    {this.props.img2 && (
                        <div>
                            <div
                                className="background-image"
                                style={{
                                    backgroundImage: `url(${this.props.img2})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        </div>
                    )}
                    {this.props.img3 && (
                        <div>
                            <div
                                className="background-image"
                                style={{
                                    backgroundImage: `url(${this.props.img3})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        </div>
                    )}
                </Slider>
            </div>
        );
    }
}
