import React, { Component } from 'react'
import { connect } from 'react-redux';
import Loader from '../../../components/UI/Loader/Loader';
import * as routerActionTypes from '../../../store/actions/routerActions'

class Redirect extends Component {

    componentDidMount() { 
        if (!localStorage.getItem('token')) {
            this.props.onSetCurrentRoute(this.props.history.location.pathname)
            this.props.history.push('/login?redirect=true')
        }

        if (this.props.userSlug) {
          this.props.history.push(`/my-aura/${this.props.userSlug}/practical-information`)
        }
     }

    componentDidUpdate(prevProps, prevState) { 
        if ((prevProps.userSlug !== this.props.userSlug) || this.props.userSlug) {
            this.props.history.push(`/my-aura/${this.props.userSlug}/practical-information`)
        }
    } 

  render() {
    return (
      <Loader />
    )
  }
}

const mapStateToProps = state => ({
    userSlug: state.usersReducer.userSlug,
  })

const mapDispatchToProps = dispatch => {
  return {
      onSetCurrentRoute: (route) => dispatch(routerActionTypes.onSetCurrentRoute(route)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redirect)

