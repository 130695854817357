import { connect } from 'react-redux';
import React, { Component } from 'react';
import NumberFormater from '../../../service/number_formater.service';
import triangle from '../../../assets/images/funeral-payment/navigation/triangle.svg';

class Amount extends Component {
    render() {
        return this.props.currentStep ? (
            <div className="c-funeral__nav--amount">
                <span>Total cost</span>
                <p>£{NumberFormater.formatNumber(this.props.amount)}</p>
                <img src={triangle} alt="triangle"></img>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state) => ({
    amount: state.funeralPaymentReducer.total,
    currentStep: state.funeralPaymentReducer.currentStep,
});

export default connect(mapStateToProps)(Amount);
