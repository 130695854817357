import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as funeralPaymentActionTypes from '../../../store/actions/funeralPaymentActions';
import ApiService from '../../../service/api.service';
import loader from '../../../assets/images/loader.svg';
import ReactPixel from 'react-facebook-pixel';

class Navigation extends Component {
    handleGoBack = () => {
        if (window.location.href.includes('simple')) {
            let int = this.props.funeralPayment.currentStep === 3 ? 3 : 1;
            this.props.onSetCurrentStep(
                this.props.funeralPayment.currentStep - int
            );
            ApiService.getTotalPrice(this.props.funeralPayment.uuid).then(
                (r) => {
                    this.props.onSetTotal(r.data.total, r.data.subtotal);
                }
            );
        } else {
            this.props.onSetCurrentStep(
                this.props.funeralPayment.currentStep - 1
            );
            ApiService.getTotalPrice(this.props.funeralPayment.uuid).then(
                (r) => {
                    this.props.onSetTotal(r.data.total, r.data.subtotal);
                }
            );
        }
    };

    handleContinue2 = () => {
        if (this.props.funeralPayment?.currentStep === 0) {
            ReactPixel.init('218942439488342');
            ReactPixel.track('Initiate checkout');
        }

        // if (this.props.funeralPayment?.currentStep === 3) {
        //     ReactPixel.init('218942439488342');
        //     ReactPixel.track('Contact');
        // }

        // if (this.props.funeralPayment?.currentStep === 4) {
        //     ReactPixel.init('218942439488342');
        //     ReactPixel.track('InitiateCheckout');
        // }
    };

    handleContinue = (e) => {
        // if (this.props.funeralPayment?.currentStep === 1) {
        //     ReactPixel.init('218942439488342');
        //     ReactPixel.track('AddToWishlist');
        // }

        // if (this.props.funeralPayment?.currentStep === 2) {
        //     ReactPixel.init('218942439488342');
        //     ReactPixel.track('AddToWishlist');
        // }

        if (this.props.funeralPayment?.currentStep) {
            if (!this.props.stepUpWait) {
                this.props.onSetCurrentStep(
                    this.props.funeralPayment.currentStep + 1
                );
            }
        } else {
            e.submit();
        }

        if (!this.props.setOption) {
            ApiService.getTotalPrice(this.props.funeralPayment.uuid).then(
                (r) => {
                    this.props.onSetTotal(r.data.total, r.data.subtotal);
                }
            );
        }

        if (this.props.setOption) {
            this.props.setOption();
        }

        if (
            this.props.funeralPayment?.currentStep === 2 &&
            (this.props.funeralPayment?.totalStep === 2 ||
                this.props.funeralPayment?.totalStep === 1)
        ) {
            const data = {
                step: 3,
            };
            ApiService.funeralPlanStepUpdate(
                this.props.funeralPayment.uuid,
                data
            ).then((r) => {
                this.props.onSetTotalStep(3);
            });
        }
    };

    render() {
        return (
            <div
                className={`c-funeral__bottom-nav ${
                    this.props.backHidden ? 'o-flex o-flex-end' : ''
                }`}
            >
                {!this.props.backHidden ? (
                    <button
                        className="c-funeral__bottom-nav--back o-d-inline-block btn text-center btn--padding-left-right"
                        onClick={() => this.handleGoBack()}
                    >
                        Back
                    </button>
                ) : null}
                {this.props.submit ? (
                    !this.props.hideNext ? (
                        <button
                            type="submit"
                            className={`c-funeral__bottom-nav--next o-d-inline-block btn btn--full btn--size-59 btn--size-width-199 text-center btn--padding-left-right btn-loader ${
                                this.props.buttonLoading
                                    ? 'save-in-progress'
                                    : ''
                            }`}
                            onClick={() => this.handleContinue2()}
                        >
                            Next{' '}
                            <img
                                width="100%"
                                height="100%"
                                src={loader}
                                alt="loaderImage"
                            />
                        </button>
                    ) : null
                ) : !this.props.hideNext ? (
                    <button
                        onClick={() => this.handleContinue()}
                        className={`c-funeral__bottom-nav--next o-d-inline-block btn btn--full btn--size-59 btn--size-width-199 text-center btn--padding-left-right btn-loader ${
                            this.props.buttonLoading ? 'save-in-progress' : ''
                        }`}
                    >
                        {this.props.funeralPayment.totalStep === 2
                            ? this.props.funeralPayment.subtotal > 0
                                ? 'Next'
                                : this.props.funeralPayment.currentStep === 1
                                ? 'Next'
                                : 'Decide later'
                            : 'Next'}
                        <img
                            width="100%"
                            height="100%"
                            src={loader}
                            alt="loaderImage"
                        />
                    </button>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
    funeralPayment: state.funeralPaymentReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCurrentStep: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(currentStep)),
        onSetTotal: (total, subtotal) =>
            dispatch(funeralPaymentActionTypes.onSetTotal(total, subtotal)),
        onSetTotalStep: (step) =>
            dispatch(funeralPaymentActionTypes.onSetTotalStep(step)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
