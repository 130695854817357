import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';
export default class FuneralPersonal extends Component {
    render() {
        let item;

        if (this.props.content.length) {
            item = this.props.content.map((item, index) => {
                return (
                    <div
                        key={`key-feature-${index}`}
                        className="c-plans__angels--item"
                    >
                        <img src={item.image} alt="key-feature-respect" />
                        <h4>{ReactHtmlParser(item.title ? item.title : '')}</h4>
                        <p>{ReactHtmlParser(item.body ? item.body : '')}</p>
                    </div>
                );
            });
        }

        return this.props.content.length ? (
            <div className="light-blue-background">
                <div className="c-plans__angels c-angels c-plans__funeral-personal container mt-50">
                    <h2>
                        {ReactHtmlParser(
                            this.props.title ? this.props.title : ''
                        )}
                    </h2>
                    <p>
                        {ReactHtmlParser(
                            this.props.body ? this.props.body : ''
                        )}
                    </p>
                    <div className="c-plans__angels--items">{item}</div>
                </div>
            </div>
        ) : null;
    }
}
