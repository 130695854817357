export const SET_STEP = 'SET_STEP';
export const STEP_UP = 'STEP_UP';
export const UNLOCK_TRUSTED_BUTTON = 'UNLOCK_TRUSTED_BUTTON';
export const UNLOCK_FAMILY_BUTTON = 'UNLOCK_FAMILY_BUTTON';

//dispatch changes
export const setStep = (step) => {
    return {
        type: SET_STEP,
        step: step,
    };
};

export const stepUp = () => {
    return {
        type: STEP_UP,
    };
};

export const unlockTrustedButton = () => {
    return {
        type: UNLOCK_TRUSTED_BUTTON,
    };
};

export const unlockFamilyButton = () => {
    return {
        type: UNLOCK_FAMILY_BUTTON,
    };
};

//dispatch actions
export const onSetStep = (payload) => {
    return (dispatch) => {
        dispatch(setStep(payload));
    };
};

export const onStepUp = () => {
    return (dispatch) => {
        dispatch(stepUp());
    };
};

export const onUnlockFamilyButton = () => {
    return (dispatch) => {
        dispatch(unlockFamilyButton());
    };
};

export const onUnlockTrustedButton = () => {
    return (dispatch) => {
        dispatch(unlockTrustedButton());
    };
};
