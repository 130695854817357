import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';

export default class SingleItem extends Component {
    render() {
        return (
            <div className="c-plans__plan-slider--slide">
                <div>
                    <h4>{this.props.item.title}</h4>
                    <p>
                        {ReactHtmlParser(
                            this.props.item.body ? this.props.item.body : ''
                        )}
                    </p>
                </div>
                <div className="c-plans__plan-slider--slide-img">
                    <img src={this.props.item.image} alt="Slide item" />
                </div>
            </div>
        );
    }
}
