import * as notifActionTypes from '../../../../store/actions/notificationActions';
import * as userActionTypes from '../../../../store/actions/usersActions';

import React, { Component } from 'react';

import ApiService from '../../../../service/api.service';
import Loader from '../../../UI/Loader/Loader';
import Notification from '../Notification/Notification';
import { connect } from 'react-redux';
import loader from '../../../../assets/images/loader.svg';
class NotificationList extends Component {
    state = {
        pageNumber: 1,
        itemsPerPage: 5,
        offset: 5,
        hasMore: false,
        loadMoreLoading: false,
    };

    componentDidMount() {
        this.props.onSetNotificationLoading(true);
        this.loadInitialNotifications();
    }

    // initial load
    loadInitialNotifications() {
        const searchParams = new URLSearchParams();
        searchParams.append('_page', this.state.pageNumber);
        searchParams.append('itemsPerPage', this.state.itemsPerPage);

        ApiService.usersNotifications(this.props.user.id, searchParams).then(
            (r) => {
                this.props.onSetNotifications(r.data);
                this.props.onSetNotificationLoading(false);
                this.setState({ hasMore: r.data.length === this.state.offset });
                this.markAsReadLoadedNotifications(r.data);
            }
        );
    }

    // infinite scroll load
    loadNotifications = (pageNumber) => {
        this.setState({ loadMoreLoading: true }, () => {
            const searchParams = new URLSearchParams();
            searchParams.append('_page', pageNumber);
            searchParams.append('itemsPerPage', this.state.itemsPerPage);

            ApiService.usersNotifications(
                this.props.user.id,
                searchParams
            ).then((r) => {
                this.props.onAppendNotifications(r.data);
                this.markAsReadLoadedNotifications(r.data);
                this.setState(
                    {
                        offset: this.state.offset + this.state.itemsPerPage,
                        pageNumber: pageNumber,
                    },
                    () => {
                        this.setState({
                            hasMore:
                                this.state.offset ===
                                this.props.notifications.notifications.length,
                            loadMoreLoading: false,
                        });
                    }
                );
            });
        });
    };

    markAsReadLoadedNotifications(notifs) {
        const newNotifications = notifs.filter((n) => n.new);
        const newNotifArray = [];
        newNotifications.forEach((nn) => {
            newNotifArray[nn.id] = false;
            ApiService.updateNotification(nn.id, {
                new: false,
            }).then(() => {
                newNotifArray[nn.id] = true;
            });
        });
    }

    render() {
        let notifications = this.props.notifications.notifications.map(
            (notification, index) => (
                <Notification
                    key={`notification-${index}`}
                    notification={notification}
                />
            )
        );

        return (
            <>
                {!this.props.notifications.loading && notifications.length ? (
                    <div id="notifParent">
                        {notifications}
                        {this.state.hasMore ? (
                            <div className="o-flex o-flex-center">
                                <button
                                    className={`btn--size-54 btn btn--ghost-purple btn--double-border btn--size-width-339 margin-reset btn-loader ${
                                        this.state.loadMoreLoading
                                            ? 'save-in-progress'
                                            : ''
                                    } position-relative overflow-both-hidden z-index-1`}
                                    onClick={() =>
                                        this.loadNotifications(
                                            this.state.pageNumber + 1
                                        )
                                    }
                                >
                                    Load More{' '}
                                    <img
                                        width="100%"
                                        height="100%"
                                        src={loader}
                                        alt="loaderImage"
                                    />
                                </button>
                            </div>
                        ) : null}
                    </div>
                ) : null}
                {this.props.notifications.loading ? <Loader /> : null}
                {this.props.notifications.notifications.length === 0 &&
                !this.props.notifications.loading ? (
                    <div className="c-zero-state text-center margin-reset">
                        <i className="icon-notification margin-auto o-flex o-flex-center o-flex-middle border-radius-50 font-333 opacity-5"></i>
                        <p className="font-500 font-18 font-333 opacity-9">
                            No notifications
                        </p>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notificationsReducer,
    user: state.usersReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetNotifications: (notifications) =>
            dispatch(notifActionTypes.onSetNotifications(notifications)),
        onSetNotificationLoading: (loading) =>
            dispatch(notifActionTypes.onSetNotificationLoading(loading)),
        onAppendNotifications: (notifications) =>
            dispatch(notifActionTypes.onAppendNotifications(notifications)),
        onUpdateNotifCount: (notifReadCount) =>
            dispatch(userActionTypes.onUpdateNotifCount(notifReadCount)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
