import React, { useEffect, useState, useRef } from 'react';

import { useField } from 'formik';

const RequiredInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [focused, setFocused] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [filled, setFilled] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (field.value) {
            inputRef.current.focus();
            inputRef.current.blur();
            setFocused(false);
            setInvalid(false);
            setFilled(true);
        } else {
            setFilled(false);
        }

        setTimeout(() => {
            if (inputRef.current && inputRef.current.value) {
                setFocused(false);
                setInvalid(false);
                setFilled(true);
            }

            if (
                inputRef.current &&
                inputRef.current.matches(':-internal-autofill-selected')
            ) {
                setFilled(true);
            }
        }, 300);

        if (props.ignoreBrowser) {
            setInterval(() => {
                if (
                    inputRef.current &&
                    inputRef.current.value.length === 0 &&
                    !inputRef.current.matches(':-internal-autofill-selected')
                ) {
                    setFilled(false);
                }
            }, 1000);
        }
        // eslint-disable-next-line
    }, []);

    const onFocus = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
        setInvalid(false);
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
        if (field.value) {
            setInvalid(false);
            setFilled(true);
        } else {
            setInvalid(true);
            setFilled(false);
        }
    };

    let returnValue = (
        <>
            <div
                onClick={() => onFocus()}
                onBlur={() => onBlur()}
                tabIndex="-1"
                className={`c-input c-input__wrap ${
                    (meta.error && meta.touched) || invalid
                        ? 'c-input__invalid'
                        : ''
                } ${focused ? 'c-input__focused' : ''} ${
                    filled ? 'c-input__filled' : ''
                }`}
            >
                {label ? (
                    <label className="c-input__label">
                        {label}
                        <span className="c-input__label--required">*</span>
                    </label>
                ) : null}
                <input
                    onFocus={() => onFocus()}
                    ref={inputRef}
                    form="novalidatedform"
                    {...field}
                    {...props}
                ></input>
                {meta.touched && meta.error ? (
                    <div className="c-input__error-message">{meta.error}</div>
                ) : null}
            </div>
        </>
    );

    return returnValue;
};

export default RequiredInput;
