import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
export default class PlanRatings extends Component {
    render() {
        return (
            <div className="c-plan-ratings">
                <h2>
                    {ReactHtmlParser(this.props.title ? this.props.title : '')}
                </h2>
                <p>{ReactHtmlParser(this.props.body ? this.props.body : '')}</p>
            </div>
        );
    }
}
