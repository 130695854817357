import React, { Component } from 'react';
import infinitySign from '../../../assets/images/aura2022/infinityHpSign-gray.svg';
import ReactHtmlParser from 'react-html-parser';

class Introduction extends Component {
    render() {
        return (
            <div className="position-relative overflow-both-hidden">
                <img
                    src={infinitySign}
                    alt="Aura Sign"
                    className="c-introduction--infinity"
                />
                <div className="c-introduction container">
                    {this.props.title && <h2>{this.props.title}</h2>}
                    <div className="text-center">
                        <div className="c-introduction--txt">
                            {/* {this.props.content.title && (
                                <h3>{this.props.content.title}</h3>
                            )} */}
                            {this.props.content.description && (
                                <p>
                                    {ReactHtmlParser(
                                        this.props.content.description
                                    )}
                                </p>
                            )}
                            {this.props.content.image && (
                                <div
                                    className="c-introduction--img"
                                    style={{
                                        backgroundImage: `url(${this.props.content.image})`,
                                        backgroundPosition: 'center',
                                    }}
                                ></div>
                            )}

                            {this.props.content.imageEditor && (
                                <div className="c-introduction__ck-editor">
                                    {ReactHtmlParser(
                                        this.props.content.imageEditor
                                    )}
                                </div>
                            )}

                            <div className="c-introduction--cta">
                                {this.props.content.cta1Title && (
                                    <a
                                        href={
                                            !this.props.content.cta1Link
                                                ? 'tel:+448008085723'
                                                : this.props.content.cta1Link &&
                                                  !this.props.content.cta1Link.includes(
                                                      'tel'
                                                  )
                                                ? this.props.content.cta1Link
                                                : this.props.content.cta1Link.includes(
                                                      'tel'
                                                  )
                                                ? `tel:${this.props.content.cta1Link.replace(
                                                      'http://tel:',
                                                      ''
                                                  )}`
                                                : ''
                                        }
                                        className="c-btn btn btn--full btn--size-width-241 btn--size-57 text-center o-d-inline-block"
                                    >
                                        {this.props.content.cta1Title}
                                    </a>
                                )}
                                {this.props.content.cta2Title && (
                                    <a
                                        href={
                                            !this.props.content.cta2Link
                                                ? 'tel:+448008085723'
                                                : this.props.content.cta2Link &&
                                                  !this.props.content.cta2Link.includes(
                                                      'tel'
                                                  )
                                                ? this.props.content.cta2Link
                                                : this.props.content.cta2Link.includes(
                                                      'tel'
                                                  )
                                                ? `tel:${this.props.content.cta2Link.replace(
                                                      'http://tel:',
                                                      ''
                                                  )}`
                                                : ''
                                        }
                                        className="c-btn btn btn--full btn--size-width-241 btn--size-57 text-center o-d-inline-block"
                                    >
                                        {this.props.content.cta2Title}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Introduction;
