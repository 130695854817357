import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class FcaRegulation extends Component {
    state = {
        isQuestionOpen: false,
    };

    handleQuestionOpen = () => {
        this.setState({ isQuestionOpen: !this.state.isQuestionOpen });
    };

    render() {
        return (
            <div className="light-yellow-background fca-accordion">
                <div className="c-fca-regulation container">
                    <div className="c-faq-question">
                        <div
                            className="c-faq-question__header cursor-pointer position-relative o-flex o-flex-between o-flex-top"
                            onClick={this.handleQuestionOpen}
                        >
                            <div className="c-fca-regulation__header">
                                {this.props.title && (
                                    <h3>{this.props.title}</h3>
                                )}
                                <p>Find out what this means for you</p>
                            </div>

                            <span
                                onClick={this.handleQuestionOpen}
                                className={`c-faq-question__header--icon position-relative c-faq-question__header--icon-body-${
                                    this.state.isQuestionOpen
                                        ? 'revealed'
                                        : 'hidden'
                                }`}
                            ></span>
                        </div>
                        <div
                            className={`c-faq-question__body font-333 font-18 font-normal lh-28 ${
                                this.state.isQuestionOpen ? 'visible' : ''
                            }`}
                        >
                            <hr />
                            {this.props.description && (
                                <p>
                                    {ReactHtmlParser(this.props.description)}{' '}
                                </p>
                            )}
                            <div className="c-fca-regulation__comparison-accordion-table-wrapper">
                                <div>
                                    {ReactHtmlParser(
                                        this.props.content.tableEditor
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FcaRegulation;
