import * as actionTypes from '../actions/notificationActions';

const initialState = {
    notifications: [],
    notificationsOpen: false,
    loading: false,
    notificationCount: 0,
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_NOTIFICATIONS:
            const newState = { ...state };
            newState.notificationsOpen = !state.notificationsOpen;
            return newState;
        case actionTypes.SET_NOTIFICATIONS:
            const newNotificationsState = { ...state };
            newNotificationsState.notifications = action.notifications;
            return newNotificationsState;
        case actionTypes.SET_ALL_READ:
            const notificationState = [...state.notifications].map(
                (notification) => ({ ...notification, new: false })
            );
            return {
                ...state,
                notifications: notificationState,
                notificationCount: 0,
            };

        case actionTypes.NOTIFICATION_COUNT_SUBSCRIBE:
            return {
                ...state,
                notificationCount: parseInt(action.notificationCount),
            };
        case actionTypes.SET_DELETE:
            const deletedNotification = [...state.notifications].filter(
                (notification) => action.notificationId !== notification.id
            );
            return {
                ...state,
                notifications: deletedNotification,
            };
        case actionTypes.SET_CONNECTION:
            const removeAddedNotification = [...state.notifications].filter(
                (notification) => action.notificationId !== notification.id
            );
            return {
                ...state,
                notifications: removeAddedNotification,
            };
        case actionTypes.LOADING_NOTIFICATIONS:
            return {
                ...state,
                loading: action.loading,
            };
        case actionTypes.APPEND_NOTIFICATIONS:
            const notifs = [...state.notifications, ...action.notifications];

            return {
                ...state,
                notifications: notifs,
            };
        case actionTypes.ACCEPT_REQUEST_FROM_NOTIF:
            const notifsWithStatusUpdated = state.notifications.map((n) => ({
                ...n,
                connection: {
                    ...n.connection,
                    status:
                        n.id === action.notifId ? action.connectionStatus : 1,
                },
            }));

            return {
                ...state,
                notifications: notifsWithStatusUpdated,
            };
        default:
            return state;
    }
};

export default notificationsReducer;
