import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';
export default class SingleItem extends Component {
    render() {
        return (
            <div
                className={`o-flex c-plans__how-it-works--item ${
                    this.props.leftSide ? 'how' : ''
                }`}
            >
                {/* <img src={this.props.item.image}/> */}
                <div
                    style={{ backgroundImage: `url(${this.props.item.image})` }}
                ></div>
                <h4>{this.props.item.title ? this.props.item.title : ''}</h4>
                <p>
                    {ReactHtmlParser(
                        this.props.item.body ? this.props.item.body : ''
                    )}
                </p>
            </div>
        );
    }
}
