import * as notifActionTypes from '../../../store/actions/notificationActions';
import * as userActionTypes from '../../../store/actions/usersActions';

import React, { Component } from 'react';

import ApiService from '../../../service/api.service';
import NotificationList from './NotificationList/NotificationList';
import { connect } from 'react-redux';

class Notifications extends Component {
    handleMarkAll = () => {
        ApiService.markAllRead().then((r) => {
            this.props.onSetAllRead();
        });
    };

    handleCloseNotifications() {
        this.props.onNotificationToggle();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.notifications.notificationsOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }

    render() {
        return (
            <>
                <div className="c-notifications__header o-flex o-flex-column o-flex-bottom">
                    <button
                        aria-label="notification-close"
                        onClick={() => this.handleCloseNotifications()}
                        className="c-notifications__close-btn btn btn--no-border btn--size-auto"
                    >
                        <i className="icon-times-thin font-13"></i>
                    </button>
                    <div className="c-notifications__header--notification-title o-flex o-flex-between o-flex-end width-full mt-20 mb-10">
                        <span>Notification Feed</span>
                        <button
                            onClick={() => this.handleMarkAll()}
                            className="btn btn--no-border"
                        >
                            Mark all as read
                        </button>
                    </div>
                </div>
                <div className="c-notifications__body">
                    {this.props.notifications.notificationsOpen ? (
                        <NotificationList />
                    ) : null}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notificationsReducer,
    user: state.usersReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onAppendNotifications: (notifications) =>
            dispatch(notifActionTypes.onAppendNotifications(notifications)),
        onSetAllRead: () => dispatch(notifActionTypes.onSetAllRead()),
        onSetNotificationLoading: (loading) =>
            dispatch(notifActionTypes.onSetNotificationLoading(loading)),
        onNotificationToggle: () =>
            dispatch(notifActionTypes.onToggleNotifications()),
        onUpdateNotifCount: (notifReadCount) =>
            dispatch(userActionTypes.onUpdateNotifCount(notifReadCount)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
