import React, { Component } from 'react';
import ApiService from '../../service/api.service';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import PageMeta from '../../components/UI/PageMeta/PageMeta';
import Footer from '../../components/UI/Footer/Footer';
import Introduction from '../../components/Blocks/Introduction/Introduction';
import Origin from '../../components/Blocks/Origin/Origin';
import Brochure from '../../components/Blocks/Brochure/Brochure';
import NotFound from '../../pages/Routing/404/404';
import Loader from '../../components/UI/Loader/Loader';
import HowItWorks from '../../components/FuneralPlans/HowItWorks/HowItWorks';
import FuneralPersonal from '../../components/FuneralPlans/FuneralPersonal/FuneralPersonal';
import FuneralInspiration from '../../components/FuneralPlans/FuneralInspiration/FuneralInspiration';
import Testimonials from '../../components/FuneralPlans/Testimonials/Testimonials';
import Plans from '../../components/FuneralPlans/Plans/Plans';
import PlansFaq from '../../components/FuneralPlans/PlansFaq/PlansFaq';
import PlanIncludes from '../../components/FuneralPlans/PlanIncludes/PlanIncludes';
import PlanRatings from '../../components/FuneralPlans/PlanRatings/PlanRatings';
import TrustSection from '../../components/FuneralPlans/TrustSection/TrustSection';
import Carousel23 from '../../components/FuneralPlans/Carousel/Carousel23/Carousel23';
import SimpleReactLightbox from 'simple-react-lightbox';
import Carousel from '../../components/FuneralPlans/Carousel/Carousel';
import Angels from '../../components/FuneralPlans/Angels/Angels';
import Westerleigh from '../../components/Blocks/Westerleigh/Westerleight';
import PlanCost from '../../components/Blocks/PlanCost/PlanCost';
import AsSeenIn from '../../components/Blocks/AsSeenIn/AsSeenIn';
import TeamContact from '../../components/Blocks/TeamContact/TeamContact';
import ContactBlock from '../../components/Blocks/ContactBlock/ContactBlock';
import Trustpilot from '../../components/Blocks/Trustpilot/Trustpilot';
import Location from '../../components/Blocks/Location/Location';
import CustomerService from '../../components/Blocks/CustomerService/CustomerService';
import HowItWorksNew from '../../components/Blocks/HowItWorksNew/HowItWorksNew';
import Comparison from '../../components/Blocks/Comparison/Comparison';
import FcaRegulation from '../../components/Blocks/FcaRegulation/FcaRegulation';
import WISnipet from '../../components/Blocks/WISnipet/WISnipet';
import MultipleVideo from '../../components/FuneralPlans/MultipleVideo/MultipleVideo';
import Extras from '../../components/FuneralAtNeed/Extras';
import ComparisonAccordion from '../../components/Blocks/ComaprisonAccordion/ComparisonAccordion';
import TrustWorks from '../../components/FuneralPlans/TrustWorks/TrustWorks';
import AmendComponent from '../../components/Blocks/Amends/Amends';
import StickyFooter from '../../components/UI/StickyFooter/StickyFooter';
import LocationsBlock from '../../components/Blocks/LocationsBlock/LocationsBlock';
import SchemaOrg from '../../components/SchemaOrg/SchemaOrg';
class CustomPage extends Component {
    state = {
        content: null,
        error: '',
        pageShown: true,
        resultList: [],
        pageTitle: '',
        title: '',
        banner: [],
        metaData: [],
    };
    constructor(props) {
   
        super(props);
        this.trustBoxRef1 = React.createRef();
     
          }
    componentDidMount() {
        // const reloadCount = sessionStorage.getItem('reloadCount');
        // if(reloadCount < 2) {
        //   sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        //   window.location.reload();
        // } else {
        //   sessionStorage.removeItem('reloadCount');
        // }
        const searchParams = new URLSearchParams(
            this.props.history.location.search
        );
        this.setState({
            title: searchParams.getAll('title')[0],
        });

        //This is how to get slug from plan
        let plan = this.props.match.params.custom;

        ApiService.getBanners(plan).then((r) => {
            this.setState({
                banner: r.data,
            });
        });

        ApiService.getFuneralPlans(plan)
            .then((r) => {
                this.setState({
                    content: r.data,
                    pageShown: r.data.page,
                    pageTitle: r.data.title,
                    metaData: r.data.planMetaPublished,
                });
                console.log('test');
            })
            .then(() => {
                const list = [
                    {
                        weight: this.state.content?.howItWorksWeight,
                        name: 'Name1',
                    },
                    {
                        weight: this.state.content?.personalWeight,
                        name: 'Name2',
                    },
                    {
                        weight: this.state.content?.inspirationWeight,
                        name: 'Name3',
                    },
                    {
                        weight: this.state.content?.testimonialWeight,
                        name: 'Name4',
                    },
                    {
                        weight: this.state.content?.planCompareWeight,
                        name: 'Name5',
                    },
                    {
                        weight: this.state.content?.faqWeight,
                        name: 'Name6',
                    },
                    {
                        weight: this.state.content?.ratingWeight,
                        name: 'Name7',
                    },
                    {
                        weight: this.state.content?.trustWeight,
                        name: 'Name8',
                    },
                    {
                        weight: this.state.content?.multiStepBlockWeight,
                        name: 'Name9',
                    },
                    {
                        weight: this.state.content?.carouselWeight,
                        name: 'Name10',
                    },
                    {
                        weight: this.state.content?.includedIntroWeight,
                        name: 'Name11',
                    },
                    {
                        weight: this.state.content?.angelsIntroWeight,
                        name: 'Name12',
                    },
                    {
                        weight: this.state.content?.introductionWeight,
                        name: 'Name13',
                    },
                    {
                        weight: this.state.content?.brochureWeight,
                        name: 'Name14',
                    },
                    {
                        weight: this.state.content?.auraOriginWeight,
                        name: 'Name15',
                    },
                    {
                        weight: this.state.content?.asSeenInWeight,
                        name: 'Name16',
                    },
                    {
                        weight: this.state.content?.contactTeamWeight,
                        name: 'Name17',
                    },
                    {
                        weight: this.state.content?.trustpilotWeight,
                        name: 'Name18',
                    },
                    {
                        weight: this.state.content?.contactSectionWeight,
                        name: 'Name19',
                    },
                    {
                        weight: this.state.content?.customerServiceWeight,
                        name: 'Name20',
                    },
                    {
                        weight: this.state.content?.costWeight,
                        name: 'Name21',
                    },
                    {
                        weight: this.state.content?.productComparisonWeight,
                        name: 'Name22',
                    },
                    {
                        weight: this.state.content?.ourLocationsWeight,
                        name: 'Name23',
                    },
                    {
                        weight: this.state.content?.westerleighWeight,
                        name: 'Name24',
                    },
                    {
                        weight: this.state.content?.howItWorksWeight2,
                        name: 'Name25',
                    },
                    {
                        weight: this.state.content?.wonderfullyImagineWeight,
                        name: 'Name26',
                    },
                    {
                        weight: this.state.content?.extraWeight,
                        name: 'Name27',
                    },
                    {
                        weight: this.state.content?.fcaRegulationWeight,
                        name: 'Name28',
                    },
                    {
                        weight: this.state.content?.comparisonAccordionWeight,
                        name: 'Name29',
                    },
                    {
                        weight: this.state.content?.keyFeaturesWeight,
                        name: 'Name30',
                    },
                    {
                        weight: this.state.content?.peaceOfMindWeight,
                        name: 'Name31',
                    },
                    {
                        weight: this.state.content?.locationWeight,
                        name: 'Name32',
                    },
                ];
                // const resultList = list.sort((a,b) => a.name.localeCompare(b.name))
                const result = list.sort((a, b) => a.weight - b.weight);

                this.setState({
                    resultList: result,
                });
            })

            .catch((e) => {
                this.setState({
                    error: e.message,
                });
            });
    }

    updateBannerVisibilty = () => {
        this.setState({ banner: [] });
    };

    render() {
        const notFound = this.state.error.includes('404');
        const pageSwitchedOn = this.state.pageShown;
        const tabTitle = this.state.pageTitle
            .replace('<div>', '')
            .replace('</div>', '');

            console.info(this.state.content);

            const stickyFooter = this.state.content?.stickyFooterPublished[0];

            return (
                <>
                    {this.state.pageTitle && (
                        <PageMeta title={`${tabTitle} | Aura`} />
                    )}
                    {this.state.metaData?.length > 0 ? (
                        <SchemaOrg content={this.state.metaData} />
                    ) : null}

                    {notFound || !pageSwitchedOn ? (
                        <NotFound />
                    ) : !this.state.content ? (
                        <Loader />
                    ) : (
                        <>
                            <Header
                                bannerContent={
                                    this.state.banner?.length > 0 &&
                                    this.state.banner?.[0]
                                }
                                bannerVisible={this.state.banner?.length > 0}
                                hideBanner={this.updateBannerVisibilty}
                            />
                            {this.state.content?.headersPublished.length >
                                0 && (
                                <MultipleVideo
                                    hasBanner={this.state.banner?.length > 0}
                                    horizontalVideo={
                                        this.state.content?.headersPublished[0]
                                            .horizontalVideo
                                    }
                                    verticalVideo={
                                        this.state.content?.headersPublished[0]
                                            .verticalVideo
                                    }
                                    popupVideo={
                                        this.state.content?.headersPublished[0]
                                            .videoLink
                                    }
                                    horizontalImage={
                                        this.state.content?.headersPublished[0]
                                            .horizontalImage
                                    }
                                    verticalImage={
                                        this.state.content?.headersPublished[0]
                                            .verticalImage
                                    }
                                    // title={
                                    //     this.state.content?.headersPublished[0].title
                                    // }
                                    title={
                                        window.location.href.includes('?title')
                                            ? this.state.title
                                            : this.state.content
                                                  ?.headersPublished[0].title
                                    }
                                    description={
                                        this.state.content?.headersPublished[0]
                                            ? this.state.content
                                                  ?.headersPublished[0]
                                                  .fullDescription
                                            : null
                                    }
                                    price={
                                        this.state.content?.headersPublished[0]
                                            .titlePrice
                                    }
                                    priceInfo={
                                        this.state.content?.headersPublished[0]
                                            .priceInfo
                                    }
                                    ctaLink={
                                        this.state.content?.headersPublished[0]
                                            .ctaLink
                                    }
                                    ctaPopup={
                                        this.state.content?.headersPublished[0]
                                            .ctaPopup
                                    }
                                    ctaTitle={
                                        this.state.content?.headersPublished[0]
                                            .ctaTitle
                                    }
                                    requestLink={
                                        this.state.content?.headersPublished[0]
                                            .requestLink
                                    }
                                    requestTitle={
                                        this.state.content?.headersPublished[0]
                                            .requestTitle
                                    }
                                    ratingImages={
                                        this.state.content
                                            ?.headerRatingsPublished
                                    }
                                    type="custom-page"
                                />
                            )}
                            <div className="c-plans c-funerals">
                                {this.state.resultList.map((item) => {
                                    return (
                                        <>
                                            {item.name === 'Name1' &&
                                            this.state.content
                                                .howItWorksPublished.length >
                                                0 &&
                                            this.state.content
                                                .howItWorksVisible ? (
                                                <HowItWorks
                                                    title={
                                                        this.state.content
                                                            ?.howItWorksTitle
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.howItWorksPublished
                                                    }
                                                />
                                            ) : item.name === 'Name2' &&
                                              this.state.content
                                                  .personalItemsPublished
                                                  .length > 0 &&
                                              this.state.content
                                                  .personalIntroVisible ? (
                                                <FuneralPersonal
                                                    body={
                                                        this.state.content
                                                            ?.personalIntroBody
                                                    }
                                                    title={
                                                        this.state.content
                                                            ?.personalIntroTitle
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.personalItemsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name3' &&
                                              this.state.content
                                                  .inspirationsPublished
                                                  .length > 0 &&
                                              this.state.content
                                                  .inspirationVisible ? (
                                                <FuneralInspiration
                                                    title={
                                                        this.state.content
                                                            ?.inspirationTitle
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.inspirationsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name4' &&
                                              this.state.content
                                                  .testimonialsPublished
                                                  .length > 0 &&
                                              this.state.content
                                                  .testimonialVisible ? (
                                                <Testimonials
                                                    body={
                                                        this.state.content
                                                            ?.testimonialIntro
                                                    }
                                                    title={
                                                        this.state.content
                                                            ?.testimonialTitle
                                                    }
                                                    testimonials={
                                                        this.state.content
                                                            ?.testimonialsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name5' &&
                                              this.state.content
                                                  .planCompareVisible &&
                                              this.state.content
                                                  .comparisonPublished.length >
                                                  0 ? (
                                                <Plans
                                                    title={
                                                        this.state.content
                                                            ?.planCompareTitle
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.planCompareDescription
                                                    }
                                                    tableContent={
                                                        this.state.content
                                                            ?.comparisonPublished
                                                    }
                                                    planImage1={
                                                        this.state.content
                                                            ?.planCompareImage1
                                                    }
                                                    planImage2={
                                                        this.state.content
                                                            ?.planCompareImage2
                                                    }
                                                    planTitle1={
                                                        this.state.content
                                                            ?.planCompareTitle1
                                                    }
                                                    planTitle2={
                                                        this.state.content
                                                            ?.planCompareTitle2
                                                    }
                                                    planSubtitle1={
                                                        this.state.content
                                                            ?.planCompareSubtitle1
                                                    }
                                                    planSubtitle2={
                                                        this.state.content
                                                            ?.planCompareSubtitle2
                                                    }
                                                    planDescription1={
                                                        this.state.content
                                                            ?.planCompareDescription1
                                                    }
                                                    planDescription2={
                                                        this.state.content
                                                            ?.planCompareDescription2
                                                    }
                                                    planPrice1={
                                                        this.state.content
                                                            ?.planComparePrice1
                                                    }
                                                    planPrice2={
                                                        this.state.content
                                                            ?.planComparePrice2
                                                    }
                                                    planCtaTitle1={
                                                        this.state.content
                                                            ?.planCompareCtaTitle1
                                                    }
                                                    planCtaTitle2={
                                                        this.state.content
                                                            ?.planCompareCtaTitle2
                                                    }
                                                    planCtaLink1={
                                                        this.state.content
                                                            ?.planCompareCtaLink1
                                                    }
                                                    planCtaLink2={
                                                        this.state.content
                                                            ?.planCompareCtaLink2
                                                    }
                                                />
                                            ) : item.name === 'Name6' &&
                                              this.state.content.faqsPublished
                                                  .length > 0 &&
                                              this.state.content.faqVisible ? (
                                                <PlansFaq
                                                    title={
                                                        this.state.content
                                                            ?.faqTitle
                                                    }
                                                    body={
                                                        this.state.content
                                                            ?.faqBody
                                                    }
                                                    faqs={
                                                        this.state.content
                                                            ?.faqsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name7' &&
                                              this.state.content
                                                  .ratingVisible ? (
                                                <PlanRatings
                                                    title={
                                                        this.state.content
                                                            ?.ratingTitle
                                                    }
                                                    body={
                                                        this.state.content
                                                            ?.ratingBody
                                                    }
                                                    images={[
                                                        this.state.content
                                                            ?.ratingImage1,
                                                        this.state.content
                                                            ?.ratingImage2,
                                                        this.state.content
                                                            ?.ratingImage3,
                                                    ]}
                                                />
                                            ) : item.name === 'Name8' &&
                                              this.state.content.trustsPublished
                                                  .length > 0 &&
                                              this.state.content
                                                  .trustVisible ? (
                                                <TrustSection
                                                    body={
                                                        this.state.content
                                                            ?.trustIntro
                                                    }
                                                    title={
                                                        this.state.content
                                                            ?.trustTitle
                                                    }
                                                    trusts={
                                                        this.state.content
                                                            ?.trustsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name9' &&
                                              this.state.content
                                                  .multiStepsPublished.length >
                                                  0 &&
                                              this.state.content
                                                  .multiStepBlockVisible ? (
                                                <Carousel23
                                                    title={
                                                        this.state.content
                                                            ?.multiStepBlockTitle
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.multiStepsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name10' &&
                                              this.state.content
                                                  .carouselsPublished.length >
                                                  0 &&
                                              this.state.content
                                                  .carouselsVisible ? (
                                                <SimpleReactLightbox>
                                                    <Carousel
                                                        title={
                                                            this.state.content
                                                                ?.carouselTitle
                                                        }
                                                        content={
                                                            this.state.content
                                                                ?.carouselsPublished
                                                        }
                                                    />
                                                </SimpleReactLightbox>
                                            ) : item.name === 'Name11' &&
                                              this.state.content
                                                  .includedItemsPublished
                                                  .length > 0 &&
                                              this.state.content
                                                  .includedIntroVisible ? (
                                                <PlanIncludes
                                                    title={
                                                        this.state.content
                                                            ?.includedIntroTitle
                                                    }
                                                    body={
                                                        this.state.content
                                                            ?.includedIntroBody
                                                    }
                                                    customPage={
                                                        this.props.match.params
                                                            .custom
                                                    }
                                                />
                                            ) : item.name === 'Name12' &&
                                              this.state.content
                                                  .angelsItemsPublished.length >
                                                  0 &&
                                              this.state.content
                                                  .angelsIntroVisible ? (
                                                <Angels
                                                    body={
                                                        this.state.content
                                                            ?.angelsIntroBody
                                                    }
                                                    title={
                                                        this.state.content
                                                            ?.angelsIntroTitle
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.angelsItemsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name13' &&
                                              this.state.content
                                                  ?.introductionVisible &&
                                              this.state.content
                                                  ?.introductionsPublished
                                                  .length > 0 ? (
                                                <Introduction
                                                    title={
                                                        this.state.content
                                                            ?.introductionTitle
                                                            ? this.state.content
                                                                  .introductionTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.introductionsPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name14' &&
                                              this.state.content
                                                  ?.brochureVisible &&
                                              this.state.content
                                                  ?.brochuresPublished.length >
                                                  0 ? (
                                                <Brochure
                                                    title={
                                                        this.state.content
                                                            ?.brochureTitle
                                                            ? this.state.content
                                                                  .brochureTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.brochuresPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name15' &&
                                              this.state.content
                                                  ?.auraOriginVisible &&
                                              this.state.content
                                                  ?.auraOriginsPublished
                                                  .length > 0 ? (
                                                <Origin
                                                    title={
                                                        this.state.content
                                                            ?.auraOriginTitle
                                                            ? this.state.content
                                                                  .auraOriginTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.auraOriginsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name16' &&
                                              this.state.content
                                                  ?.asSeenInVisible &&
                                              this.state.content
                                                  ?.asSeenInsPublished.length >
                                                  0 ? (
                                                <AsSeenIn
                                                    title={
                                                        this.state.content
                                                            ?.asSeenInTitle
                                                            ? this.state.content
                                                                  .asSeenInTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.asSeenInsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name17' &&
                                              this.state.content
                                                  ?.contactTeamVisible &&
                                              this.state.content
                                                  ?.contactTeamsPublished
                                                  .length > 0 ? (
                                                <TeamContact
                                                    title={
                                                        this.state.content
                                                            ?.contactTeamTitle
                                                            ? this.state.content
                                                                  .contactTeamTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.contactTeamsPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name18' &&
                                              this.state.content
                                                  ?.trustpilotVisible ? (
                                                <Trustpilot
                                                    body={
                                                        this.state.content
                                                            ?.trustpilotDescription
                                                    }
                                                    title={
                                                        this.state.content
                                                            ?.trustpilotTitle
                                                    }
                                                    testimonials={
                                                        this.state.content
                                                            ?.trustpilotsPublished
                                                    }
                                                    ctaLink={
                                                        this.state.content
                                                            ?.trustpilotCtaLink
                                                            ? this.state.content
                                                                  .trustpilotCtaLink
                                                            : ''
                                                    }
                                                    ctaTitle={
                                                        this.state.content
                                                            ?.trustpilotCtaTitle
                                                            ? this.state.content
                                                                  .trustpilotCtaTitle
                                                            : ''
                                                    }
                                                    code={
                                                        this.state.content
                                                            ? {
                                                                  businessID:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataBusinessUnitId,
                                                                  localeLang:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataLocale,
                                                                  reviewLang:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataReviewLanguages,
                                                                  reviewNumb:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataReviewNumber,
                                                                  dataStars:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataStars,
                                                                  styleHeight:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataStyleHeight,
                                                                  styleWidth:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataStyleWidth,
                                                                  dataTags:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataTags,
                                                                  templateID:
                                                                      this.state
                                                                          .content
                                                                          .trustpilotDataTemplateId,
                                                                  theme: this
                                                                      .state
                                                                      .content
                                                                      .trustpilotDataTheme,
                                                              }
                                                            : ''
                                                    }
                                                />
                                            ) : item.name === 'Name19' &&
                                              this.state.content
                                                  ?.contactSectionVisible &&
                                              this.state.content
                                                  ?.contactSectionsPublished
                                                  .length > 0 ? (
                                                <ContactBlock
                                                    title={
                                                        this.state.content
                                                            ?.contactSectionTitle
                                                            ? this.state.content
                                                                  .contactSectionTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.contactSectionsPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name20' &&
                                              this.state.content
                                                  ?.customerServiceVisible &&
                                              this.state.content
                                                  ?.customerServicePublished
                                                  .length > 0 ? (
                                                <CustomerService
                                                    title={
                                                        this.state.content
                                                            ?.customerServiceTitle
                                                            ? this.state.content
                                                                  .customerServiceTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.customerServicePublished
                                                    }
                                                />
                                            ) : item.name === 'Name21' &&
                                              this.state.content?.costVisible &&
                                              this.state.content?.costsPublished
                                                  .length > 0 ? (
                                                <PlanCost
                                                    title={
                                                        this.state.content
                                                            ?.costTitle
                                                            ? this.state.content
                                                                  .costTitle
                                                            : null
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.costDescription
                                                            ? this.state.content
                                                                  .costDescription
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.costsPublished
                                                    }
                                                />
                                            ) : item.name === 'Name22' &&
                                              this.state.content
                                                  ?.productComparisonVisible &&
                                              this.state.content
                                                  ?.productComparisonPublished
                                                  .length > 0 ? (
                                                <Comparison
                                                    title={
                                                        this.state.content
                                                            ?.productComparisonTitle
                                                            ? this.state.content
                                                                  .productComparisonTitle
                                                            : null
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.productComparisonBody
                                                            ? this.state.content
                                                                  .productComparisonBody
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.productComparisonPublished
                                                    }
                                                />
                                            ) : item.name === 'Name23' &&
                                              this.state.content
                                                  ?.ourLocationsVisible &&
                                              this.state.content
                                                  ?.ourLocationsPublished
                                                  .length > 0 ? (
                                                <Location
                                                    title={
                                                        this.state.content
                                                            ?.ourLocationsTitle
                                                            ? this.state.content
                                                                  .ourLocationsTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.ourLocationsPublished[0]
                                                            ? this.state.content
                                                                  .ourLocationsPublished[0]
                                                            : null
                                                    }
                                                />
                                            ) : item.name === 'Name24' &&
                                              this.state.content
                                                  ?.westerleighVisible &&
                                              this.state.content
                                                  ?.westerleighPublished
                                                  .length > 0 ? (
                                                <Westerleigh
                                                    title={
                                                        this.state.content
                                                            ?.westerleighTitle
                                                            ? this.state.content
                                                                  .westerleighTitle
                                                            : null
                                                    }
                                                    subheader={
                                                        this.state.content
                                                            ?.westerleighSubheader
                                                            ? this.state.content
                                                                  .westerleighSubheader
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.westerleighPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name25' &&
                                              this.state.content
                                                  ?.howItWorksVisible2 ? (
                                                <HowItWorksNew
                                                    title={
                                                        this.state.content
                                                            ?.howItWorksTitle2
                                                            ? this.state.content
                                                                  .howItWorksTitle2
                                                            : null
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.howItWorksDescription2
                                                            ? this.state.content
                                                                  .howItWorksDescription2
                                                            : null
                                                    }
                                                />
                                            ) : item.name === 'Name26' &&
                                              this.state.content
                                                  ?.wonderfullyImagineVisible &&
                                              this.state.content
                                                  ?.wonderfullyImaginesPublished
                                                  .length > 0 ? (
                                                <WISnipet
                                                    horizontalImage={
                                                        this.state.content
                                                            ?.wonderfullyImaginesPublished[0]
                                                            ? this.state.content
                                                                  .wonderfullyImaginesPublished[0]
                                                                  .horizontalImage
                                                            : null
                                                    }
                                                    verticalImage={
                                                        this.state.content
                                                            ?.wonderfullyImaginesPublished[0]
                                                            ? this.state.content
                                                                  .wonderfullyImaginesPublished[0]
                                                                  .verticalImage
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.wonderfullyImaginesPublished[0]
                                                            ? this.state.content
                                                                  .wonderfullyImaginesPublished[0]
                                                            : null
                                                    }
                                                />
                                            ) : item.name === 'Name27' &&
                                              this.state.content
                                                  ?.extraVisible &&
                                              this.state.content
                                                  ?.extraCategoriesPublished
                                                  .length > 0 ? (
                                                <div className="c-funeral-at-need__extras">
                                                    {this.state.content
                                                        ?.extraTitle && (
                                                        <h2>
                                                            {
                                                                this.state
                                                                    .content
                                                                    .extraTitle
                                                            }{' '}
                                                        </h2>
                                                    )}
                                                    <Extras
                                                        data={
                                                            this.state.content
                                                                ?.extraCategoriesPublished
                                                        }
                                                    />
                                                </div>
                                            ) : item.name === 'Name28' &&
                                              this.state.content
                                                  ?.fcaRegulationVisible &&
                                              this.state.content
                                                  ?.fcaRegulationPublished
                                                  .length > 0 ? (
                                                <FcaRegulation
                                                    title={
                                                        this.state.content
                                                            ?.fcaRegulationTitle
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.fcaRegulationDescription
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.fcaRegulationPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name29' &&
                                              this.state.content
                                                  ?.comparisonAccordionVisible &&
                                              this.state.content
                                                  ?.comparisonAccordionPublished
                                                  .length > 0 ? (
                                                <ComparisonAccordion
                                                    title={
                                                        this.state.content
                                                            ?.comparisonAccordionTitle
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.comparisonAccordionDescription
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.comparisonAccordionPublished
                                                    }
                                                />
                                            ) : item.name === 'Name30' &&
                                              this.state.content
                                                  ?.keyFeaturesVisible &&
                                              this.state.content
                                                  ?.keyFeaturesPublished
                                                  .length > 0 ? (
                                                <TrustWorks
                                                    title={
                                                        this.state.content
                                                            ?.keyFeaturesTitle
                                                    }
                                                    description={
                                                        this.state.content
                                                            ?.keyFeaturesDescription
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.keyFeaturesPublished
                                                    }
                                                    btn1Title={
                                                        this.state.content
                                                            ?.keyFeaturesCtaTitle1
                                                    }
                                                    btn2Title={
                                                        this.state.content
                                                            ?.keyFeaturesCtaTitle2
                                                    }
                                                    btn1Link={
                                                        this.state.content
                                                            ?.keyFeaturesCtaLink1
                                                    }
                                                    btn2Link={
                                                        this.state.content
                                                            ?.keyFeaturesCtaLink2
                                                    }
                                                />
                                            ) : item.name === 'Name31' &&
                                              this.state.content
                                                  ?.peaceOfMindVisible &&
                                              this.state.content
                                                  ?.peaceOfMindPublished
                                                  .length > 0 ? (
                                                <AmendComponent
                                                    title={
                                                        this.state.content
                                                            ?.peaceOfMindTitle
                                                            ? this.state.content
                                                                  ?.peaceOfMindTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.peaceOfMindPublished[0]
                                                    }
                                                />
                                            ) : item.name === 'Name32' &&
                                              this.state.content
                                                  ?.locationVisible &&
                                              this.state.content
                                                  ?.planLocationsPublished
                                                  .length > 0 ? (
                                                <LocationsBlock
                                                    title={
                                                        this.state.content
                                                            ?.locationTitle
                                                            ? this.state.content
                                                                  ?.locationTitle
                                                            : null
                                                    }
                                                    content={
                                                        this.state.content
                                                            ?.planLocationsPublished
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    );
                                })}
                            </div>
                            {this.state.content?.stickyFooterVisible &&
                            this.state.content?.stickyFooterPublished ? (
                                <StickyFooter
                                    slug={this.state.content.slug}
                                    email="customercare@aura.life"
                                    //For new footer
                                    type={
                                        stickyFooter.type1 &&
                                        !stickyFooter.type2
                                            ? '1'
                                            : stickyFooter.type2 &&
                                              !stickyFooter.type1
                                            ? '2'
                                            : stickyFooter.type1 &&
                                              stickyFooter.type2
                                            ? '1'
                                            : null
                                    }
                                    text={stickyFooter.title}
                                    phoneNum={stickyFooter.phoneNumber}
                                    //if there is normal link, goes to link, if some number with prefix 'tel:' in CMS, goes to that num, if same as infitiy num, goes to infity num, if link is empty, it opens popup
                                    firstBtnTitle={stickyFooter.ctaTitle1}
                                    firstBtnLink={stickyFooter.ctaLink1}
                                    secondBtnTitle={stickyFooter.ctaTitle2}
                                    secondBtnLink={stickyFooter.ctaLink2}
                                />
                            ) : (
                                ''
                            )}
                            <Footer
                                withStickyFooter={
                                    this.state.content?.stickyFooterVisible &&
                                    this.state.content?.stickyFooterPublished
                                }
                            />
                        </>
                    )}
                </>
            );
    }
}

export default withRouter(CustomPage);
