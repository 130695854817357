import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class SchemaOrg extends Component {
    render() {
        const keywords = this.props.content[0].keywords?.split(' ').join(', ');
        const schema = {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: this.props.content[0].url,
            description: this.props.content[0].description,
            openingHours: this.props.content[0].openingHours,
            priceRange: this.props.content[0].priceRange,
            telephone: this.props.content[0].telephone,
            founder: this.props.content[0].founder,
            address: {
                '@type': this.props.content[0].addressType,
                addressLocality: this.props.content[0].addressLocality,
                addressRegion: this.props.content[0].addressRegion,
                postalCode: this.props.content[0].postalCode,
                streetAddress: this.props.content[0].streetAddress,
            },
            areaServed: {
                '@type': this.props.content[0].areaServedType,
                geoMidpoint: {
                    '@type': this.props.content[0].geoMidpointType,
                    latitude: this.props.content[0].geoMidpointLatitude,
                    longitude: this.props.content[0].geoMidpointLongitude,
                },
                geoRadius: this.props.content[0].geoRadius,
            },
            keywords: keywords ? keywords : null,
            review: this.props.content[0].review,
        };

        return (
            <Helmet>
                {/* Set metadata using React Helmet */}
                <meta
                    name="description"
                    content={this.props.content.description}
                />

                {/* Add JSON-LD script for Schema.org properties */}
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
            </Helmet>
        );
    }
}

export default SchemaOrg;
