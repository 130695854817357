class DateFormater {
    static formatDate(date, isMessage, isSingleMessage) {
        const monthNames = isMessage
            ? [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
              ]
            : [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
              ];
        const weekNames = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ];
        let day = parseInt(date.split('-')[2].substring(0, 2));
        let month = parseInt(date.split('-')[1]) - 1;
        let year = parseInt(date.split('-')[0]);
        const newDate = new Date(date);
        let dayOfWeek = newDate.getDay();
        let ordinalIndicator = 'th';
        if (day === 1 || day === 21 || day === 31) {
            ordinalIndicator = 'st';
        } else if (day === 2 || day === 22) {
            ordinalIndicator = 'nd';
        } else if (day === 3 || day === 23) {
            ordinalIndicator = 'rd';
        } else {
            ordinalIndicator = 'th';
        }
        let finalDateFormat;
        //message format on messages
        if (isSingleMessage) {
            finalDateFormat = `${
                weekNames[dayOfWeek - 1]
            }, ${day}${ordinalIndicator} ${monthNames[month]} ${year}`;
        } else {
            finalDateFormat = `${day}${ordinalIndicator} ${monthNames[month]} ${year}`;
        }
        return finalDateFormat;
    }

    static convertDate(timestamp) {
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        let stringDate;
        let month;
        let day;
        let year;
        if (timestamp) {
            let date = timestamp.toString();
            if (date.split(' ').length === 1) {
                day = date.split('-')[2].substring(0, 2);
                month = monthNames[parseInt(date.split('-')[1]) - 1];
                year = date.split('-')[0];
            } else {
                month = date.split(' ')[1];
                day = date.split(' ')[2];
                year = date.split(' ')[3];
            }
            stringDate = `${month}-${day}-${year}`;
        }
        return stringDate;
    }
}

export default DateFormater;
