export const SET_LIFESTORY = 'SET_LIFESTORY';
export const SET_LIFE_EDITORS = 'SET_LIFE_EDITORS';
export const SET_NEW_LIFE_EDITORS = 'SET_NEW_LIFE_EDITORS';
export const REMOVE_LIFE_EDITOR = 'REMOVE_LIFE_EDITOR';
export const ADD_LIFE_EDITOR = 'ADD_LIFE_EDITOR';
export const PUBLISH_TOGGLE = 'PUBLISH_TOGGLE';
export const ADD_NEW_SECTION = 'ADD_NEW_SECTION';
export const REMOVE_LIFE_SECTION = 'REMOVE_LIFE_SECTION';
export const UPDATE_LIFESTORY_SUBSECTION = 'UPDATE_LIFESTORY_SUBSECTION';
export const CLEAR_LIFESTORY_EDITORS = 'CLEAR_LIFESTORY_EDITORS';

//dispatch changes
export const setLifestory = (lifeStorySections) => {
    return {
        type: SET_LIFESTORY,
        lifeStorySections: lifeStorySections,
    };
};

export const setLifeEditors = (lifeEditors) => {
    return {
        type: SET_LIFE_EDITORS,
        lifeEditors: lifeEditors,
    };
};

export const setNewLifeEditors = (newLifeEditors) => {
    return {
        type: SET_NEW_LIFE_EDITORS,
        newLifeEditors: newLifeEditors,
    };
};

export const removeLifeEditor = (payload) => {
    return {
        type: REMOVE_LIFE_EDITOR,
        lifeEditor: payload.lifeEditor,
        userId: payload.userId,
    };
};

export const addLifeEditor = (payload) => {
    return {
        type: ADD_LIFE_EDITOR,
        userPayload: payload,
    };
};

export const publishToggle = () => {
    return {
        type: PUBLISH_TOGGLE,
    };
};

export const addNewLifeStory = (lifeStory) => {
    return {
        type: ADD_NEW_SECTION,
        lifeStory: lifeStory,
    };
};

export const removeStorySection = (lifeStoryId) => {
    return {
        type: REMOVE_LIFE_SECTION,
        lifeStoryId: lifeStoryId,
    };
};
export const updateLifeStorySubsection = (updatedSubsection) => {
    return {
        type: UPDATE_LIFESTORY_SUBSECTION,
        updatedSubsection: updatedSubsection,
    };
};

export const removeLifeStoryEditors = () => {
    return {
        type: CLEAR_LIFESTORY_EDITORS,
    };
};

//dispatch actions
export const onClearLifeStoryEditors = () => {
    return (dispatch) => {
        dispatch(removeLifeStoryEditors());
    };
};

export const onSetLifestory = (payload) => {
    return (dispatch) => {
        dispatch(setLifestory(payload));
    };
};

export const onSetLifeEditors = (payload) => {
    return (dispatch) => {
        dispatch(setLifeEditors(payload));
    };
};

export const onRemoveLifeEditor = (payload) => {
    return (dispatch) => {
        dispatch(removeLifeEditor(payload));
    };
};

export const onAddLifeEditor = (payload) => {
    return (dispatch) => {
        dispatch(addLifeEditor(payload));
    };
};

export const onPublishToggle = () => {
    return (dispatch) => {
        dispatch(publishToggle());
    };
};

export const onSetNewLifeEditors = (payload) => {
    return (dispatch) => {
        dispatch(setNewLifeEditors(payload));
    };
};

export const onAddNewLifeStory = (payload) => {
    return (dispatch) => {
        dispatch(addNewLifeStory(payload));
    };
};

export const onRemoveStorySection = (payload) => {
    return (dispatch) => {
        dispatch(removeStorySection(payload));
    };
};

export const onUpdateLifeStorySubsection = (payload) => {
    return (dispatch) => {
        dispatch(updateLifeStorySubsection(payload));
    };
};
