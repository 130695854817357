import React, { Component } from 'react';

export default class AngelTestimonial extends Component {
    render() {
        return (
            <div className="c-angel-testimonial o-flex">
                <quote>{this.props.body}</quote>
            </div>
        );
    }
}
