import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NumberFormater from '../../../../service/number_formater.service';
import ApiService from '../../../../service/api.service';
import Loader from '../../../../components/UI/Loader/Loader';
import Popup from 'reactjs-popup';
import logo from '../../../../assets/images/logo-revert/Aura-big-logo.svg';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Select from 'react-select';
import Input from '../../../../components/UI/Input/RequiredInput';
import thankYou from '../../../../assets/images/aura2022/icons/paper-pen.svg';
import loader from '../../../../assets/images/loader.svg';
import * as funeralPaymentActionTypes from '../../../../store/actions/funeralPaymentActions';
import Cookie from 'js-cookie';
import ReactPixel from 'react-facebook-pixel';
class InstallmentThankYou extends Component {
    state = {
        success: false,
        isLoading: true,
        optionTitle: '',
        optionAmount: '',
        popupOpen: false,
        month: null,
        day: null,
        year: '',
        hour: null,
        minute: null,
        dayValid: null,
        yearValid: null,
        phone: '',
        formSubmitted: false,
        buttonLoading: false,
        firstTimeLoaded: true,
    };

    componentDidMount() {
        //facebook pixel
        ReactPixel.init('218942439488342');
        ReactPixel.track('Purchase');

        const date = new Date().toLocaleDateString('en-GB');
        const datetime = new Date();
        const day = date.split('/')[0];
        const month = date.split('/')[1];
        const year = date.split('/')[2];
        const hour = datetime.getHours();
        const minute = datetime.getMinutes();
        this.setState({
            month: month,
            year: year,
            day: day,
            hour: hour,
            minute: minute,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user && this.state.firstTimeLoaded) {
            let plan = 'simple';
            if (window.location.href.includes('essential')) {
                plan = 'essential';
            } else if (window.location.href.includes('celebration')) {
                plan = 'celebration-of-life';
            } else if (window.location.href.includes('imagined')) {
                plan = 'imagined';
            } else if (window.location.href.includes('simple')) {
                plan = 'simple';
            }
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    const funeralPlanId = Cookie.get('funeralPaymentId');
                    ApiService.getUserFuneralPlan(funeralPlanId)
                        .then((r) => {
                            this.props.onSetFuneralCheckout(r.data);
                            this.props.onSetFuneralPlan(plan);
                            const date = new Date().toLocaleDateString('en-GB');
                            const day = date.split('/')[0];
                            const month = date.split('/')[1];
                            const year = date.split('/')[2];

                            // Cookie.remove('funeralPaymentId')
                            this.setState({
                                month: month,
                                year: year,
                                day: day,
                            });
                            document.body.classList.add('thankyou');
                            if (funeralPlanId) {
                                if (window.location.href.includes('success')) {
                                    this.setState({
                                        success: true,
                                    });
                                    Cookie.remove('demands');
                                    let id =
                                        this.props.funeralPayment.selectedOption.split(
                                            '/'
                                        )[3];
                                    ApiService.getFuneralPlanOption(id)
                                        .then((r) => {
                                            this.setState({
                                                optionTitle: r.data.title,
                                                optionAmount: r.data.money,
                                                isLoading: false,
                                                firstTimeLoaded: false,
                                            });
                                        })
                                        .catch((e) => {
                                            this.setState({
                                                isLoading: false,
                                            });
                                        });
                                } else {
                                    let id =
                                        this.props.funeralPayment.selectedOption.split(
                                            '/'
                                        )[3];
                                    ApiService.getFuneralPlanOption(id)
                                        .then((r) => {
                                            this.setState({
                                                optionTitle: r.data.title,
                                                optionAmount: r.data.money,
                                                isLoading: false,
                                            });
                                        })
                                        .catch((e) => {
                                            this.setState({
                                                isLoading: false,
                                            });
                                        });
                                }
                            } else {
                                this.props.history.push('/order-history');
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            );
        }
    }

    handleFormSubmit = (values) => {
        this.setState(
            {
                buttonLoading: true,
            },
            () => {
                if (
                    this.state.year &&
                    this.state.month &&
                    this.state.day &&
                    this.state.hour &&
                    this.state.minute
                ) {
                    const data = {
                        phone: values.phone,
                        scheduleAt: `${this.state.year}-${this.state.month}-${this.state.day}T${this.state.hour}:${this.state.minute}:00`,
                    };
                    ApiService.scheduleCall(data)
                        .then((r) => {
                            this.setState({
                                formSubmitted: true,
                                buttonLoading: false,
                            });
                        })
                        .catch((e) => {
                            console.log(e);
                            this.setState({
                                buttonLoading: false,
                            });
                        });
                }
            }
        );
    };

    componentWillUnmount() {
        document.body.classList.remove('thankyou');
    }

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    handleSelectDayChange = (e) => {
        this.setState({
            day: e.value,
            // isTouched: true
        });
    };

    handleSelectYearChange = (e) => {
        this.setState({
            year: e.value,
            // isTouched: true
        });
    };

    handleSelectMonthChange = (e) => {
        this.setState({
            month: e.value,
            // isTouched: true
        });
    };

    handleSelectMinuteChange = (e) => {
        this.setState({
            minute: e.value,
            // isTouched: true
        });
    };

    handleSelectHourChange = (e) => {
        this.setState({
            hour: e.value,
            // minute: '00',
            // isTouched: true
        });
    };

    render() {
        let addons;
        let content;
        let dayOptions = [
            { value: '01', label: '01' },
            { value: '02', label: '02' },
            { value: '03', label: '03' },
            { value: '04', label: '04' },
            { value: '05', label: '05' },
            { value: '06', label: '06' },
            { value: '07', label: '07' },
            { value: '08', label: '08' },
            { value: '09', label: '09' },
            { value: '10', label: '10' },
            { value: '11', label: '11' },
            { value: '12', label: '12' },
            { value: '13', label: '13' },
            { value: '14', label: '14' },
            { value: '15', label: '15' },
            { value: '16', label: '16' },
            { value: '17', label: '17' },
            { value: '18', label: '18' },
            { value: '19', label: '19' },
            { value: '20', label: '20' },
            { value: '21', label: '21' },
            { value: '22', label: '22' },
            { value: '23', label: '23' },
            { value: '24', label: '24' },
            { value: '25', label: '25' },
            { value: '26', label: '26' },
            { value: '27', label: '27' },
            { value: '28', label: '28' },
            { value: '29', label: '29' },
            { value: '30', label: '30' },
            { value: '31', label: '31' },
        ];

        let todaysDate = new Date();
        let year = todaysDate.getFullYear();

        let yearOptions = [];
        let i;
        for (i = 0; i < 2; i++) {
            const yearOption = {
                value: `${year + i}`,
                label: `${year + i}`,
            };
            yearOptions.push(yearOption);
        }

        let monthOptions = [
            { value: '01', label: 'January' },
            { value: '02', label: 'February' },
            { value: '03', label: 'March' },
            { value: '04', label: 'April' },
            { value: '05', label: 'May' },
            { value: '06', label: 'Jun' },
            { value: '07', label: 'July' },
            { value: '08', label: 'August' },
            { value: '09', label: 'September' },
            { value: '10', label: 'October' },
            { value: '11', label: 'November' },
            { value: '12', label: 'December' },
        ];

        let hourOptions = [
            { value: '06', label: '06' },
            { value: '07', label: '07' },
            { value: '08', label: '08' },
            { value: '09', label: '09' },
            { value: '10', label: '10' },
            { value: '11', label: '11' },
            { value: '12', label: '12' },
            { value: '13', label: '13' },
            { value: '14', label: '14' },
            { value: '15', label: '15' },
            { value: '16', label: '16' },
            { value: '17', label: '17' },
            { value: '18', label: '18' },
            { value: '19', label: '19' },
            { value: '20', label: '20' },
            { value: '21', label: '21' },
            { value: '22', label: '22' },
            // {value: '23', label: '23'},
        ];

        let minuteOptions = [
            { value: '00', label: '00' },
            { value: '15', label: '15' },
            { value: '30', label: '30' },
            { value: '45', label: '45' },
        ];

        var newToday = new Date();
        var selectedDay = `${this.state.year}-${this.state.month}-${this.state.day}T${this.state.hour}:${this.state.minute}:00`;
        var finalDay = new Date(selectedDay);

        let initialValues = {
            phone: this.props.funeralPayment?.mobile
                ? this.props.funeralPayment.mobile
                : this.props.funeralPayment?.createdByMobile
                ? this.props.funeralPayment?.createdByMobile
                : '',
        };

        let validationSchema = Yup.object({
            phone: Yup.string().required('Required'),
        });

        let popupContent = (
            <>
                <div className="c-popup__header text-center">
                    <img
                        className="c-funeral__popup-schedulecall--logo"
                        src={logo}
                        alt="logo"
                    ></img>
                </div>
                <div className="c-popup__body">
                    {!this.state.formSubmitted ? (
                        <div>
                            <p className="max-width-full">
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Dolor eaque eius laboriosam
                                laborum mollitia natus reprehenderit, unde
                                voluptatibus?{' '}
                            </p>

                            <Formik
                                onSubmit={(values) =>
                                    this.handleFormSubmit(values)
                                }
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                            >
                                {(props) => (
                                    <>
                                        <div className="c-form">
                                            <div className="c-form">
                                                <Form
                                                    noValidate
                                                    className="o-flex o-flex-column c-contact-form"
                                                >
                                                    <div className="o-flex c-funeral__popup-schedulecall--date">
                                                        <div className="form-item-date position-relative">
                                                            <label>Day</label>
                                                            <Select
                                                                className={`c-react-select c-react-select--day form-item--border-gray margin-reset c-react-select__event`}
                                                                options={
                                                                    dayOptions
                                                                }
                                                                placeholder={
                                                                    <div>
                                                                        01
                                                                    </div>
                                                                }
                                                                classNamePrefix="c-react-select"
                                                                isSearchable={
                                                                    false
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSelectDayChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .day
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .day,
                                                                              label: this
                                                                                  .state
                                                                                  .day,
                                                                          }
                                                                        : null
                                                                }
                                                                name={
                                                                    this.state
                                                                        .day
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .day,
                                                                              label: this
                                                                                  .state
                                                                                  .day,
                                                                          }
                                                                        : null
                                                                }
                                                            ></Select>
                                                        </div>

                                                        <div className="form-item-date position-relative">
                                                            <label>Month</label>
                                                            <Select
                                                                className={`c-react-select c-react-select--month form-item--border-gray margin-reset c-react-select__event`}
                                                                options={
                                                                    monthOptions
                                                                }
                                                                placeholder={
                                                                    <div>
                                                                        01
                                                                    </div>
                                                                }
                                                                classNamePrefix="c-react-select"
                                                                isSearchable={
                                                                    false
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSelectMonthChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .month
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .month,
                                                                              label: monthOptions[
                                                                                  parseInt(
                                                                                      this
                                                                                          .state
                                                                                          .month
                                                                                  ) -
                                                                                      1
                                                                              ]
                                                                                  .label,
                                                                          }
                                                                        : null
                                                                }
                                                                name={
                                                                    this.state
                                                                        .month
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .month,
                                                                              label: monthOptions[
                                                                                  parseInt(
                                                                                      this
                                                                                          .state
                                                                                          .month
                                                                                  ) -
                                                                                      1
                                                                              ]
                                                                                  .label,
                                                                          }
                                                                        : null
                                                                }
                                                            ></Select>
                                                        </div>

                                                        <div className="form-item-date position-relative">
                                                            <label>Year</label>
                                                            <Select
                                                                className={`c-react-select c-react-select--year form-item--border-gray margin-reset c-react-select__event`}
                                                                options={
                                                                    yearOptions
                                                                }
                                                                placeholder={
                                                                    <div>
                                                                        2022
                                                                    </div>
                                                                }
                                                                classNamePrefix="c-react-select"
                                                                isSearchable={
                                                                    false
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSelectYearChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .year
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .year,
                                                                              label: this
                                                                                  .state
                                                                                  .year,
                                                                          }
                                                                        : null
                                                                }
                                                                name={
                                                                    this.state
                                                                        .year
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .year,
                                                                              label: this
                                                                                  .state
                                                                                  .year,
                                                                          }
                                                                        : null
                                                                }
                                                            ></Select>
                                                        </div>

                                                        <div className="form-item-date position-relative">
                                                            <label>Hour</label>
                                                            <Select
                                                                className={`c-react-select c-react-select--hour form-item--border-gray margin-reset c-react-select__event`}
                                                                options={
                                                                    hourOptions
                                                                }
                                                                placeholder={
                                                                    <div>
                                                                        12
                                                                    </div>
                                                                }
                                                                classNamePrefix="c-react-select"
                                                                isSearchable={
                                                                    false
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSelectHourChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .hour
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .hour,
                                                                              label: this
                                                                                  .state
                                                                                  .hour,
                                                                          }
                                                                        : null
                                                                }
                                                                name={
                                                                    this.state
                                                                        .hour
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .hour,
                                                                              label: this
                                                                                  .state
                                                                                  .hour,
                                                                          }
                                                                        : null
                                                                }
                                                            ></Select>
                                                        </div>

                                                        <div className="form-item-date position-relative">
                                                            <label>
                                                                Minute
                                                            </label>
                                                            <Select
                                                                className={`c-react-select c-react-select--minute form-item--border-gray margin-reset c-react-select__event`}
                                                                options={
                                                                    minuteOptions
                                                                }
                                                                placeholder={
                                                                    <div>
                                                                        00
                                                                    </div>
                                                                }
                                                                classNamePrefix="c-react-select"
                                                                isSearchable={
                                                                    false
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleSelectMinuteChange
                                                                }
                                                                value={
                                                                    this.state
                                                                        .minute
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .minute,
                                                                              label: this
                                                                                  .state
                                                                                  .minute,
                                                                          }
                                                                        : null
                                                                }
                                                                name={
                                                                    this.state
                                                                        .minute
                                                                        ? {
                                                                              value: this
                                                                                  .state
                                                                                  .minute,
                                                                              label: this
                                                                                  .state
                                                                                  .minute,
                                                                          }
                                                                        : null
                                                                }
                                                            ></Select>
                                                        </div>
                                                    </div>

                                                    <div
                                                        onFocus={
                                                            this.handleNameFocus
                                                        }
                                                        onBlur={
                                                            this.handleNameBlur
                                                        }
                                                        tabIndex="-1"
                                                        className={`form-item-phone position-relative ${this.state.isNameFocused}`}
                                                    >
                                                        <label>
                                                            Phone number
                                                        </label>
                                                        <Input
                                                            className="form-item--border-gray-light"
                                                            placeholder="Phone number"
                                                            name="phone"
                                                            type="text"
                                                        />
                                                    </div>
                                                    <button
                                                        disabled={
                                                            !this.state.day ||
                                                            !this.state.month ||
                                                            !this.state.year ||
                                                            !this.state.hour ||
                                                            !this.state
                                                                .minute ||
                                                            finalDay < newToday
                                                        }
                                                        className={`btn btn--full btn-loader o-flex o-flex-middle o-flex-center margin-auto btn--size-55 ${
                                                            this.state
                                                                .buttonLoading
                                                                ? 'save-in-progress'
                                                                : ''
                                                        }`}
                                                        type="submit"
                                                    >
                                                        Send{' '}
                                                        <img
                                                            width="100%"
                                                            height="100%"
                                                            src={loader}
                                                            alt="loaderImage"
                                                        />
                                                    </button>
                                                </Form>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <>
                            <div className="c-crowdfunding__form-overlay c-landing__thank-you-modal o-flex o-flex-column o-flex-center o-flex-middle text-center">
                                <div className="position-relative margin-auto m-20">
                                    <img
                                        className="c-landing__thank-you-modal--mail-image"
                                        src={thankYou}
                                        alt="thankYou"
                                    />
                                </div>

                                <h4 className="font-family-secondary">
                                    Thank you for scheduling a call with Aura
                                    Angel
                                </h4>
                                <p className="o-d-block">
                                    Someone will get in touch with you soon
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </>
        );

        if (this.props.funeralPayment?.userAddOns) {
            addons = this.props.funeralPayment.userAddOns.map(
                (addon, index) => {
                    return (index ? ', ' : '') + addon.addOn.title;
                }
            );
        }

        if (this.props.funeralPayment) {
            if (!this.state.isLoading) {
                content = (
                    <>
                        <div className="logo-holder-color"></div>
                        <div className="c-funeral__form c-funeral__thankyou">
                            {this.state.success ? (
                                <>
                                    <h1>
                                        Thank you for purchasing an Aura funeral
                                        plan
                                    </h1>
                                    <div className="c-funeral__thankyou--msg">
                                        {this.props.funeralPayment.forMe ? (
                                            <>
                                                <p>
                                                    So that we can be as
                                                    environmentally friendly as
                                                    possible, details of your
                                                    plan will be sent to both
                                                    you and your Nominated
                                                    Representative shortly via
                                                    email. If you would like to
                                                    request a printed copy of
                                                    your plan documents, please
                                                    reply to your order
                                                    confirmation email and we
                                                    will arrange to have one
                                                    sent out to you.{' '}
                                                </p>
                                                <p className="font-bold">
                                                    Your confirmation email
                                                    details everything about
                                                    your plan, so please take
                                                    the time to review it
                                                    carefully and let us know of
                                                    any questions as soon as
                                                    possible.
                                                </p>
                                                <p>
                                                    Remember, if you change your
                                                    mind or circumstances
                                                    change, your plan can be
                                                    cancelled at any time at no
                                                    cost. We will issue a full
                                                    refund within 14 days of
                                                    receiving your cancellation
                                                    request.
                                                </p>
                                                <p>
                                                    You can also make changes to
                                                    your plan at any time with
                                                    no administration fee.
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    So that we can be as
                                                    environmentally friendly as
                                                    possible, details of the
                                                    plan will be sent to both
                                                    you as the Nominated
                                                    Representative and the
                                                    planholder via email. If you
                                                    would like to request a
                                                    printed copy of the plan
                                                    documents, please reply to
                                                    your order confirmation
                                                    email and we will arrange to
                                                    have one sent out to the
                                                    planholder.{' '}
                                                </p>
                                                <p>
                                                    Remember, if you change your
                                                    mind or circumstances
                                                    change, your plan can be
                                                    cancelled at any time at no
                                                    cost. We will issue a full
                                                    refund within 14 days of
                                                    receiving your cancellation
                                                    request.
                                                </p>
                                                <p>
                                                    You can also make changes to
                                                    your plan at any time with
                                                    no administration fee.
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h1 className="c-funeral__thankyou--problem">
                                        Something went wrong, please try again.
                                    </h1>
                                </>
                            )}
                            <div className="c-funeral__form--wrapper">
                                <h5>Purchase summary</h5>
                                <ul className="c-funeral__thankyou--bill">
                                    <li>
                                        <p>Plan is for:</p>
                                        <span>
                                            {
                                                this.props.funeralPayment
                                                    .holderTitle
                                            }{' '}
                                            {
                                                this.props.funeralPayment
                                                    .holderFirstName
                                            }{' '}
                                            {
                                                this.props.funeralPayment
                                                    .holderMiddleName
                                            }{' '}
                                            {
                                                this.props.funeralPayment
                                                    .holderLastName
                                            }
                                        </span>
                                    </li>
                                    <li>
                                        <p>Plan name:</p>
                                        <span>
                                            {this.props.funeralPayment.plan
                                                .slug === 'essential'
                                                ? 'Essential'
                                                : this.props.funeralPayment.plan
                                                      .slug ===
                                                  'celebration-of-life'
                                                ? 'Celebration of Life'
                                                : this.props.funeralPayment.plan
                                                      .slug === 'imagined'
                                                ? 'Wonderfully Imagined'
                                                : this.props.funeralPayment.plan
                                                      .slug === 'simple'
                                                ? 'Perfectly Simple'
                                                : ''}
                                        </span>
                                    </li>
                                    {this.state.optionTitle ? (
                                        <li>
                                            <p>{this.state.optionTitle}:</p>
                                            <span>
                                                £
                                                {NumberFormater.formatNumber(
                                                    this.state.optionAmount
                                                )}
                                            </span>
                                        </li>
                                    ) : null}
                                    <li className="c-funeral__thankyou--bill-total">
                                        <p>Total cost</p>
                                        <span>
                                            £
                                            {NumberFormater.formatNumber(
                                                this.props.funeralPayment.total
                                            )}
                                        </span>
                                    </li>
                                    {addons.length ? (
                                        <ul className="c-funeral__wishes-list-payment">
                                            <li>
                                                <p>Wishes:</p>
                                                <span>{addons}</span>
                                            </li>
                                        </ul>
                                    ) : null}
                                    {this.props.funeralPayment
                                        .ownDescription ? (
                                        <ul className="c-funeral__special-request">
                                            <li>
                                                <p>Special request:</p>
                                                <span>
                                                    {' '}
                                                    {
                                                        this.props
                                                            .funeralPayment
                                                            .ownDescription
                                                    }
                                                </span>
                                            </li>
                                        </ul>
                                    ) : null}
                                </ul>
                            </div>

                            {this.props.funeralPayment.forMe ? (
                                <p className="c-funeral__thankyou--details-txt">
                                    One of our customer care advisors will give
                                    you a brief courtesy call over the next
                                    seven days to run over the details of your
                                    plan and answer any questions you may have.
                                    If you would like to speak to a member of
                                    our team beforehand, you are welcome to
                                    schedule a call at a time that suits you
                                    using the button below.
                                </p>
                            ) : (
                                <p className="c-funeral__thankyou--details-txt">
                                    One of our customer care advisors will give
                                    the planholder a brief courtesy call over
                                    the next seven days to run over the details
                                    of their plan and answer any questions. If
                                    you would prefer to arrange the call for a
                                    convenient time when you and the planholder
                                    are together, you can arrange a callback
                                    with our customer care team using the button
                                    below.
                                </p>
                            )}
                            <p className="c-funeral__thankyou--thank-you-txt">
                                Thank you.
                            </p>
                            {this.state.success ? (
                                <div className="c-funeral__thankyou--buttons">
                                    <Link
                                        to={`/my-aura/${this.props.user.userSlug}/life-story`}
                                        className="o-d-inline-block btn btn--full text-center"
                                    >
                                        View account
                                    </Link>
                                    <button
                                        className="o-d-inline-block btn btn--white-purple btn--full-white text-center"
                                        onClick={this.handlePopupOpen}
                                    >
                                        Arrange a callback
                                    </button>
                                </div>
                            ) : (
                                <div className="c-funeral__thankyou--buttons">
                                    <button
                                        onClick={() =>
                                            this.props.history.push(
                                                `/funeral-payment/${
                                                    this.props.funeralPayment
                                                        .currentPlan ===
                                                    'essential'
                                                        ? 'essential'
                                                        : 'celebration-of-life'
                                                }`
                                            )
                                        }
                                        className="c-btn o-d-inline-block btn btn--full-shakespeare text-center"
                                    >
                                        Back
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                );
            } else {
                content = <Loader />;
            }
        }
        return (
            <div className="c-funeral">
                {content}
                <Popup
                    lockScroll
                    open={this.state.popupOpen}
                    onClose={this.handlePopupClose}
                    className="c-funeral__popup c-funeral__popup-schedulecall c-popup-wrapper"
                >
                    {(close) => (
                        <div className="c-popup o-flex o-flex-column">
                            <button
                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                onClick={close}
                            >
                                <i className="icon-times font-35 cursor-pointer"></i>
                            </button>
                            {popupContent}
                        </div>
                    )}
                </Popup>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    funeralPayment: state.funeralPaymentReducer,
    user: state.usersReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCurrentStep: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(currentStep)),
        onSetFuneralCheckout: (data) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralCheckout(data)),
        onSetFuneralPlan: (plan) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralPlan(plan)),
        onSetTotalAmount: (price) =>
            dispatch(funeralPaymentActionTypes.onSetTotalAmount(price)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstallmentThankYou);
