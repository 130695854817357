import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import imageLogo from '../../../assets/images/aura-logo-medium.svg';
class Footer extends Component {
    state = {
        isNavOpen: false,
        notificationsLoading: false,
        secureFooter: false,
    };

    componentDidMount() {
        if (
            window.location.href.includes('/funeral-plans') ||
            window.location.href.includes('/funerals') ||
            window.location.href.includes('funeral-at-need')
        ) {
            this.setState({
                secureFooter: true,
            });
        }
    }

    getYear() {
        return new Date().getFullYear();
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    render() {
        const token = localStorage.getItem('token');

        return (
            <footer
                id="footer"
                className={`c-footer  ${
                    this.props.withStickyFooter
                        ? 'c-footer--with-sticky-footer'
                        : ''
                } `}
            >
                <i
                    onClick={this.scrollToTop}
                    className="c-scroll-top cursor-pointer position-absolute border-radius-50 font-20 o-flex o-flex-middle o-flex-center icon-arrow"
                ></i>

                {this.props.user ? (
                    <Link
                        to={`/my-aura/${
                            this.props.user && this.props.user.profile.slug
                        }`}
                        className="c-footer__logo"
                    >
                        <img src={imageLogo} alt="imageLogo" />
                    </Link>
                ) : null}
                {!this.props.token ? (
                    <a href="/" class="c-footer__logo">
                        <img src={imageLogo} alt="imageLogo" />
                    </a>
                ) : null}
                <div className="c-footer__container container o-flex o-flex-column-mobile">
                    <div className="c-footer__info">
                        <a
                            href="https://www.aura.life/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="c-footer__home-link"
                        >
                            <h4>Aura Life</h4>
                        </a>
                        <ul>
                            <li>Ltd Company No: 12287045</li>
                            <li>Studio One Bunkers Hill Farm </li>
                            <li>Godalming Surrey, GU7 1UP </li>
                            <li>&#169; Copyright {this.getYear()}</li>
                        </ul>
                    </div>

                    <div className="c-footer__menu o-flex o-flex-between">
                        {/* <div className="o-flex o-flex-column position-relative">
                            <h4>Aura</h4>
                            <ul className="o-flex o-flex-column position-relative">
                                <li>
                                    <a
                                        href="/partner-with-us"
                                    >
                                        Partner with Us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/meet-the-team"
                                    >
                                        Meet the Team
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/in-the-press"
                                    >
                                        In the Press
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/testimonials"
                                    >
                                        Testimonials
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/help/contact-us"
                                    >
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        {/* <div className="o-flex o-flex-column position-relative">
                            <h4>Discover</h4>
                            <ul className="o-flex o-flex-column position-relative">
                                <li>
                                    <a
                                        href="/about"
                                    >
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <NavLink
                                        exact={true}
                                        activeClassName="active"
                                        to="/knowledge/guides"
                                    >
                                        Help Guides
                                    </NavLink>
                                </li>
                                <li>
                                    <a
                                        href="/knowledge/articles"
                                    >
                                        Articles
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/help/faqs"
                                    >
                                        FAQs
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/aura-angels"
                                    >
                                        Aura Angels
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        {/* <div className="o-flex o-flex-column position-relative">
                            <h4>Legal</h4>
                            <ul className="o-flex o-flex-column position-relative">
                                <li>
                                    <a
                                        href="/aura-security"
                                    >
                                        Security
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/privacy-policy"
                                    >
                                        Privacy &#38; GDPR
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/terms-of-use"
                                    >
                                        Terms of Use
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/terms-and-conditions"
                                    >
                                        Funeral Plan T&Cs
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/funeral-terms-conditions"
                                    >
                                        Funeral T&Cs
                                    </a>
                                </li>

                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.aura.life/uploads/files/Ultimate-ownership.pdf"
                                    >
                                        Ownership
                                    </a>
                                </li>

                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.aura.life/uploads/files/Aura_Standardised_Price_List_CMA_July2024.pdf"
                                    >
                                        CMA Comparison Price List
                                    </a>
                                </li>
                               
                            </ul>
                        </div> */}

                        {/* <div className="o-flex o-flex-column position-relative">
                            <h4>Follow Us</h4>
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.facebook.com/auracelebratelife"
                                    >
                                        Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://twitter.com/AuraLifeLtd"
                                    >
                                        Twitter
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.instagram.com/aura_celebrate_life/"
                                    >
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.linkedin.com/company/auralifeltd/"
                                    >
                                        Linkedin
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.youtube.com/channel/UCdJwdoATiNDCvdt5ZnKQFDQ"
                                    >
                                        Youtube
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>

                <div className="container c-footer__status">
                    <p>
                        Aura Life (FRN: 976308) is authorised and regulated by
                        the Financial Conduct Authority for the purposes of
                        entering into and carrying out Funeral Plan Contracts.
                        You can search the FCA register to verify any company
                        you deal with for the purchase of a prepaid funeral plan
                        via the FCA website:{' '}
                        <a href="https://www.fca.org.uk/firms/financial-services-register">
                            https://www.fca.org.uk/firms/financial-services-register
                        </a>
                    </p>
                </div>

                <div
                    className={`c-footer__container--secure ${
                        token
                            ? 'c-footer__container--secure-added-padd'
                            : 'c-footer__container--secure-normal-pad'
                    } ${
                        this.state.secureFooter
                            ? 'c-footer__container--secure-plans'
                            : ''
                    }`}
                >
                    <div className="c-footer__security-features o-flex-column-tablet">
                        <div className="c-footer__security-features--list">
                            <h4 className="font-450 font-family-secondary font-20 font-333 opacity-9 margin-reset">
                                Aura’s Security Features
                            </h4>
                            <h5 className="margin-reset">Secure Connection</h5>
                            <h5 className="margin-reset">
                                Gold Standard Data Storage
                            </h5>
                            <h5 className="margin-reset">GDPR Compliance</h5>
                            <h5 className="margin-reset">Data Encryption</h5>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
