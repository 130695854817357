import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import React, { Component } from 'react';
import AuraLogoBg from '../../../assets/images/aura2022/redesign/plans-bg-logo-blue.svg';
import Footer from '../../../components/UI/Footer/Footer';
import Header from '../../../components/Header/Header';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
import Loader from '../../../components/UI/Loader/Loader';
import ApiService from '../../../service/api.service';
import ReactHtmlParser from 'react-html-parser';
import Popup from 'reactjs-popup';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import RequiredInput from '../../../components/UI/Input/RequiredInput/RequiredInput';
import loader from '../../../assets/images/loader.svg';
import logo from '../../../assets/images/logo-revert/Aura-big-logo.svg';
import { Link } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import MultipleVideo from '../../../components/FuneralPlans/MultipleVideo/MultipleVideo';
class AuraCircle extends Component {
    state = {
        content: null,
        popupOpen: false,
        saveInProgress: false,
        banner: [],
    };

    componentDidMount() {
        let page = 'auraCircle';

        ApiService.auraCircle().then((r) => {
            this.setState(
                {
                    content: r.data[0],
                },
                () => {
                    window.scrollTo(0, 0);
                }
            );
        });

        ApiService.getBannersOtherPages(page).then((r) => {
            this.setState({
                banner: r.data,
            });
        });
    }

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    updateBannerVisibilty = () => {
        this.setState({ banner: [] });
    };

    handleSubmitLogin = (values) => {
        ReactPixel.init('218942439488342');
        ReactPixel.track('Complete registration');

        this.setState(
            {
                saveInProgress: true,
            },
            () => {
                const data = {
                    email: values.email,
                    firstName: values.name,
                    sourcePage: 'circle',
                };
                ApiService.simpleRegister(data)
                    .then((r) => {
                        // set token to localstorage
                        const token = r.data.token;
                        const refreshToken = r.data.refresh_token;
                        localStorage.setItem('token', token);
                        localStorage.setItem('tokenRefreshedDate', new Date());
                        localStorage.setItem('refreshToken', refreshToken);
                        window.location.reload();
                    })
                    .catch((e) => {
                        this.setState({
                            saveInProgress: false,
                            loginError: 'This email is already in use.',
                        });
                    });
            }
        );
    };

    closeError = () => {
        this.setState({
            loginError: '',
        });
    };
    render() {
        let content = <Loader />;

        const bannerData = this.state.banner?.find(
            (item) => item.auraCircle === true
        );


        if (this.state.content) {
            content = (
                <>
                    {/* video header start */}
                    <MultipleVideo
                        hasBanner={bannerData?.auraCircle === true}
                        horizontalVideo={this.state.content.horizontalVideo}
                        verticalVideo={this.state.content.verticalVideo}
                        popupVideo={this.state.content.video}
                        horizontalImage={this.state.content.horizontalImage}
                        verticalImage={this.state.content.verticalImage}
                        title={this.state.content.headerTitle}
                        description={this.state.content.headerIntro}
                        ctaTitle={this.state.content.headerCTATitle}
                        ctaLink={() => this.handlePopupOpen()}
                        openPopup={
                            this.state.content &&
                            this.state.content.headerCTAUrl
                        }
                        type="circle"
                    />
                    {/* video header end */}

                    <div className="container">
                        <img
                            className="c-circle--background"
                            src={AuraLogoBg}
                            alt="logo-bg"
                        />
                        <h2>{this.state.content.contentTitle}</h2>
                        <div className="c-circle__desc c-homepage__description">
                            <div className="o-flex two-col">
                                <div
                                    className="two-col--img-lg"
                                    style={{
                                        backgroundImage: `url(${this.state.content.block1Image})`,
                                    }}
                                ></div>
                                <div>
                                    <h3>
                                        {ReactHtmlParser(
                                            this.state.content.block1Title
                                        )}
                                    </h3>
                                    <p>
                                        {ReactHtmlParser(
                                            this.state.content.block1Body
                                        )}
                                    </p>
                                    <a
                                        href={this.state.content.block1CTAUrl}
                                        className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                    >
                                        {this.state.content.block1CTATitle}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="c-circle__desc c-homepage__description">
                            <div className="o-flex two-col two-col-reverse">
                                <div
                                    className="two-col--img-lg"
                                    style={{
                                        backgroundImage: `url(${this.state.content.block2Image})`,
                                    }}
                                ></div>
                                <div>
                                    <h3>
                                        {ReactHtmlParser(
                                            this.state.content.block2Title
                                        )}
                                    </h3>
                                    <p>
                                        {ReactHtmlParser(
                                            this.state.content.block2Body
                                        )}
                                    </p>
                                    <a
                                        href={this.state.content.block2CTAUrl}
                                        className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                    >
                                        {this.state.content.block2CTATitle}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="c-circle__desc c-homepage__description">
                            <div className="o-flex two-col">
                                <div
                                    className="two-col--img-lg"
                                    style={{
                                        backgroundImage: `url(${this.state.content.block3Image})`,
                                    }}
                                ></div>
                                <div>
                                    <h3>
                                        {ReactHtmlParser(
                                            this.state.content.block3Title
                                        )}
                                    </h3>
                                    <p>
                                        {ReactHtmlParser(
                                            this.state.content.block3Body
                                        )}
                                    </p>
                                    <a
                                        href={this.state.content.block3CTAUrl}
                                        className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                    >
                                        {this.state.content.block3CTATitle}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="c-circle__more-bg">
                        <div className="c-circle__more">
                            <h2>
                                {ReactHtmlParser(
                                    this.state.content.actionsTitle
                                )}
                            </h2>
                            <p>
                                {ReactHtmlParser(
                                    this.state.content.actionsIntro
                                )}
                            </p>
                            <div className="c-plans__angels--items">
                                <div className="c-plans__angels--item">
                                    <img
                                        src={this.state.content.action1Image}
                                        alt="icon"
                                    />
                                    <h4>
                                        {ReactHtmlParser(
                                            this.state.content.action1Title
                                        )}
                                    </h4>
                                    <p>
                                        {ReactHtmlParser(
                                            this.state.content.action1Body
                                        )}
                                    </p>
                                </div>
                                <div className="c-plans__angels--item">
                                    <img
                                        src={this.state.content.action2Image}
                                        alt="icon"
                                    />
                                    <h4>
                                        {ReactHtmlParser(
                                            this.state.content.action2Title
                                        )}
                                    </h4>
                                    <p>
                                        {ReactHtmlParser(
                                            this.state.content.action2Body
                                        )}
                                    </p>
                                </div>
                                <div className="c-plans__angels--item">
                                    <img
                                        src={this.state.content.action3Image}
                                        alt="icon"
                                    />
                                    <h4>
                                        {ReactHtmlParser(
                                            this.state.content.action3Title
                                        )}
                                    </h4>
                                    <p>
                                        {ReactHtmlParser(
                                            this.state.content.action3Body
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="c-circle__cta">
                            {this.state.content &&
                            this.state.content.actionButtonUrl ? (
                                <a
                                    href={this.state.content.actionButtonUrl}
                                    className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-293 text-center btn--padding-left-right"
                                >
                                    {this.state.content.actionButtonTitle}
                                </a>
                            ) : (
                                <button
                                    onClick={() => this.handlePopupOpen()}
                                    className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-293 text-center btn--padding-left-right"
                                >
                                    {this.state.content.actionButtonTitle}
                                </button>
                            )}
                        </div>
                    </div>
                    <Popup
                        lockScroll
                        open={this.state.popupOpen}
                        onClose={this.handlePopupClose}
                        className=" c-funeral__popup c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                    >
                        {(close) => (
                            <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser c-funeral__popup--simple-register">
                                <button
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                    onClick={close}
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                                <>
                                    <img
                                        className="o-flex-self-center c-funeral__nav--logo"
                                        src={logo}
                                        alt="logo"
                                    ></img>
                                    <p className="font-18">
                                        If you're new to the Aura Circle, enter
                                        your details below to sign up. If you're
                                        already a member, please click the 'Log
                                        in' button at the bottom of this page.
                                    </p>
                                    <Formik
                                        initialValues={{
                                            email: this.state.accountEmail,
                                            name: '',
                                        }}
                                        validationSchema={Yup.object({
                                            email: Yup.string()
                                                .email(
                                                    'Please enter a valid E-mail Address.'
                                                )
                                                .required(
                                                    'This field is required.'
                                                ),
                                            name: Yup.string(),
                                        })}
                                        onSubmit={this.handleSubmitLogin}
                                    >
                                        {(props) => (
                                            <>
                                                <Form
                                                    noValidate
                                                    className="o-flex o-flex-column c-funeral__popup--login-form"
                                                >
                                                    {this.state.loginError ? (
                                                        <div className="font-13 font-white font-semibold status-message-error o-flex o-flex-between">
                                                            <p>
                                                                {
                                                                    this.state
                                                                        .loginError
                                                                }
                                                            </p>
                                                            <i
                                                                className="icon-times-thin font-white font-13"
                                                                onClick={
                                                                    this
                                                                        .closeError
                                                                }
                                                            ></i>
                                                        </div>
                                                    ) : null}
                                                    <RequiredInput
                                                        label="First Name"
                                                        type="text"
                                                        name="name"
                                                    />
                                                    <RequiredInput
                                                        name="email"
                                                        label="Email Address"
                                                        type="Email"
                                                    />
                                                    <button
                                                        className={`btn btn--full btn-loader ${
                                                            this.state
                                                                .saveInProgress
                                                                ? 'save-in-progress'
                                                                : ''
                                                        } position-relative overflow-both-hidden z-index-1 mb-20`}
                                                        type="submit"
                                                    >
                                                        Submit
                                                        <img
                                                            width="100%"
                                                            height="100%"
                                                            src={loader}
                                                            alt="loaderImage"
                                                        />
                                                    </button>
                                                    <h1 className="text-center width-full font-16 c-auth-page__auth-form--cta mt-10 mb-20 ">
                                                        <span className="already-have-label">
                                                            Already have an Aura
                                                            Account?
                                                        </span>{' '}
                                                        <span>
                                                            <Link
                                                                className="link-underline already-have-label"
                                                                to="/login"
                                                            >
                                                                Log in
                                                            </Link>
                                                        </span>
                                                    </h1>
                                                </Form>
                                            </>
                                        )}
                                    </Formik>
                                    <p className="font-18 opacity-9">
                                        By registering your email address you
                                        agree to receive marketing emails from
                                        Aura.
                                    </p>
                                </>
                            </div>
                        )}
                    </Popup>
                </>
            );
        }

        return (
            <>
                <PageMeta
                    title="Aura Circle | Aura"
                    description="Aura Circle | Aura"
                    canonical={window.location.href}
                />
                <div className="c-circle">
                    <Header
                        bannerContent={
                            this.state.banner?.length > 0 && bannerData
                        }
                        bannerVisible={
                            this.state.banner?.length > 0 &&
                            bannerData?.auraCircle === true
                        }
                        hideBanner={this.updateBannerVisibilty}
                    />
                    {content}
                    <Footer />
                </div>
            </>
        );
    }
}

const auraCircleWithRouter = withRouter(AuraCircle);

export default auraCircleWithRouter;
