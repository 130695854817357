import React, { Component } from 'react';
import ApiService from '../../../../../service/api.service';
import { connect } from 'react-redux';
import * as funeralPaymentActionTypes from '../../../../../store/actions/funeralPaymentActions';
import Loader from '../../../../../assets/images/loader.svg';
class SliderAddon extends Component {
    state = {
        added: false,
        uuid: null,
        modalOpen: false,
        preselected: false,
    };

    componentDidMount() {
        if (
            this.props.funeralPayment &&
            this.props.funeralPayment.userAddOns.length
        ) {
            this.props.funeralPayment.userAddOns.forEach((addon) => {
                if (addon.addOn.id === this.props.addon.id) {
                    this.setState({
                        added: true,
                        uuid: addon.uuid,
                        preselected: addon.preselected,
                    });
                }
            });
        }
    }

    handleAddonClick = () => {
        if (!this.state.preselected) {
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    if (!this.state.added) {
                        const data = {
                            addOn: `/api/funeral_plan_add_ons/${this.props.addon.id}`,
                            userFuneralPlan: `/api/user_funeral_plans/${this.props.funeralPayment.uuid}`,
                        };

                        ApiService.addAddon(data).then((r) => {
                            ApiService.getTotalPrice(
                                this.props.funeralPayment.uuid
                            ).then((res) => {
                                this.props.onSetTotal(
                                    res.data.total,
                                    res.data.subtotal
                                );
                                this.setState(
                                    {
                                        added: true,
                                        uuid: r.data.uuid,
                                        isLoading: false,
                                    },
                                    () => {
                                        this.props.addAddon(r.data);
                                    }
                                );
                            });
                        });
                    } else {
                        ApiService.removeAddon(this.state.uuid).then((r) => {
                            this.props.removeAddon(this.state.uuid);
                            ApiService.getTotalPrice(
                                this.props.funeralPayment.uuid
                            ).then((res) => {
                                this.props.onSetTotal(
                                    res.data.total,
                                    res.data.subtotal
                                );
                                this.setState({
                                    added: false,
                                    uuid: null,
                                    isLoading: false,
                                });
                            });
                        });
                    }
                }
            );
        }
    };

    handleOpenModal = (e) => {
        e.stopPropagation();
        this.props.onOpenModal(
            this.props.addon.title,
            this.props.addon.fullDescription
                ? this.props.addon.fullDescription
                : ''
        );
    };

    render() {
        return (
            <div
                onClick={
                    !this.props.deletable ? () => this.handleAddonClick() : null
                }
                style={{ backgroundImage: `url(${this.props.addon.image})` }}
                className={`${this.state.added ? 'added' : ''}`}
            >
                {!this.props.deletable ? (
                    <>
                        <div className="slide-card-mark">
                            <i
                                onClick={(e) => this.handleOpenModal(e)}
                                className="icon-question font-15"
                            ></i>
                        </div>
                    </>
                ) : null}
                {!this.props.deletable && this.state.preselected ? (
                    <div className="slide-card-money">
                        <h6>Included</h6>
                    </div>
                ) : null}
                <h4>{this.props.addon.title}</h4>
                {this.state.isLoading ? (
                    <div className="slide-card-loader">
                        <img src={Loader} alt="loader"></img>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    funeralPayment: state.funeralPaymentReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        removeAddon: (uuid) =>
            dispatch(funeralPaymentActionTypes.removeAddon(uuid)),
        addAddon: (addon) =>
            dispatch(funeralPaymentActionTypes.addAddon(addon)),
        onSetTotal: (total, subTotal) =>
            dispatch(funeralPaymentActionTypes.onSetTotal(total, subTotal)),
        onOpenModal: (title, body) =>
            dispatch(funeralPaymentActionTypes.onOpenModal(title, body)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderAddon);
