import * as actionTypes from '../actions/routerActions';

const initalState = {
    route: '',
};

const routerReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_ROUTE:
            return {
                ...state,
                route: action.route,
            };
        default:
            return state;
    }
};

export default routerReducer;
