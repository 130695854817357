import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class WISnipet extends Component {
    state = {
        activeImage: '',
    };
    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));
    }
    resize = () => {
        if (window.innerWidth > 720) {
            if (this.state.activeImage !== this.props.horizontalImage) {
                this.setState({
                    activeImage: this.props.horizontalImage,
                });
            }
        } else {
            if (this.state.activeImage !== this.props.verticalImage) {
                this.setState({
                    activeImage: this.props.verticalImage,
                });
            }
        }
    };
    render() {
        return (
            <div
                className="c-wi-snippet"
                style={{
                    backgroundImage: `url(${this.state.activeImage})`,
                }}
            >
                <div className="c-wi-snippet__content container">
                    {this.props.content.title && (
                        <h2>{ReactHtmlParser(this.props.content.title)} </h2>
                    )}
                    {this.props.content.titlePrice && (
                        <p>{ReactHtmlParser(this.props.content.titlePrice)}</p>
                    )}
                    {this.props.content.fullDescription && (
                        <p>
                            {ReactHtmlParser(
                                this.props.content.fullDescription
                            )}
                        </p>
                    )}
                    <div>
                        {this.props.content.ctaTitle && (
                            <a
                                href={
                                    !this.props.content.ctaLink
                                        ? 'tel:+448008085723'
                                        : this.props.content.ctaLink &&
                                          !this.props.content.ctaLink.includes(
                                              'tel'
                                          )
                                        ? this.props.content.ctaLink
                                        : this.props.content.ctaLink.includes(
                                              'tel'
                                          )
                                        ? `tel:${this.props.content.ctaLink.replace(
                                              'http://tel:',
                                              ''
                                          )}`
                                        : ''
                                }
                                className="btn--ghost-purple btn--double-border c-btn btn btn--full btn--size-width-246 btn--size-57 text-center o-d-inline-block"
                            >
                                {this.props.content.ctaTitle}
                            </a>
                        )}
                        {this.props.content.ctaTitle2 && (
                            <a
                                href={
                                    !this.props.content.ctaLink2
                                        ? 'tel:+448008085723'
                                        : this.props.content.ctaLink2 &&
                                          !this.props.content.ctaLink2.includes(
                                              'tel'
                                          )
                                        ? this.props.content.ctaLink2
                                        : this.props.content.ctaLink2.includes(
                                              'tel'
                                          )
                                        ? `tel:${this.props.content.ctaLink2.replace(
                                              'http://tel:',
                                              ''
                                          )}`
                                        : ''
                                }
                                className='"c-btn btn btn--full btn--size-width-246 btn--size-57 text-center o-d-inline-block'
                            >
                                {this.props.content.ctaTitle2}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default WISnipet;
