import * as actionTypes from '../actions/lifeStoryActions';

const initialState = {
    lifeStory: null,
    lifeStoryEditors: [],
    newLifeStoryEditors: [],
    lifeStorySections: [],
};

const lifeStoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIFESTORY:
            return { ...state, lifeStorySections: action.lifeStorySections };
        case actionTypes.SET_LIFE_EDITORS:
            return { ...state, lifeStoryEditors: action.lifeEditors };
        case actionTypes.SET_NEW_LIFE_EDITORS:
            return { ...state, newLifeStoryEditors: action.newLifeEditors };
        case actionTypes.REMOVE_LIFE_EDITOR:
            let newLifeStoryEditors = [...state.newLifeStoryEditors];
            let lifeStoryEditor = newLifeStoryEditors.find(
                (e) => e.id === action.userId
            );
            let editorIndex = newLifeStoryEditors.indexOf(lifeStoryEditor);
            if (lifeStoryEditor) {
                newLifeStoryEditors[editorIndex].isMyLSEditor = false;
            }
            return {
                ...state,
                lifeStoryEditors: state.lifeStoryEditors.filter(
                    (e) => e.id !== action.lifeEditor
                ),
                newLifeStoryEditors: newLifeStoryEditors,
            };
        case actionTypes.ADD_LIFE_EDITOR:
            let lifeEditors = [...state.lifeStoryEditors];
            let newLifeEditors = [...state.newLifeStoryEditors];
            let lifeEditor = newLifeEditors.find(
                (e) => e.id === action.userPayload.user.id
            );
            let index = newLifeEditors.indexOf(lifeEditor);
            newLifeEditors[index].isMyLSEditor = true;
            const user = {
                id: action.userPayload.id,
                userLifeStory: `/api/user_life_stories/${action.userPayload.id}`,
                user: action.userPayload.user,
            };
            lifeEditors.push(user);
            return {
                ...state,
                lifeStoryEditors: lifeEditors,
                newLifeStoryEditors: newLifeEditors,
            };
        case actionTypes.PUBLISH_TOGGLE:
            return { ...state };
        case actionTypes.ADD_NEW_SECTION:
            let lifeStoryArr = [...state.lifeStorySections];
            lifeStoryArr.push(action.lifeStory);
            return { ...state, lifeStorySections: lifeStoryArr };
        case actionTypes.REMOVE_LIFE_SECTION:
            return {
                ...state,
                lifeStorySections: state.lifeStorySections.filter(
                    (s) => s.id !== action.lifeStoryId
                ),
            };
        case actionTypes.UPDATE_LIFESTORY_SUBSECTION:
            const lsSections = [...state.lifeStorySections];
            lsSections[
                lsSections.findIndex(
                    (s) => s.id === action.updatedSubsection.id
                )
            ] = action.updatedSubsection;

            return {
                ...state,
                lifeStorySections: lsSections,
            };
        case actionTypes.CLEAR_LIFESTORY_EDITORS:
            return {
                ...state,
                newLifeStoryEditors: [],
            };
        default:
            return state;
    }
};

export default lifeStoryReducer;
