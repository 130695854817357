import * as funeralPaymentActionTypes from '../../store/actions/funeralPaymentActions';
import * as userActionTypes from '../../store/actions/usersActions';

import React, { Component } from 'react';

import AddOns from './Steps/AddOns/AddOns';
import Amount from '../../components/FuneralPayment/Amount/Amount';
import ApiService from '../../service/api.service';
import Contact from './Steps/Contact/Contact';
import Loader from '../../components/UI/Loader/Loader';
import Options from './Steps/Options/Options';
import PageMeta from '../../components/UI/PageMeta/PageMeta';
import Payment from './Steps/Payment/Payment';
import Personal from './Steps/Personal/Personal';
import StepTrack from '../../components/FuneralPayment/StepTrack/StepTrack';
import { connect } from 'react-redux';
import Demands from './Steps/Contact/Demands/Demands';
import PaymentFlowSidebar from '../../components/PaymentFlowSidebar/PaymentFlowSidebar';
class FuneralPayment extends Component {
    state = {
        options: [],
        isLoading: true,
        demands: false,
    };

    componentDidMount() {
        if (this.props.location.state?.prevPath?.includes('/payment/')) {
            this.setState({ demands: false });
        } else {
            this.setState({ demands: true });
        }
        document.body.classList.add('white-bg');
        if (window.addressNow) {
            window.addressNow.load();
            window.addressNow.listen('load', (control) => {
                const createdByAddress =
                    document.getElementsByName('createdByAddress');
                const holderFullAddress =
                    document.getElementsByName('holderFullAddress');
                const kinFullAddress =
                    document.getElementsByName('kinFullAddress');
                control.listen('populate', (address) => {
                    if (holderFullAddress[0] && holderFullAddress[0].value) {
                        holderFullAddress[0].focus();
                        holderFullAddress[0].blur();
                    }

                    if (kinFullAddress[0] && kinFullAddress[0].value) {
                        kinFullAddress[0].focus();
                        kinFullAddress[0].blur();
                    }

                    if (createdByAddress[0] && createdByAddress[0].value) {
                        createdByAddress[0].focus();
                        createdByAddress[0].blur();
                    }
                });
            });
        }
        let plan = 'essential';
        if (window.location.href.includes('essential')) {
            plan = 'essential';
        } else if (window.location.href.includes('celebration')) {
            plan = 'celebration-of-life';
        } else if (window.location.href.includes('imagined')) {
            plan = 'imagined';
        } else if (window.location.href.includes('simple')) {
            plan = 'simple';
        }

        const params = new URLSearchParams();
        params.append('plans.slug', plan);
        params.append('order[price]', 'asc');
        ApiService.funeralPlanOptionsFiltered(params).then((response) => {
            if (response.data.length) {
                this.props.onSetTotalAmount(response.data[0].money);
            }
            this.setState({
                options: response.data,
                isLoading: false,
            });
        });

        //get user plans if redirected from plans page
        if (this.props.user && this.props.user.userFuneralPlan) {
            const funeralPlanId = this.props.user.userFuneralPlan.uuid;
            ApiService.getUserFuneralPlan(funeralPlanId)
                .then((r) => {
                    this.props.onSetFuneralCheckout(r.data);
                    this.props.onSetFuneralPlan(plan);
                    this.setState({
                        isLoading: false,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('white-bg');
    }

    handleFormikChange = (type, value) => {
        if (type === 'kin') {
            this.setState({ kinFullAddress: value });
        } else if (type === 'holder') {
            this.setState({ holderFullAddress: value });
        } else {
            this.setState({ createdByFullAddress: value });
        }
    };

    handleDemandClick = () => {
        this.setState({
            demands: false,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.user !== this.props.user &&
            this.props.user &&
            this.props.user.userFuneralPlan
        ) {
            let plan = 'essential';
            if (window.location.href.includes('essential')) {
                plan = 'essential';
            } else if (window.location.href.includes('celebration')) {
                plan = 'celebration-of-life';
            } else if (window.location.href.includes('imagined')) {
                plan = 'imagined';
            } else if (window.location.href.includes('simple')) {
                plan = 'simple';
            }
            this.setState(
                {
                    isLoading: true,
                },
                () => {
                    const funeralPlanId = this.props.user.userFuneralPlan.uuid;
                    if (this.props.user.userFuneralPlan.plan.slug === plan) {
                        ApiService.getUserFuneralPlan(funeralPlanId)
                            .then((r) => {
                                this.props.onSetFuneralCheckout(r.data);
                                this.props.onSetFuneralPlan(plan);
                                this.setState({
                                    isLoading: false,
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        ApiService.deleteFuneralPlanPayment(
                            this.props.user.userFuneralPlan.uuid
                        ).then((r) => {
                            this.props.onDeleteFuneralPlan();
                            this.setState({
                                isLoading: false,
                            });
                        });
                    }
                }
            );
        }
    }

    render() {
        let content;

        if (this.state.isLoading) {
            content = <Loader />;
        } else {
            content = (
                <>
                    <div className="c-funeral__nav o-flex o-flex-middle o-flex-center position-relative">
                        <StepTrack />
                        <Amount />
                    </div>
                    <div className="c-funeral__form o-flex">
                        {this.props.funeralPayment.currentStep === 0 ? (
                            <Contact />
                        ) : null}
                        {this.props.funeralPayment.currentStep === 1 ? (
                            <Options options={this.state.options} />
                        ) : null}
                        {this.props.funeralPayment.currentStep === 2 ? (
                            <AddOns />
                        ) : null}
                        {this.props.funeralPayment.currentStep === 3 ? (
                            <Personal />
                        ) : null}
                        {this.props.funeralPayment.currentStep === 4 ? (
                            <Payment />
                        ) : null}
                        <PaymentFlowSidebar />
                    </div>
                </>
            );
        }

        if (this.state.demands) {
            content = <Demands setDemands={() => this.handleDemandClick()} />;
        }

        return (
            <div className="c-funeral">
                <PageMeta
                    title="Payment | Aura"
                    description="Payment | Aura"
                    canonical={window.location.href}
                />
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
    funeralPayment: state.funeralPaymentReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCurrentStep: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(currentStep)),
        onSetFuneralCheckout: (data) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralCheckout(data)),
        onSetFuneralPlan: (plan) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralPlan(plan)),
        onSetTotalAmount: (price) =>
            dispatch(funeralPaymentActionTypes.onSetTotalAmount(price)),
        onDeleteFuneralPlan: () =>
            dispatch(userActionTypes.onDeleteFuneralPlan()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralPayment);
