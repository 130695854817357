import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import imagePlaceholder from '../../../assets/images/profile-placeholder.png';
import imageLogo from '../../../assets/images/logo-without-slogan.svg';
import loginImage from '../../../assets/images/aura2022/icons/new-user.svg';
import callImage from '../../../assets/images/aura2022/icons/phone-gray.svg';
import bellImage from '../../../assets/images/aura2022/icons/bellImage.svg';
import { connect } from 'react-redux';
import * as notifActionTypes from '../../../store/actions/notificationActions';
import * as userActionTypes from '../../../store/actions/usersActions';
// import Submenu from '../Submenu/Submenu';
import lifeStoryIcon from '../../../assets/images/lifstory.svg';
import wishesIcon from '../../../assets/images/wishesIconDropdown.svg';
import practicalInfoIcon from '../../../assets/images/practical.svg';
import messagesIcon from '../../../assets/images/messagesIcon.svg';
import connectionsIcon from '../../../assets/images/connectionsIcon.svg';
import ProfileSidebar from '../../Profile/ProfileSidebar/ProfileSidebar';
import Cookie from 'js-cookie';
import ApiService from '../../../service/api.service';
import { confirmAlert } from 'react-confirm-alert';
import confirmLogoutImg from '../../../assets/images/are-you-sure-logout.png';

class NewNavigation extends Component {
    state = {
        mobileMenuOpen: false,
        isProfileOpen: false,
        phone: null,
        showBanner: true,
    };

    componentDidMount() {
        setTimeout(() => {
            let phone = Cookie.get('phone');
            if (phone) {
                this.setState({
                    phone: phone,
                });
            }
        });
    }

    handleToggleMobileMenu = () => {
        this.setState({
            mobileMenuOpen: !this.state.mobileMenuOpen,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.mobileMenuOpen !== prevState.mobileMenuOpen) {
            if (this.state.mobileMenuOpen) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        }

        if (
            this.props.history.location.pathname !==
            prevProps.history.location.pathname
        ) {
            let phone = Cookie.get('phone');
            if (phone) {
                this.setState({
                    phone: phone,
                });
            }
        }
    }

    toggleNotifications = (e) => {
        e.preventDefault();
        this.props.onNotificationToggle();
        if (!this.props.notifications.notificationsOpen) {
            this.props.onNotificationCountSubscribe(parseInt(0));
        }
    };

    handleClickLogout = (e) => {
        e.preventDefault();
        ApiService.getUncompletedPlans(this.props.user.id).then((r) => {
            if (r.data.userFuneralPlan) {
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <div className="popup-content popup-content--smaller text-center">
                            <div className="c-popup">
                                <h2 className="margin-reset font-24 mb-20 font-bold mt-29 font-family-secondary font-333 opacity-9">
                                    Are you sure you want to log out?
                                </h2>
                                <div className="c-popup__header">
                                    <img
                                        src={confirmLogoutImg}
                                        alt="confirm alert icon"
                                    />
                                </div>
                                <p className="margin-reset font-18 mt-20 font-333 lh-28">
                                    If you log out now, you will need to
                                    re-enter your email and password again
                                    before you can finish purchasing your
                                    funeral plan. Would you still like to log
                                    out of your account?"
                                </p>
                                <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                    <button
                                        className="btn btn--full btn--size-width-180"
                                        onClick={() => {
                                            this.handleLogout();
                                            onClose();
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn--ghost-purple btn--size-width-180"
                                        onClick={onClose}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else {
                this.handleLogout();
            }
        });
    };

    handleLogout = () => {
        this.handleToggleMobileMenu();
        this.props.onCloseMobileProfile();
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenRefreshedDate');
        this.props.onLogoutUser();
        this.props.history.push('/logout');
        window.location.reload();
    };

    handleProfileOpen = () => {
        if (this.props.mobileProfileOpen) {
            document.body.classList.remove('profile-open');
            this.setState(
                {
                    isProfileOpen: false,
                },
                () => {
                    this.props.onToggleMobileProfile();
                }
            );
        } else {
            document.body.classList.add('profile-open');
            this.setState(
                {
                    isProfileOpen: true,
                },
                () => {
                    this.props.onToggleMobileProfile();
                }
            );
        }
    };

    handleProfileOpen = () => {
        if (this.props.mobileProfileOpen) {
            document.body.classList.remove('profile-open');
            this.setState(
                {
                    isProfileOpen: false,
                },
                () => {
                    this.props.onToggleMobileProfile();
                }
            );
        } else {
            document.body.classList.add('profile-open');
            this.setState(
                {
                    isProfileOpen: true,
                },
                () => {
                    this.props.onToggleMobileProfile();
                }
            );
        }
    };

    handleHideBanner = () => {
        this.props.hideBanner();
        this.setState({ showBanner: false });
    };

    render() {
        return (
            <div>
                {/* <div className="c-navigation o-flex o-flex-around"> */}
                {/* <div className="c-navigation__logo-wrapper">
                        <Link to="/" className="c-navigation__logo"></Link>
                    </div> */}

                {/* <div className="c-navigation__submenu">
                        <Submenu />
                    </div> */}
                {/* <div className="c-navigation__link-wrapper">
                        <Link
                            className={`${
                                window.location.href.includes(
                                    '/funerals/funeral-plan'
                                )
                                    ? 'active'
                                    : ''
                            }`}
                            to="/funerals/funeral-plan"
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            Funeral Plans
                        </a>
                    </div>

                    <div className="c-navigation__link-wrapper">
                        <a
                            href="/funerals"
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            Arrange a Funeral
                        </Link>
                    </div> */}

                {/* <div className="c-navigation__link-wrapper">
                        <Link
                            className={`${
                                window.location.pathname === '/locations'
                                    ? 'active'
                                    : ''
                            }`}
                            to="/locations"
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            Locations
                        </Link>
                    </div> */}

                {/* <div className="c-navigation__link-wrapper">
                        <Link
                            className={`${
                                window.location.href.includes('aura-circle')
                                    ? 'active'
                                    : ''
                            }`}
                            to={
                                this.props.user
                                    ? '/aura-circle-member'
                                    : '/aura-circle'
                            }
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            Circle
                        </Link>
                    </div> */}
                {/* <div className="c-navigation__link-wrapper">
                        <Link
                            className={`${
                                window.location.href.includes('about')
                                    ? 'active'
                                    : ''
                            }`}
                            to="/about"
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            About us
                        </a>
                    </div>

                    <div className="c-navigation__link-wrapper">
                        <a
                            href="/knowledge/articles"
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            Knowledge
                        </a>
                    </div>

                    <div className="c-navigation__link-wrapper">
                        <a
                            href="/help/faqs"
                        >
                            <span className="c-navigation__link">aura</span>{' '}
                            Help
                        </Link>
                    </div> */}
                {/* </div> */}

                {this.props.user ? (
                    <div
                        className={`c-user-mobile-menu o-none-from-mobile ${
                            this.state.isProfileOpen
                                ? 'c-user-mobile-menu__profile-visible'
                                : ''
                        }`}
                    >
                        <ul className="c-user-mobile-menu__list o-flex o-flex-middle o-flex-between position-relative margin-reset">
                            <li
                                className={`position-relative c-user-mobile-menu__list--item ${
                                    window.location.pathname.includes(
                                        '/life-story'
                                    ) &&
                                    window.location.pathname.includes(
                                        this.props.user.profile.slug
                                    )
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {window.location.pathname.includes(
                                    '/life-story'
                                ) &&
                                window.location.pathname.includes(
                                    this.props.user.profile.slug
                                ) &&
                                !window.location.pathname.includes(
                                    'my-aura'
                                ) ? (
                                    <div
                                        className="o-flex o-flex-middle o-flex-column"
                                        onClick={this.handleLSOpen}
                                    >
                                        <span className="c-user-mobile-menu__list--icon">
                                            <img
                                                src={lifeStoryIcon}
                                                alt="life story icon"
                                            />
                                        </span>
                                        <span className="font-family-secondary font-white font-500 font-16 c-user-mobile-menu__list--title">
                                            Life Story
                                        </span>
                                    </div>
                                ) : (
                                    <Link
                                        className="o-flex o-flex-middle o-flex-column"
                                        to={`/my-aura/${
                                            this.props.user &&
                                            this.props.user.profile.slug
                                        }/life-story`}
                                    >
                                        <span className="c-user-mobile-menu__list--icon">
                                            <img
                                                src={lifeStoryIcon}
                                                alt="life story icon"
                                            />
                                        </span>
                                        <span className="font-family-secondary font-white font-500 font-16 c-user-mobile-menu__list--title">
                                            Life Story
                                        </span>
                                    </Link>
                                )}
                            </li>
                            <li
                                className={`position-relative c-user-mobile-menu__list--item ${
                                    window.location.pathname.includes(
                                        '/wishes'
                                    ) &&
                                    window.location.pathname.includes('my-aura')
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <Link
                                    className="o-flex o-flex-middle o-flex-column"
                                    to={`/my-aura/${
                                        this.props.user &&
                                        this.props.user.profile.slug
                                    }/wishes`}
                                >
                                    <span className="c-user-mobile-menu__list--icon">
                                        <img
                                            src={wishesIcon}
                                            alt="life story icon"
                                        />
                                    </span>
                                    <span className="font-family-secondary font-white font-500 font-16 c-user-mobile-menu__list--title">
                                        Wishes
                                    </span>
                                </Link>
                            </li>
                            <li
                                className={`position-relative c-user-mobile-menu__list--item ${
                                    window.location.pathname.includes(
                                        '/practical-information'
                                    ) &&
                                    window.location.pathname.includes('my-aura')
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <Link
                                    className="o-flex o-flex-middle o-flex-column"
                                    to={`/my-aura/${
                                        this.props.user &&
                                        this.props.user.profile.slug
                                    }/practical-information`}
                                >
                                    <span className="c-user-mobile-menu__list--icon">
                                        <img
                                            src={practicalInfoIcon}
                                            alt="life story icon"
                                        />
                                    </span>
                                    <span className="font-family-secondary font-white font-500 font-16 c-user-mobile-menu__list--title">
                                        Practical Info
                                    </span>
                                </Link>
                            </li>
                            <li
                                className={`position-relative c-user-mobile-menu__list--item ${
                                    window.location.pathname.includes(
                                        '/messages'
                                    ) &&
                                    window.location.pathname.includes('my-aura')
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {this.props.unreadThreadCount > 0 ? (
                                    <span className="c-counter position-absolute o-flex o-flex-middle o-flex-center font-white z-index-1">
                                        {this.props.unreadThreadCount}
                                    </span>
                                ) : null}
                                <Link
                                    className="o-flex o-flex-middle o-flex-column"
                                    to={`/my-aura/${
                                        this.props.user &&
                                        this.props.user.profile.slug
                                    }/messages`}
                                >
                                    <span className="c-user-mobile-menu__list--icon">
                                        <img
                                            src={messagesIcon}
                                            alt="life story icon"
                                        />
                                    </span>
                                    <span className="font-family-secondary font-white font-500 font-16 c-user-mobile-menu__list--title">
                                        Messages
                                    </span>
                                </Link>
                            </li>
                            <li
                                className={`position-relative c-user-mobile-menu__list--item ${
                                    window.location.pathname.includes(
                                        '/my-connections'
                                    )
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <Link
                                    className="o-flex o-flex-middle o-flex-column"
                                    to={`/profile/connections/my-connections/${
                                        this.props.user &&
                                        this.props.user.profile.slug
                                    }/connected`}
                                >
                                    <span className="c-user-mobile-menu__list--icon">
                                        <img
                                            src={connectionsIcon}
                                            alt="life story icon"
                                        />
                                    </span>

                                    <span className="font-family-secondary font-white font-500 font-16 c-user-mobile-menu__list--title">
                                        Connections
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                ) : null}

                <div
                    className={`c-navigation c-navigation-mob ${
                        this.props.bannerVisible && this.state.showBanner
                            ? 'c-pre-header__has-banner'
                            : ''
                    }`}
                >
                    {/* <div className="c-top-banner">
                        {this.props.bannerVisible && this.state.showBanner && (
                            <div className="c-top-banner__content">
                                <img src={this.props.bannerImage} alt="" />
                                <div>
                                    {' '}
                                    {ReactHtmlParser(
                                        this.props.bannerContent
                                    )}{' '}
                                </div>
                                <button
                                    onClick={this.handleHideBanner}
                                ></button>{' '}
                            </div>
                        )}
                    </div> */}

                    <div className="o-flex width-full c-navigation-mob__box">
                        <div className="c-navigation-mob__logo-label">
                            <img
                                width="60"
                                height="100%"
                                src={imageLogo}
                                alt="imageLogo"
                            />
                            <strong>
                                Aura <br />
                                Circle{' '}
                            </strong>
                        </div>

                        {window.innerWidth < 768 ? (
                            <div
                                className={`c-user-profile-mobile o-none-from-mobile ${
                                    this.state.isProfileOpen
                                        ? 'c-user-profile-mobile__visible'
                                        : ''
                                }`}
                            >
                                <h2 className="font-bold font-24 lh-28 text-center font-333 opacity-8 mb-40 o-none-from-mobile">
                                    My Profile
                                </h2>
                                <ProfileSidebar isUser={true} />
                            </div>
                        ) : null}

                        {this.props.user ? (
                            <div className="c-navigation__icons c-navigation__icons__postlogin">
                                <div
                                    onClick={(e) => this.toggleNotifications(e)}
                                    className="position-relative"
                                >
                                    {this.props.notifications
                                        .notificationCount > 0 ? (
                                        <span className="c-counter position-absolute o-flex o-flex-middle o-flex-center font-white z-index-1">
                                            {
                                                this.props.notifications
                                                    .notificationCount
                                            }
                                        </span>
                                    ) : null}
                                    <img
                                        className="c-navigation__icons__postlogin--bell-icon"
                                        width="16"
                                        height="18"
                                        src={bellImage}
                                        alt="bellImage"
                                    />
                                </div>
                                <span></span>
                                <img
                                    onClick={() => this.handleProfileOpen()}
                                    width="16"
                                    height="18"
                                    src={loginImage}
                                    alt="loginImage"
                                />
                                <span></span>
                            </div>
                        ) : (
                            <div className="c-navigation__icons c-navigation__icons__prelogin">
                                {this.props.funeralPhone ? (
                                    <a
                                        href={`tel:+44${this.props.funeralPhone.substring(
                                            1
                                        )}`}
                                    >
                                        <img
                                            width="16"
                                            height="100%"
                                            src={callImage}
                                            alt="callImage"
                                        />
                                        <span className="font-tundora">
                                            CALL
                                        </span>
                                    </a>
                                ) : (
                                    <a href="tel:+448008085723">
                                        <img
                                            width="16"
                                            height="100%"
                                            src={callImage}
                                            alt="callImage"
                                        />
                                        <span className="font-tundora">
                                            CALL
                                        </span>
                                    </a>
                                )}
                                <span></span>
                                <Link to="/register">
                                    <img
                                        width="18"
                                        height="100%"
                                        src={loginImage}
                                        alt="loginImage"
                                    />
                                    <span className="font-tundora">JOIN</span>
                                </Link>
                                <span></span>
                            </div>
                        )}

                        <div
                            className={`c-navigation-mob__dropdown ${
                                this.state.mobileMenuOpen ? 'nav-open' : ''
                            }`}
                        >
                            <button
                                onClick={() => this.handleToggleMobileMenu()}
                                className="c-header__hamburger-menu c-hamburger-menu o-d-flex-tablet o-flex-column btn btn--no-border btn--size-auto btn--padding-clear font-tundora font-16 btn--font-w-400 o-flex-middle"
                            >
                                <div className="c-hamburger-menu__icon position-relative cursor-pointer">
                                    <span className="c-hamburger-menu__icon--first position-absolute"></span>
                                    <span className="c-hamburger-menu__icon--second position-absolute"></span>
                                    <span className="c-hamburger-menu__icon--third position-absolute"></span>
                                    <span className="c-hamburger-menu__icon--fourth position-absolute"></span>
                                </div>
                                <span className="font-tundora font-14 c-hamburger-menu__title c-hamburger-menu__title--closed">
                                    MENU
                                </span>
                                <span className="font-tundora font-14 c-hamburger-menu__title c-hamburger-menu__title--opened">
                                    CLOSE
                                </span>
                            </button>

                            <div
                                className={`c-navigation-mob__dropdown--menu ${
                                    this.state.mobileMenuOpen
                                        ? 'c-navigation-mob__dropdown--menu--open'
                                        : ''
                                }`}
                            >
                                {this.props.user ? (
                                    <div className="c-navigation-mob__dropdown--user-info">
                                        <img
                                            src={
                                                this.props.user &&
                                                this.props.user.profile.photo
                                                    ? `${this.props.user.profile.photo.url}`
                                                    : imagePlaceholder
                                            }
                                            alt="mobile user icon"
                                        />

                                        <div className="c-navigation-mob__dropdown--user-info-details">
                                            <p className="c-navigation-mob__dropdown--user-info-name">
                                                {this.props.user?.name
                                                    ? this.props.user.name
                                                    : ''}
                                            </p>
                                            <p className="c-navigation-mob__dropdown--user-info-mail">
                                                {this.props.user?.email
                                                    ? this.props.user.email
                                                    : ''}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {/* <Link
                                    className={`${
                                        window.location.href.includes(
                                            '/funerals/funeral-plan'
                                        )
                                            ? 'active'
                                            : ''
                                    }`}
                                    to="/funerals/funeral-plan"
                                >
                                    <span>aura</span> Funeral Plans
                                </a>
                                <a
                                    href="/funerals"
                                >
                                    <span>aura</span> Arrange a Funeral
                                </Link> */}

                                {/* <Link
                                    className={`${
                                        window.location.pathname ===
                                        '/locations'
                                            ? 'active'
                                            : ''
                                    }`}
                                    to="/locations"
                                >
                                    <span>aura</span> Locations
                                </Link> */}
                                {/* <Link
                                    className={`${
                                        window.location.href.includes(
                                            'aura-angels'
                                        )
                                            ? 'active'
                                            : ''
                                    }`}
                                    to="/aura-angels"
                                >
                                    <span>aura</span> Angels
                                </Link> */}
                                {/* <Link
                                    className={`${
                                        window.location.href.includes(
                                            'aura-circle'
                                        )
                                            ? 'active'
                                            : ''
                                    }`}
                                    to={
                                        this.props.user
                                            ? '/aura-circle-member'
                                            : '/aura-circle'
                                    }
                                >
                                    <span>aura</span> Circle
                                </Link> */}
                                {/* <Link
                                    className={`${
                                        window.location.href.includes('about')
                                            ? 'active'
                                            : ''
                                    }`}
                                    to="/about"
                                >
                                    <span>aura</span> About us
                                </a>
                                <a
                                    href="/knowledge/articles"
                                >
                                    <span>aura</span> Knowledge
                                </a>
                                <a
                                    href="/help/faqs"
                                >
                                    <span>aura</span> Help
                                </Link> */}

                                {/* <div className="c-navigation-mob__dropdown--menu-section"> */}
                                {/* <Link
                                        onClick={() =>
                                            this.handleToggleMobileMenu()
                                        }
                                        className={`${
                                            window.location.pathname === '/'
                                                ? 'active'
                                                : ''
                                        }`}
                                        to="/"
                                    >
                                        <i className="icon-homeIcon"></i>Home
                                    </Link> */}
                                {/* <Link
                                        onClick={() =>
                                            this.handleToggleMobileMenu()
                                        }
                                        className={`${
                                            window.location.href.includes(
                                                '/help/faqs'
                                            )
                                                ? 'active'
                                                : ''
                                        }`}
                                        to="/help/faqs"
                                    >
                                        <i className="icon-helpIcon"></i>Help
                                    </a>
                                    <a
                                        href="/knowledge/articles"
                                    >
                                        <i className="icon-knowledgeIcon"></i>
                                        Knowledge
                                    </Link> */}
                                {/* </div> */}
                                {this.props.user ? (
                                    <div className="c-navigation-mob__dropdown--menu-section c-navigation-mob__postlogin">
                                        <ul>
                                            <li>
                                                <Link
                                                    onClick={() =>
                                                        this.handleToggleMobileMenu()
                                                    }
                                                    className={`o-flex-middle o-flex ${
                                                        window.location.href.includes(
                                                            'aura-circle-member'
                                                        )
                                                            ? 'active-sidebar-link'
                                                            : ''
                                                    }`}
                                                    to="/aura-circle-member"
                                                >
                                                    <i className="icon-circle-icon"></i>
                                                    Your Aura Circle
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() =>
                                                        this.handleToggleMobileMenu()
                                                    }
                                                    className={`o-flex-middle o-flex ${
                                                        window.location.href.includes(
                                                            'profile/edit/'
                                                        )
                                                            ? 'active-sidebar-link'
                                                            : ''
                                                    }`}
                                                    to={`/profile/edit/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }`}
                                                >
                                                    <i className="icon-edit"></i>
                                                    Edit Profile
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() =>
                                                        this.handleToggleMobileMenu()
                                                    }
                                                    className={`o-flex-middle o-flex ${
                                                        window.location.href.includes(
                                                            'profile/settings/'
                                                        )
                                                            ? 'active-sidebar-link'
                                                            : ''
                                                    }`}
                                                    to={`/profile/settings/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }`}
                                                >
                                                    <i className="icon-settings"></i>
                                                    Account Settings
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() =>
                                                        this.handleToggleMobileMenu()
                                                    }
                                                    className={`o-flex-middle o-flex ${
                                                        window.location.href.includes(
                                                            'profile/connections/my-connections/'
                                                        )
                                                            ? 'active-sidebar-link'
                                                            : ''
                                                    }`}
                                                    to={`/profile/connections/my-connections/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }/connected`}
                                                >
                                                    <i className="icon-connections"></i>
                                                    Connections
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() =>
                                                        this.handleToggleMobileMenu()
                                                    }
                                                    className={`o-flex-middle o-flex order-history-nav-link ${
                                                        window.location.href.includes(
                                                            'purchase-history'
                                                        )
                                                            ? 'active-sidebar-link'
                                                            : ''
                                                    }`}
                                                    to="/purchase-history"
                                                >
                                                    <i className="icon-purchase"></i>
                                                    Purchase History
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    onClick={() =>
                                                        this.handleToggleMobileMenu()
                                                    }
                                                    className={`o-flex-middle o-flex ${
                                                        window.location.href.includes(
                                                            '/guides'
                                                        )
                                                            ? 'active-sidebar-link'
                                                            : ''
                                                    }`}
                                                    to={`/my-aura/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }/guides`}
                                                >
                                                    <i className="icon-info-solid"></i>
                                                    Guides
                                                </Link>
                                            </li>
                                            {this.props.user &&
                                            this.props.user.profile
                                                .profileDeceased ? (
                                                <li>
                                                    <Link
                                                        onClick={() =>
                                                            this.handleToggleMobileMenu()
                                                        }
                                                        className={`o-flex-middle o-flex order-history-nav-link ${
                                                            window.location.href.includes(
                                                                'deceased'
                                                            )
                                                                ? 'active-sidebar-link'
                                                                : ''
                                                        }`}
                                                        to={`/my-aura/${
                                                            this.props.user &&
                                                            this.props.user
                                                                .profile.slug
                                                        }/deceased`}
                                                    >
                                                        <i className="icon-statusIcon"></i>
                                                        Status
                                                    </Link>
                                                </li>
                                            ) : null}
                                            <li>
                                                <Link
                                                    onClick={(e) =>
                                                        this.handleClickLogout(
                                                            e
                                                        )
                                                    }
                                                    className={`o-flex-middle o-flex logout`}
                                                >
                                                    <i className="icon-logout-icon"></i>
                                                    Log Out
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="c-navigation-mob__dropdown--menu-section c-navigation-mob__prelogin">
                                        <Link
                                            className="color-blue"
                                            to="/register"
                                        >
                                            Join Us
                                        </Link>
                                        <Link to="/login">Log in</Link>
                                        {!this.props.user && (
                                            <a href="/aura-circle">
                                                Your Aura Circle
                                            </a>
                                        )}
                                    </div>
                                )}
                                {this.props.funeralPhone ? (
                                    <a
                                        className="btn btn--ghost-purple btn--size-38 btn--size-width-179 text-center call-btn"
                                        href={`tel:+44${this.props.funeralPhone
                                            .substring(1)
                                            .trim()}`}
                                    >
                                        {this.props.funeralPhone}
                                    </a>
                                ) : (
                                    <a
                                        className="btn btn--ghost-purple btn--size-38 btn--size-width-179 text-center call-btn"
                                        href="tel:+448008085723"
                                    >
                                        0800 808 5723
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const newNavigationWithRouter = withRouter(NewNavigation);

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
    notifications: state.notificationsReducer,
    mobileProfileOpen: state.usersReducer.mobileProfileOpen,
    funeralPhone: state.usersReducer.funeralPhone,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onNotificationToggle: () =>
            dispatch(notifActionTypes.onToggleNotifications()),
        onNotificationCountSubscribe: (resetCount) =>
            dispatch(notifActionTypes.onNotificationCountSubscribe(resetCount)),
        onLogoutUser: () => dispatch(userActionTypes.onLogoutUser()),
        onToggleMobileProfile: () =>
            dispatch(userActionTypes.onToggleMobileProfile()),
        onCloseMobileProfile: () =>
            dispatch(userActionTypes.onCloseMobileProfile()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(newNavigationWithRouter);
