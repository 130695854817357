import * as Yup from 'yup';
import * as funeralPaymentActionTypes from '../../../../store/actions/funeralPaymentActions';
import * as userActionTypes from '../../../../store/actions/usersActions';

import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';

import RequiredInput from '../../../../components/UI/Input/RequiredInput/RequiredInput';
import RegularInput from '../../../../components/UI/Input/RegularInput/RegularInput';
import Navigation from '../../../../components/FuneralPayment/Navigation/Navigation';
import { connect } from 'react-redux';
import Select from 'react-select';
import ApiService from '../../../../service/api.service';
import { debounce } from '../../../../functions/debounce';
import Popup from 'reactjs-popup';

export function Checkbox({ id, name, className }) {
    return (
        <>
            <Field
                name={name}
                render={({ field, form }) => {
                    return (
                        <input
                            type="checkbox"
                            id={id}
                            className={className}
                            checked={field.value}
                            {...field}
                        />
                    );
                }}
            />
        </>
    );
}

class Personal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postCodeVisible: true,
            holderPostCodeVisible: true,
            kinPostCodeVisible: true,
            addresses: [],
            day: null,
            year: null,
            month: null,
            buttonLoading: false,
            popupOpen: false,
            popupOpen2: false,
            fullAddressPostcodeResults: [],
            holderFullAddressPostcode: '',
            formatedFullHolderAddressPostcode: '',
            kinFullAddressPostcodeResults: [],
            kinFullAddressPostcode: '',
            formatedFullKinAddressPostcode: '',
            createdByFullAddressPostcodeResults: [],
            createdByFullAddressPostcode: '',
            formatedFullCreatedByAddressPostcode: '',
            holderFullAddressTouched: false,
            createdByFullAddressTouched: false,
            kinFullAddressTouched: false,
            trimPostcode: '',
            trimHolderPostcode: '',
        };

        this.birthdayRef = React.createRef();
        this.dayRef = React.createRef();
        this.kinPostcodeRef = React.createRef();
        this.holderPostcodeRef = React.createRef();
        this.createdByPostcodeRef = React.createRef();
        this.debounceHolderPostcode = debounce(
            () => this.holderPostcode(),
            400
        );
        this.debounceKinPostcode = debounce(() => this.kinPostcode(), 400);
        this.debounceCreatedByPostcode = debounce(
            () => this.createdByPostcode(),
            400
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.funeralPayment?.createdByAddressLine1) {
            this.setState({
                postCodeVisible: false,
            });
        }
        if (this.props.funeralPayment?.holderAddressLine1) {
            this.setState({
                holderPostCodeVisible: false,
            });
        }
        if (this.props.funeralPayment?.kinAddressLine1) {
            this.setState({
                kinPostCodeVisible: false,
            });
        }
        if (window.addressNow) {
            window.addressNow.load();
        }

        let inputCheckbox = document.getElementById('optout');
        if (this.props.funeralPayment?.nominated) {
            if (inputCheckbox) {
                inputCheckbox.click();
            }
        }

        setTimeout(() => {
            if (this.props.funeralPayment) {
                this.setState({
                    kinFullAddressPostcode: this.props.funeralPayment
                        .kinFullAddress
                        ? this.props.funeralPayment.kinFullAddress
                              .replaceAll(',', '')
                              .trim()
                        : '',
                    holderFullAddressPostcode: this.props.funeralPayment
                        .fullAddress
                        ? this.props.funeralPayment.fullAddress
                              .replaceAll(',', '')
                              .trim()
                        : '',
                    createdByFullAddressPostcode: this.props.funeralPayment
                        .createdByAddress
                        ? this.props.funeralPayment.createdByAddress
                              .replaceAll(',', '')
                              .trim()
                        : '',
                });
            }
            window.scrollTo(0, 0);
        }, 300);
    }

    handleAddressSwitch = (props) => {
        this.setState(
            {
                postCodeVisible: !this.state.postCodeVisible,
            },
            () => {
                props.setFieldValue('createdByAddress', '');
                props.setFieldValue('createdByAddressLine1', '');
                props.setFieldValue('createdByAddressLine2', '');
                props.setFieldValue('createdByTown', '');
                props.setFieldValue('createdByCounty', '');
                props.setFieldValue('createdByPostcode', '');
                props.setFieldValue('createdByCountry', 'United Kingdom');
            }
        );
    };

    handleHolderAddressSwitch = (props) => {
        this.setState({
            holderFullAddressPostcode: '',
        });
        this.setState(
            {
                holderPostCodeVisible: !this.state.holderPostCodeVisible,
            },
            () => {
                props.setFieldValue('holderFullAddress', '');
                props.setFieldValue('holderAddressLine1', '');
                props.setFieldValue('holderAddressLine2', '');
                props.setFieldValue('holderTown', '');
                props.setFieldValue('holderCounty', '');
                props.setFieldValue('holderPostcode', '');
                props.setFieldValue('holderCountry', 'United Kingdom');
            }
        );
    };

    handleKinAddressSwitch = (props) => {
        const kinFullAddress = document.getElementsByName('kinFullAddress');
        kinFullAddress[0].value = '';
        this.setState(
            {
                kinPostCodeVisible: !this.state.kinPostCodeVisible,
            },
            () => {
                props.setFieldValue('kinFullAddress', '');
                props.setFieldValue('kinAddressLine1', '');
                props.setFieldValue('kinAddressLine2', '');
                props.setFieldValue('kinTown', '');
                props.setFieldValue('kinCounty', '');
                props.setFieldValue('kinPostcode', '');
                props.setFieldValue('kinCountry', 'United Kingdom');
            }
        );
    };

    handleMonthFocus = () => {
        this.setState({
            isFocusedMonth: 'focused',
        });
    };

    handleMonthBlur = () => {
        this.setState({ isFocusedMonth: '' });
    };

    handleSubmit = (values) => {
        this.setState(
            {
                buttonLoading: true,
            },
            () => {
                const data = {
                    //holder info
                    nominated: values.optout,
                    holderTitle: values.holderTitle,
                    holderFirstName: values.holderFirstName,
                    holderMiddleName: values.holderMiddleName,
                    holderLastName: values.holderLastName,
                    holderEmail: values.holderEmail,
                    holderMobile: values.holderMobile,
                    //fullAddress is holders full address
                    fullAddress: values.holderFullAddress
                        ? values.holderFullAddress
                        : null,
                    holderAddressLine1: values.holderAddressLine1
                        ? values.holderAddressLine1
                        : null,
                    holderAddressLine2: values.holderAddressLine2
                        ? values.holderAddressLine2
                        : null,
                    holderTown: values.holderTown ? values.holderTown : null,
                    holderCounty: values.holderCounty
                        ? values.holderCounty
                        : null,
                    holderPostcode: values.holderPostcode
                        ? values.holderPostcode
                        : null,
                    holderCountry: values.holderPostcode
                        ? 'United Kingdom'
                        : null,
                    birthday: `${values.year}-${
                        values.month.value ? values.month.value : values.month
                    }-${values.day}T00:00:00`,
                    //kin info
                    kinFirstName: values.kinFirstName
                        ? values.kinFirstName
                        : null,
                    kinMiddleName: values.kinMiddleName
                        ? values.kinMiddleName
                        : null,
                    kinLastName: values.kinLastName ? values.kinLastName : null,
                    kinMobile: values.kinMobile ? values.kinMobile : null,
                    kinEmail: values.kinEmail ? values.kinEmail : null,
                    kinRelationship: values.kinRelationship
                        ? values.kinRelationship
                        : null,
                    kinFullAddress: values.kinFullAddress
                        ? values.kinFullAddress
                        : null,
                    kinAddressLine1: values.kinAddressLine1
                        ? values.kinAddressLine1
                        : null,
                    kinAddressLine2: values.kinAddressLine2
                        ? values.kinAddressLine2
                        : null,
                    kinTown: values.kinTown ? values.kinTown : null,
                    kinCounty: values.kinCounty ? values.kinCounty : null,
                    kinPostcode: values.kinPostcode ? values.kinPostcode : null,
                    kinCountry: values.kinPostcode ? 'United Kingdom' : null,
                    //my info
                    createdByFirstName: values.createdByFirstName
                        ? values.createdByFirstName
                        : null,
                    createdByMiddleName: values.createdByMiddleName
                        ? values.createdByMiddleName
                        : null,
                    createdByLastName: values.createdByLastName
                        ? values.createdByLastName
                        : null,
                    createdByEmail: values.createdByEmail
                        ? values.createdByEmail
                        : null,
                    createdByMobile: values.createdByMobile
                        ? values.createdByMobile
                        : null,
                    createdByRelationship: values.createdByRelationship
                        ? values.createdByRelationship
                        : null,
                    createdByAddress: values.createdByAddress
                        ? values.createdByAddress
                        : null,
                    createdByAddressLine1: values.createdByAddressLine1
                        ? values.createdByAddressLine1
                        : null,
                    createdByAddressLine2: values.createdByAddressLine2
                        ? values.createdByAddressLine2
                        : null,
                    createdByTown: values.createdByTown
                        ? values.createdByTown
                        : null,
                    createdByCounty: values.createdByCounty
                        ? values.createdByCounty
                        : null,
                    createdByPostcode: values.createdByPostcode
                        ? values.createdByPostcode
                        : null,
                    createdByCountry: values.createdByPostcode
                        ? 'United Kingdom'
                        : null,
                };

                ApiService.setPersonal(
                    this.props.funeralPayment.uuid,
                    data
                ).then((r) => {
                    ApiService.getUser().then((response) => {
                        this.setState(
                            {
                                buttonLoading: false,
                            },
                            () => {
                                this.props.onSetUser(response.data);
                                this.props.onSetCurrentStep(4);
                                this.props.onSetFuneralCheckout(r.data);
                            }
                        );
                    });
                });
            }
        );
    };

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    handlePopupOpen2 = () => {
        this.setState({
            popupOpen2: true,
        });
    };

    handlePopupClose2 = () => {
        this.setState({
            popupOpen2: false,
        });
    };

    holderPostcode = () => {
        ApiService.postCodeLookup(this.state.holderFullAddressPostcode).then(
            (r) => {
                this.setState({
                    fullAddressPostcodeResults: r.data.result
                        ? r.data.result
                        : [],
                });
            }
        );
    };

    handleHolderFullAddressPostcodeChange = (e, props) => {
        props.setFieldError('holderFullAddress', false);
        props.setFieldValue('holderFullAddress', e.target.value);
        this.setState(
            {
                trimHolderPostcode: false,
                holderFullAddressPostcode: e.target.value,
            },
            () => {
                this.debounceHolderPostcode();
            }
        );
    };

    handleFullAddressPostcodeResultClick = (postcode, props) => {
        this.setState(
            {
                trimHolderPostcode: true,
                holderFullAddressPostcode: `${postcode.building_number} ${
                    postcode.building_name === postcode.line_1
                        ? ''
                        : postcode.building_name
                } ${postcode.line_1} ${postcode.post_town} ${
                    postcode.country
                } ${postcode.postcode}`,
                formatedFullHolderAddressPostcode: `${
                    postcode.building_number
                }, ${
                    postcode.building_name === postcode.line_1
                        ? ''
                        : postcode.building_name
                }, ${postcode.line_1}, ${postcode.post_town}, ${
                    postcode.country
                }, ${postcode.postcode}`,
                fullAddressPostcodeResults: [],
            },
            () => {
                props.setFieldValue(
                    'holderFullAddress',
                    `${postcode.building_number}, ${
                        postcode.building_name === postcode.line_1
                            ? ''
                            : postcode.building_name
                    }, ${postcode.line_1}, ${postcode.post_town}, ${
                        postcode.country
                    }, ${postcode.postcode}`
                );
            }
        );
    };

    handleHolderFullAddressPostcodeResultsClear = (props) => {
        this.setState(
            {
                holderFullAddressPostcode: ``,
                formatedFullHolderAddressPostcode: ``,
                fullAddressPostcodeResults: [],
            },
            () => {
                props.setFieldValue('holderFullAddress', ``);
            }
        );
    };

    kinPostcode = () => {
        ApiService.postCodeLookup(this.state.kinFullAddressPostcode).then(
            (r) => {
                this.setState({
                    kinFullAddressPostcodeResults: r.data.result
                        ? r.data.result
                        : [],
                });
            }
        );
    };

    handleKinFullAddressPostcodeChange = (e, props) => {
        props.setFieldError('kinFullAddress', false);
        props.setFieldValue('kinFullAddress', e.target.value);
        this.setState(
            {
                kinFullAddressPostcode: e.target.value,
            },
            () => {
                this.debounceKinPostcode();
            }
        );
    };

    handleKinFullAddressPostcodeResultClick = (postcode, props) => {
        this.setState(
            {
                kinFullAddressPostcode: `${postcode.building_number} ${
                    postcode.building_name === postcode.line_1
                        ? ''
                        : postcode.building_name
                } ${postcode.line_1} ${postcode.post_town} ${
                    postcode.country
                } ${postcode.postcode}`,
                formatedFullKinAddressPostcode: `${postcode.building_number}, ${
                    postcode.building_name === postcode.line_1
                        ? ''
                        : postcode.building_name
                }, ${postcode.line_1}, ${postcode.post_town}, ${
                    postcode.country
                }, ${postcode.postcode}`,
                kinFullAddressPostcodeResults: [],
            },
            () => {
                props.setFieldValue(
                    'kinFullAddress',
                    `${postcode.building_number}, ${
                        postcode.building_name === postcode.line_1
                            ? ''
                            : postcode.building_name
                    }, ${postcode.line_1}, ${postcode.post_town}, ${
                        postcode.country
                    }, ${postcode.postcode}`.trim()
                );
            }
        );
    };

    handleKinFullAddressPostcodeResultsClear = (props) => {
        this.setState(
            {
                kinFullAddressPostcode: ``,
                formatedFullKinAddressPostcode: ``,
                kinFullAddressPostcodeResults: [],
            },
            () => {
                props.setFieldValue('kinFullAddress', ``);
            }
        );
    };

    createdByPostcode = () => {
        ApiService.postCodeLookup(this.state.createdByFullAddressPostcode).then(
            (r) => {
                this.setState({
                    createdByFullAddressPostcodeResults: r.data.result
                        ? r.data.result
                        : [],
                });
            }
        );
    };

    handleCreatedByFullAddressPostcodeChange = (e, props) => {
        props.setFieldError('createdByAddress', false);
        props.setFieldValue('createdByAddress', e.target.value);
        this.setState(
            {
                trimPostcode: false,
                createdByFullAddressPostcode: e.target.value,
            },
            () => {
                this.debounceCreatedByPostcode();
            }
        );
    };

    handleCreatedByFullAddressPostcodeResultClick = (postcode, props) => {
        this.setState(
            {
                trimPostcode: true,
                createdByFullAddressPostcode: `${postcode.building_number} ${
                    postcode.building_name === postcode.line_1
                        ? null
                        : postcode.building_name
                } ${postcode.line_1} ${postcode.post_town} ${
                    postcode.country
                } ${postcode.postcode}`,
                formatedFullCreatedByAddressPostcode: `${
                    postcode.building_number
                }, ${
                    postcode.building_name === postcode.line_1
                        ? null
                        : postcode.building_name
                }, ${postcode.line_1}, ${postcode.post_town}, ${
                    postcode.country
                }, ${postcode.postcode}`,
                createdByFullAddressPostcodeResults: [],
            },
            () => {
                props.setFieldValue(
                    'createdByAddress',
                    `${postcode.building_number}, ${
                        postcode.building_name === postcode.line_1
                            ? ''
                            : postcode.building_name
                    }, ${postcode.line_1}, ${postcode.post_town}, ${
                        postcode.country
                    }, ${postcode.postcode}`
                );
            }
        );
    };

    handleCreatedByFullAddressPostcodeResultsClear = (props) => {
        this.setState(
            {
                createdByFullAddressPostcode: ``,
                formatedFullCreatedByAddressPostcode: ``,
                createdByFullAddressPostcodeResults: [],
            },
            () => {
                props.setFieldValue('createdByAddress', ``);
            }
        );
    };

    handleCreatedByFullAddressBlur = (e, props) => {
        this.setState({ createdByFullAddressPostcodeFocused: false });
        if (e.target.value.length === 0) {
            props.setFieldError('createdByAddress', true);
        }
    };

    handleKinFullAddressBlur = (e, props) => {
        this.setState({ kinFullAddressPostcodeFocused: false });
        if (e.target.value.length === 0) {
            props.setFieldError('kinFullAddress', true);
        }
    };

    handleHolderFullAddressBlur = (e, props) => {
        this.setState({ holderFullAddressPostcodeFocused: false });
        if (e.target.value.length === 0) {
            props.setFieldError('kinFullAddress', true);
        }
    };

    render() {
        let popupContent = (
            <>
                <h6>
                    By selecting this option you are confirming that you are
                    aware of the benefits of having someone act as your
                    Nominated Representative and it is your decision to decline
                    this.
                </h6>
            </>
        );

        let popupContent2 = (
            <>
                <h6>
                    Please tick this box if you have chosen not to have a
                    Nominated Representative.{' '}
                </h6>
            </>
        );

        let date;
        let bdayYear;
        let bdayDay;
        let bdayMonth;
        let toInt = 0;

        if (this.props.funeralPayment?.birthday) {
            date = new Date(this.props.funeralPayment?.birthday);
        } else if (
            this.props.user &&
            this.props.user.profile.birthday &&
            this.props.funeralPayment &&
            this.props.funeralPayment.forMe
        ) {
            date = new Date(this.props.user.profile.birthday);
        } else {
            date = null;
        }

        // if (this.props.funeralPayment?.birthday) {
        //     date = new Date(this.props.funeralPayment?.birthday);
        // } else if (this.props.user && this.props.user.profile.birthday) {
        //     date = new Date(this.props.user.profile.birthday);
        // }

        if (date) {
            bdayYear = date.getFullYear();
            bdayDay = date.getDate();
            // bdayDay = this.props.funeralPayment?.birthday.split('-')[2].substring(0, 2);
            bdayMonth = date.getMonth() + 1;
        }

        //if there's problem with bday it's because of toInt
        if (bdayMonth && toInt) {
            toInt = +bdayMonth;
        }

        const phoneRegExp =
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

        const forMeValidationSchema = Yup.object({
            //me
            optout: Yup.bool(),
            holderTitle: Yup.string().required('This field is required.'),
            holderFirstName: Yup.string().required('This field is required.'),
            holderLastName: Yup.string().required('This field is required.'),
            holderEmail: Yup.string()
                .email('Please enter a valid E-mail Address')
                .required('This field is required.'),
            holderMobile: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required('This field is required.'),
            holderFullAddress: this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderAddressLine1: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderAddressLine2: Yup.string(),
            holderTown: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderCounty: Yup.string(),
            holderPostcode: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderCountry: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            day: Yup.string().required('Required.'),
            year: Yup.string().required('Required.'),
            month: Yup.string().required('This field is required.'),
            //kin
            kinFullAddress: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: this.state.kinPostCodeVisible
                    ? Yup.string().required('This field is required.')
                    : Yup.string(),
            }),
            kinFirstName: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: Yup.string().required('This field is required.'),
            }),
            kinLastName: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: Yup.string().required('This field is required.'),
            }),
            kinAddressLine1: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: !this.state.kinPostCodeVisible
                    ? Yup.string().required('This field is required.')
                    : Yup.string(),
            }),
            kinAddressLine2: Yup.string(),

            kinTown: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: !this.state.kinPostCodeVisible
                    ? Yup.string().required('This field is required.')
                    : Yup.string(),
            }),
            kinCounty: Yup.string(),

            kinPostcode: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: !this.state.kinPostCodeVisible
                    ? Yup.string().required('This field is required.')
                    : Yup.string(),
            }),
            kinCountry: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: !this.state.kinPostCodeVisible
                    ? Yup.string().required('This field is required.')
                    : Yup.string(),
            }),
            kinRelationship: Yup.string().when('optout', {
                is: (optout) => optout === false,
                then: Yup.string().required('This field is required.'),
            }),
        });

        const forMeInitialValues = {
            //me
            optout: false,
            holderTitle:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderTitle
                    ? this.props.funeralPayment.holderTitle
                    : this.props.user && this.props.user.profile.title
                    ? this.props.user.profile.title
                    : '',
            holderFirstName:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderFirstName
                    ? this.props.funeralPayment.holderFirstName
                    : this.props.user && this.props.user.profile.firstName
                    ? this.props.user.profile.firstName
                    : '',
            holderMiddleName:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderMiddleName
                    ? this.props.funeralPayment.holderMiddleName
                    : this.props.user && this.props.user.profile.middleName
                    ? this.props.user.profile.middleName
                    : '',
            holderLastName:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderLastName
                    ? this.props.funeralPayment.holderLastName
                    : this.props.user && this.props.user.profile.lastName
                    ? this.props.user.profile.lastName
                    : '',
            holderEmail:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderEmail
                    ? this.props.funeralPayment.holderEmail
                    : '',
            holderMobile:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderMobile
                    ? this.props.funeralPayment.holderMobile
                    : '',
            holderFullAddress: this.props.funeralPayment?.fullAddress,
            holderAddressLine1: this.props.funeralPayment?.holderAddressLine1,
            holderAddressLine2: this.props.funeralPayment?.holderAddressLine2,
            holderTown: this.props.funeralPayment?.holderTown,
            holderCounty: this.props.funeralPayment?.holderCounty,
            holderPostcode: this.props.funeralPayment?.holderPostcode,
            holderCountry: 'United Kingdom',
            day: bdayDay ? bdayDay : '',
            month: bdayMonth
                ? bdayMonth.toString().length === 1
                    ? `0${bdayMonth}`
                    : bdayMonth
                : '01',
            // month: bdayMonth && bdayMonth.toString().length === 1 ? `0${bdayMonth}` : '01',
            year: bdayYear ? bdayYear : '',
            //kin
            kinFirstName: this.props.funeralPayment?.kinFirstName,
            kinMiddleName: this.props.funeralPayment?.kinMiddleName,
            kinLastName: this.props.funeralPayment?.kinLastName,
            kinEmail: this.props.funeralPayment?.kinEmail,
            kinMobile: this.props.funeralPayment?.kinMobile,
            kinFullAddress: this.props.funeralPayment?.kinFullAddress,
            kinAddressLine1: this.props.funeralPayment?.kinAddressLine1,
            kinAddressLine2: this.props.funeralPayment?.kinAddressLine2,
            kinTown: this.props.funeralPayment?.kinTown,
            kinCounty: this.props.funeralPayment?.kinCounty,
            kinPostcode: this.props.funeralPayment?.kinPostcode,
            kinCountry: 'United Kingdom',
            kinRelationship: this.props.funeralPayment?.kinRelationship,
        };

        const forOtherValidationSchema = Yup.object({
            //plan holder
            holderTitle: Yup.string().required('This field is required.'),
            holderFirstName: Yup.string()
                .required('This field is required.')
                .nullable(),
            holderLastName: Yup.string().required('This field is required.'),
            holderEmail: Yup.string()
                .email('Please enter a valid E-mail Address')
                .required('This field is required.'),
            holderMobile: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required('This field is required.'),
            holderFullAddress: this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderAddressLine1: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderAddressLine2: Yup.string(),
            holderTown: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderCounty: Yup.string(),
            holderPostcode: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            holderCountry: !this.state.holderPostCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            day: Yup.string().required('Required.'),
            month: Yup.string().required('This field is required.'),
            year: Yup.string().required('Required.'),
            //created by
            createdByFirstName: Yup.string().required(
                'This field is required.'
            ),
            createdByLastName: Yup.string().required('This field is required.'),
            createdByEmail: Yup.string()
                .email('Please enter a valid E-mail Address')
                .required('This field is required.'),
            createdByMobile: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required('This field is required.'),
            createdByAddress: this.state.postCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            createdByAddressLine1: !this.state.postCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            createdByAddressLine2: Yup.string(),
            createdByTown: !this.state.postCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            createdByCounty: Yup.string(),
            createdByPostcode: !this.state.postCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            createdByCountry: !this.state.postCodeVisible
                ? Yup.string().required('This field is required.')
                : Yup.string(),
            createdByRelationship: Yup.string().required(
                'This field is required.'
            ),
        });

        const forOtherInitialValues = {
            //plan holder
            holderTitle: this.props.funeralPayment?.holderTitle,
            holderFirstName: this.props.funeralPayment?.holderFirstName,
            holderMiddleName: this.props.funeralPayment?.holderMiddleName,
            holderLastName: this.props.funeralPayment?.holderLastName,
            holderEmail:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderEmail
                    ? this.props.funeralPayment.holderEmail
                    : '',
            holderMobile:
                this.props.funeralPayment &&
                this.props.funeralPayment.holderMobile
                    ? this.props.funeralPayment.holderMobile
                    : '',
            holderFullAddress: this.props.funeralPayment?.fullAddress,
            holderAddressLine1: this.props.funeralPayment?.holderAddressLine1,
            holderAddressLine2: this.props.funeralPayment?.holderAddressLine2,
            holderTown: this.props.funeralPayment?.holderTown,
            holderCounty: this.props.funeralPayment?.holderCounty,
            holderPostcode: this.props.funeralPayment?.holderPostcode,
            holderCountry: 'United Kingdom',
            day: this.props.funeralPayment ? (bdayDay ? bdayDay : '') : '',
            month: bdayMonth
                ? bdayMonth.toString().length === 1
                    ? `0${bdayMonth}`
                    : bdayMonth
                : '01',
            year: this.props.funeralPayment ? (bdayYear ? bdayYear : '') : '',
            createdByFirstName:
                this.props.funeralPayment &&
                this.props.funeralPayment.createdByFirstName
                    ? this.props.funeralPayment.createdByFirstName
                    : this.props.user && this.props.user.profile.firstName
                    ? this.props.user.profile.firstName
                    : this.props.user && this.props.user.firstName
                    ? this.props.user.firstName
                    : '',
            createdByMiddleName:
                this.props.funeralPayment &&
                this.props.funeralPayment.createdByMiddleName
                    ? this.props.funeralPayment.createdByMiddleName
                    : this.props.user && this.props.user.profile.middleName
                    ? this.props.user.profile.middleName
                    : '',
            createdByLastName:
                this.props.funeralPayment &&
                this.props.funeralPayment.createdByLastName
                    ? this.props.funeralPayment.createdByLastName
                    : this.props.user && this.props.user.profile.lastName
                    ? this.props.user.profile.lastName
                    : '',

            createdByEmail: this.props.funeralPayment?.email
                ? this.props.funeralPayment.email
                : this.props.funeralPayment?.createdByEmail
                ? this.props.funeralPayment?.createdByEmail
                : '',
            createdByMobile: this.props.funeralPayment?.mobile
                ? this.props.funeralPayment.mobile
                : this.props.funeralPayment?.createdByMobile
                ? this.props.funeralPayment?.createdByMobile
                : '',
            createdByRelationship:
                this.props.funeralPayment?.createdByRelationship,
            createdByAddress: this.props.funeralPayment?.createdByAddress,
            createdByAddressLine1:
                this.props.funeralPayment?.createdByAddressLine1,
            createdByAddressLine2:
                this.props.funeralPayment?.createdByAddressLine2,
            createdByTown: this.props.funeralPayment?.createdByTown,
            createdByCounty: this.props.funeralPayment?.createdByCounty,
            createdByPostcode: this.props.funeralPayment?.createdByPostcode,
            createdByCountry: 'United Kingdom',
        };

        let options = [
            { value: 'Mr.', label: 'Mr.' },
            { value: 'Mrs.', label: 'Mrs.' },
            { value: 'Miss', label: 'Miss' },
            { value: 'Ms.', label: 'Ms.' },
            { value: 'Mx.', label: 'Mx.' },
            { value: 'Dr.', label: 'Dr.' },
            { value: 'Sir', label: 'Sir' },
            { value: 'Lady', label: 'Lady' },
            { value: 'Lord', label: 'Lord' },
            { value: 'Prof.', label: 'Prof.' },
        ];

        let addressOptions = [];

        if (this.state.addresses.length) {
            this.state.addresses.map((address) => {
                let formatedAddress = `${address.split(',')[0]}${
                    address.split(',')[5]
                }${address.split(',')[6]}`;
                const val = {
                    value: formatedAddress,
                    label: formatedAddress,
                };
                addressOptions.push(val);
                return null;
            });
        }

        let monthOptions = [
            { value: '01', label: 'January' },
            { value: '02', label: 'February' },
            { value: '03', label: 'March' },
            { value: '04', label: 'April' },
            { value: '05', label: 'May' },
            { value: '06', label: 'Jun' },
            { value: '07', label: 'July' },
            { value: '08', label: 'August' },
            { value: '09', label: 'September' },
            { value: '10', label: 'October' },
            { value: '11', label: 'November' },
            { value: '12', label: 'December' },
        ];

        let relationshipOptions = [
            { value: 'Husband', label: 'Husband' },
            { value: 'Wife', label: 'Wife' },
            { value: 'Partner', label: 'Partner' },
            { value: 'Brother', label: 'Brother' },
            { value: 'Sister', label: 'Sister' },
            { value: 'Son', label: 'Son' },
            { value: 'Daughter', label: 'Daughter' },
            { value: 'Cousin', label: 'Cousin' },
            { value: 'Nephew', label: 'Nephew' },
            { value: 'Niece', label: 'Niece' },
            { value: 'Friend', label: 'Friend' },
            { value: 'Other', label: 'Other' },
        ];

        return (
            <div className="c-funeral__personal">
                <Formik
                    enableReinitialize={true}
                    initialValues={
                        this.props.funeralPayment.forMe
                            ? forMeInitialValues
                            : forOtherInitialValues
                    }
                    validationSchema={
                        this.props.funeralPayment.forMe
                            ? forMeValidationSchema
                            : forOtherValidationSchema
                    }
                    validateOnBlur={true}
                    validateOnMount={false}
                    onSubmit={(values) => this.handleSubmit(values)}
                >
                    {(props) => {
                        return (
                            <Form className="c-funeral__contact--form position-relative">
                                {!this.props.funeralPayment.forMe ? (
                                    <>
                                        <h2 className="c-funeral__contact--form--header">
                                            My details
                                        </h2>

                                        {this.props.funeralPayment.forMe ? (
                                            <>
                                                <h6>
                                                    You will receive details of
                                                    the planholder's funeral
                                                    plan as a secondary point of
                                                    contact
                                                </h6>
                                            </>
                                        ) : (
                                            <>
                                                <h6>
                                                    By purchasing the plan for
                                                    someone else, you will be
                                                    made their Nominated
                                                    Representative. You are
                                                    their trusted individual we
                                                    will send details of the
                                                    plan to. You will be made
                                                    aware of its features and
                                                    exclusions and what you need
                                                    to do.
                                                </h6>

                                                <h6 className="c-funeral__contact--form--name">
                                                    Please provide your full
                                                    legal name.
                                                </h6>
                                            </>
                                        )}

                                        <div className="o-flex c-funeral__personal--3col">
                                            <RequiredInput
                                                type="text"
                                                name="createdByFirstName"
                                                label="First name"
                                            ></RequiredInput>
                                            <RegularInput
                                                className="middle-name"
                                                type="text"
                                                name="createdByMiddleName"
                                                label="Middle (optional)"
                                            ></RegularInput>
                                            <RequiredInput
                                                type="text"
                                                name="createdByLastName"
                                                label="Last name"
                                            ></RequiredInput>
                                        </div>
                                        <RequiredInput
                                            disabled={this.props.user}
                                            type="email"
                                            name="createdByEmail"
                                            label="Email address"
                                        ></RequiredInput>
                                        <RequiredInput
                                            type="text"
                                            name="createdByMobile"
                                            label="Phone number"
                                        ></RequiredInput>
                                        <div
                                            className={`${
                                                props.errors
                                                    ?.createdByRelationship &&
                                                props.touched
                                                    ?.createdByRelationship
                                                    ? 'c-select mt-10 invalid'
                                                    : 'c-select mt-10'
                                            }`}
                                        >
                                            <label
                                                className={`${
                                                    this.state.titleFocused
                                                        ? 'title-label'
                                                        : ''
                                                } c-select__label `}
                                                htmlFor="title"
                                            >
                                                Relationship to plan holder{' '}
                                                <span>*</span>
                                            </label>
                                            <Select
                                                options={relationshipOptions}
                                                value={
                                                    props.values
                                                        .createdByRelationship
                                                        ? {
                                                              value: props
                                                                  .values
                                                                  .createdByRelationship
                                                                  ? props.values
                                                                        .createdByRelationship
                                                                  : '',
                                                              label: props
                                                                  .values
                                                                  .createdByRelationship
                                                                  ? props.values
                                                                        .createdByRelationship
                                                                  : 'Relationship to planholder',
                                                          }
                                                        : null
                                                }
                                                name="createdByRelationship"
                                                onBlur={(e) =>
                                                    props.setFieldTouched(
                                                        'createdByRelationship',
                                                        true
                                                    )
                                                }
                                                placeholder={' '}
                                                classNamePrefix="c-react-select"
                                                isSearchable={false}
                                                onChange={(e) =>
                                                    props.setFieldValue(
                                                        'createdByRelationship',
                                                        e.value
                                                    )
                                                }
                                            />
                                            {props.errors
                                                ?.createdByRelationship &&
                                            props.touched
                                                ?.createdByRelationship ? (
                                                <div className="font-13 font-semibold font-form-error error-message required-icon margin-reset position-relative">
                                                    This field is required.
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div className="o-flex-inline o-flex-middle o-flex-between width-full mb-20 mt-10">
                                                <h4 className="edit-profile__postcode-address">
                                                    {this.state.postCodeVisible
                                                        ? 'Postcode lookup:'
                                                        : 'Full address:'}
                                                </h4>
                                                <h4
                                                    onClick={() =>
                                                        this.handleAddressSwitch(
                                                            props
                                                        )
                                                    }
                                                    className="font-20 cursor-pointer text-right margin-reset link-underline-hover-blue edit-profile__postcode-address"
                                                >
                                                    {this.state.postCodeVisible
                                                        ? 'Manually enter your address'
                                                        : 'Postcode lookup'}
                                                </h4>
                                            </div>
                                            <div
                                                className={`${
                                                    !this.state.postCodeVisible
                                                        ? 'hide'
                                                        : ''
                                                } ${
                                                    props.values.postcodeLookup
                                                        ? 'placeholder-dark'
                                                        : ''
                                                } position-relative`}
                                            >
                                                <div>
                                                    <div
                                                        onClick={() =>
                                                            this.createdByPostcodeRef.current.focus()
                                                        }
                                                        onChange={(e) =>
                                                            this.handleCreatedByFullAddressPostcodeChange(
                                                                e,
                                                                props
                                                            )
                                                        }
                                                        tabIndex="-1"
                                                        className={`c-input c-input__wrap ${
                                                            this.state
                                                                .createdByFullAddressPostcode
                                                                .length ||
                                                            this.state
                                                                .createdByFullAddressPostcodeFocused
                                                                ? 'c-input__filled'
                                                                : ''
                                                        } ${
                                                            !this.state
                                                                .createdByFullAddressPostcodeFocused &&
                                                            props.errors
                                                                ?.createdByAddress &&
                                                            props.touched
                                                                ?.createdByAddress
                                                                ? 'c-input__invalid'
                                                                : ''
                                                        }`}
                                                    >
                                                        <label className="c-input__label">
                                                            Postcode
                                                            <span className="c-input__label--required">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            ref={
                                                                this
                                                                    .createdByPostcodeRef
                                                            }
                                                            onFocus={() => {
                                                                this.setState(
                                                                    {
                                                                        createdByFullAddressPostcodeFocused: true,
                                                                        createdByFullAddressTouched: true,
                                                                    },
                                                                    () => {
                                                                        props.setFieldTouched(
                                                                            'createdByAddress',
                                                                            true
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                            onBlur={(e) =>
                                                                this.handleCreatedByFullAddressBlur(
                                                                    e,
                                                                    props
                                                                )
                                                            }
                                                            value={
                                                                this.state
                                                                    .trimPostcode
                                                                    ? this.state.createdByFullAddressPostcode.trim()
                                                                    : this.state
                                                                          .createdByFullAddressPostcode
                                                            }
                                                            form="novalidatedform"
                                                            type="text"
                                                            name="createdByAddress"
                                                            autoComplete="off"
                                                        ></input>
                                                        {!this.state
                                                            .createdByFullAddressPostcodeFocused &&
                                                        props.errors
                                                            ?.createdByAddress &&
                                                        props.touched
                                                            ?.createdByAddress ? (
                                                            <div className="c-input__error-message">
                                                                This field is
                                                                required.
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        {this.state
                                                            .createdByFullAddressPostcodeResults
                                                            .length ? (
                                                            <div className="c-postcode-results">
                                                                <i
                                                                    className="icon-times-thin c-postcode-results__close"
                                                                    onClick={() => {
                                                                        this.handleCreatedByFullAddressPostcodeResultsClear(
                                                                            props
                                                                        );
                                                                    }}
                                                                ></i>
                                                                <ul className="c-postcode-results__list">
                                                                    {this.state.createdByFullAddressPostcodeResults.map(
                                                                        (
                                                                            postcode
                                                                        ) => (
                                                                            <li
                                                                                className="c-postcode-results__item"
                                                                                onClick={() =>
                                                                                    this.handleCreatedByFullAddressPostcodeResultClick(
                                                                                        postcode,
                                                                                        props
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    postcode.building_number
                                                                                }{' '}
                                                                                {postcode.building_name ===
                                                                                postcode.line_1
                                                                                    ? null
                                                                                    : postcode.building_name}{' '}
                                                                                {
                                                                                    postcode.line_1
                                                                                }{' '}
                                                                                {
                                                                                    postcode.post_town
                                                                                }{' '}
                                                                                {
                                                                                    postcode.country
                                                                                }{' '}
                                                                                {
                                                                                    postcode.postcode
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                    this.state.postCodeVisible
                                                        ? 'hide'
                                                        : ''
                                                } col2-v22 ${
                                                    props.values.postcodeLookup
                                                        ? 'placeholder-dark'
                                                        : ''
                                                } `}
                                            >
                                                <RequiredInput
                                                    className="mt-i-0"
                                                    type="text"
                                                    name="createdByAddressLine1"
                                                    label="Address Line 1"
                                                />
                                                <RegularInput
                                                    className="mt-i-0"
                                                    type="text"
                                                    name="createdByAddressLine2"
                                                    label="Address Line 2 (optional)"
                                                />
                                                <RequiredInput
                                                    type="text"
                                                    name="createdByTown"
                                                    label="Town"
                                                />
                                                <RegularInput
                                                    type="text"
                                                    name="createdByCounty"
                                                    label="County (optional)"
                                                />
                                                <RequiredInput
                                                    type="text"
                                                    name="createdByPostcode"
                                                    label="Postcode"
                                                />
                                                <div data-tip="Only place we operate">
                                                    <RequiredInput
                                                        disabled={true}
                                                        type="text"
                                                        name="createdByCountry"
                                                        label="Country"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                <h2 className="c-funeral__contact--form--header">
                                    Please tell us your address
                                </h2>
                                {this.props.funeralPayment.forMe ? (
                                    <>
                                        <h6 className="c-funeral__contact--form--name">
                                            Please provide your full legal name.
                                        </h6>
                                    </>
                                ) : (
                                    <>
                                        <h6 className="c-funeral__contact--form--name">
                                            Please provide the planholders full
                                            legal name.
                                        </h6>
                                    </>
                                )}
                                <div
                                    className={`${
                                        props.errors?.holderTitle &&
                                        props.touched?.holderTitle &&
                                        !props.values.holderTitle
                                            ? 'c-select invalid'
                                            : 'c-select '
                                    }`}
                                >
                                    <label
                                        className={`${
                                            this.state.titleFocused
                                                ? 'title-label'
                                                : ''
                                        } c-select__label `}
                                        htmlFor="title"
                                    >
                                        Title <span>*</span>
                                    </label>
                                    <Select
                                        options={options}
                                        value={
                                            props.values.holderTitle
                                                ? {
                                                      value: props.values
                                                          .holderTitle
                                                          ? props.values
                                                                .holderTitle
                                                          : '',
                                                      label: props.values
                                                          .holderTitle
                                                          ? props.values
                                                                .holderTitle
                                                          : 'Title',
                                                  }
                                                : null
                                        }
                                        name="holderTitle"
                                        onBlur={(e) => {
                                            props.setFieldTouched(
                                                'holderTitle',
                                                true
                                            );
                                            this.setState({
                                                titleFocused: false,
                                            });
                                        }}
                                        onFocus={(e) =>
                                            this.setState({
                                                titleFocused: true,
                                            })
                                        }
                                        placeholder={''}
                                        classNamePrefix="c-react-select"
                                        isSearchable={false}
                                        onChange={(e) => {
                                            props.setFieldValue(
                                                'holderTitle',
                                                e.value
                                            );
                                            this.setState({
                                                isTouched: true,
                                                canSave: true,
                                                titleFocused: false,
                                            });
                                        }}
                                    />
                                </div>
                                {props.errors?.holderTitle &&
                                props.touched?.holderTitle &&
                                !props.values.holderTitle ? (
                                    <div className="font-13 font-semibold font-form-error error-message required-icon margin-reset position-relative">
                                        This field is required.
                                    </div>
                                ) : null}

                                <div className="o-flex c-funeral__personal--3col">
                                    <RequiredInput
                                        type="text"
                                        name="holderFirstName"
                                        label="First name"
                                    ></RequiredInput>
                                    <RegularInput
                                        className="middle-name"
                                        type="text"
                                        name="holderMiddleName"
                                        label="Middle (optional)"
                                    ></RegularInput>
                                    <RequiredInput
                                        type="text"
                                        name="holderLastName"
                                        label="Last name"
                                    ></RequiredInput>
                                </div>
                                <RequiredInput
                                    type="email"
                                    name="holderEmail"
                                    label="Email address"
                                ></RequiredInput>
                                <RequiredInput
                                    type="text"
                                    name="holderMobile"
                                    label="Phone number"
                                ></RequiredInput>
                                <div>
                                    <div className="o-flex-inline o-flex-middle o-flex-between width-full mb-20 mt-10">
                                        <h4 className="edit-profile__postcode-address">
                                            {this.state.holderPostCodeVisible
                                                ? 'Postcode lookup:'
                                                : 'Full address:'}
                                        </h4>
                                        <h4
                                            onClick={() =>
                                                this.handleHolderAddressSwitch(
                                                    props
                                                )
                                            }
                                            className="font-20 cursor-pointer text-right margin-reset link-underline-hover-blue edit-profile__postcode-address"
                                        >
                                            {this.state.holderPostCodeVisible
                                                ? 'Manually enter your address'
                                                : 'Postcode lookup'}
                                        </h4>
                                    </div>
                                    <div
                                        className={`${
                                            !this.state.holderPostCodeVisible
                                                ? 'hide'
                                                : ''
                                        } ${
                                            props.values.postcodeLookup
                                                ? 'placeholder-dark'
                                                : ''
                                        } position-relative`}
                                    >
                                        <div>
                                            <div
                                                onClick={() =>
                                                    this.holderPostcodeRef.current.focus()
                                                }
                                                onChange={(e) =>
                                                    this.handleHolderFullAddressPostcodeChange(
                                                        e,
                                                        props
                                                    )
                                                }
                                                tabIndex="-1"
                                                className={`c-input c-input__wrap ${
                                                    this.state
                                                        .holderFullAddressPostcode
                                                        .length ||
                                                    this.state
                                                        .holderFullAddressPostcodeFocused
                                                        ? 'c-input__filled'
                                                        : ''
                                                } ${
                                                    !this.state
                                                        .holderFullAddressPostcodeFocused &&
                                                    props.errors
                                                        ?.holderFullAddress &&
                                                    props.touched
                                                        ?.holderFullAddress
                                                        ? 'c-input__invalid'
                                                        : ''
                                                }`}
                                            >
                                                <label className="c-input__label">
                                                    Postcode
                                                    <span className="c-input__label--required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    ref={this.holderPostcodeRef}
                                                    onFocus={() => {
                                                        this.setState(
                                                            {
                                                                holderFullAddressPostcodeFocused: true,
                                                                holderFullAddressTouched: true,
                                                            },
                                                            () => {
                                                                props.setFieldTouched(
                                                                    'holderFullAddress',
                                                                    true
                                                                );
                                                            }
                                                        );
                                                    }}
                                                    onBlur={(e) =>
                                                        this.handleHolderFullAddressBlur(
                                                            e,
                                                            props
                                                        )
                                                    }
                                                    value={
                                                        this.state
                                                            .trimHolderPostcode
                                                            ? this.state.holderFullAddressPostcode.trim()
                                                            : this.state
                                                                  .holderFullAddressPostcode
                                                    }
                                                    form="novalidatedform"
                                                    type="text"
                                                    name="holderFullAddress"
                                                    autoComplete="off"
                                                ></input>
                                                {!this.state
                                                    .holderFullAddressPostcodeFocused &&
                                                props.errors
                                                    ?.holderFullAddress &&
                                                props.touched
                                                    ?.holderFullAddress ? (
                                                    <div className="c-input__error-message">
                                                        This field is required.
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div>
                                                {this.state
                                                    .fullAddressPostcodeResults
                                                    .length ? (
                                                    <div className="c-postcode-results">
                                                        <i
                                                            className="icon-times-thin c-postcode-results__close"
                                                            onClick={() => {
                                                                this.handleHolderFullAddressPostcodeResultsClear(
                                                                    props
                                                                );
                                                            }}
                                                        ></i>
                                                        <ul className="c-postcode-results__list">
                                                            {this.state.fullAddressPostcodeResults.map(
                                                                (postcode) => (
                                                                    <li
                                                                        className="c-postcode-results__item"
                                                                        onClick={() =>
                                                                            this.handleFullAddressPostcodeResultClick(
                                                                                postcode,
                                                                                props
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            postcode.building_number
                                                                        }{' '}
                                                                        {postcode.building_name ===
                                                                        postcode.line_1
                                                                            ? null
                                                                            : postcode.building_name}{' '}
                                                                        {
                                                                            postcode.line_1
                                                                        }{' '}
                                                                        {
                                                                            postcode.post_town
                                                                        }{' '}
                                                                        {
                                                                            postcode.country
                                                                        }{' '}
                                                                        {
                                                                            postcode.postcode
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`${
                                            this.state.holderPostCodeVisible
                                                ? 'hide'
                                                : ''
                                        } col2-v22 ${
                                            props.values.postcodeLookup
                                                ? 'placeholder-dark'
                                                : ''
                                        } `}
                                    >
                                        <RequiredInput
                                            className="mt-i-0"
                                            type="text"
                                            name="holderAddressLine1"
                                            label="Address Line 1"
                                        />
                                        <RegularInput
                                            className="mt-i-0"
                                            type="text"
                                            name="holderAddressLine2"
                                            label="Address Line 2 (optional)"
                                        />
                                        <RequiredInput
                                            type="text"
                                            name="holderTown"
                                            label="Town"
                                        />
                                        <RegularInput
                                            type="text"
                                            name="holderCounty"
                                            label="County (optional)"
                                        />
                                        <RequiredInput
                                            type="text"
                                            name="holderPostcode"
                                            label="Postcode"
                                        />
                                        <div data-tip="Only place we operate">
                                            <RequiredInput
                                                disabled={true}
                                                type="text"
                                                name="holderCountry"
                                                label="Country"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <h4 className="c-funeral__contact--form--title">
                                    Date of birth
                                </h4>
                                <div
                                    className="c-birthday"
                                    ref={this.birthdayRef}
                                >
                                    <div className="c-birthday__day">
                                        <RequiredInput
                                            id="day"
                                            label="Day"
                                            name="day"
                                            type="text"
                                        />
                                    </div>
                                    <div className="c-select c-birthday__month">
                                        <label
                                            className={`${
                                                this.state.titleFocused
                                                    ? 'title-label'
                                                    : ''
                                            } c-select__label `}
                                            htmlFor="title"
                                        >
                                            Month <span>*</span>
                                        </label>
                                        <Select
                                            options={monthOptions}
                                            onFocus={this.handleMonthFocus}
                                            onBlur={this.handleMonthBlur}
                                            placeholder=""
                                            classNamePrefix="c-react-select"
                                            isSearchable={false}
                                            value={
                                                monthOptions[
                                                    props.values.month - 1
                                                ]
                                                    ? monthOptions[
                                                          props.values.month - 1
                                                      ]
                                                    : monthOptions[0]
                                            }
                                            onChange={(e) =>
                                                props.setFieldValue(
                                                    'month',
                                                    e.value ? e.value : e
                                                )
                                            }
                                        ></Select>
                                    </div>
                                    <div className="c-birthday__year">
                                        <RequiredInput
                                            id="year"
                                            label="Year"
                                            name="year"
                                            type="text"
                                        />
                                    </div>
                                    {this.state.birthdayError ? (
                                        <p className="font-13 font-semibold font-form-error error-message required-icon position-absolute margin-reset">
                                            Date you've selected is invalid
                                        </p>
                                    ) : null}
                                </div>
                                {this.props.funeralPayment.forMe ? (
                                    <>
                                        <h4 className="c-funeral__nominated-headline">
                                            Nominated Representative
                                        </h4>
                                        <p>
                                            The Nominated Representative is the
                                            trusted individual we will send
                                            details of the plan to. They will be
                                            made aware of its features and
                                            exclusions and what they need to do.
                                        </p>
                                        <Popup
                                            lockScroll
                                            open={this.state.popupOpen}
                                            onClose={this.handlePopupClose}
                                            className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                                        >
                                            {(close) => (
                                                <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                                    <button
                                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                                        onClick={close}
                                                    >
                                                        <i className="icon-times font-35 cursor-pointer"></i>
                                                    </button>
                                                    {popupContent}

                                                    <button
                                                        className="c-popup__close btn btn--full btn--smaller"
                                                        onClick={close}
                                                    >
                                                        Got it, close this
                                                    </button>
                                                </div>
                                            )}
                                        </Popup>
                                        <Popup
                                            lockScroll
                                            open={this.state.popupOpen2}
                                            onClose={this.handlePopupClose2}
                                            className="c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                                        >
                                            {(close) => (
                                                <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                                    <button
                                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                                        onClick={close}
                                                    >
                                                        <i className="icon-times font-35 cursor-pointer"></i>
                                                    </button>
                                                    {popupContent2}
                                                    <button
                                                        className="c-popup__close btn btn--full btn--smaller"
                                                        onClick={close}
                                                    >
                                                        Got it, close this
                                                    </button>
                                                </div>
                                            )}
                                        </Popup>
                                        <div className="c-funeral__nominated-optout c-field-checkbox o-flex o-flex-middle position-relative mb-30">
                                            <Checkbox
                                                id="optout"
                                                name="optout"
                                                className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset hide"
                                            />
                                            <label
                                                htmlFor="optout"
                                                className="c-funeral__contact--terms c-field-checkbox__label position-relative"
                                            >
                                                Nominated Representative opt out
                                            </label>
                                            <span
                                                onClick={this.handlePopupOpen2}
                                            >
                                                <i className="icon-question font-15"></i>
                                            </span>
                                        </div>
                                        <div
                                            className={`${
                                                props.values.optout
                                                    ? 'hide'
                                                    : ''
                                            }`}
                                        >
                                            <div className="o-flex c-funeral__personal--3col">
                                                <RequiredInput
                                                    type="text"
                                                    name="kinFirstName"
                                                    label="First name"
                                                />
                                                <RegularInput
                                                    className="middle-name"
                                                    type="text"
                                                    name="kinMiddleName"
                                                    label="Middle (optional)"
                                                />
                                                <RequiredInput
                                                    type="text"
                                                    name="kinLastName"
                                                    label="Last name"
                                                />
                                            </div>
                                            <RegularInput
                                                // disabled={this.props.user}
                                                type="email"
                                                name="kinEmail"
                                                label="Email address (optional)"
                                            />
                                            <RegularInput
                                                type="text"
                                                name="kinMobile"
                                                label="Phone number (optional)"
                                            />
                                            <div
                                                className={`${
                                                    props.errors
                                                        ?.kinRelationship &&
                                                    props.touched
                                                        ?.kinRelationship
                                                        ? 'c-select invalid'
                                                        : 'c-select '
                                                }`}
                                            >
                                                <label
                                                    className={`${
                                                        this.state.titleFocused
                                                            ? 'title-label'
                                                            : ''
                                                    } c-select__label `}
                                                    htmlFor="title"
                                                >
                                                    Relationship to plan holder{' '}
                                                    <span>*</span>
                                                </label>
                                                <Select
                                                    options={
                                                        relationshipOptions
                                                    }
                                                    value={
                                                        props.values
                                                            .kinRelationship
                                                            ? {
                                                                  value: props
                                                                      .values
                                                                      .kinRelationship
                                                                      ? props
                                                                            .values
                                                                            .kinRelationship
                                                                      : '',
                                                                  label: props
                                                                      .values
                                                                      .kinRelationship
                                                                      ? props
                                                                            .values
                                                                            .kinRelationship
                                                                      : 'Relationship to planholder',
                                                              }
                                                            : null
                                                    }
                                                    name="kinRelationship"
                                                    onBlur={(e) =>
                                                        props.setFieldTouched(
                                                            'kinRelationship',
                                                            true
                                                        )
                                                    }
                                                    placeholder={' '}
                                                    classNamePrefix="c-react-select"
                                                    isSearchable={false}
                                                    onChange={(e) =>
                                                        props.setFieldValue(
                                                            'kinRelationship',
                                                            e.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            {props.errors?.kinRelationship &&
                                            props.touched?.kinRelationship ? (
                                                <div className="font-13 font-semibold font-form-error error-message required-icon margin-reset position-relative">
                                                    This field is required.
                                                </div>
                                            ) : null}

                                            <div>
                                                <div className="o-flex-inline o-flex-middle o-flex-between width-full mb-20 mt-10">
                                                    <h4 className="edit-profile__postcode-address">
                                                        {this.state
                                                            .kinPostCodeVisible
                                                            ? 'Postcode lookup:'
                                                            : 'Full address:'}
                                                    </h4>
                                                    <h4
                                                        onClick={() =>
                                                            this.handleKinAddressSwitch(
                                                                props
                                                            )
                                                        }
                                                        className="font-20 cursor-pointer text-right margin-reset link-underline-hover-blue edit-profile__postcode-address"
                                                    >
                                                        {this.state
                                                            .holderPostCodeVisible
                                                            ? 'Manually enter your address'
                                                            : 'Postcode lookup'}
                                                    </h4>
                                                </div>
                                                <div
                                                    className={`${
                                                        !this.state
                                                            .kinPostCodeVisible
                                                            ? 'hide'
                                                            : ''
                                                    } ${
                                                        props.values
                                                            .postcodeLookup
                                                            ? 'placeholder-dark'
                                                            : ''
                                                    } position-relative`}
                                                >
                                                    <div>
                                                        <div
                                                            onClick={() =>
                                                                this.kinPostcodeRef.current.focus()
                                                            }
                                                            onChange={(e) =>
                                                                this.handleKinFullAddressPostcodeChange(
                                                                    e,
                                                                    props
                                                                )
                                                            }
                                                            tabIndex="-1"
                                                            className={`c-input c-input__wrap ${
                                                                this.state
                                                                    .kinFullAddressPostcode
                                                                    .length ||
                                                                this.state
                                                                    .kinFullAddressPostcodeFocused
                                                                    ? 'c-input__filled'
                                                                    : ''
                                                            } ${
                                                                !this.state
                                                                    .kinFullAddressPostcodeFocused &&
                                                                props.errors
                                                                    ?.kinFullAddress &&
                                                                props.touched
                                                                    ?.kinFullAddress
                                                                    ? 'c-input__invalid'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <label className="c-input__label">
                                                                Postcode
                                                                <span className="c-input__label--required">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                ref={
                                                                    this
                                                                        .kinPostcodeRef
                                                                }
                                                                onFocus={() => {
                                                                    this.setState(
                                                                        {
                                                                            kinFullAddressPostcodeFocused: true,
                                                                            kinFullAddressTouched: true,
                                                                        },
                                                                        () => {
                                                                            props.setFieldTouched(
                                                                                'kinFullAddress',
                                                                                true
                                                                            );
                                                                        }
                                                                    );
                                                                }}
                                                                onBlur={(e) =>
                                                                    this.handleKinFullAddressBlur(
                                                                        e,
                                                                        props
                                                                    )
                                                                }
                                                                value={
                                                                    this.state
                                                                        .kinFullAddressPostcode
                                                                }
                                                                form="novalidatedform"
                                                                type="text"
                                                                name="kinFullAddress"
                                                                autoComplete="off"
                                                            ></input>
                                                            {!this.state
                                                                .kinFullAddressPostcodeFocused &&
                                                            props.errors
                                                                ?.kinFullAddress &&
                                                            props.touched
                                                                ?.kinFullAddress ? (
                                                                <div className="c-input__error-message">
                                                                    This field
                                                                    is required.
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div>
                                                            {this.state
                                                                .kinFullAddressPostcodeResults
                                                                .length ? (
                                                                <div className="c-postcode-results">
                                                                    <i
                                                                        className="icon-times-thin c-postcode-results__close"
                                                                        onClick={() => {
                                                                            this.handleKinFullAddressPostcodeResultsClear(
                                                                                props
                                                                            );
                                                                        }}
                                                                    ></i>
                                                                    <ul className="c-postcode-results__list">
                                                                        {this.state.kinFullAddressPostcodeResults.map(
                                                                            (
                                                                                postcode
                                                                            ) => (
                                                                                <li
                                                                                    className="c-postcode-results__item"
                                                                                    onClick={() =>
                                                                                        this.handleKinFullAddressPostcodeResultClick(
                                                                                            postcode,
                                                                                            props
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        postcode.building_number
                                                                                    }{' '}
                                                                                    {postcode.building_name ===
                                                                                    postcode.line_1
                                                                                        ? ''
                                                                                        : postcode.building_name}{' '}
                                                                                    {
                                                                                        postcode.line_1
                                                                                    }{' '}
                                                                                    {
                                                                                        postcode.post_town
                                                                                    }{' '}
                                                                                    {
                                                                                        postcode.country
                                                                                    }{' '}
                                                                                    {
                                                                                        postcode.postcode
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${
                                                        this.state
                                                            .kinPostCodeVisible
                                                            ? 'hide'
                                                            : ''
                                                    } col2-v22 ${
                                                        props.values
                                                            .postcodeLookup
                                                            ? 'placeholder-dark'
                                                            : ''
                                                    } `}
                                                >
                                                    <RequiredInput
                                                        className="mt-i-0"
                                                        type="text"
                                                        name="kinAddressLine1"
                                                        label="Address Line 1"
                                                    />
                                                    <RegularInput
                                                        className="mt-i-0"
                                                        type="text"
                                                        name="kinAddressLine2"
                                                        label="Address Line 2 (optional)"
                                                    />
                                                    <RequiredInput
                                                        type="text"
                                                        name="kinTown"
                                                        label="Town"
                                                    />
                                                    <RegularInput
                                                        type="text"
                                                        name="kinCounty"
                                                        label="County (optional)"
                                                    />
                                                    <RequiredInput
                                                        type="text"
                                                        name="kinPostcode"
                                                        label="Postcode"
                                                    />
                                                    <div data-tip="Only place we operate">
                                                        <RequiredInput
                                                            disabled={true}
                                                            type="text"
                                                            name="kinCountry"
                                                            label="Country"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                <Navigation
                                    buttonLoading={this.state.buttonLoading}
                                    submit={true}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    funeralPayment: state.funeralPaymentReducer,
    user: state.usersReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCurrentStep: (step) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(step)),
        onSetFuneralCheckout: (data) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralCheckout(data)),
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
