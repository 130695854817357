import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export default function AngelsSingleSlide({
    additionalInfo,
    body,
    image,
    name,
    title,
    handlePopupOpen,
}) {
    return (
        <div className="c-angels-slide o-flex">
            <div
                className="c-angels-slide__image"
                style={{ backgroundImage: `url(${image})` }}
            ></div>
            <div className="c-angels-slide__text o-flex o-flex-column">
                <h3>{name}</h3>
                <h4>{title}</h4>
                <p>{ReactHtmlParser(body ? body : '')}</p>
                {additionalInfo ? (
                    <button
                        className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-293 text-center btn--padding-left-right"
                        onClick={handlePopupOpen}
                    >
                        Learn more about {name}
                    </button>
                ) : null}
            </div>
        </div>
    );
}
