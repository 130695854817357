import * as notifActionTypes from '../../../store/actions/notificationActions';
import * as searchActionTypes from '../../../store/actions/searchActions';
import * as userActionTypes from '../../../store/actions/usersActions';

import React, { Component } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Notifications from '../../Profile/Notifications/Notifications';
import imagePlaceholder from '../../../assets/images/profile-placeholder.png';
import imageLogo from '../../../assets/images/logo-without-slogan.svg';
import loginImage from '../../../assets/images/login.svg';
import wishesMenuIcon from '../../../assets/images/wishesIconDropdown.svg';
import lifeStoryMenuIcon from '../../../assets/images/lifstory.svg';
import practicalMenuIcon from '../../../assets/images/practical.svg';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import Cookie from 'js-cookie';
import { confirmAlert } from 'react-confirm-alert';
import ApiService from '../../../service/api.service';
import confirmLogoutImg from '../../../assets/images/are-you-sure-logout.png';
// import ReactHtmlParser from 'react-html-parser';

class PreHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            notificationsHovered: false,
            searchQuery: '',
            isSearchFocused: false,
            isLSOpen: false,
            phone: null,
            showBanner: true,
        };
        this.searchInputRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.headerRef = React.createRef();
    }

    handleLSOpen = () => {
        if (this.state.isLSOpen) {
            this.setState({
                isLSOpen: true,
            });
            document.body.classList.remove('ls-open');
            document.body.classList.remove('modal-open--with-overlay');
            document.body.classList.remove('modal-open');
        } else {
            this.setState({
                isLSOpen: false,
            });
            document.body.classList.add('ls-open');
            document.body.classList.add('modal-open--with-overlay');
            document.body.classList.add('modal-open');
        }
    };

    handleDropdownMenu = () => {
        if (this.state.dotsClass === 'active') {
            this.setState({
                dotsClass: '',
            });
        } else {
            this.setState({
                dotsClass: 'active',
            });
        }
    };

    handleNavOpen = () => {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    };

    handleNavMobile = () => {
        if (window.innerWidth < 992) {
            this.setState({ isNavOpen: !this.state.isNavOpen });
        } else {
            this.setState({ isNavOpen: false });
        }
    };

    componentWillUnmount() {
        document.body.classList.remove('ls-open');
        document.body.classList.remove('modal-open--with-overlay');
        document.body.classList.remove('modal-open');
        document.body.classList.remove('profile-open');
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.props.onCloseMobileProfile();
        });
    }

    componentDidMount() {
        let phone = Cookie.get('phone');
        if (phone) {
            this.setState({
                phone: phone,
            });
        }

        if (this.state.isNavOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        if (this.props.user) {
            document.body.classList.add('user-logged-in');
        } else {
            document.body.classList.remove('user-logged-in');
        }

        if (this.state.isLSOpen) {
            document.body.classList.add('ls-open');
            document.body.classList.add('modal-open--with-overlay');
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('ls-open');
            document.body.classList.remove('modal-open--with-overlay');
            document.body.classList.remove('modal-open');
        }

        this.prev = window.scrollY;
        window.addEventListener('scroll', (e) => this.handleNavigation(e));

        let isIOS =
            /iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        if (isIOS) {
            document.body.classList.add('ios-detected');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.history.location.pathname !==
            prevProps.history.location.pathname
        ) {
            let phone = Cookie.get('phone');
            if (phone) {
                this.setState({
                    phone: phone,
                });
            }
        }
    }

    handleNavigation = (e) => {
        const window = e.currentTarget;
        const header = document.getElementById('header');

        if ((this.prev > window.scrollY || window.pageYOffset <= 0) && header) {
            header.classList.remove('c-header__not-sticky');
        } else if (this.prev < window.scrollY && header) {
            header.classList.add('c-header__not-sticky');
        }
        this.prev = window.scrollY;
    };

    handleProfileOpen = () => {
        if (this.props.mobileProfileOpen) {
            document.body.classList.remove('profile-open');
            this.setState(
                {
                    isProfileOpen: '',
                },
                () => {
                    this.props.onToggleMobileProfile();
                }
            );
        } else {
            document.body.classList.add('profile-open');
            this.setState(
                {
                    isProfileOpen: 'active',
                },
                () => {
                    this.props.onToggleMobileProfile();
                }
            );
        }
    };

    handleSearchOpen = () => {
        this.setState({ isSearchOpen: !this.state.isSearchOpen });
        this.searchInputRef.current.focus();
    };

    toggleNotifications = (e) => {
        e.preventDefault();
        this.props.onNotificationToggle();
        if (!this.props.notifications.notificationsOpen) {
            this.props.onNotificationCountSubscribe(parseInt(0));
        }
    };

    closeNotificationOnHeaderClick = (event) => {
        if (
            this.headerRef.current &&
            !this.headerRef.current.contains(event.target)
        ) {
            if (this.props.notifications.notificationsOpen) {
                if (!this.state.notificationsHovered) {
                    this.props.onNotificationToggle();
                }
            }

            if (this.state.isProfileOpen) {
                document.body.classList.remove('profile-open');
                this.setState({
                    isProfileOpen: '',
                });
            }

            if (this.state.isNavOpen) {
                this.setState({
                    isNavOpen: '',
                });
            }
        }
    };

    handleNotificationsLeave = () =>
        this.setState({ notificationsHovered: false });

    handleNotificationsEnter = () =>
        this.setState({ notificationsHovered: true });

    handleSearchChange = (e) => this.setState({ searchQuery: e.target.value });

    handleSearch = (e) => {
        e.preventDefault();
        const searchQuery = this.state.searchQuery;
        if (this.state.searchQuery.trim().length > 1) {
            this.props.history.push(`/search/${searchQuery}`);
        }
    };

    onFocusSearch = () => this.setState({ isSearchFocused: true });
    onBlurSearch = () => this.setState({ isSearchFocused: false });

    handlePhoneClick = () => {
        ReactPixel.init('218942439488342');
        ReactPixel.track('Contact');

        ReactGA.initialize('UA-159178529-1');
        ReactGA.event({
            category: 'CTA',
            action: 'Click',
            label: 'Speak to us',
        });
    };

    handleLogout = (e) => {
        e.preventDefault();
        ApiService.getUncompletedPlans(this.props.user.id).then((r) => {
            if (r.data.userFuneralPlan) {
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <div className="popup-content popup-content--smaller text-center">
                            <div className="c-popup">
                                <h2 className="margin-reset font-24 font-bold mt-29 mb-20 font-family-secondary font-333 opacity-9">
                                    Are you sure you want to log out?
                                </h2>
                                <div className="c-popup__header">
                                    <img
                                        src={confirmLogoutImg}
                                        alt="confirm alert icon"
                                    />
                                </div>
                                <p className="margin-reset font-18 mt-20 font-333 lh-28">
                                    If you log out now, you will need to
                                    re-enter your email and password again
                                    before you can finish purchasing your
                                    funeral plan. Would you still like to log
                                    out of your account?"
                                </p>
                                <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                    <button
                                        className="btn btn--full btn--size-width-180"
                                        onClick={() => {
                                            this.handleContinueLogout();
                                            onClose();
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn--ghost-purple btn--size-width-180"
                                        onClick={onClose}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else {
                this.handleContinueLogout();
            }
        });
    };

    handleContinueLogout = () => {
        this.props.history.push('/logout');
    };

    handleHideBanner = () => {
        this.props.hideBanner();
        this.setState({ showBanner: false });
    };

    render() {
        const nonLoggedMenu = (
            <>
                {/* {this.props.bannerVisible && this.state.showBanner && (
                    <div className="c-top-banner">
                        <div className="c-top-banner__content">
                            <img src={this.props.bannerImage} alt="" />
                            <div>
                                {' '}
                                {ReactHtmlParser(this.props.bannerContent)}{' '}
                            </div>
                            <button onClick={this.handleHideBanner}></button>{' '}
                        </div>
                    </div>
                )} */}
                <div className="c-top-header-box o-flex width-full">
                    <div className="c-pre-header__box o-flex">
                        <div>
                            {/* <Link to="/">Home</Link> */}
                            {/* <span></span> */}
                            {/* <Link to="/help/faqs">Help</Link>
                            <span></span>
                            <Link to="/knowledge/articles">Knowledge</Link> */}
                            <p>
                                <strong>Aura Circle</strong>{' '}
                                <span>Plan your legacy</span>
                            </p>
                        </div>
                        <div>
                            {this.props.funeralPhone ? (
                                <>
                                    <a
                                        className="c-pre-header__phone"
                                        href={`tel:+44${this.props.funeralPhone
                                            .substring(1)
                                            .trim()}`}
                                        onClick={() => this.handlePhoneClick()}
                                    >
                                        {this.props.funeralPhone}
                                    </a>
                                    <span></span>
                                </>
                            ) : (
                                <>
                                    <a
                                        className="c-pre-header__phone"
                                        href="tel:+448008085723"
                                        onClick={() => this.handlePhoneClick()}
                                    >
                                        0800 808 5723
                                    </a>
                                    <span></span>
                                </>
                            )}
                            <Link className="c-pre-header__join" to="/register">
                                Join Us
                            </Link>
                            <span></span>
                            <Link className="c-pre-header__join" to="/login">
                                Log In
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );

        const userMenu = (
            <>
                <form
                    className={`c-header-search-form-wrapper ${
                        this.state.isSearchOpen
                            ? 'c-header-search-form-wrapper--open'
                            : ''
                    }`}
                >
                    <div className="c-header__search-form border-radius-7">
                        <label htmlFor="search-form">
                            <span className="label-hidden">Search form</span>
                            <input
                                id="search-form"
                                ref={this.searchInputRef}
                                onFocus={this.onFocusSearch}
                                onBlur={this.onBlurSearch}
                                onChange={($event) =>
                                    this.handleSearchChange($event)
                                }
                                className={`c-header__search-form--input form-item--border-gray ${
                                    this.state.isSearchFocused
                                        ? 'focused'
                                        : null
                                }`}
                                type="text"
                                placeholder="Search"
                                value={this.state.searchQuery}
                            />
                        </label>
                        <button
                            disabled={this.state.searchQuery.trim().length < 2}
                            onClick={($event) => this.handleSearch($event)}
                            className="btn btn--full  z-index-1 o-flex o-flex-middle o-flex-center"
                            type="submit"
                        >
                            <i className="icon-search font-19 margin-right-10"></i>
                            Search
                        </button>
                    </div>
                </form>
                <div className="c-pre-header__postlogin-box">
                    {/* <div className="c-top-banner">
                        {this.props.bannerVisible && this.state.showBanner && (
                            <div className="c-top-banner__content">
                                <img src={this.props.bannerImage} alt="" />
                                <div>
                                    {' '}
                                    {ReactHtmlParser(
                                        this.props.bannerContent
                                    )}{' '}
                                </div>
                                <button
                                    onClick={this.handleHideBanner}
                                ></button>{' '}
                            </div>
                        )}
                    </div> */}

                    <div className="c-pre-header__postlogin-box--nav">
                        <div className="c-pre-header__postlogin-box-label">
                            {/* <Link to="/">Home</Link> */}
                            {/* <span></span>
                            <Link to="/help/faqs">Help</Link>
                            <span></span>
                            <Link to="/knowledge/articles">Knowledge</Link> */}
                            <strong>Aura Circle</strong> Plan your legacy
                        </div>
                        <div>
                            <ul className="c-pre-header__postlogin">
                                <li>
                                    <a
                                        className="c-pre-header__phone"
                                        href="tel:+448008085723"
                                        onClick={() => this.handlePhoneClick()}
                                    >
                                        0800 808 5723
                                    </a>
                                </li>
                                <li className="position-relative c-header__user-menu--item">
                                    {this.props.notifications
                                        .notificationCount > 0 ? (
                                        <span className="c-counter c-counter__notification position-absolute o-flex o-flex-middle o-flex-center font-white z-index-1 active">
                                            {
                                                this.props.notifications
                                                    .notificationCount
                                            }
                                        </span>
                                    ) : null}
                                    <Link
                                        data-tooltip="Notifications"
                                        className="o-d-block"
                                        to="#"
                                        onClick={(e) =>
                                            this.toggleNotifications(e)
                                        }
                                    >
                                        <i className="icon-bell-notification-icon"></i>
                                    </Link>
                                </li>
                                <span></span>
                                <li className="position-relative c-header__user-menu--item c-header__user-menu--item-messages">
                                    {this.props.unreadThreadCount > 0 ? (
                                        <span className="c-counter position-absolute o-flex o-flex-middle o-flex-center font-white z-index-1 active">
                                            {this.props.unreadThreadCount}
                                        </span>
                                    ) : null}
                                    <NavLink
                                        to={`/my-aura/${
                                            this.props.user &&
                                            this.props.user.profile.slug
                                        }/messages/conversations`}
                                        data-tooltip="Messages"
                                        className="font-25 o-d-block c-header__user-menu--item-messages"
                                    >
                                        <i className="icon-message-icon"></i>
                                    </NavLink>
                                </li>
                                <span></span>
                                <li className="position-relative c-header__user-menu--user-nav-item o-d-none-tablet">
                                    <Link
                                        className="c-user-nav o-flex-middle o-flex"
                                        to="#"
                                    >
                                        <div className="c-user-nav__avatar-wrapper position-relative overflow-both-hidden border-radius-50">
                                            <img
                                                width="38"
                                                height="38"
                                                className="position-absolute position-alignment"
                                                src={
                                                    this.props.user &&
                                                    this.props.user.profile
                                                        .photo
                                                        ? `${this.props.user.profile.photo.url}`
                                                        : imagePlaceholder
                                                }
                                                alt="Avatar"
                                            />
                                        </div>
                                        {this.props.user &&
                                        this.props.user.firstName ? (
                                            <span className="c-user-nav__user-name">
                                                {this.props.user.firstName}
                                            </span>
                                        ) : null}
                                        <i className="icon-arrow-down-1 dropdown-arrow font-gray font-12"></i>
                                    </Link>
                                    <ul className="c-dropdown-menu c-dropdown-menu--hover position-absolute">
                                        <li className="c-dropdown-menu__image">
                                            <img
                                                src={
                                                    this.props.user &&
                                                    this.props.user.profile
                                                        .photo
                                                        ? `${this.props.user.profile.photo.url}`
                                                        : imagePlaceholder
                                                }
                                                alt="profile icon big"
                                            />
                                        </li>
                                        <li className="c-dropdown-menu__name">
                                            {this.props.user &&
                                            this.props.user.profile
                                                ? `${
                                                      this.props.user.profile
                                                          .firstName
                                                          ? this.props.user
                                                                .profile
                                                                .firstName
                                                          : ''
                                                  } ${
                                                      this.props.user.profile
                                                          .lastName
                                                          ? this.props.user
                                                                .profile
                                                                .lastName
                                                          : ''
                                                  }`
                                                : null}
                                        </li>
                                        <li className="c-dropdown-menu__email">
                                            {this.props.user &&
                                            this.props.user.email
                                                ? `${this.props.user.email}`
                                                : null}
                                        </li>
                                        <li className="c-dropdown-menu__tools-heading">
                                            FREE Aura Tools:
                                        </li>
                                        <li className="c-dropdown-menu__tools">
                                            <ul className="c-dropdown-menu__tools-list">
                                                <li className="c-dropdown-menu__tools-item">
                                                    <Link
                                                        to={`/my-aura/${
                                                            this.props.user &&
                                                            this.props.user
                                                                .profile.slug
                                                        }/life-story`}
                                                        className="c-dropdown-menu__tools-link"
                                                    >
                                                        {' '}
                                                        <img
                                                            src={
                                                                lifeStoryMenuIcon
                                                            }
                                                            alt="wishes menu icon"
                                                        />
                                                        Life Story
                                                    </Link>
                                                </li>
                                                <li className="c-dropdown-menu__tools-item">
                                                    <Link
                                                        to={`/my-aura/${
                                                            this.props.user &&
                                                            this.props.user
                                                                .profile.slug
                                                        }/practical-information`}
                                                        className="c-dropdown-menu__tools-link"
                                                    >
                                                        {' '}
                                                        <img
                                                            src={
                                                                practicalMenuIcon
                                                            }
                                                            alt="wishes menu icon"
                                                        />
                                                        Practical <br />{' '}
                                                        Information
                                                    </Link>
                                                </li>
                                                <li className="c-dropdown-menu__tools-item">
                                                    <Link
                                                        to={`/my-aura/${
                                                            this.props.user &&
                                                            this.props.user
                                                                .profile.slug
                                                        }/wishes`}
                                                        className="c-dropdown-menu__tools-link"
                                                    >
                                                        {' '}
                                                        <img
                                                            src={wishesMenuIcon}
                                                            alt="wishes menu icon"
                                                        />
                                                        Wishes
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link
                                                className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                    window.location.href.includes(
                                                        'profile/edit/'
                                                    )
                                                        ? 'active-sidebar-link'
                                                        : ''
                                                }`}
                                                to={`/profile/edit/${
                                                    this.props.user &&
                                                    this.props.user.profile.slug
                                                }`}
                                            >
                                                <i className="icon-edit"></i>
                                                Edit Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                    window.location.href.includes(
                                                        'profile/settings/'
                                                    )
                                                        ? 'active-sidebar-link'
                                                        : ''
                                                }`}
                                                to={`/profile/settings/${
                                                    this.props.user &&
                                                    this.props.user.profile.slug
                                                }`}
                                            >
                                                <i className="icon-settings"></i>
                                                Account Settings
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                    window.location.href.includes(
                                                        'profile/connections/my-connections/'
                                                    )
                                                        ? 'active-sidebar-link'
                                                        : ''
                                                }`}
                                                to={`/profile/connections/my-connections/${
                                                    this.props.user &&
                                                    this.props.user.profile.slug
                                                }/connected`}
                                            >
                                                <i className="icon-connections"></i>
                                                Connections
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={`o-flex-middle o-flex font-semibold font-16 font-333  order-history-nav-link ${
                                                    window.location.href.includes(
                                                        'purchase-history'
                                                    )
                                                        ? 'active-sidebar-link'
                                                        : ''
                                                }`}
                                                to="/purchase-history"
                                            >
                                                <i className="icon-purchase"></i>
                                                Purchase History
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={`o-flex-middle o-flex font-semibold font-16 font-333 ${
                                                    window.location.href.includes(
                                                        '/guides'
                                                    )
                                                        ? 'active-sidebar-link'
                                                        : ''
                                                }`}
                                                onClick={this.handleNavOpen}
                                                to={`/my-aura/${
                                                    this.props.user &&
                                                    this.props.user.profile.slug
                                                }/guides`}
                                            >
                                                <i className="icon-info-solid"></i>
                                                Guides
                                            </Link>
                                        </li>
                                        {this.props.user &&
                                        this.props.user.profile
                                            .profileDeceased ? (
                                            <li>
                                                <Link
                                                    className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                        window.location.pathname.includes(
                                                            '/deceased'
                                                        )
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={this.handleNavOpen}
                                                    to={`/my-aura/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }/deceased`}
                                                >
                                                    <i className="icon-statusIcon"></i>
                                                    Status
                                                </Link>
                                            </li>
                                        ) : null}
                                        <li>
                                            <Link
                                                onClick={(e) => {
                                                    this.handleLogout(e);
                                                }}
                                                className="logout o-flex-middle o-flex font-semibold font-16 font-333 "
                                                to="/logout"
                                            >
                                                <i className="icon-logout-icon"></i>
                                                Log Out
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );

        const loginMenu = (
            <div className="c-header__login-menu o-flex o-flex-middle">
                <ul className="c-header__user-menu o-flex o-flex-middle">
                    {!window.location.href.includes('search') ? (
                        <li
                            className={`c-header__user-menu--item o-d-none-tablet`}
                            onClick={this.handleSearchOpen}
                        >
                            <p
                                data-tooltip="Search"
                                className="font-scoripion font-25 margin-reset cursor-pointer"
                            >
                                <i
                                    className={`${
                                        this.state.isSearchOpen
                                            ? 'icon-times font-orange'
                                            : 'icon-search'
                                    }`}
                                ></i>
                            </p>
                        </li>
                    ) : (
                        <li className="c-header__user-menu--item o-d-none-tablet">
                            <p className="font-scoripion font-25 margin-reset">
                                <i
                                    className={`${
                                        this.state.isSearchOpen
                                            ? 'icon-times font-orange'
                                            : 'icon-search font-secondary'
                                    }`}
                                ></i>
                            </p>
                        </li>
                    )}
                </ul>
                <Link
                    className="btn btn--full btn--size-48 text-center btn--padding-left-right"
                    to="/register"
                >
                    Create FREE Account
                </Link>
                <Link
                    className="btn btn--ghost-purple btn--size-48 text-center btn--size-width-94 ml-10"
                    to="/login"
                >
                    Log in
                </Link>
            </div>
        );

        return (
            <>
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={`c-notifications ${
                        this.props.notifications.notificationsOpen ? 'open' : ''
                    }`}
                >
                    <div className="c-notifications__inner">
                        <span
                            onClick={() => this.props.onNotificationToggle()}
                            className="c-notifications__dim"
                        ></span>
                        <Notifications />
                    </div>
                </div>
                {/* <div className="c-top-banner">
                    <div>
                        <h3>AUTUMN SALE</h3>
                        <p>
                            <span>
                                <strong>£100</strong> off
                            </span>{' '}
                            all funeral plans purchased online
                        </p>
                    </div>
                </div> */}
                <div
                    className={`c-pre-header  o-flex o-flex-middle o-flex-end `}
                >
                    {this.props.user ? userMenu : nonLoggedMenu}
                    <header
                        onClick={(e) => this.closeNotificationOnHeaderClick(e)}
                        id="header"
                        className={`c-header ${
                            this.state.isNavOpen ? 'nav-open' : ''
                        } ${this.state.dotsClass} ${
                            !this.props.user ? 'not-logged-in' : ''
                        } ${
                            window.location.pathname.includes('crowdfunding')
                                ? 'c-header__preheader-hidden'
                                : null
                        }`}
                    >
                        <div className="container position-relative">
                            {!window.location.href.includes('search') ? (
                                <form
                                    className={`c-header-search-form-wrapper position-absolute o-d-none-tablet ${
                                        this.state.isSearchOpen
                                            ? 'c-header-search-form-wrapper--open'
                                            : ''
                                    }`}
                                >
                                    <div className="c-header__search-form o-flex -flex-middle o-flex-between border-radius-7 white-background">
                                        <label htmlFor="search-form">
                                            <span className="label-hidden">
                                                Search form
                                            </span>
                                            <input
                                                id="search-form"
                                                ref={this.searchInputRef}
                                                onFocus={this.onFocusSearch}
                                                onBlur={this.onBlurSearch}
                                                onChange={($event) =>
                                                    this.handleSearchChange(
                                                        $event
                                                    )
                                                }
                                                className={`c-header__search-form--input form-item--border-gray ${
                                                    this.state.isSearchFocused
                                                        ? 'focused'
                                                        : null
                                                }`}
                                                type="text"
                                                placeholder="Search"
                                                value={this.state.searchQuery}
                                            />
                                        </label>
                                        <button
                                            disabled={
                                                this.state.searchQuery.trim()
                                                    .length < 2
                                            }
                                            onClick={($event) =>
                                                this.handleSearch($event)
                                            }
                                            className="btn btn--full btn--size-48 position-relative overflow-both-hidden z-index-1 o-flex o-flex-middle o-flex-center"
                                            type="submit"
                                        >
                                            <i className="icon-search font-19 margin-right-10"></i>
                                            Search
                                        </button>
                                    </div>
                                </form>
                            ) : null}

                            <div className="o-flex o-flex-between o-flex-middle">
                                <div className="c-header__logo-nav-wrapper o-flex o-flex-between o-flex-middle">
                                    <Link
                                        to="/"
                                        className="c-header__logo position-relative"
                                    >
                                        <img
                                            width="107"
                                            height="100%"
                                            src={imageLogo}
                                            alt="imageLogo"
                                        />
                                    </Link>

                                    {this.props.user ? (
                                        <ul className="c-header__user-menu o-flex o-flex-middle o-none-from-tablet">
                                            <li
                                                className={`position-relative c-header__user-menu--item o-none-from-mobile ${
                                                    this.state.isProfileOpen
                                                        ? 'active-item'
                                                        : ''
                                                }`}
                                            >
                                                <p
                                                    className="font-25 o-d-block margin-reset cursor-pointer"
                                                    onClick={
                                                        this.handleProfileOpen
                                                    }
                                                >
                                                    <i
                                                        className={`icon-single-user position-relative ${
                                                            this.props.user &&
                                                            (this.props.user
                                                                .profile
                                                                .slug ===
                                                                this.props.match
                                                                    .params
                                                                    .slug ||
                                                                window.location
                                                                    .pathname ===
                                                                    '/' ||
                                                                window.location.pathname.includes(
                                                                    'partner-with-us'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'meet-the-team'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'in-the-press'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'testimonials'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'about'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'help'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'terms-of-use'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'aura-security'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'privacy-policy'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'articles'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'guides'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'aura'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'life-story'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'connections'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'profile/settings'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'profile/edit'
                                                                ) ||
                                                                window.location.pathname.includes(
                                                                    'knowledge'
                                                                )) &&
                                                            this.props.user
                                                                .profile
                                                                .profileDeceased
                                                                ? 'icon-single-user__deceased'
                                                                : ''
                                                        }`}
                                                    ></i>
                                                </p>
                                            </li>
                                            <li
                                                className={`position-relative c-header__user-menu--item c-header__user-menu--item-notification ${
                                                    this.props.notifications
                                                        .notificationsOpen
                                                        ? 'active-item'
                                                        : ''
                                                } ${
                                                    this.state.isNavOpen
                                                        ? 'nav-open'
                                                        : ''
                                                }`}
                                            >
                                                {this.props.notifications
                                                    .notificationCount > 0 ? (
                                                    <span className="c-counter position-absolute o-flex o-flex-middle o-flex-center font-white z-index-1">
                                                        {
                                                            this.props
                                                                .notifications
                                                                .notificationCount
                                                        }
                                                    </span>
                                                ) : null}
                                                <Link
                                                    data-tooltip="Notifications"
                                                    onMouseEnter={
                                                        this
                                                            .handleNotificationsEnter
                                                    }
                                                    onMouseLeave={
                                                        this
                                                            .handleNotificationsLeave
                                                    }
                                                    onClick={($event) => {
                                                        this.toggleNotifications(
                                                            $event
                                                        );
                                                    }}
                                                    className="font-25 o-d-block"
                                                    to="#"
                                                >
                                                    <i className="icon-notification"></i>
                                                </Link>
                                            </li>
                                            <li className="position-relative c-header__user-menu--item c-header__user-menu--item-messages o-d-none-mobile">
                                                {this.props.unreadThreadCount >
                                                0 ? (
                                                    <span className="c-counter position-absolute o-flex o-flex-middle o-flex-center font-white z-index-1">
                                                        {
                                                            this.props
                                                                .unreadThreadCount
                                                        }
                                                    </span>
                                                ) : null}
                                                <NavLink
                                                    to={`/my-aura/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }/messages/conversations`}
                                                    data-tooltip="Messages"
                                                    className="font-25 o-d-block c-header__user-menu--item-messages"
                                                >
                                                    <i className="icon-message"></i>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul className="c-header__user-menu o-flex o-flex-middle o-none-from-tablet">
                                            <li className="position-relative c-header__user-menu--item c-header__user-menu--item--login o-d-none o-d-flex-tablet o-flex-column">
                                                <Link
                                                    className="o-d-flex-tablet o-flex-column"
                                                    to="/login"
                                                >
                                                    <img
                                                        width="48"
                                                        height="100%"
                                                        src={loginImage}
                                                        alt="loginImage"
                                                    />
                                                    <span className="font-tundora font-16">
                                                        LOGIN
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    )}

                                    <ul
                                        className={`c-header__menu o-flex o-flex-column-tablet ${
                                            this.state.isNavOpen
                                                ? 'nav-open'
                                                : ''
                                        }`}
                                    >
                                        {!window.location.href.includes(
                                            'search'
                                        ) ? (
                                            <li className="c-header__menu--user-menu o-d-none o-d-block-tablet">
                                                <form
                                                    ref={this.headerRef}
                                                    onSubmit={($event) =>
                                                        this.handleSearch(
                                                            $event
                                                        )
                                                    }
                                                    className="c-header__search-form o-flex -flex-middle position-relative o-flex-between border-radius-7 white-background"
                                                >
                                                    <input
                                                        autoFocus={false}
                                                        onFocus={
                                                            this.onFocusSearch
                                                        }
                                                        onBlur={
                                                            this.onBlurSearch
                                                        }
                                                        onChange={($event) =>
                                                            this.handleSearchChange(
                                                                $event
                                                            )
                                                        }
                                                        className={`c-header__search-form--input form-item--border-gray ${
                                                            this.state
                                                                .isSearchFocused
                                                                ? 'focused'
                                                                : null
                                                        }`}
                                                        type="text"
                                                        placeholder="Search"
                                                        value={
                                                            this.state
                                                                .searchQuery
                                                        }
                                                    />
                                                    <button
                                                        aria-label="search"
                                                        className="btn btn--initial btn--no-border c-header__search-form--action position-absolute"
                                                        type="submit"
                                                    >
                                                        <i className="icon-search opacity-5 font-scoripion font-25"></i>
                                                    </button>
                                                </form>
                                            </li>
                                        ) : null}
                                        <li className="o-d-none-mobile">
                                            {this.props.user ? ( // if
                                                <NavLink
                                                    activeClassName="active"
                                                    className={`font-family-secondary font-semibold font-20 font-333 opacity-8 position-relative
                                        ${
                                            (window.location.href.includes(
                                                'life-story'
                                            ) &&
                                                window.location.href.includes(
                                                    'my-aura'
                                                ) &&
                                                this.props.user) ||
                                            (window.location.href.includes(
                                                'wishes'
                                            ) &&
                                                window.location.href.includes(
                                                    'my-aura'
                                                )) ||
                                            (window.location.href.includes(
                                                'deceased'
                                            ) &&
                                                window.location.href.includes(
                                                    'my-aura'
                                                )) ||
                                            (window.location.href.includes(
                                                'practical-information'
                                            ) &&
                                                window.location.href.includes(
                                                    'my-aura'
                                                )) ||
                                            (window.location.href.includes(
                                                'messages'
                                            ) &&
                                                window.location.href.includes(
                                                    'my-aura'
                                                ))
                                                ? 'active'
                                                : ''
                                        }`}
                                                    to={`/my-aura/${
                                                        this.props.user &&
                                                        this.props.user.profile
                                                            .slug
                                                    }/life-story`}
                                                    onClick={
                                                        this.handleNavMobile
                                                    }
                                                >
                                                    My Aura
                                                </NavLink>
                                            ) : (
                                                // else
                                                <NavLink
                                                    activeClassName="active"
                                                    className="font-family-secondary font-semibold font-20 font-333 opacity-8 position-relative"
                                                    to="/features"
                                                    onClick={
                                                        this.handleNavMobile
                                                    }
                                                >
                                                    Features
                                                </NavLink>
                                            )}
                                        </li>
                                        <li>
                                            <NavLink
                                                className={`font-family-secondary font-semibold font-20 font-333 opacity-8 position-relative c-header__menu--parent-mobile ${
                                                    window.location.pathname.includes(
                                                        'knowledge'
                                                    ) ||
                                                    window.location.pathname.includes(
                                                        'guides'
                                                    ) ||
                                                    window.location.pathname.includes(
                                                        'articles'
                                                    )
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                to={`${
                                                    window.location.pathname.includes(
                                                        'knowledge/articles'
                                                    )
                                                        ? '/knowledge/articles'
                                                        : '/knowledge/articles'
                                                }`}
                                            >
                                                Knowledge
                                            </NavLink>
                                            <ul
                                                className={`c-dropdown-menu c-dropdown-menu--hover c-dropdown-menu--mobile o-none-from-tablet`}
                                            >
                                                <li>
                                                    <a
                                                        class="o-flex-middle o-flex font-semibold font-16 font-333 opacity-7 "
                                                        href="/knowledge/articles"
                                                    >
                                                        Articles
                                                    </a>
                                                </li>
                                                <li>
                                                    <Link
                                                        className={`o-flex-middle o-flex font-semibold font-16 font-333 opacity-7 ${
                                                            window.location.pathname.includes(
                                                                'knowledge/guides'
                                                            )
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={
                                                            this.handleNavOpen
                                                        }
                                                        to="/knowledge/guides"
                                                    >
                                                        Guides
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a
                                                class="font-family-secondary font-semibold font-20 font-333 opacity-8 position-relative c-header__menu--parent-mobile "
                                                href="/help/faqs"
                                            >
                                                Help
                                            </a>
                                            <ul
                                                className={`c-dropdown-menu c-dropdown-menu--hover c-dropdown-menu--mobile o-none-from-tablet`}
                                            >
                                                {/* <li><Link className={`o-flex-middle o-flex font-semibold font-16 font-333 opacity-7 ${window.location.pathname.includes('help/video-help') ? 'active' : ''}`} onClick={this.handleNavOpen} to="/help/video-help">Video Help</Link></li> */}
                                                <li>
                                                    <a
                                                        class="o-flex-middle o-flex font-semibold font-16 font-333 opacity-7 "
                                                        href="/help/faqs"
                                                    >
                                                        FAQs
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className="o-flex-middle o-flex font-semibold font-16 font-333 opacity-7 "
                                                        href="/help/contact-us"
                                                    >
                                                        Contact Us
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        {!this.props.token ? (
                                            <li>
                                                <a
                                                    class="font-family-secondary font-semibold font-20 font-333 opacity-8 position-relative"
                                                    href="/about"
                                                >
                                                    About
                                                </a>
                                            </li>
                                        ) : null}
                                        {this.props.user ? (
                                            <li className="position-relative o-d-none o-d-block-tablet">
                                                <Link
                                                    className={`o-flex-middle o-flex font-family-secondary font-semibold font-20 font-333 opacity-8 position-relative c-header__menu--parent-mobile ${
                                                        window.location.pathname.includes(
                                                            'profile'
                                                        )
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    to="#"
                                                >
                                                    <span>Account</span>
                                                </Link>
                                                <ul
                                                    className={`c-dropdown-menu c-dropdown-menu--hover c-dropdown-menu--mobile ${this.state.dotsClass}`}
                                                >
                                                    <li>
                                                        <Link
                                                            className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                                window.location.pathname.includes(
                                                                    '/profile/edit'
                                                                )
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={
                                                                this
                                                                    .handleNavOpen
                                                            }
                                                            to={`/profile/edit/${
                                                                this.props
                                                                    .user &&
                                                                this.props.user
                                                                    .profile
                                                                    .slug
                                                            }`}
                                                        >
                                                            <i className="icon-edit"></i>
                                                            Edit Profile
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                                window.location.pathname.includes(
                                                                    '/profile/settings'
                                                                )
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={
                                                                this
                                                                    .handleNavOpen
                                                            }
                                                            to={`/profile/settings/${
                                                                this.props
                                                                    .user &&
                                                                this.props.user
                                                                    .profile
                                                                    .slug
                                                            }`}
                                                        >
                                                            <i className="icon-settings"></i>
                                                            Account Settings
                                                        </Link>
                                                    </li>
                                                    {this.props.user &&
                                                    this.props.user.profile
                                                        .profileDeceased ? (
                                                        <li className="o-none-from-mobile">
                                                            <Link
                                                                className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                                    window.location.pathname.includes(
                                                                        '/deceased'
                                                                    )
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                                onClick={
                                                                    this
                                                                        .handleNavOpen
                                                                }
                                                                to={`/my-aura/${
                                                                    this.props
                                                                        .user &&
                                                                    this.props
                                                                        .user
                                                                        .profile
                                                                        .slug
                                                                }/deceased`}
                                                            >
                                                                <i className="icon-deceased"></i>
                                                                Status
                                                            </Link>
                                                        </li>
                                                    ) : null}
                                                    <li>
                                                        <Link
                                                            className={`o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                                window.location.pathname.includes(
                                                                    '/profile/connections'
                                                                )
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={
                                                                this
                                                                    .handleNavOpen
                                                            }
                                                            to={`/profile/connections/my-connections/${
                                                                this.props
                                                                    .user &&
                                                                this.props.user
                                                                    .profile
                                                                    .slug
                                                            }/connected`}
                                                        >
                                                            <i className="icon-connections"></i>
                                                            Connections
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="o-flex-middle o-flex font-semibold font-16 font-333  order-history-nav-link"
                                                            onClick={
                                                                this
                                                                    .handleNavOpen
                                                            }
                                                            to="/purchase-history"
                                                        >
                                                            <i className="icon-purchase"></i>
                                                            Purchase History
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="o-flex-middle o-flex font-semibold font-16 font-333  order-history-nav-link"
                                                            onClick={
                                                                this
                                                                    .handleNavOpen
                                                            }
                                                            to={`/my-aura/${
                                                                this.props
                                                                    .user &&
                                                                this.props.user
                                                                    .profile
                                                                    .slug
                                                            }/guides`}
                                                        >
                                                            <i className="icon-purchase"></i>
                                                            Guides
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="o-flex-middle o-flex font-semibold font-16 font-333  logout"
                                                            onClick={(e) => {
                                                                this.handleNavOpen();
                                                                this.handleLogout(
                                                                    e
                                                                );
                                                            }}
                                                            to="/logout"
                                                        >
                                                            <i className="icon-logout-icon"></i>
                                                            Log Out
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        ) : null}
                                        <div className="o-flex o-none-from-tablet o-flex-center mt-20">
                                            {!this.props.token ? (
                                                <div className="c-header__login-menu o-flex o-flex-middle o-flex-column-iphone">
                                                    <Link
                                                        className="btn btn--full btn--size-48 text-center btn--padding-left-right"
                                                        to="/register"
                                                    >
                                                        Create FREE Account
                                                    </Link>
                                                    <Link
                                                        className="btn btn--ghost-purple btn--size-48 text-center ml-20"
                                                        to="/login"
                                                    >
                                                        Log in
                                                    </Link>
                                                </div>
                                            ) : null}
                                        </div>
                                    </ul>
                                </div>
                                <div className="o-d-none-tablet">
                                    {this.props.token
                                        ? this.props.user
                                            ? userMenu
                                            : null
                                        : loginMenu}
                                </div>
                                <button
                                    className={`c-header__hamburger-menu c-hamburger-menu o-d-none o-d-flex-tablet o-flex-column btn btn--no-border btn--size-auto btn--padding-clear font-tundora font-16 btn--font-w-400 o-flex-middle ml-20 ${
                                        this.state.isNavOpen ? 'nav-open' : ''
                                    }`}
                                    type="button"
                                    onClick={this.handleNavOpen}
                                >
                                    <div className="c-hamburger-menu__icon position-relative cursor-pointer">
                                        <span className="c-hamburger-menu__icon--first position-absolute"></span>
                                        <span className="c-hamburger-menu__icon--second position-absolute"></span>
                                        <span className="c-hamburger-menu__icon--third position-absolute"></span>
                                        <span className="c-hamburger-menu__icon--fourth position-absolute"></span>
                                    </div>
                                    <span className="font-tundora font-16 c-hamburger-menu__title c-hamburger-menu__title--closed">
                                        MENU
                                    </span>
                                    <span className="font-tundora font-16 c-hamburger-menu__title c-hamburger-menu__title--opened">
                                        CLOSE
                                    </span>
                                </button>
                            </div>
                        </div>
                    </header>
                </div>
            </>
        );
    }
}

const preHeaderWithRouter = withRouter(PreHeader);

const mapStateToProps = (state) => ({
    notifications: state.notificationsReducer,
    user: state.usersReducer.user,
    token: state.usersReducer.token,
    unreadThreadCount: state.messagesReducer.unreadThreadCount,
    mobileProfileOpen: state.usersReducer.mobileProfileOpen,
    funeralPhone: state.usersReducer.funeralPhone,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onNotificationToggle: () =>
            dispatch(notifActionTypes.onToggleNotifications()),
        onSetNotifications: (notifications) =>
            dispatch(notifActionTypes.onSetNotifications(notifications)),
        onSetNotificationLoading: (loading) =>
            dispatch(notifActionTypes.onSetNotificationLoading(loading)),
        onSetSearchQuery: (searchQuery) =>
            dispatch(searchActionTypes.onSetSearchQuery(searchQuery)),
        onNotificationCountSubscribe: (resetCount) =>
            dispatch(notifActionTypes.onNotificationCountSubscribe(resetCount)),
        onClearNotifCount: () => dispatch(userActionTypes.onClearNotifCount()),
        onToggleMobileProfile: () =>
            dispatch(userActionTypes.onToggleMobileProfile()),
        onCloseMobileProfile: () =>
            dispatch(userActionTypes.onCloseMobileProfile()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(preHeaderWithRouter);
