class VideoEmbed {
    static parseLink(link, autoplay) {
        const match = link.match(
            /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
        );
        let url = '';

        if (match) {
            switch (match[2]) {
                case 'www.youtube.com':
                    if (match[5] !== '/watch') {
                        url = 'https://www.youtube.com' + match[5];
                    } else if (match[6]) {
                        url = `https://www.youtube.com/embed/${
                            match[6].split('=')[1]
                        }`;
                    }
                    break;

                case 'youtu.be':
                    if (match[5]) {
                        url = 'https://www.youtube.com/embed' + match[5];
                    }
                    break;
                case 'youtube.com':
                    if (match[6]) {
                        match[6]
                            .replace('?', '')
                            .split('&')
                            .forEach((param) => {
                                const values = param.split('=');

                                if (values[0] === 'v') {
                                    url =
                                        'https://www.youtube.com/embed/' +
                                        values[1];
                                }
                            });
                    }
                    break;
                case 'www.vimeo.com':
                case 'vimeo.com':
                    if (match[5]) {
                        if (autoplay) {
                            url =
                                'https://player.vimeo.com/video' +
                                match[5] +
                                '?background=1';
                        } else {
                            url = 'https://player.vimeo.com/video' + match[5];
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return url;
    }
}

export default VideoEmbed;
