import React from 'react';
import ExtraItem from './ExtraItem';
import ReactHtmlParser from 'react-html-parser';

const Extras = ({ data }) => {
    const listOfExtras = data.map((extra) => {
        return (
            <div key={extra.id}>
                <h2>{extra.title}</h2>
                <p>{ReactHtmlParser(extra.shortDescription)}</p>
                <ExtraItem dataItem={extra.extras} />
            </div>
        );
    });

    return (
        <div className="c-funeral-at-need__extras--container">
            {listOfExtras}
        </div>
    );
};

export default Extras;
