export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const SET_ALL_READ = 'SET_ALL_READ';
export const SET_DELETE = 'SET_DELETE';
export const SET_CONNECTION = 'SET_CONNECTION';
export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const APPEND_NOTIFICATIONS = 'APPEND_NOTIFICATIONS';
export const ACCEPT_REQUEST_FROM_NOTIF = 'ACCEPT_REQUEST_FROM_NOTIF';
export const NOTIFICATION_COUNT_SUBSCRIBE = 'NOTIFICATION_COUNT_SUBSCRIBE';

//dispatch changes
export const toggleNotifications = (payload) => {
    return {
        type: TOGGLE_NOTIFICATIONS,
    };
};

export const setNotifications = (payload) => {
    return {
        type: SET_NOTIFICATIONS,
        notifications: payload,
    };
};

export const setAllRead = () => {
    return {
        type: SET_ALL_READ,
    };
};

export const decline = (notificationId) => {
    return {
        type: SET_DELETE,
        notificationId: notificationId,
    };
};

export const acceptConnection = (payload) => {
    return {
        type: ACCEPT_REQUEST_FROM_NOTIF,
        ...payload,
    };
};

//dispatch actions
export const onToggleNotifications = (payload) => {
    return (dispatch) => {
        dispatch(toggleNotifications(payload));
    };
};

export const onSetNotifications = (payload) => {
    return (dispatch) => {
        dispatch(setNotifications(payload));
    };
};

export const onSetAllRead = () => {
    return (dispatch) => {
        dispatch(setAllRead());
    };
};

export const onDecline = (notificationId) => {
    return (dispatch) => {
        dispatch(decline(notificationId));
    };
};

export const onAcceptConnection = (payload) => {
    return (dispatch) => {
        dispatch(acceptConnection(payload));
    };
};

export const setNotificationLoading = (loading) => {
    return {
        type: LOADING_NOTIFICATIONS,
        loading: loading,
    };
};
export const onSetNotificationLoading = (payload) => {
    return (dispatch) => {
        dispatch(setNotificationLoading(payload));
    };
};

export const appendNotifications = (payload) => {
    return {
        type: APPEND_NOTIFICATIONS,
        notifications: payload,
    };
};
export const onAppendNotifications = (payload) => {
    return (dispatch) => {
        dispatch(appendNotifications(payload));
    };
};

export const onNotificationCountSubscribe = (payload) => {
    return (dispatch) => {
        dispatch(notificationCountSubscribe(payload));
    };
};

export const notificationCountSubscribe = (notifCount) => {
    return {
        type: NOTIFICATION_COUNT_SUBSCRIBE,
        notificationCount: notifCount,
    };
};
