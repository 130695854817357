import 'aos/dist/aos.css';
import './css/styles.css';

import * as actionTypes from './store/actions/usersActions';
import * as connectionActionTypes from './store/actions/connectionActions';
import * as messagesActionTypes from './store/actions/messagesActions';
import * as notificationActionTypes from './store/actions/notificationActions';
import * as routerActionTypes from './store/actions/routerActions';

import { Route, Switch, withRouter } from 'react-router-dom';
import React, { Component, Suspense } from 'react';

import AOS from 'aos';
import ApiService from './service/api.service';
import AuraAngels from './pages/Landing/Angels/AuraAngels';
import AuraCircle from './pages/Landing/Circle/AuraCircle';
import LoggedAuraCircle from './pages/Landing/Circle/AuraCircleLogged';
// import Cookie from 'js-cookie';
import { EventSourcePolyfill } from 'event-source-polyfill';
import FuneralPayment from './pages/FuneralPayment/FuneralPayment';
import FuneralPlans from './pages/FuneralPlans.js/FuneralPlans';
import Funerals from './pages/Funerals/Funerals';
import InstallmentThankYou from './pages/FuneralPayment/Steps/ThankYou/InstallmentThankYou';
import Loader from './components/UI/Loader/Loader';
import OrderHistoryList from './pages/OrderHistory/OrderHistoryList/OrderHistoryList';
import OrderHistorySingle from './pages/OrderHistory/OrderHistorySingle/OrderHistorySingle';
import PaymentSelection from './pages/PaymentSelection/PaymentSelection';
import ReactGA from 'react-ga';
import ThankYou from './pages/FuneralPayment/Steps/ThankYou/ThankYou';
import config from './config';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { hotjar } from 'react-hotjar';
import ifvisible from 'ifvisible.js';
import AuraLanding from './pages/Landing/Landing/AuraLanding';
import Funeral from './pages/Landing/Funeral/Funeral';
import TagManager from 'react-gtm-module';
import Redirect from './pages/Routing/Redirect/Redirect';
import CustomPage from './pages/CustomPage/CustomPage';
import LocationsPage from './pages/LocationsPage/LocationsPage';
import AllLocations from './pages/LocationsPage/AllLocations';
import FuneralPlansSecond from './pages/FuneralPlans.js/FuneralPlansSecond';
import AmlCheck from './pages/AmlCheck/AmlCheck';

export const history = createBrowserHistory();

const Homepage = React.lazy(() =>
    retry(() => import('./pages/Homepage/Homepage'))
);
const ProtectedRoute = React.lazy(() =>
    retry(() => import('./pages/Routing/ProtectedRoute/ProtectedRoute'))
);
const Profile = React.lazy(() =>
    retry(() => import('./pages/Profile/Profile'))
);
const MyAura = React.lazy(() => retry(() => import('./pages/MyAura/MyAura')));
const Login = React.lazy(() => retry(() => import('./pages/auth/Login/Login')));
const Register = React.lazy(() =>
    retry(() => import('./pages/auth/Register/Register'))
);
const ForgotPassword = React.lazy(() =>
    retry(() => import('./pages/auth/ForgotPassword/ForgotPassword'))
);
const ConfirmAccount = React.lazy(() =>
    retry(() => import('./pages/auth/ConfirmAccount/ConfirmAccount'))
);
const ResetPassword = React.lazy(() =>
    retry(() => import('./pages/auth/ResetPassword/ResetPassword'))
);
const Logout = React.lazy(() =>
    retry(() => import('./pages/auth/Logout/Logout'))
);
const Invitation = React.lazy(() =>
    retry(() => import('./pages/auth/Invitation/Invitation'))
);
const LifeStory = React.lazy(() =>
    retry(() => import('./pages/LifeStory/LifeStory'))
);
const InfoPages = React.lazy(() =>
    retry(() => import('./pages/Info/InfoPages'))
);
const Help = React.lazy(() => retry(() => import('./pages/Help/Help')));
const Features = React.lazy(() =>
    retry(() => import('./pages/Features/Features'))
);
const Partnerships = React.lazy(() =>
    retry(() => import('./pages/Partnerships/Partnerships'))
);
const MeetTheTeam = React.lazy(() =>
    retry(() => import('./pages/MeetTheTeam/MeetTheTeam'))
);
const Testimonials = React.lazy(() =>
    retry(() => import('./pages/Testimonials/Testimonials'))
);
const InThePress = React.lazy(() =>
    retry(() => import('./pages/InThePress/InThePress'))
);
const Knowledge = React.lazy(() =>
    retry(() => import('./pages/Knowledge/Knowledge'))
);
const BlogArticle = React.lazy(() =>
    retry(() => import('./pages/Knowledge/BlogArticle/BlogArticle'))
);
const Author = React.lazy(() =>
    retry(() => import('./pages/Knowledge/Author/Author'))
);
const Search = React.lazy(() => retry(() => import('./pages/Search/Search')));
const NotFound = React.lazy(() =>
    retry(() => import('./pages/Routing/404/404'))
);
const NotAccessible = React.lazy(() =>
    retry(() => import('./pages/Routing/403/403'))
);

let eventSource = null;

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        reject(error);
                        return;
                    }

                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

class App extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this._countInterval = null;
        this._inactivityTime = 0;
        this._inactivityDate = Date.now();
    }

    componentDidMount() {
        this._isMounted = true;
        if (config.apiGateway.URL.includes('aura.life')) {
            //hotjar
            let hjid = 1998921;
            let hjsv = 6;
            hotjar.initialize(hjid, hjsv);

            //google analytics
            ReactGA.initialize('UA-159178529-1');
            ReactGA.pageview(window.location.pathname + window.location.search);

            //facebook pixel
            const ReactPixel = require('react-facebook-pixel');
            ReactPixel.default.init('218942439488342');
        }

        const tagManagerArgs = {
            gtmId: 'GTM-W53NPBZ',
        };

        TagManager.initialize(tagManagerArgs);

        // const script = document.createElement('script');

        // script.src = '//js-na1.hs-scripts.com/20309021.js';
        // script.id = 'hubspot';
        // script.async = true;

        // document.body.appendChild(script);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.async = true;
        script.defer = true;
        script.src = '//js.hs-scripts.com/20309021.js';

        document.body.appendChild(script);

        const now = new Date();
        const token = localStorage.getItem('token');
        const tokenRefreshedDate = localStorage.getItem('tokenRefreshedDate')
            ? new Date(localStorage.getItem('tokenRefreshedDate'))
            : null;
        const diff = tokenRefreshedDate
            ? (now.getTime() - tokenRefreshedDate.getTime()) / 1000
            : null;

        if (token && tokenRefreshedDate && diff && parseInt(diff) < 1200) {
            this.props.onSetToken(token);

            ApiService.getUser()
                .then((response) => {
                    this.props.onSetUser(response.data);
                    this.props.onConnectionCountSubscribe(
                        response.data.profile.connectionNumber
                    );
                    this.props.onUnreadThreadCountSubscribe(
                        parseInt(response.data.unreadThreadsCount)
                    );
                    ApiService.getNotificationsCount(this.props.user.id).then(
                        (r) => {
                            this.props.onNotificationCountSubscribe(
                                parseInt(r.data)
                            );
                        }
                    );

                    if (this._countInterval) {
                        clearInterval(this._countInterval);
                    }

                    this.inacitivityCounterStart();
                })
                .catch((e) => {
                    this.logoutUser();
                });
        } else {
            if (tokenRefreshedDate && diff && parseInt(diff) > 1200) {
                this.logoutUser();
            }
        }

        ['mousemove', 'touchstart'].forEach((ev) => {
            document.addEventListener(ev, this.inacitivityCounterReset);
        });

        ifvisible.on('focus', () => {
            if (tokenRefreshedDate && diff && parseInt(diff) > 1200) {
                this.logoutUser();
            }
        });
        // setTimeout(() => {
        //     if (document.cookie.includes('preferences:false')) {
        //         Object.keys(Cookie.get()).forEach((key) => {
        //             if (key !== 'CookieConsent') {
        //                 Cookie.remove(key, { path: '/', domain: '.aura.life' });
        //             }
        //         });
        //     }
        // }, 5000);

        // setTimeout(() => {
        //     if (window.HubSpotConversations) {
        //         window.HubSpotConversations.widget.load();
        //     }
        // }, 1000);

        //set initial pathname
        let location = this.props.history.location.pathname;
        if (
            !(
                location.includes('register') ||
                location.includes('password') ||
                location.includes('confirm') ||
                location.includes('logout') ||
                location.includes('invitations') ||
                location.includes('login')
            )
        ) {
            this.props.onSetCurrentRoute(location);
        }
    }

    logoutUser() {
        clearInterval(this._countInterval);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenRefreshedDate');
        localStorage.setItem('showInactivityModal', true);
        history.push('/login?redirect=true');
        window.location.reload();
    }

    inacitivityCounterStart = () => {
        this._countInterval = setInterval(() => {
            let currentTime = Date.now();
            if (currentTime - this._inactivityDate >= 1200000) {
                this.logoutUser();
            }
        }, 1000);
    };

    inacitivityCounterReset = () => {
        this._inactivityDate = Date.now();
        this._inactivityTime = 0;
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            ReactGA.initialize('UA-159178529-1');
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        if (prevProps.userId !== this.props.userId) {
            if (this.props.token && this.props.userId) {
                if (this._countInterval) {
                    clearInterval(this._countInterval);
                }

                this.inacitivityCounterStart();

                this.mercurySubscribe(this.props.userId);
            } else {
                clearInterval(this._countInterval);

                if (eventSource) {
                    eventSource.close();
                    eventSource = null;
                }
            }
        }
        if (prevProps.token !== this.props.token || this.props.token) {
            document.body.classList.add('user-logged-in');
        } else {
            document.body.classList.remove('user-logged-in');
        }
        let location = this.props.history.location.pathname;

        if (
            !(
                location.includes('register') ||
                location.includes('password') ||
                location.includes('confirm') ||
                location.includes('logout') ||
                location.includes('invitations') ||
                location.includes('login')
            )
        ) {
            this.props.onSetCurrentRoute(location);
        }
    }

    UNSAFE_componentWillUnmount() {
        this._isMounted = false;
    }

    mercurySubscribe(uid) {
        let url = '';

        if (process.env.REACT_APP_STAGE) {
            url = new URL(`${config.apiGateway.URL}/.well-known/mercure`);
        } else {
            url = new URL(`${config.apiGateway.URL}:9090/.well-known/mercure`);
        }

        url.searchParams.append('topic', `user/${uid}`);

        eventSource = new EventSourcePolyfill(`${url}`, {
            heartbeatTimeout: 3600000,
            headers: {
                // Authorization: `Bearer ${config.JWSToken}`
                Authorization: `Bearer ${this.props.jwtToken}`,
            },
        });

        eventSource.onmessage = (message) => {
            const parsedData = JSON.parse(message.data);
            switch (parsedData.type) {
                case 'notification_count':
                    this.props.onNotificationCountSubscribe(
                        parseInt(parsedData.count)
                    );
                    break;
                case 'number_unread_threads':
                    this.props.onUnreadThreadCountSubscribe(
                        parseInt(parsedData.count)
                    );
                    break;
                case 'new_message':
                    const msg = JSON.parse(parsedData.message);
                    const payload = {
                        message: msg,
                        thread: msg.thread,
                        thread_id: parsedData.thread_id,
                    };
                    this.props.onNewMessage(payload);
                    break;
                case 'tp_tooltip_show':
                    if (parsedData.tooltipShow) {
                        this.props.onConnectionsTooltipOpen();
                    }
                    break;
                case 'connection_count':
                    this.props.onConnectionCountSubscribe(parsedData.count);
                    break;
                default:
                    break;
            }
        };
    }

    componentDidCatch(error, info) {
        if (
            info.componentStack.includes('Suspense') &&
            info.componentStack.includes('Unknown')
        ) {
            window.location.reload();
        }
    }

    render() {
        return (
            <>
                {/* <iframe
                    src="https://chat.dante-ai.com/embed?kb_id=46e6ed75-43fe-49b8-b279-f83be962b77b&token=da71d769-9cf8-4589-8483-7f3d7c9b25df&modeltype=gpt-3.5-turbo&logo=ZmFsc2U=&mode=false&utm_source=aura&utm_campaign=google"
                    allow="clipboard-write; microphone"
                    width="100%"
                    height="950"
                    frameborder="0"
                    title="dante-ai"
                ></iframe> */}
                <>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {/* homepage */}
                            <Route
                                path={'/'}
                                exact
                                render={(props) => <Homepage {...props} />}
                            />
                            {/* profile pages */}
                            <ProtectedRoute
                                path={'/profile'}
                                render={() => <Profile />}
                            />
                            {/* my aura */}
                            <ProtectedRoute
                                path={['/my-aura/:slug']}
                                render={() => <MyAura />}
                            />
                            <Route
                                path={'/aura/:slug'}
                                render={() => <MyAura />}
                            />
                            {/* auth pages */}
                            <Route
                                path={'/login'}
                                render={(props) => <Login {...props} />}
                            />
                            <Route
                                path={'/register'}
                                exact
                                render={(props) => <Register {...props} />}
                            />
                            <Route
                                path={'/forgot-password'}
                                exact
                                render={(props) => (
                                    <ForgotPassword {...props} />
                                )}
                            />
                            <Route
                                path={['/confirm/:token']}
                                render={(props) => (
                                    <ConfirmAccount {...props} />
                                )}
                            />
                            <Route
                                path={'/reset-password/:token'}
                                render={(props) => <ResetPassword {...props} />}
                            />
                            <Route
                                path={'/logout'}
                                render={(props) => <Logout {...props} />}
                            />
                            <Route
                                path={'/invitations/:token'}
                                render={(props) => <Invitation {...props} />}
                            />
                            {/* life story */}
                            <Route
                                path={[
                                    '/life-story/:slug',
                                    '/life-story-preview/:slug',
                                ]}
                                render={(props) => <LifeStory {...props} />}
                            />
                            {/* info pages */}
                            <Route
                                path={[
                                    '/about',
                                    '/privacy-policy',
                                    '/aura-security',
                                    '/terms-of-use',
                                    '/crematorium-locations',
                                    '/terms-and-conditions',
                                    '/funeral-terms-conditions',
                                ]}
                                render={(props) => <InfoPages {...props} />}
                            />
                            <Route
                                path={'/help'}
                                render={(props) => <Help {...props} />}
                            />
                            <Route
                                exact
                                path={'/funerals'}
                                render={(props) => <Funerals {...props} />}
                            />
                            {/* features pages */}
                            <Route
                                path={['/features/:slug', '/features']}
                                render={(props) => <Features {...props} />}
                            />
                            <Route
                                path={'/partner-with-us'}
                                render={(props) => <Partnerships {...props} />}
                            />
                            <Route
                                path={'/meet-the-team'}
                                render={(props) => <MeetTheTeam {...props} />}
                            />
                            <Route
                                path={'/testimonials'}
                                render={(props) => <Testimonials {...props} />}
                            />
                            <Route
                                path={'/in-the-press'}
                                render={(props) => <InThePress {...props} />}
                            />
                            {/* knowledge - guides/articles */}
                            <Route
                                path={[
                                    '/knowledge/guides',
                                    '/knowledge/articles',
                                    '/knowledge',
                                ]}
                                exact
                                render={(props) => <Knowledge {...props} />}
                            />
                            <Route
                                path={['/guides/:slug', '/articles/:slug']}
                                exact
                                render={(props) => <BlogArticle {...props} />}
                            />
                            <Route
                                path={[
                                    '/articles/author/:slug',
                                    '/guides/author/:slug',
                                ]}
                                exact
                                render={(props) => <Author {...props} />}
                            />
                            {/* funeral plans */}
                            <Route
                                exact
                                path={[
                                    '/funeral-plans',
                                    '/funeral-plans/wonderfully-imagined',
                                    '/funeral-plans/perfectly-simple',
                                ]}
                                render={(props) => <FuneralPlans {...props} />}
                            />
                            <Route
                                exact
                                path="/funerals/funeral-plan"
                                render={(props) => (
                                    <FuneralPlansSecond {...props} />
                                )}
                            />
                            {/* funeral payment */}
                            <Route
                                exact
                                path={'/funeral-payment/:slug'}
                                render={(props) => (
                                    <FuneralPayment {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={'/payment/:slug'}
                                render={(props) => (
                                    <PaymentSelection {...props} />
                                )}
                            />
                            <Route
                                exact
                                path={['/payment-success', '/payment-problem']}
                                render={(props) => <ThankYou {...props} />}
                            />
                            <Route
                                exact
                                path={[
                                    '/installment-success',
                                    '/installment-problem',
                                ]}
                                render={(props) => (
                                    <InstallmentThankYou {...props} />
                                )}
                            />
                            {/* search */}
                            <Route
                                path={'/search/:searchParam'}
                                exact
                                render={(props) => <Search {...props} />}
                            />
                            {/* order history */}
                            <Route
                                path={'/purchase-history'}
                                exact
                                render={(props) => (
                                    <OrderHistoryList {...props} />
                                )}
                            />
                            <Route
                                path={'/purchase/:id'}
                                exact
                                render={(props) => (
                                    <OrderHistorySingle {...props} />
                                )}
                            />
                            {this.props.user ? (
                                <Route
                                    path={'/aura-circle-member'}
                                    exact
                                    render={(props) => (
                                        <LoggedAuraCircle {...props} />
                                    )}
                                />
                            ) : (
                                <Route
                                    path={'/aura-circle'}
                                    exact
                                    render={(props) => (
                                        <AuraCircle {...props} />
                                    )}
                                />
                            )}
                            <Route
                                path={'/aura-angels'}
                                exact
                                render={(props) => <AuraAngels {...props} />}
                            />
                            <Route
                                path={'/aura-landing'}
                                exact
                                render={(props) => <AuraLanding {...props} />}
                            />
                            <Route
                                path={'/funeral-at-need'}
                                render={(props) => <Funeral {...props} />}
                            />
                            <Route
                                path={'/cremations/direct-cremation'}
                                render={(props) => <Funeral {...props} />}
                            />
                            <Route
                                path={'/pages/:custom'}
                                render={(props) => <CustomPage {...props} />}
                            />
                            <Route
                                path={'/locations'}
                                exact
                                render={(props) => <AllLocations {...props} />}
                            />
                            <Route
                                path={'/locations/*'}
                                render={(props) => <LocationsPage {...props} />}
                            />

                            <Route
                                path={'/practical-information'}
                                exact
                                render={(props) => <Redirect {...props} />}
                            />
                            <Route
                                path={'/aml-check'}
                                exact
                                render={(props) => <AmlCheck />}
                            />
                            {/* 403 */}
                            <Route
                                path={'/403'}
                                exact
                                render={(props) => <NotAccessible {...props} />}
                            />
                            {/* 404 must be last */}
                            <Route
                                path={'*'}
                                render={(props) => <NotFound {...props} />}
                            />
                        </Switch>
                    </Suspense>
                </>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.usersReducer.token,
    userId: state.usersReducer.userId,
    userSlug: state.usersReducer.userSlug,
    user: state.usersReducer.user,
    jwtToken: state.usersReducer.user
        ? state.usersReducer.user.jwtMercuryToken
        : null,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUser: (user) => dispatch(actionTypes.onSetUser(user)),
        onSetToken: (token) => dispatch(actionTypes.onSetToken(token)),
        onNotificationCountSubscribe: (notifCount) =>
            dispatch(
                notificationActionTypes.onNotificationCountSubscribe(notifCount)
            ),
        onUnreadThreadCountSubscribe: (threadCount) =>
            dispatch(
                messagesActionTypes.onUnreadThreadCountSubscribe(threadCount)
            ),
        onNewMessage: (payload) =>
            dispatch(messagesActionTypes.onNewMessage(payload)),
        onConnectionsTooltipOpen: () =>
            dispatch(actionTypes.onConnectionsTooltipOpen()),
        onConnectionCountSubscribe: (connCount) =>
            dispatch(
                connectionActionTypes.onConnectionCountSubscribe(connCount)
            ),
        onSetCurrentRoute: (route) =>
            dispatch(routerActionTypes.onSetCurrentRoute(route)),
    };
};

const appWithRouter = withRouter(App);

export default connect(mapStateToProps, mapDispatchToProps)(appWithRouter);

AOS.init();
