import React, { Component } from 'react';

import ApiService from '../../../service/api.service';
import ReactHtmlParser from 'react-html-parser';
import Popup from 'reactjs-popup';
export default class PlanIncludes extends Component {
    state = {
        included: [],
        exclusive: [],
        optional: [],
        notIncluded: [],
        popupOpen: false,
        isLoading: true,
        infoTitle: '',
        infoContent: '',
        infoPhoto: '',
    };

    componentDidMount() {
        const includedParams = new URLSearchParams();
        const exclusiveParams = new URLSearchParams();
        const optionalParams = new URLSearchParams();
        const notIncludedParams = new URLSearchParams();
        includedParams.append(
            'plans.slug',
           window.location.pathname === '/funeral-plans/wonderfully-imagined' ? 'imagined' : window.location.pathname === '/cremations/direct-cremation' ? 'funeral-at-need' :  'simple',
            this.props.customPage
                ? this.props.customPage
                : window.location.href.split('/')[4]
                ? window.location.href.split('/')[4].split('-')[1]
                : 'funeral-at-need'
        );
        includedParams.append('category', 1);
        includedParams.append('_page', 1);
        exclusiveParams.append(
            'plans.slug',
           window.location.pathname === '/funeral-plans/wonderfully-imagined' ? 'imagined' : window.location.pathname === '/cremations/direct-cremation' ? 'funeral-at-need' :  'simple',
            this.props.customPage
                ? this.props.customPage
                : window.location.href.split('/')[4]
                ? window.location.href.split('/')[4].split('-')[1]
                : 'funeral-at-need'
        );
        exclusiveParams.append('category', 3);
        exclusiveParams.append('_page', 1);
        optionalParams.append(
            'plans.slug',
            window.location.pathname === '/funeral-plans/wonderfully-imagined'
                ? 'imagined'
                : window.location.pathname === '/cremations/direct-cremation'
                ? 'funeral-at-need'
                : 'simple',

            this.props.customPage
                ? this.props.customPage
                : window.location.href.split('/')[4]
                ? window.location.href.split('/')[4].split('-')[1]
                : 'funeral-at-need'
        );
        optionalParams.append('category', 4);
        optionalParams.append('_page', 1);
        notIncludedParams.append(
            'plans.slug',
            window.location.pathname === '/funeral-plans/wonderfully-imagined'
                ? 'imagined'
                : window.location.pathname === '/cremations/direct-cremation'
                ? 'funeral-at-need'
                : 'simple',

            this.props.customPage
                ? this.props.customPage
                : window.location.href.split('/')[4]
                ? window.location.href.split('/')[4].split('-')[1]
                : 'funeral-at-need'
        );
        notIncludedParams.append('category', 2);
        notIncludedParams.append('_page', 1);
        ApiService.funeralPlanIncludedItems(exclusiveParams).then((r) => {
            this.setState({
                exclusive: r.data,
            });
        });
        ApiService.funeralPlanIncludedItems(includedParams).then((r) => {
            this.setState({
                included: r.data,
            });
        });
        ApiService.funeralPlanIncludedItems(optionalParams).then((r) => {
            this.setState({
                optional: r.data,
            });
        });
        ApiService.funeralPlanIncludedItems(notIncludedParams).then((r) => {
            this.setState({
                notIncluded: r.data,
            });
        });
        let slug = 'crematorium-locations';
        ApiService.getInfoPages(slug).then((r) => {
            this.setState({
                infoTitle: r.data.title,
                infoContent: r.data.content,
                infoPhoto: r.data.photo,
                isLoading: false,
            });
        });
    }

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    render() {
        let popupContent = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle}
                </h1>
                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent)}
                </div>
                <div>
                    <img src={this.state.infoPhoto} alt="" />
                </div>
            </>
        );

        let included;
        let exclusive;
        let optional;
        let notIncluded;

        if (this.state.included.length) {
            included = this.state.included.map((item, index) => {
                return (
                    <li key={`included-${index}`}>
                        <p>{ReactHtmlParser(item.title)}</p>
                        <span>
                            <i className="icon-checkmark"></i>
                        </span>
                    </li>
                );
            });
        }
        if (this.state.exclusive.length) {
            exclusive = this.state.exclusive.map((item, index) => {
                return (
                    <li key={`exclusive-${index}`}>
                        <p>{ReactHtmlParser(item.title)}</p>
                        <span>
                            <i className="icon-checkmark"></i>
                        </span>
                    </li>
                );
            });
        }
        if (this.state.optional.length) {
            optional = this.state.optional.map((item, index) => {
                return (
                    <li key={`optional-${index}`}>
                        <p>{ReactHtmlParser(item.title)}</p>
                        <span>
                            {item.optionalText ? (
                                <span>
                                    {ReactHtmlParser(item.optionalText)}
                                </span>
                            ) : (
                                <span className="optional-span">
                                    Optional (£)
                                </span>
                            )}{' '}
                        </span>
                    </li>
                );
            });
        }
        if (this.state.notIncluded.length) {
            notIncluded = this.state.notIncluded.map((item, index) => {
                return (
                    <li key={`notIncluded-${index}`}>
                        <p>{ReactHtmlParser(item.title)}</p>
                        <span>
                            <i className="icon-times-thin"></i>
                        </span>
                    </li>
                );
            });
        }

        let showTitle = true;

        let numberOfItems =
            this.state.included.length +
            this.state.exclusive.length +
            this.state.optional.length +
            this.state.notIncluded.length;

        if (numberOfItems > 0) {
            showTitle = true;
        } else {
            showTitle = false;
        }

        return (
            <div className="c-plans__plan-table-bg">
                <div className="c-plans__plan-table text-center margin-auto">
                    {showTitle ? (
                        <>
                            <h2>
                                {ReactHtmlParser(
                                    this.props.title ? this.props.title : ''
                                )}
                            </h2>
                            <p>
                                {ReactHtmlParser(
                                    this.props.body ? this.props.body : ''
                                )}
                            </p>
                        </>
                    ) : null}
                    {this.state.included.length ? (
                        <div>
                            <ul className="c-plans__plan-table--content">
                                <h4>What's included:</h4>
                                {included}
                            </ul>
                        </div>
                    ) : null}
                    {this.state.exclusive.length ? (
                        <div>
                            <ul className="c-plans__plan-table--content">
                                <h4>
                                    <span>Exclusive</span> to this plan:
                                </h4>
                                {exclusive}
                            </ul>
                        </div>
                    ) : null}
                    {this.state.optional.length ? (
                        <div>
                            <ul className="c-plans__plan-table--content">
                                <h4>
                                    {this.props.funeralAtNeed
                                        ? 'Extras you may wish to add:'
                                        : 'Choose your guests:'}
                                </h4>
                                {optional}
                            </ul>
                        </div>
                    ) : null}

                    {window.location.href.includes('imagined') ? (
                        <p className="c-funeral__option--terms crematoria-maps">
                            {' '}
                            <span
                                onClick={this.handlePopupOpen}
                                className="link-underline"
                            >
                                Click here
                            </span>{' '}
                            to see our list of crematoria locations available
                            with our Wonderfully Imagined plan
                        </p>
                    ) : null}

                    <Popup
                        lockScroll
                        open={this.state.popupOpen}
                        onClose={this.handlePopupClose}
                        className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--map c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                    >
                        {(close) => (
                            <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                <button
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                    onClick={close}
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                                {popupContent}
                            </div>
                        )}
                    </Popup>

                    {this.state.notIncluded.length ? (
                        <div className="c-plans__plan-table--content">
                            <ul className="c-plans__plan-table c-plans__plan-table--content">
                                <h4>What's not included:</h4>
                                {notIncluded}
                            </ul>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
