import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class Westerleigh extends Component {
    render() {
        return (
            <div className="c-westerleight">
                {this.props.title && <h2>{this.props.title} </h2>}
                {this.props.subheader && (
                    <h3>{ReactHtmlParser(this.props.subheader)} </h3>
                )}
                <div className="container">
                    <div className="o-flex two-col">
                        <div>
                            {this.props.content.title && (
                                <h4> {this.props.content.title} </h4>
                            )}
                            {this.props.content.description && (
                                <p>
                                    {ReactHtmlParser(
                                        this.props.content.description
                                    )}
                                </p>
                            )}
                        </div>
                        {this.props.content.map && (
                            <div>
                                <iframe
                                    src={this.props.content.map}
                                    title="westerleight"
                                ></iframe>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Westerleigh;
