import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../../components/UI/Footer/Footer';
import Header from '../../../components/Header/Header';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
import Loader from '../../../components/UI/Loader/Loader';
import ApiService from '../../../service/api.service';
import ReactHtmlParser from 'react-html-parser';
import articleDefault from '../../../assets/images/article-default.webp';
import { Link } from 'react-router-dom';
class AuraCircle extends Component {
    state = {
        content: null,
        banner: [],
    };

    componentDidMount() {
        let page = 'auraCircleLogged';
        ApiService.getBannersOtherPages(page).then((r) => {
            this.setState({
                banner: r.data,
            });
        });

        setTimeout(() => {
            ApiService.auraCircleHeader().then((r) => {
                this.setState(
                    {
                        content: r.data[0],
                    },
                    () => {
                        window.scrollTo(0, 0);
                    }
                );
            });
        }, 300);
    }

    updateBannerVisibilty = () => {
        this.setState({ banner: [] });
    };

    render() {
        let content = <Loader />;
        const bannerData = this.state.banner?.find(
            (item) => item.auraCircleLogged === true
        );

        let publishedContent = null;

        if (this.state.content) {
            publishedContent = this.state.content.publishedBlocks.length
                ? this.state.content.publishedBlocks.map((item, index) => {
                      let ctaLink = item.ctaLink.replace(
                          'user',
                          this.props.slug
                      );

                      //Target button that will lead to life story
                      const userLifeStoryLinkBtn =
                          item.ctaLink.includes('life-story');

                      return (
                          <div className="c-circle__desc c-homepage__description">
                              <div className={`o-flex two-col`}>
                                  <div
                                      className="two-col--img-lg"
                                      style={{
                                          backgroundImage: `url(${item.image})`,
                                      }}
                                  ></div>
                                  <div>
                                      <h3>{ReactHtmlParser(item.title)}</h3>
                                      <div className="c-homepage__description--body">
                                          {ReactHtmlParser(item.body)}
                                      </div>
                                      {/* Added condition that all other links are from CMS, just the one for Life story is from code below */}
                                      {item.ctaTitle &&
                                      !userLifeStoryLinkBtn ? (
                                          <a
                                              className="o-d-inline-block btn btn--full btn--size-57 text-center btn--padding-left-right mt-30"
                                              href={ctaLink}
                                          >
                                              {item.ctaTitle}
                                          </a>
                                      ) : null}
                                      {/* This link below is added so users can go to their life story directly */}
                                      {userLifeStoryLinkBtn ? (
                                          <Link
                                              to={`/my-aura/${this.props.slug}/life-story`}
                                              className="o-d-inline-block btn btn--full btn--size-57 text-center btn--padding-left-right mt-30"
                                          >
                                              {item.ctaTitle}
                                          </Link>
                                      ) : null}
                                  </div>
                              </div>
                          </div>
                      );
                  })
                : null;

            content = (
                <>
                    <div
                        ref={this.myRef}
                        className="c-circle-banner c-circle-banner__single position-relative overflow-both-hidden"
                    >
                        <img
                            className="c-circle-banner__image"
                            src={
                                this.state.content.image
                                    ? this.state.content.image
                                    : articleDefault
                            }
                            alt="imageBanner"
                        />
                        <div className="c-circle-banner__container">
                            <h1>
                                Hello {this.props.currentUser.name}, welcome to
                                the Aura Circle
                            </h1>
                            <div className="c-circle-banner__container--body">
                                {ReactHtmlParser(this.state.content.intro)}
                            </div>
                            <div className="c-circle-banner__content mt-119 position-relative">
                                {publishedContent}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <PageMeta
                    title="Aura Circle | Aura"
                    description="Aura Circle | Aura"
                    canonical={window.location.href}
                />
                <div className="c-circle">
                    <Header
                        bannerContent={
                            this.state.banner?.length ? bannerData : null
                        }
                        bannerVisible={
                            this.state.banner?.length > 0 &&
                            bannerData?.auraCircleLogged === true
                        }
                        hideBanner={this.updateBannerVisibilty}
                    />
                    {content}
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.usersReducer.user,
    slug: state.usersReducer.userSlug,
});

const auraCircleWithRouter = withRouter(AuraCircle);

export default connect(mapStateToProps)(auraCircleWithRouter);
