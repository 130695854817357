import React, { Component } from 'react';
import NewNavigation from './NewNavigation/NewNavigation';
import PreHeader from './PreHeader/PreHeader';

export default class Header extends Component {
    componentDidMount() {
        var doc = document.documentElement;
        var w = window;

        var prevScroll = w.scrollY || doc.scrollTop;
        var curScroll;
        var direction = 0;
        var prevDirection = 0;

        var header = document.getElementById('hideable-header');
        const submenu = document.querySelector('.c-submenu');

        var checkScroll = function () {
            curScroll = w.scrollY || doc.scrollTop;
            if (curScroll > prevScroll) {
                direction = 2;
            } else if (curScroll < prevScroll) {
                direction = 1;
            }

            if (direction !== prevDirection) {
                toggleHeader(direction, curScroll);
            }

            prevScroll = curScroll;
        };

        var toggleHeader = function (direction, curScroll) {
            if (direction === 2 && curScroll > 165) {
                header.classList.add('c-header__hidden');
                document.body.classList.add('c-header__hidden');
                if (submenu) {
                    submenu.classList.add('c-submenu-offset');
                }
                prevDirection = direction;
            } else if (direction === 1) {
                header.classList.remove('c-header__hidden');
                document.body.classList.remove('c-header__hidden');

                if (submenu) {
                    submenu.classList.remove('c-submenu-offset');
                }

                prevDirection = direction;
            }

            if (
                window.location.href.includes('funeral-at-need') ||
                window.location.href.includes('aura-circle') ||
                window.location.href.includes('wonderfully-imagined') ||
                window.location.href.includes('/pages') ||
                window.location.href.includes('perfectly-simple')
            ) {
                if (curScroll > 5) {
                    header.classList.add('c-header__no-opacity-bg');
                }

                if (prevScroll < 25) {
                    header.classList.remove('c-header__no-opacity-bg');
                }
            }

            if (
                window.location.href.includes('aura-angels') ||
                window.location.href.includes('about') ||
                window.location.pathname === '/' ||
                window.location.pathname === '/funeral-plans' ||
                window.location.pathname === '/funerals' ||
                window.location.href.includes('/pages')
            ) {
                if (prevScroll > 165) {
                    header.classList.add('c-header__no-opacity-bg');
                }

                if (prevScroll < 165) {
                    header.classList.remove('c-header__no-opacity-bg');
                }
            }

            if (w.pageYOffset > 450) {
                header.classList.add('c-header__hidden--offset');
            } else {
                header.classList.remove('c-header__hidden--offset');
            }
        };
        window.addEventListener('scroll', toggleHeader);
        window.addEventListener('scroll', checkScroll);

        if (window.location.href.includes('funeral-at-need')) {
            header.classList.remove('c-header__no-opacity-bg');
        }

        if (window.location.href.includes('aura-circle')) {
            header.classList.remove('c-header__no-opacity-bg');
        }

        if (window.location.href.includes('funeral-plans')) {
            header.classList.remove('c-header__no-opacity-bg');
        }

        if (window.location.href.includes('funerals')) {
            header.classList.remove('c-header__no-opacity-bg');
        }
    }

    render() {
        const auraCircle = window.location.pathname === '/aura-circle';
        const funeralAtNeed = window.location.pathname === '/funeral-at-need';
        const auraAngel = window.location.pathname === '/aura-angels';
        const aboutPage = window.location.pathname === '/about';
        const funeralPlans = window.location.pathname === '/funerals';
        const perfectlySimple =
            window.location.pathname === '/funeral-plans/perfectly-simple';
        const wonderfullyImagined =
            window.location.pathname === '/funeral-plans/wonderfully-imagined';
        const homepage = window.location.pathname === '/';
        // const token = localStorage.getItem('token');

        return (
            <div
                className={`c-header ${
                    auraAngel ||
                    funeralPlans ||
                    homepage ||
                    perfectlySimple ||
                    funeralAtNeed ||
                    window.location.href.includes('/funeral-at-need/?title') ||
                    window.location.href.includes('/pages') ||
                    wonderfullyImagined ||
                    auraCircle
                        ? 'c-header--semi-transparent-bg'
                        : ''
                } 
                ${aboutPage ? 'c-header--transparent-about-page' : ''}
                `}
                id="hideable-header"
            >
                <PreHeader
                    bannerContent={
                        this.props.bannerContent
                            ? this.props.type === 'info'
                                ? this.props.bannerContent[0].body
                                : this.props.bannerContent.body
                            : null
                    }
                    bannerImage={
                        this.props.bannerContent
                            ? this.props.type === 'info'
                                ? this.props.bannerContent[0].image
                                : this.props.bannerContent.image
                            : null
                    }
                    bannerVisible={this.props.bannerVisible}
                    hideBanner={this.props.hideBanner}
                />
                <NewNavigation
                    bannerContent={
                        this.props.bannerContent
                            ? this.props.type === 'info'
                                ? this.props.bannerContent[0].body
                                : this.props.bannerContent.body
                            : null
                    }
                    bannerImage={
                        this.props.bannerContent
                            ? this.props.type === 'info'
                                ? this.props.bannerContent[0].image
                                : this.props.bannerContent.image
                            : null
                    }
                    bannerVisible={this.props.bannerVisible}
                    hideBanner={this.props.hideBanner}
                />
            </div>
        );
    }
}
