import React from 'react';
import auraLogo from '../../assets/images/Aura-big-logo.svg';

function AmlCheck() {
    return (
        <div className="c-aml-check">
            <div className="c-aml-check__wrapper">
                <img src={auraLogo} alt="logo" />
                <h1>
                    We're sorry, we were unable to electronically verify your
                    identity.
                </h1>
                <div>
                    <p>
                        We'll need to conduct the final checks over the phone
                        before we can finalise your funeral plan.
                    </p>
                    <p>
                        Please call our team free on the number below, Monday to
                        Friday, 9am to 5:30pm.
                    </p>
                    <a href="tel:08008085723">
                        <i className="icon-phone" />
                        0800 808 5723
                    </a>
                </div>
            </div>
        </div>
    );
}

export default AmlCheck;
