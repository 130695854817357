import React, { Component } from 'react';
import VideoEmbed from '../../../service/video_embed.service';
import ReactHtmlParser from 'react-html-parser';
import Popup from 'reactjs-popup';
import 'react-html5video/dist/styles.css';
import ReactPixel from 'react-facebook-pixel';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import RequiredInput from '../../UI/Input/RequiredInput/RequiredInput';
import RegularInput from '../../UI/Input/RegularInput/RegularInput';
import Button from '../../UI/Button/Button';
import thankYou from '../../../assets/images/register-thanks.png';
import logoPlans from '../../../assets/images/logo-without-slogan.svg';
import logoAtNeedPopup from '../../../assets/images/logo-revert/requestMoreInfoSvg.webp';
import phoneIcon from '../../../assets/images/aura2022/icons/call-us-icon.png';
import loader from '../../../assets/images/loader.svg';
import ApiService from '../../../service/api.service';
export class MultipleVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeVideo: '',
            activeImage: '',
            changeProportion: true,
            popupOpen: false,
            modalIsOpen: false,
            buttonLoading: false,
            formSubmited: false,
            printAndDigitalBrochure: false,
            digitalBrochureOnly: false,
            popupBtnClass: '',
        };
    }

    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));
        ReactPixel.init('218942439488342');
    }

    resize = () => {
        if (window.innerWidth > 720) {
            if (
                this.state.activeVideo !== this.props.horizontalVideo ||
                this.state.activeImage !== this.props.horizontalImage
            ) {
                this.setState({
                    activeVideo: this.props.horizontalVideo,
                    activeImage: this.props.horizontalImage,
                });
            }
        } else {
            if (
                this.state.activeVideo !== this.props.verticalVideo ||
                this.state.activeImage !== this.props.verticalImage
            ) {
                this.setState({
                    activeVideo: this.props.verticalVideo,
                    activeImage: this.props.verticalImage,
                });
            }
        }
    };

    handleTogglePopup = () => {
        this.setState({
            popupOpen: !this.state.popupOpen,
        });
    };

    handlePopupBtnClass = () => {
        this.setState({
            popupBtnClass: this.props.ctaPopup,
        });
    };

    handleRequestbrochure = (e) => {
        e.preventDefault();
        if (
            window.location.href.includes('funeral-plans') ||
            window.location.href.includes('pages') ||
            window.location.href.includes('funeral-at-need')
        ) {
            this.setState({
                modalIsOpen: true,
            });
        } else {
            this.handleBasketButtonClick(e);
        }
    };

    handleFormSubmit = (values) => {
        this.setState(
            {
                buttonLoading: true,
            },
            () => {
                let urlParts = window.location.href.split('/');
                let data = {
                    email: values.email,
                    phone: values.phone,
                    name: values.name,
                    page: urlParts.at(-1),
                };

                ApiService.requestBrochure(data)
                    .then((r) => {
                        this.setState({
                            buttonLoading: false,
                            formSubmited: true,
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            buttonLoading: false,
                        });
                    });
            }
        );
    };

    // not needed atm, since request a brochure button was implemented
    handleBasketButtonClick = (e) => {
        e.preventDefault();
        if (window.location.href.includes('funeral-plans/')) {
            ReactPixel.track('Add to basket');
        }
        setTimeout(() => {
            window.location.href = this.props.ctaLink;
        }, 300);
    };

    render() {
        if (this.props.type !== 'funeral-at-need') {
            const script = document.createElement('script');
            script.src = '//js.hsforms.net/forms/embed/v2.js';
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                // @TS-ignore
                if (window.hbspt) {
                    // @TS-ignore
                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: '20309021',
                        formId: 'f8333296-39ff-47e6-b975-4ef812a12e1a',
                        target: '#hubspotForm',
                    });
                }
            });
        }

        return (
            <>
                <div
                    className={`c-multiple-video-header ${
                        this.props.hasBanner ? 'hasBanner' : ''
                    } ${
                        !this.state.activeVideo?.length &&
                        this.props.ratingImages?.length > 0
                            ? 'c-multiple-video-header--rating-no-video'
                            : ''
                    }
                    ${
                        this.state.activeVideo?.length &&
                        !this.props.ratingImages?.length > 0
                            ? 'c-multiple-video-header--video-no-rating'
                            : ''
                    }
                     ${
                         this.props.ratingImages?.length > 0 &&
                         this.state.activeVideo?.length
                             ? 'c-multiple-video-header--video-rating'
                             : ''
                     }
                    ${
                        !this.state.activeVideo?.length &&
                        !this.props.ratingImages?.length > 0
                            ? 'c-multiple-video-header--no-video-no-rating'
                            : ''
                    }
                    `}
                >
                    {this.state.activeVideo?.length ? (
                        <div
                            className={`c-multiple-video-header__video-slide-media`}
                        >
                            <video
                                className="c-multiple-video-header__video"
                                id="video-autoplay"
                                loop
                                muted
                                autoPlay
                                playsinline="true"
                                poster={this.state.activeImage}
                                src={this.state.activeVideo}
                            ></video>
                        </div>
                    ) : (
                        <div className="c-multiple-video-header__image">
                            <img src={this.state.activeImage} alt="header" />
                        </div>
                    )}
                    <div className="container">
                        <div
                            className={`c-multiple-video-header__heading
                        ${
                            this.props.type === 'angels' &&
                            this.state.activeVideo
                                ? 'c-multiple-video-header__heading--aura-angels-video'
                                : ''
                        } ${
                                this.props.type === 'angels' &&
                                !this.state.activeVideo
                                    ? 'c-multiple-video-header__heading--aura-angels-img'
                                    : ''
                            }
                        ${
                            this.props.type === 'homepage'
                                ? 'c-multiple-video-header__heading--homepage-header'
                                : ''
                        } ${
                                this.props.type === 'homepage' &&
                                this.state.activeVideo
                                    ? 'c-multiple-video-header__heading--homepage-video'
                                    : ''
                            } ${
                                this.props.type === 'homepage' &&
                                !this.state.activeVideo
                                    ? 'c-multiple-video-header__heading--homepage-img'
                                    : ''
                            }

                            ${
                                this.props.type === 'funeral-plans'
                                    ? 'c-multiple-video-header__heading--funeral-plans-header'
                                    : ''
                            }

                            ${
                                this.props.type === 'funeral-plans' &&
                                this.state.activeVideo
                                    ? 'c-multiple-video-header__heading--funeral-plans-video'
                                    : ''
                            } ${
                                this.props.type === 'funeral-plans' &&
                                !this.state.activeVideo
                                    ? 'c-multiple-video-header__heading--funeral-plans-img'
                                    : ''
                            }
                        `}
                        >
                            {this.props.title ? (
                                <div className="c-multiple-video-header__title">
                                    <h1>{ReactHtmlParser(this.props.title)}</h1>
                                </div>
                            ) : null}
                            {this.props.price ? (
                                <div className="c-multiple-video-header__title">
                                    <h1>
                                        {' '}
                                        {ReactHtmlParser(this.props.price)}
                                    </h1>
                                </div>
                            ) : null}
                            {this.props.priceInfo ? (
                                <div className="c-multiple-video-header__price-info">
                                    {ReactHtmlParser(this.props.priceInfo)}
                                </div>
                            ) : null}
                            {this.props.description ? (
                                <div className="c-multiple-video-header__description">
                                    {ReactHtmlParser(this.props.description)}
                                </div>
                            ) : null}
                            <div
                                className={`${
                                    window.location.href.includes(
                                        'funeral-plans'
                                    ) ||
                                    window.location.href.includes('funerals') ||
                                    this.props.type === 'custom-page' ||
                                    this.props.type === 'funeral-at-need'
                                        ? 'c-multiple-video-header__plans-brochure-btns'
                                        : ''
                                } 
                                        ${
                                            window.location.href.includes(
                                                '/perfectly-simple'
                                            ) ||
                                            window.location.href.includes(
                                                '/wonderfully-imagined'
                                            ) ||
                                            this.props.type === 'custom-page'
                                                ? 'c-multiple-video-header__plans-brochure-btns--left'
                                                : ''
                                        }`}
                            >
                                {(window.location.href.includes(
                                    'funeral-plans'
                                ) &&
                                    !this.props.requestLink) ||
                                (this.props.type === 'funeral-at-need' &&
                                    !this.props.requestLink) ||
                                (this.props.type === 'funeral-plans' &&
                                    !this.props.requestLink) ||
                                (this.props.type === 'custom-page' &&
                                    !this.props.requestLink) ? (
                                    <button
                                        onClick={(e) =>
                                            this.handleRequestbrochure(e)
                                        }
                                        // href={this.props.ctaLink}
                                        id="request-more-info"
                                        className={`c-btn o-d-inline-block c-multiple-video-header__request-brochure-btn btn btn--full ${
                                            this.props.type ===
                                                'funeral-at-need' ||
                                            window.location.pathname ===
                                                '/funerals'
                                                ? 'btn--size-width-293'
                                                : ''
                                        } btn--size-57   text-center `}
                                    >
                                        {this.props.requestTitle}
                                    </button>
                                ) : (
                                    <div>
                                        {this.props.requestLink && (
                                            <a
                                                href={
                                                    !this.props.requestLink
                                                        ? 'tel:+448008085723'
                                                        : this.props
                                                              .requestLink &&
                                                          !this.props.requestLink.includes(
                                                              'tel'
                                                          )
                                                        ? this.props.requestLink
                                                        : this.props.requestLink.includes(
                                                              'tel'
                                                          )
                                                        ? `tel:${this.props.requestLink.replace(
                                                              'http://tel:',
                                                              ''
                                                          )}`
                                                        : ''
                                                }
                                                className="c-btn o-d-inline-block btn--size-57 text-center c-multiple-video-header__request-brochure-btn btn btn--full btn--size-width-293"
                                            >
                                                {this.props.requestTitle}
                                            </a>
                                        )}
                                    </div>
                                )}
                                {this.props.ctaTitle &&
                                // this.props.ctaLink &&
                                !this.props.ctaPopup &&
                                //!uncomment this line below if you want to remove extra button from PS and WI plans
                                // this.props.type !== 'plans' &&
                                this.props.type !== 'homepage' &&
                                this.props.type !== 'circle' ? (
                                    <a
                                        href={
                                            !this.props.ctaLink
                                                ? 'tel:+448008085723'
                                                : this.props.ctaLink &&
                                                  !this.props.ctaLink.includes(
                                                      'tel'
                                                  )
                                                ? this.props.ctaLink
                                                : this.props.ctaLink.includes(
                                                      'tel'
                                                  )
                                                ? `tel:${this.props.ctaLink.replace(
                                                      'http://tel:',
                                                      ''
                                                  )}`
                                                : ''
                                        }
                                        className={`c-btn o-d-inline-block c-multiple-video-header__view-plans-btn   btn btn--full btn--size-57   text-center `}
                                    >
                                        {this.props.ctaTitle}
                                    </a>
                                ) : this.props.ctaPopup &&
                                  this.props.ctaLink ? (
                                    <button
                                        onClick={() =>
                                            this.handlePopupBtnClass()
                                        }
                                        className={`${
                                            this.props.ctaPopup
                                                ? this.props.ctaPopup
                                                : ''
                                        } c-btn o-d-inline-block c-multiple-video-header__view-plans-btn btn btn--full btn--size-57 text-center`}
                                    >
                                        {this.props.ctaTitle}
                                    </button>
                                ) : null}
                            </div>
                            {/* {this.props.ctaTitle &&
                            this.props.ctaLink &&
                            this.props.type !== 'plans' &&
                            this.props.type !== 'homepage' &&
                            this.props.type !== 'circle' ? (
                                <a
                                    href={this.props.ctaLink}
                                    className={`c-btn o-d-inline-block c-multiple-video-header__view-plans-btn   btn btn--full btn--size-57 btn--size-width-293  text-center ${
                                        window.location.pathname ===
                                        '/funeral-plans'
                                            ? 'mt-35'
                                            : ''
                                    }`}
                                >
                                    {this.props.ctaTitle}
                                </a>
                            ) : null} */}
                            {/* {window.location.href.includes('funeral-plans') ||
                            this.props.type === 'funeral-at-need' ? (
                                <button
                                    onClick={(e) =>
                                        this.handleRequestbrochure(e)
                                    }
                                    href={this.props.ctaLink}
                                    id="request-more-info"
                                    className={`c-btn o-d-inline-block c-multiple-video-header__request-brochure-btn btn btn--full btn--size-57   text-center `}
                                >
                                    Request a free brochure
                                </button>
                            ) : null} */}
                            {this.props.ctaTitle &&
                            this.props.ctaLink &&
                            this.props.type === 'circle' &&
                            !this.props.openPopup ? (
                                <button
                                    onClick={this.props.ctaLink}
                                    className="c-btn o-d-inline-block   btn btn--full btn--size-57 btn--size-width-293  text-center"
                                >
                                    {this.props.ctaTitle}
                                </button>
                            ) : null}
                            {this.props.ctaTitle &&
                            this.props.ctaLink &&
                            this.props.type === 'circle' &&
                            this.props.openPopup ? (
                                <a
                                    href={this.props.openPopup}
                                    className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-293 text-center btn--padding-left-right"
                                >
                                    {this.props.ctaTitle}
                                </a>
                            ) : null}
                            {this.props.type === 'homepage' && (
                                <div className="c-multiple-video-header__hp-cta">
                                    <a
                                        href={this.props.ctaLink}
                                        className="c-btn o-d-inline-block   btn btn--full btn--size-57 btn--size-width-293  text-center"
                                    >
                                        {this.props.ctaTitle}
                                    </a>
                                    <a
                                        href={this.props.ctaLink2}
                                        className="c-btn o-d-inline-block   btn btn--full btn--size-57 btn--size-width-293  text-center"
                                    >
                                        {this.props.ctaTitle2}
                                    </a>
                                    <a
                                        href={this.props.ctaLink3}
                                        className="c-btn o-d-inline-block   btn btn--full btn--size-57 btn--size-width-293  text-center"
                                    >
                                        {this.props.ctaTitle3}
                                    </a>
                                </div>
                            )}

                            {this.props.popupVideo ? (
                                <div
                                    className={`c-video-header__play-button ${
                                        this.props.type === 'homepage'
                                            ? 'c-video-header__play-button--hp'
                                            : ''
                                    }
                                    ${
                                        this.props.type === 'angels'
                                            ? 'c-video-header__play-button--angels'
                                            : ''
                                    }
                                    ${
                                        this.props.type === 'funeral-plans'
                                            ? 'c-video-header__play-button--funeral-plans'
                                            : ''
                                    }

                                    ${
                                        this.props.type === 'circle'
                                            ? 'c-video-header__play-button--circle'
                                            : ''
                                    }
                                    `}
                                    onClick={() => this.handleTogglePopup()}
                                >
                                    <div className="rings">
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div className="circle">
                                        <div className="triangle">
                                            <i class="icon-video-play"></i>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {/* {this.props.ratingImages && (
                        <div className="c-multiple-video-header__rating-imgs">
                            {this.props.ratingImages?.map((item) => {
                                return (
                                    <img src={item.image} alt="rating img" />
                                );
                            })}
                        </div>
                    )} */}
                </div>
                {this.props.ratingImages?.length > 0 && (
                    <div className="c-multiple-video-header__rating-imgs">
                        {this.props.ratingImages?.map((item, i) => {
                            return (
                                <img
                                    key={i}
                                    src={item.image}
                                    alt="rating img"
                                />
                            );
                        })}
                    </div>
                )}
                <Popup
                    lockScroll
                    modal
                    className="lightbox-popup"
                    open={this.state.popupOpen}
                    onClose={() =>
                        this.setState({
                            popupOpen: false,
                        })
                    }
                >
                    {(close) => {
                        return (
                            <>
                                <div className="lightbox-content">
                                    {this.props.popupVideo ? (
                                        <iframe
                                            className="lightbox-video width-full height-full padding-reset border-radius-7"
                                            title="embed-title"
                                            allowFullScreen
                                            src={VideoEmbed.parseLink(
                                                this.props.popupVideo
                                            )}
                                        ></iframe>
                                    ) : null}
                                </div>
                                <button
                                    onClick={close}
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                            </>
                        );
                    }}
                </Popup>

                <Popup
                    lockScroll
                    className="request-info-modal-popup"
                    modal
                    open={this.state.modalIsOpen}
                    onClose={() => this.setState({ modalIsOpen: false })}
                >
                    {(close) => (
                        <>
                            {!this.state.formSubmited ? (
                                <div className="request-info-modal">
                                    <div className="request-info-modal__logo-text">
                                        {this.props.type ===
                                            'funeral-at-need' && (
                                            <h2>Aura Digital Brochure</h2>
                                        )}
                                        <img
                                            src={
                                                this.props.type ===
                                                'funeral-at-need'
                                                    ? logoAtNeedPopup
                                                    : logoPlans
                                            }
                                            alt="logo"
                                        ></img>
                                        {window.location.href.includes(
                                            '/funeral-plans'
                                        ) && (
                                            <h2>Aura Funeral Plans Brochure</h2>
                                        )}

                                        {window.location.href.includes(
                                            '/funeral-plans'
                                        ) ? null : (
                                            <p>
                                                Please let us know your contact
                                                details below and we'll send a
                                                digital copy of our Aura
                                                Funerals brochure over to you
                                                straight away via email. If you
                                                can't find it - please check
                                                your promotions or spam and let
                                                us know if you have any issues.
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <div id="hubspotForm"></div>
                                    </div>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            email: this.props.user
                                                ? this.props.user.email
                                                : '',
                                            name: this.props.user
                                                ? this.props.user.name
                                                : '',
                                            phone:
                                                this.props.user &&
                                                this.props.user.profile &&
                                                this.props.user.profile.mobile
                                                    ? this.props.user.profile
                                                          .mobile
                                                    : '',

                                            user_full_name: '',
                                            address: this.props.user
                                                ? this.props.user.address
                                                : '',
                                            postcode: this.props.user
                                                ? this.props.user.postcode
                                                : '',
                                        }}
                                        validationSchema={
                                            this.props.type ===
                                            'funeral-at-need'
                                                ? Yup.object({
                                                      email: Yup.string()
                                                          .email(
                                                              'Please enter a valid E-mail Address'
                                                          )
                                                          .required(
                                                              'This field is required.'
                                                          ),
                                                      name: Yup.string().required(
                                                          'This field is required.'
                                                      ),
                                                      phone: Yup.string(),
                                                  })
                                                : Yup.object({
                                                      email: Yup.string()
                                                          .email(
                                                              'Please enter a valid E-mail Address'
                                                          )
                                                          .required(
                                                              'This field is required.'
                                                          ),
                                                      name: Yup.string().required(
                                                          'This field is required.'
                                                      ),

                                                      phone: Yup.string(),
                                                  })
                                        }
                                        onSubmit={(values) => {
                                            this.handleFormSubmit(values);
                                        }}
                                    >
                                        {(props) => (
                                            <>
                                                {this.props.type ===
                                                    'funeral-at-need' && (
                                                    <div className="c-form request-info-modal__form">
                                                        <Form
                                                            noValidate
                                                            className={`o-flex o-flex-column c-contact-form request-info-modal__form-content ${window.location.href
                                                                .split('/')
                                                                .at(-1)}`}
                                                            id={window.location.href
                                                                .split('/')
                                                                .at(-1)}
                                                        >
                                                            <div
                                                                tabIndex="-1"
                                                                className={`required-indicator ${
                                                                    props.errors &&
                                                                    props.touched &&
                                                                    props
                                                                        .touched
                                                                        .email &&
                                                                    props.errors
                                                                        .email
                                                                        ? 'invalid'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <RequiredInput
                                                                    className="form-item--border-gray-light request-info-modal__form-input"
                                                                    something={
                                                                        props
                                                                    }
                                                                    label="Email Address"
                                                                    page="contact"
                                                                    name="email"
                                                                    type="email"
                                                                />
                                                            </div>
                                                            <div
                                                                tabIndex="-1"
                                                                className={`required-indicator ${
                                                                    props.errors &&
                                                                    props.touched &&
                                                                    props
                                                                        .touched
                                                                        .name &&
                                                                    props.errors
                                                                        .name
                                                                        ? 'invalid'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <RequiredInput
                                                                    className="form-item--border-gray-light request-info-modal__form-input"
                                                                    label="Name"
                                                                    name="name"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <div
                                                                tabIndex="-1"
                                                                className={`${
                                                                    props.errors &&
                                                                    props.touched &&
                                                                    props
                                                                        .touched
                                                                        .phone &&
                                                                    props.errors
                                                                        .phone
                                                                        ? 'invalid'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <RegularInput
                                                                    className="form-item--border-gray-light request-info-modal__form-input"
                                                                    label="Phone Number"
                                                                    name="phone"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            {/* <div className="hide"><Input className="form-item--border-gray-light" label="User Full Name"  name="user_full_name" type="text"/></div> */}
                                                            <Button
                                                                disabled={
                                                                    props.values
                                                                        .email ===
                                                                        '' ||
                                                                    (props.errors &&
                                                                        props
                                                                            .errors
                                                                            .email) ||
                                                                    props.values
                                                                        .name ===
                                                                        ''
                                                                }
                                                                className={`request-info-modal__btn btn btn--full btn-loader btn--size-57 ${
                                                                    this.state
                                                                        .buttonLoading
                                                                        ? 'save-in-progress'
                                                                        : ''
                                                                }`}
                                                                type="submit"
                                                            >
                                                                Submit
                                                                <img
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={loader}
                                                                    alt="loaderImage"
                                                                />
                                                            </Button>
                                                        </Form>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Formik>
                                    <div className="request-info-modal__bottom">
                                        {this.props.type !==
                                            'funeral-at-need' && (
                                            <p className="max-width-full">
                                                If you can't find your digital
                                                brochure email in your inbox,
                                                please check your promotions or
                                                spam and let us know if you have
                                                any issues.
                                            </p>
                                        )}
                                        <p className="max-width-full">
                                            If you have any questions and would
                                            like to speak to one of our team,
                                            you can call:
                                        </p>
                                        <img src={phoneIcon} alt="phone-icon" />
                                        <a
                                            className="c-pre-header__phone"
                                            href="tel:+448008085723"
                                        >
                                            0800 808 5723
                                        </a>
                                        <p>Monday - Friday 9am-5pm.</p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="c-crowdfunding__form-overlay c-landing__thank-you-modal o-flex o-flex-column o-flex-center o-flex-middle text-center">
                                        {/* <img className="c-landing__thank-you-modal--logo" src={logo} alt="logo"></img> */}
                                        <h4 className="font-family-secondary">
                                            Thank you for submitting your
                                            contact details.
                                        </h4>
                                        <img
                                            className="c-landing__thank-you-modal--mail-image"
                                            src={thankYou}
                                            alt="thankYou"
                                        />
                                        <p className="o-d-block">
                                            Someone will get in touch with you
                                            soon.
                                        </p>
                                    </div>
                                </>
                            )}
                            <button
                                onClick={close}
                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                            >
                                <i className="icon-times font-18 cursor-pointer"></i>
                            </button>
                        </>
                    )}
                </Popup>
            </>
        );
    }
}

export default MultipleVideo;
