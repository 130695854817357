import * as actionTypes from '../actions/onbaordingActions';

const initalState = {
    step: 0,
    unlockFamilyButton: false,
    unlockTrustedButton: false,
};

const onboardingReducer = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.SET_STEP:
            return {
                ...state,
                step: action.step,
            };
        case actionTypes.STEP_UP:
            return {
                ...state,
                step: state.step + 1,
            };
        case actionTypes.UNLOCK_TRUSTED_BUTTON:
            return {
                ...state,
                unlockTrustedButton: true,
            };
        case actionTypes.UNLOCK_FAMILY_BUTTON:
            return {
                ...state,
                unlockFamilyButton: true,
            };
        default:
            return state;
    }
};

export default onboardingReducer;
