import React, { Component } from 'react';
// import SlideOne from '../../../../assets/images/slider-img.png';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser';
import SingleItem from '../../Carousel/Carousel23/SingleItem/SingleItem';

export default class Carousel23 extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,

            customPaging: (i) => <div>{i + 1}</div>,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                    },
                },
            ],
        };

        let items;

        if (this.props.content.length) {
            items = this.props.content.map((item, index) => {
                return <SingleItem key={index} item={item} />;
            });
        }

        return (
            <div className="c-plans__plan-slider">
                <h2>
                    {ReactHtmlParser(this.props.title ? this.props.title : '')}
                </h2>
                <div className="container">
                    <Slider {...settings}>{items}</Slider>
                </div>
            </div>
        );
    }
}
