import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
export default class TrustSection extends Component {
    render() {
        let trust;
        if (this.props.trusts.length) {
            trust = this.props.trusts.map((trust) => {
                return (
                    <div className="o-flex o-flex-middle c-footer__security-features--list-item o-flex-column-mobile">
                        <img
                            width="30"
                            height="30"
                            src={trust.image}
                            alt={trust.title}
                        />
                        <h5 className="font-450 font-family-secondary font-16 font-333 opacity-9 margin-reset">
                            {trust.title}
                        </h5>
                    </div>
                );
            });
        }

        return this.props.trusts.length ? (
            <div className="c-plans__trust-section">
                <div className="container">
                    <h2>
                        {ReactHtmlParser(
                            this.props.title ? this.props.title : ''
                        )}
                    </h2>
                    <p>
                        {ReactHtmlParser(
                            this.props.body ? this.props.body : ''
                        )}
                    </p>
                    <div
                        className={`o-flex o-flex-middle o-flex-between o-flex-wrap c-footer__security-features--list number-of-items-${this.props.trusts.length}`}
                    >
                        {trust}
                    </div>
                </div>
            </div>
        ) : null;
    }
}
