import React, { Component } from 'react';

class AsSeenIn extends Component {
    render() {
        return (
            <div className="c-homepage__banner c-banner">
                <div className="container">
                    {this.props.title && <h2>{this.props.title}</h2>}
                    <div className="c-homepage__banner-content">
                        {this.props.content.map((item, index) => {
                            return (
                                <div key={`as-seen-in-${index}`}>
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        className="c-homepage__banner-content-img-two"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default AsSeenIn;
