export const SET_CONNECTIONS = 'SET_CONENCTIONS';
export const GET_REQUESTS = 'GET_REQUESTS';
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';
export const DECLINE_REQUEST = 'DECLINE_REQUEST';
export const SEARCH_CONNECTIONS = 'SEARCH_CONNECTIONS';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const CONNECTION_TYPE_CHANGE = 'CONNECTION_TYPE_CHANGE';
export const SEARCH_CONNECTION_PAGINATION = 'SEARCH_CONNECTION_PAGINATION';
export const SET_PAGED_CONNECTIONS = 'SET_PAGED_CONNECTIONS';
export const SEARCH_CONNECTIONS_CLEAR = 'SEARCH_CONNECTIONS_CLEAR';
export const SIDEBAR_CONNECTION_TYPE_CHANGE = 'SIDEBAR_CONNECTION_TYPE_CHANGE';
export const REMOVE_PENDING_CONNECTION = 'REMOVE_PENDING_CONNECTION';
export const CONNECTION_COUNT_SUBSRCIBE = 'CONNECTION_COUNT_SUBSRCIBE';
export const UPDATE_CONNECTION_NUMBER = 'UPDATE_CONNECTION_NUMBER';
export const DECLINE_CONNECTION = 'DECLINE_CONNECTION';
export const ACCEPT_CONNECTION = 'ACCEPT_CONNECTION';

//dispatch changes
export const setConnections = (connections) => {
    return {
        type: SET_CONNECTIONS,
        connections: connections,
    };
};

export const connectionCountSubsrcibe = (connCount) => {
    return {
        type: CONNECTION_COUNT_SUBSRCIBE,
        connCount: connCount,
    };
};

export const acceptRequest = (user_connection) => {
    return {
        type: ACCEPT_REQUEST,
        user_connection: user_connection,
    };
};

export const declineRequest = (connId) => {
    return {
        type: DECLINE_REQUEST,
        connId: connId,
    };
};

export const searchResultsClear = () => {
    return {
        type: SEARCH_CONNECTIONS_CLEAR,
    };
};

//dispatch actions
export const onSearchResultsClear = () => {
    return (dispatch) => {
        dispatch(searchResultsClear());
    };
};

export const onSetConnections = (payload) => {
    return (dispatch) => {
        dispatch(setConnections(payload));
    };
};

export const onConnectionCountSubscribe = (connCount) => {
    return (dispatch) => {
        dispatch(connectionCountSubsrcibe(connCount));
    };
};

export const onAcceptRequest = (payload) => {
    return (dispatch) => {
        dispatch(acceptRequest(payload));
    };
};

export const onDeclineRequest = (payload) => {
    return (dispatch) => {
        dispatch(declineRequest(payload));
    };
};

export const getRequests = (requests) => {
    return {
        type: GET_REQUESTS,
        requests: requests,
    };
};

export const onGetRequests = (payload) => {
    return (dispatch) => {
        dispatch(getRequests(payload));
    };
};

export const searchConnections = (searchedConnections) => {
    return {
        type: SEARCH_CONNECTIONS,
        searchedConnections: searchedConnections,
    };
};

export const onSearchConnections = (payload) => {
    return (dispatch) => {
        dispatch(searchConnections(payload));
    };
};

export const addConnection = (payload) => {
    return {
        type: ADD_CONNECTION,
        userId: payload.userId,
        connection: payload.connection,
    };
};

export const onAddConnection = (payload) => {
    return (dispatch) => {
        dispatch(addConnection(payload));
    };
};

export const removeConnection = (userId) => {
    return {
        type: REMOVE_CONNECTION,
        userId: userId,
    };
};

export const onRemoveConnection = (payload) => {
    return (dispatch) => {
        dispatch(removeConnection(payload));
    };
};

export const connectionTypeChange = (payload) => {
    return {
        type: CONNECTION_TYPE_CHANGE,
        userId: payload.userId,
        connectionType: payload.type,
    };
};

export const onConnectionTypeChange = (payload) => {
    return (dispatch) => {
        dispatch(connectionTypeChange(payload));
    };
};

export const searchConnectionsWithPager = (payload) => {
    return {
        type: SEARCH_CONNECTION_PAGINATION,
        pagedConnections: payload,
    };
};

export const onSearchConnectionsWithPager = (pagedConnections) => {
    return (dispatch) => {
        dispatch(searchConnectionsWithPager(pagedConnections));
    };
};

export const setPagedConnections = (payload) => {
    return {
        type: SET_PAGED_CONNECTIONS,
        pagedConnections: payload,
    };
};

export const onSetPagedConnections = (pagedConnections) => {
    return (dispatch) => {
        dispatch(setPagedConnections(pagedConnections));
    };
};

export const cancelPendingConnection = (connectionId) => {
    return {
        type: REMOVE_PENDING_CONNECTION,
        connectionId: connectionId,
    };
};

export const onCancelPendingConnection = (connectionId) => {
    return (dispatch) => {
        dispatch(cancelPendingConnection(connectionId));
    };
};

export const updateConnectionNumber = (payload) => {
    return {
        type: UPDATE_CONNECTION_NUMBER,
        updateType: payload,
    };
};

export const onUpdateConnectionNumber = (payload) => {
    return (dispatch) => {
        dispatch(updateConnectionNumber(payload));
    };
};

export const connectionSearchDeclineRequest = (connId) => {
    return {
        type: DECLINE_CONNECTION,
        connId: connId,
    };
};

export const onConnectionSearchDeclineRequest = (connId) => {
    return (dispatch) => {
        dispatch(connectionSearchDeclineRequest(connId));
    };
};

export const connectionSearchAcceptRequest = (conn, connId) => {
    return {
        type: ACCEPT_CONNECTION,
        connId: connId,
        conn: conn,
    };
};

export const onConnectionSearchAcceptRequest = (conn, connId) => {
    return (dispatch) => {
        dispatch(connectionSearchAcceptRequest(conn, connId));
    };
};
