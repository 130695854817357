import * as actionTypes from '../actions/messagesActions';

const initialState = {
    messages: [],
    threads: [],
    currentThreadId: null,
    unreadThreadCount: 0,
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEW_MESSAGE:
            const updatedThreads = [...state.threads];
            const existingThread = updatedThreads.find(
                (t) => t.id === action.thread_id
            );
            let sortedThreads;
            if (existingThread) {
                existingThread.lastMessage = action.message;
                existingThread.isRead = false;
                updatedThreads[
                    updatedThreads.findIndex((t) => t.id === action.thread_id)
                ] = existingThread;
                sortedThreads = updatedThreads.sort((a, b) => {
                    const aDate = new Date(a.createdAt).getTime();
                    const bDate = new Date(b.createdAt).getTime();

                    if (aDate < bDate) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            } else {
                updatedThreads.push(action.thread);
            }

            // append messages only to currenlty active thread
            const currentMessages =
                action.thread_id === state.currentThreadId
                    ? [...state.messages, action.message]
                    : state.messages;

            return {
                ...state,
                messages: currentMessages,
                threads: sortedThreads ? sortedThreads : updatedThreads,
                unreadThreadCount:
                    action.thread_id === state.currentThreadId
                        ? state.unreadThreadCount - 1
                        : state.unreadThreadCount,
            };
        case actionTypes.SET_THREADS:
            return {
                ...state,
                threads: action.threads,
            };
        case actionTypes.UNREAD_THREAD_COUNT:
            return {
                ...state,
                unreadThreadCount: action.unreadThreadCount,
            };
        case actionTypes.SET_THREAD_READ:
            const unreadThreads = [...state.threads];
            const unreadThread = unreadThreads.find(
                (t) => t.id === action.thread.id
            );
            const index = unreadThreads.indexOf(action.thread);
            unreadThread.isRead = true;
            let unreadThreads2 = unreadThreads.filter(
                (t) => t.id !== action.thread.id
            );
            unreadThreads2.splice(index, 0, action.thread);
            return {
                ...state,
                threads: unreadThreads2,
                unreadThreadCount:
                    state.unreadThreadCount === 0
                        ? 0
                        : state.unreadThreadCount - 1,
            };
        case actionTypes.SET_MESSAGES:
            const threads = [...state.threads];
            const thread = threads.find((t) => t.id === action.threadId);
            let filteredThreads;

            if (action.messages) {
                const lm = action.messages[action.messages.length - 1];

                if (thread) {
                    thread.lastMessage = lm;
                    filteredThreads = threads.filter(
                        (t) => t.id !== action.threadId
                    );
                    filteredThreads.unshift(thread);
                }
            }

            return {
                ...state,
                messages: action.messages ? action.messages : [],
                threads: thread ? filteredThreads : state.threads,
                currentThreadId: action.threadId,
            };
        default:
            return state;
    }
};

export default messagesReducer;
