import * as connActionTypes from '../../../store/actions/connectionActions';
import * as userActionTypes from '../../../store/actions/usersActions';

import React, { Component } from 'react';

import ApiService from '../../../service/api.service';
import DateFormater from '../../../service/date_formater.service';
import ImageUpload from '../../UI/ImageUpload/ImageUpload';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import ReactFlagsSelect from 'react-flags-select';
import ReactHtmlParser from 'react-html-parser';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import connectionsPlaceholder from '../../../assets/images/users.png';
import imagePlaceholder from '../../../assets/images/profile-placeholder.png';
import { withRouter } from 'react-router-dom';
import dustBin from '../../../assets/images/aura2022/icons/dustBinIcon.svg';
import confirmLogoutImg from '../../../assets/images/are-you-sure-logout.png';
class ProfileSidebar extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.countrySelectRef = React.createRef();
    }

    state = {
        dotsClass: '',
        isModalOpen: false,
        isUpdated: this.props.isUpdated,
        photo: '',
        profileShown: false,
        readState: false,
        options: [true, false, false, false],
        optionNames: ['Trusted Person', 'Friend', 'Family'],
        dropdownHovered: false,
        dropdownShown: false,
        loadedProfile: null,
        isUser: false,
    };

    componentDidMount() {
        this._isMounted = true;
        if (window.location.pathname.includes('connection')) {
            this.setState({ printConnections: false });
        }

        if (this.props.loadedProfile) {
            this.setState({ loadedProfile: this.props.loadedProfile });
        }

        if (this.props.user) {
            window.location.pathname.split('/').forEach((item) => {
                if (item === this.props.user.profile.slug) {
                    this.setState({ isUser: true });
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user) {
            if (
                prevProps.user &&
                prevProps.user.profile.country !==
                    this.props.user.profile.country
            ) {
                this.countrySelectRef.current.updateSelected(
                    this.props.user.profile.country
                );
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearLoadedProfile();
    }

    formatUrl = (url) => {
        let finalUrl;
        if (url) {
            if (url.indexOf('http') === 0 || url.indexOf('http') === 0) {
                finalUrl = url;
            } else {
                finalUrl = `https://${url}`;
            }
        }
        return finalUrl;
    };

    handleSidebarMenuToggle = () => {
        if (this.state.dotsClass) {
            this.setState({
                dotsClass: false,
            });
        } else {
            this.setState({
                dotsClass: true,
            });
        }
    };

    handleImageChange = (updatedPhoto) => {
        this.props.onUpdateUserPhoto(updatedPhoto);
    };

    handleReadState = () => {
        this.setState({
            readState: !this.state.readState,
        });
    };

    handleProfileShow = () => {
        this.setState({ profileShown: !this.state.profileShown });
    };

    handlePopupOpen = () => document.body.classList.add('modal-open');
    handlePopupClose = () => document.body.classList.remove('modal-open');

    // hide connections dropdown
    hideConnectionOptions = (event) => {
        if (
            !event.currentTarget.contains(event.relatedTarget) &&
            !this.state.hovered
        ) {
            this._isMounted &&
                this.setState({
                    dropdownShown: false,
                    dropdownHovered: false,
                });
        }
    };

    handleDecline = () => {
        this._isMounted &&
            ApiService.declineRequest(
                this.props.loadedProfile.profile.oppositeConnection.id
            ).then((r) => {
                this.props.onRemoveConnection();
            });
    };

    onDropdownMouseEnter = () => {
        this._isMounted && this.setState({ dropdownHovered: true });
    };
    onDropdownMouseLeave = () => {
        this._isMounted && this.setState({ dropdownHovered: false });
    };

    //handle accept request
    handleAcceptRequest = (data, id) => {
        this._isMounted &&
            ApiService.acceptRequest(data, id)
                .then((r) => {
                    this.props.onAcceptRequest(r.data);
                    this._isMounted &&
                        this.setState({
                            dropdownShown: false,
                            dropdownHovered: false,
                        });
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    //handle change connection type
    handleChangeConnectionType = (id, data) => {
        this._isMounted &&
            ApiService.changeConnectionType(data, id)
                .then((r) => {
                    this.setState(
                        {
                            dropdownShown: false,
                            dropdownHovered: false,
                        },
                        () => {
                            this.props.onSidebarConnectionTypeChange(
                                r.data.id,
                                r.data.type
                            );
                        }
                    );
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    //handle add connection
    handleAddConnection = (data) => {
        this._isMounted &&
            ApiService.addConnection(data)
                .then((user) => {
                    this.props.onAddConnection(user.data);
                    this._isMounted &&
                        this.setState({
                            dropdownShown: false,
                            dropdownHovered: false,
                        });
                })
                .catch((e) => {
                    console.log(e);
                });
    };

    handleRadioChange = (type, connected, notConnected, requested, e) => {
        //handle radio change on add new connection
        if (requested) {
            const id =
                this.props.loadedProfileStore.profile.oppositeConnection.id;
            const data = {
                status: 2,
                type: parseInt(type),
            };
            if (type === 1) {
                //add popup here
                if (e) {
                    e.target.checked = false;
                }
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <div className="popup-content popup-content--smaller text-center">
                            <div className="c-popup">
                                <div className="c-popup__header">
                                    <p className="margin-reset font-18 font-bold mt-29 font-333 opacity-8 lh-28">
                                        Please confirm you would like to make{' '}
                                        <span className="font-secondary font-bold">
                                            {this.props.name}
                                        </span>{' '}
                                        a Trusted Person.
                                    </p>
                                </div>
                                <h2 className="font-bold font-18 clearfix font-333 opacity-8 lh-28">
                                    You are selecting{' '}
                                    <span className="font-secondary font-bold">
                                        {this.props.name}
                                    </span>{' '}
                                    to be a Trusted Person for your Aura
                                    account. It is an important role with many
                                    responsibilities.{' '}
                                    <span className="o-d-block">
                                        <a
                                            href="https://www.aura.life/guides/the-role-of-a-trusted-person"
                                            className="link-underline-hover-background font-orange"
                                        >
                                            Learn more about this role here
                                        </a>
                                    </span>
                                </h2>
                                <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                    <button
                                        className="btn btn--full btn--size-width-180"
                                        onClick={() => {
                                            this.handleAcceptRequest(data, id);
                                            onClose();
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn--ghost-purple btn--size-width-180"
                                        onClick={() => {
                                            this.setState({ checkedValue: 0 });
                                            onClose();
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else {
                this.handleAcceptRequest(data, id);
            }
        }

        //if connected
        if (connected) {
            const id = this.props.loadedProfileStore.profile.connection.id;
            const data = {
                type: parseInt(type),
            };
            //if trusted person
            if (type === 1) {
                //add popup here
                if (e) {
                    e.target.checked = false;
                }
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <div className="popup-content popup-content--smaller text-center">
                            <div className="c-popup">
                                <div className="c-popup__header">
                                    <p className="margin-reset font-18 font-bold mt-29 font-333 opacity-8 lh-28">
                                        Please confirm you would like to make{' '}
                                        <span className="font-secondary font-bold">
                                            {this.props.name}
                                        </span>{' '}
                                        a Trusted Person.
                                    </p>
                                </div>
                                <h2 className="font-bold font-18 clearfix font-333 opacity-8 lh-28">
                                    You are selecting{' '}
                                    <span className="font-secondary font-bold">
                                        {this.props.name}
                                    </span>{' '}
                                    to be a Trusted Person for your Aura
                                    account. It is an important role with many
                                    responsibilities.{' '}
                                    <span className="o-d-block">
                                        <a
                                            href="https://www.aura.life/guides/the-role-of-a-trusted-person"
                                            className="link-underline-hover-background font-orange"
                                        >
                                            Learn more about this role here
                                        </a>
                                    </span>
                                </h2>
                                <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                    <button
                                        className="btn btn--full btn--size-width-180"
                                        onClick={() => {
                                            this.handleChangeConnectionType(
                                                id,
                                                data
                                            );
                                            onClose();
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn--ghost-purple btn--size-width-180"
                                        onClick={() => {
                                            this.setState({ checkedValue: 0 });
                                            onClose();
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else {
                this.handleChangeConnectionType(id, data);
            }
        }
        if (notConnected) {
            const data = {
                target: `/api/users/${this.props.loadedProfileStore.id}`,
                type: parseInt(type),
            };
            if (type === 1) {
                //add popup
                if (e) {
                    e.target.checked = false;
                }
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <div className="popup-content popup-content--smaller text-center">
                            <div className="c-popup">
                                <div className="c-popup__header">
                                    <p className="margin-reset font-18 font-bold mt-29 font-333 opacity-8 lh-28">
                                        Please confirm you would like to make{' '}
                                        <span className="font-secondary font-bold">
                                            {this.props.name}
                                        </span>{' '}
                                        a Trusted Person.
                                    </p>
                                </div>
                                <h2 className="font-bold font-18 clearfix font-333 opacity-8 lh-28">
                                    You are selecting{' '}
                                    <span className="font-secondary font-bold">
                                        {this.props.name}
                                    </span>{' '}
                                    to be a Trusted Person for your Aura
                                    account. It is an important role with many
                                    responsibilities.{' '}
                                    <span className="o-d-block">
                                        <a
                                            href="https://www.aura.life/guides/the-role-of-a-trusted-person"
                                            className="link-underline-hover-background font-orange"
                                        >
                                            Learn more about this role here
                                        </a>
                                    </span>
                                </h2>
                                <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                    <button
                                        className="btn btn--full btn--size-width-180"
                                        onClick={() => {
                                            this.handleAddConnection(data);
                                            onClose();
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn--ghost-purple btn--size-width-180"
                                        onClick={() => {
                                            this.setState({ checkedValue: 0 });
                                            onClose();
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else {
                this.handleAddConnection(data);
            }
        }
    };

    handleRemoveConnectionClick = () => {
        this._isMounted &&
            ApiService.declineRequest(
                this.props.loadedProfileStore.profile.connection.id
            ).then(() => {
                this.props.onRemoveConnection();
            });
    };

    handleRemoveConnection = () => {
        //decline request in this case is remove connection
        confirmAlert({
            customUI: ({ onClose }) => (
                <div className="popup-content popup-content--smaller text-center">
                    <div className="c-popup">
                        <div className="c-popup__header">
                            {/* <svg className="margin-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 100" width="44" height="57"> */}
                            <img src={dustBin} alt="dust bin icon" />
                        </div>
                        <h2 className="margin-reset font-24 font-bold mt-29 font-family-secondary font-333 opacity-9">
                            Are you sure you want to remove {this.props.name}{' '}
                            from your connections
                        </h2>
                        <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                            <button
                                className="btn btn--full btn--size-width-180"
                                onClick={() => {
                                    this.handleRemoveConnectionClick();
                                    onClose();
                                }}
                            >
                                Yes
                            </button>
                            <button
                                className="btn btn--ghost-purple btn--size-width-180"
                                onClick={onClose}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            ),
        });
    };

    // show connections dropdown
    showConnectionOptions = () => {
        this._isMounted &&
            this.setState((prevState) => ({
                dropdownShown: !prevState.dropdownShown,
                radioValue: this.props.connectionType,
            }));
    };

    onConnectionsTooltipClose = () => {
        ApiService.tooltipClose(this.props.user.profile.id).then((r) => {
            this.props.onConnectionsTooltipClose();
        });
    };

    handleLogout = (e) => {
        e.preventDefault();
        ApiService.getUncompletedPlans(this.props.user.id).then((r) => {
            if (r.data.userFuneralPlan) {
                confirmAlert({
                    customUI: ({ onClose }) => (
                        <div className="popup-content popup-content--smaller text-center">
                            <div className="c-popup">
                                <h2 className="margin-reset font-24 font-bold mt-29 mb-20 font-family-secondary font-333 opacity-9">
                                    Are you sure you want to log out?
                                </h2>
                                <div className="c-popup__header">
                                    <img
                                        src={confirmLogoutImg}
                                        alt="confirm alert icon"
                                    />
                                </div>
                                <p className="margin-reset font-18 mt-20 font-333 lh-28">
                                    If you log out now, you will need to
                                    re-enter your email and password again
                                    before you can finish purchasing your
                                    funeral plan. Would you still like to log
                                    out of your account?"
                                </p>
                                <div className="o-flex o-flex-middle o-flex-center mt-50 o-flex-column-reverse-mobile c-popup__action">
                                    <button
                                        className="btn btn--full btn--size-width-180"
                                        onClick={() => {
                                            this.handleContinueLogout();
                                            onClose();
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn--ghost-purple btn--size-width-180"
                                        onClick={onClose}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ),
                });
            } else {
                this.handleContinueLogout();
            }
        });
    };

    handleContinueLogout = () => {
        this.props.history.push('/logout');
    };

    render() {
        let myAura;
        if (window.location.pathname.includes('/aura/')) {
            myAura = false;
        } else {
            myAura = true;
        }
        let connected = false;
        let pending = false;
        let requested = false;
        let notConnected = false;
        if (this.props.loadedProfileStore) {
            if (
                this.props.loadedProfileStore.profile.connection &&
                this.props.loadedProfileStore.profile.connection.status === 2
            ) {
                connected = true;
            } else if (
                this.props.loadedProfileStore.profile.connection &&
                this.props.loadedProfileStore.profile.connection.status === 1
            ) {
                pending = true;
            } else if (
                this.props.loadedProfileStore.profile.oppositeConnection &&
                this.props.loadedProfileStore.profile.oppositeConnection
                    .status === 1
            ) {
                requested = true;
            } else if (!this.props.loadedProfileStore.profile.connection) {
                notConnected = true;
            }
        }

        return (
            <>
                {!this.props.loadedProfile ? (
                    <button
                        className={`c-sidebar__visibility btn btn--no-border btn--padding-clear padding-reset o-d-block-tablet o-d-none-mobile o-none-from-tablet width-full ${
                            this.state.profileShown ? 'show-profile' : ''
                        } `}
                        onClick={this.handleProfileShow}
                        type="button"
                    >
                        <div className="o-flex-between o-flex-middle c-sidebar__visibility--hidden border-radius-5">
                            <span className="font-family-secondary font-16 font-secondary font-semibold">
                                Show My Profile
                            </span>
                            <i className="icon-view font-13 font-secondary"></i>
                        </div>
                        <div className="o-flex-between o-flex-middle c-sidebar__visibility--visible border-radius-5">
                            <span className="font-family-secondary font-16 font-secondary font-semibold">
                                Hide My Profile
                            </span>
                            <i className="icon-hide font-10 font-secondary"></i>
                        </div>
                    </button>
                ) : null}

                <div
                    className={`c-sidebar__body ${
                        this.state.profileShown ? 'show-profile' : ''
                    } ${
                        this.props.loadedProfile
                            ? 'c-sidebar__body--other-user'
                            : ''
                    } ${
                        this.props.loadedProfile && window.innerWidth < 768
                            ? 'c-sidebar__body--other-user-mobile'
                            : ''
                    }`}
                >
                    <div
                        className={`c-sidebar__profile-image-menu-wrapper c-sidebar-menu position-relative ${
                            this.state.profileShown ? 'show-profile' : ''
                        } ${
                            this.props.loadedProfile && window.innerWidth < 768
                                ? 'c-sidebar-menu-other-user-mobile'
                                : ''
                        }`}
                    >
                        {this.props.loadedProfile ? (
                            <div
                                className={`c-sidebar-menu__profile-image-wrapper position-relative border-radius-6 overflow-both-hidden ${
                                    this.props.loadedProfileStore &&
                                    this.props.loadedProfileStore.profile
                                        .dateOfDeath
                                        ? 'c-deceased-indicator'
                                        : null
                                } ${
                                    this.props.loadedProfile &&
                                    window.innerWidth < 768
                                        ? 'c-sidebar-menu__profile-image-wrapper-other-user-mobile'
                                        : ''
                                }`}
                            >
                                <img
                                    className="border-radius-6"
                                    height="200"
                                    width="200"
                                    alt="placeholder"
                                    src={
                                        this.props.loadedProfile.profile.photo
                                            ? `${this.props.loadedProfile.profile.photo.url}`
                                            : imagePlaceholder
                                    }
                                />
                            </div>
                        ) : null}

                        {(!this.props.loadedProfile &&
                            (this.state.isUser || myAura)) ||
                        this.props.mobileProfileOpen ? (
                            <div
                                onClick={this.handleImageClick}
                                className="c-sidebar-menu__profile-image-wrapper cursor-pointer position-relative"
                            >
                                <img
                                    className="border-radius-6"
                                    height="200"
                                    width="200"
                                    alt="placeholder"
                                    src={
                                        this.props.user &&
                                        this.props.user.profile.photo
                                            ? `${
                                                  this.props.user &&
                                                  this.props.user.profile.photo
                                                      .url
                                              }`
                                            : imagePlaceholder
                                    }
                                />
                                <Popup
                                    lockScroll
                                    onOpen={this.handlePopupOpen}
                                    onClose={this.handlePopupClose}
                                    className="c-image-upload-popup c-popup-wrapper"
                                    modal
                                    trigger={
                                        <div className="c-sidebar-popup-indicator position-absolute text-center o-flex o-flex-middle o-flex-center o-flex-column z-index-1">
                                            <i className="c-sidebar-popup-indicator--not-visible icon-snapshot icon-hover font-white"></i>
                                            <p className="font-white font-semibold font-20">
                                                Change Photo
                                            </p>
                                            <i className="c-sidebar-popup-indicator--always-visible position-absolute icon-snapshot font-white font-14 o-flex o-flex-middle o-flex-center z-index-2 border-radius-50"></i>
                                        </div>
                                    }
                                >
                                    {(close) => (
                                        <>
                                            <div>
                                                <ImageUpload
                                                    handleImageChange={(
                                                        $event
                                                    ) =>
                                                        this.handleImageChange(
                                                            $event
                                                        )
                                                    }
                                                    close={close}
                                                    closeLink={
                                                        <button
                                                            className="close btn btn--ghost-purple"
                                                            onClick={close}
                                                        >
                                                            Cancel
                                                        </button>
                                                    }
                                                />
                                            </div>
                                            <button
                                                onClick={close}
                                                className="c-popup-wrapper-overlay__close btn-icon position-absolute o-none-from-mobile"
                                            >
                                                <i className="icon-times font-35 cursor-pointer"></i>
                                            </button>
                                        </>
                                    )}
                                </Popup>
                            </div>
                        ) : null}

                        {(!this.props.loadedProfile &&
                            (this.state.isUser || myAura)) ||
                        this.props.mobileProfileOpen ? (
                            <div className="c-sidebar-menu__sidebar-menu-wrapper position-absolute">
                                <span className="o-d-block cursor-pointer">
                                    <i className="icon-menu font-silver font-21"></i>
                                </span>

                                <ul className="c-dropdown-menu c-dropdown-menu--hover position-absolute">
                                    <li>
                                        <Link
                                            className={`user-nav o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                window.location.href.includes(
                                                    'profile/edit/'
                                                )
                                                    ? 'active-sidebar-link'
                                                    : ''
                                            }`}
                                            onClick={
                                                this.handleSidebarMenuToggle
                                            }
                                            to={`/profile/edit/${this.props.userSlug}`}
                                        >
                                            <i className="icon-edit"></i>Edit
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`user-nav o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                window.location.href.includes(
                                                    'profile/settings/'
                                                )
                                                    ? 'active-sidebar-link'
                                                    : ''
                                            }`}
                                            onClick={
                                                this.handleSidebarMenuToggle
                                            }
                                            to={`/profile/settings/${this.props.userSlug}`}
                                        >
                                            <i className="icon-settings"></i>
                                            Account Settings
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`user-nav o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                window.location.href.includes(
                                                    'profile/connections/my-connections/'
                                                )
                                                    ? 'active-sidebar-link'
                                                    : ''
                                            }`}
                                            onClick={
                                                this.handleSidebarMenuToggle
                                            }
                                            to={`/profile/connections/my-connections/${this.props.userSlug}/connected`}
                                        >
                                            <i className="icon-connections"></i>
                                            Connections
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`user-nav o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                window.location.href.includes(
                                                    'purchase-history'
                                                )
                                                    ? 'active-sidebar-link'
                                                    : ''
                                            }`}
                                            onClick={
                                                this.handleSidebarMenuToggle
                                            }
                                            to="/purchase-history"
                                        >
                                            <i className="icon-purchase"></i>
                                            Purchase History
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`user-nav o-flex-middle o-flex font-semibold font-16 font-333  ${
                                                window.location.href.includes(
                                                    '/guides'
                                                )
                                                    ? 'active-sidebar-link'
                                                    : ''
                                            }`}
                                            onClick={
                                                this.handleSidebarMenuToggle
                                            }
                                            to={`/my-aura/${this.props.userSlug}/guides`}
                                        >
                                            <i className="icon-info-solid"></i>
                                            Guides
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="user-nav o-flex-middle o-flex font-semibold font-16 font-333  logout"
                                            onClick={(e) => {
                                                this.handleSidebarMenuToggle();
                                                this.handleLogout(e);
                                            }}
                                            to="/logout"
                                        >
                                            <i className="icon-logout-icon"></i>
                                            Log Out
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        ) : null}
                    </div>

                    <h1
                        className={`font-26 font-333 opacity-9 font-semibold font-family-secondary margin-reset c-sidebar__profile-name ${
                            this.props.loadedProfile && window.innerWidth < 768
                                ? 'c-sidebar__profile-name-other-user-mobile'
                                : ''
                        }`}
                    >
                        {this.props.user
                            ? (!this.props.loadedProfile &&
                                  (this.state.isUser || myAura)) ||
                              this.props.mobileProfileOpen
                                ? this.props.user
                                    ? `${
                                          this.props.user.profile.firstName
                                              ? this.props.user.profile
                                                    .firstName
                                              : ''
                                      } ${
                                          this.props.user.profile.lastName
                                              ? this.props.user.profile.lastName
                                              : ''
                                      }`
                                    : `${
                                          this.props.loadedProfile.profile
                                              .firstName
                                              ? this.props.loadedProfile.profile
                                                    .firstName
                                              : ''
                                      } ${
                                          this.props.loadedProfile.profile
                                              .lastName
                                              ? this.props.loadedProfile.profile
                                                    .lastName
                                              : ''
                                      }`
                                : null
                            : null}
                        {this.props.loadedProfile
                            ? this.props.loadedProfile.profile.name
                            : null}
                    </h1>

                    {/* life motto */}
                    {this.props.loadedProfile ? (
                        <h3 className="font-18 font-333 opacity-7 font-normal font-family-secondary c-sidebar__profile-moto">
                            {this.props.loadedProfile.profile.lifeMotto}
                        </h3>
                    ) : null}

                    {(!this.props.loadedProfile &&
                        (this.state.isUser || myAura) &&
                        this.props.user) ||
                    (this.props.mobileProfileOpen && this.props.user) ? (
                        <h3 className="font-18 font-333 opacity-7 font-normal font-family-secondary c-sidebar__profile-moto">
                            {this.props.user.profile.lifeMotto}
                        </h3>
                    ) : null}

                    {
                        // my profile bio with readmore
                        (!this.props.loadedProfile &&
                            (this.state.isUser || myAura)) ||
                        (this.props.mobileProfileOpen && this.props.user) ? (
                            <div
                                className={`font-18 font-333 opacity-9 font-semibold c-sidebar__profile-bio`}
                            >
                                {this.props.user &&
                                this.props.user.profile.bio &&
                                this.props.user.profile.bio !== '' &&
                                this.props.user.profile.bio.length > 180 ? (
                                    <p
                                        className={`${
                                            this.state.readState
                                                ? 'read-expanded'
                                                : ''
                                        }`}
                                    >
                                        {ReactHtmlParser(
                                            this.props.user.profile.bio
                                        )}
                                    </p>
                                ) : null}
                                {this.props.user &&
                                this.props.user.profile.bio &&
                                this.props.user.profile.bio !== '' &&
                                this.props.user.profile.bio.length <= 180 ? (
                                    <p className="read-full">
                                        {ReactHtmlParser(
                                            this.props.user.profile.bio
                                        )}
                                    </p>
                                ) : null}
                            </div>
                        ) : null
                    }

                    {!this.props.loadedProfile &&
                    (this.state.isUser || myAura) ? (
                        <div className="c-sidebar__read-more">
                            {this.props.user &&
                            this.props.user.profile.bio &&
                            this.props.user.profile.bio !== '' &&
                            this.props.user.profile.bio.length > 180 ? (
                                <button
                                    className="c-sidebar__read-more-btn"
                                    onClick={this.handleReadState}
                                >
                                    {this.state.readState
                                        ? 'Read Less'
                                        : 'Read More'}
                                </button>
                            ) : null}
                        </div>
                    ) : null}

                    {this.props.loadedProfile && window.innerWidth > 767 ? (
                        <div
                            className={`font-18 font-333 opacity-9 font-semibold c-sidebar__profile-bio`}
                        >
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile.bio &&
                            this.props.loadedProfile.profile.bio !== '' &&
                            this.props.loadedProfile.profile.bio.length >
                                180 ? (
                                <p
                                    className={`${
                                        this.state.readState
                                            ? 'read-expanded'
                                            : ''
                                    }`}
                                >
                                    {ReactHtmlParser(
                                        this.props.loadedProfile.profile.bio
                                    )}
                                </p>
                            ) : null}
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile.bio &&
                            this.props.loadedProfile.profile.bio !== '' &&
                            this.props.loadedProfile.profile.bio.length <=
                                180 ? (
                                <p className="read-full">
                                    {ReactHtmlParser(
                                        this.props.loadedProfile.profile.bio
                                    )}
                                </p>
                            ) : null}
                        </div>
                    ) : null}

                    {this.props.loadedProfile && window.innerWidth > 767 ? (
                        <div className="c-sidebar__read-more">
                            {this.props.loadedProfile.profile.bio &&
                            this.props.loadedProfile.profile.bio !== '' &&
                            this.props.loadedProfile.profile.bio.length >
                                180 ? (
                                <button
                                    className="c-sidebar__read-more-btn"
                                    onClick={this.handleReadState}
                                >
                                    {this.state.readState
                                        ? 'Read Less'
                                        : 'Read More'}
                                </button>
                            ) : null}
                        </div>
                    ) : null}

                    {(!this.props.loadedProfile &&
                        (this.state.isUser || myAura)) ||
                    (this.props.mobileProfileOpen && this.props.user) ? (
                        <ul className="o-flex o-flex-middle c-sidebar__social-links">
                            {this.props.user &&
                            this.props.user.profile.facebookProfileUrl &&
                            this.props.user.profile.facebookProfileUrl !==
                                '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.user.profile
                                                .facebookProfileUrl
                                        )}
                                    >
                                        <i className="icon-fb"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.user &&
                            this.props.user.profile.twitterProfileUrl &&
                            this.props.user.profile.twitterProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.user.profile
                                                .twitterProfileUrl
                                        )}
                                    >
                                        <i className="icon-twitter"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.user &&
                            this.props.user.profile.instagramProfileUrl &&
                            this.props.user.profile.instagramProfileUrl !==
                                '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.user.profile
                                                .instagramProfileUrl
                                        )}
                                    >
                                        <i className="icon-instagram"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.user &&
                            this.props.user.profile.linkedInProfileUrl &&
                            this.props.user.profile.linkedInProfileUrl !==
                                '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.user.profile
                                                .linkedInProfileUrl
                                        )}
                                    >
                                        <i className="icon-linkedin"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.user &&
                            this.props.user.profile.youtubeProfileUrl &&
                            this.props.user.profile.youtubeProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-35 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.user.profile
                                                .youtubeProfileUrl
                                        )}
                                    >
                                        <i className="icon-youtube"></i>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    ) : null}

                    {this.props.loadedProfile && window.innerWidth > 767 ? (
                        <ul className="o-flex o-flex-middle c-sidebar__social-links">
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile
                                .facebookProfileUrl &&
                            this.props.loadedProfile.profile
                                .facebookProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.loadedProfile.profile
                                                .facebookProfileUrl
                                        )}
                                    >
                                        <i className="icon-fb"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile
                                .twitterProfileUrl &&
                            this.props.loadedProfile.profile
                                .twitterProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.loadedProfile.profile
                                                .twitterProfileUrl
                                        )}
                                    >
                                        <i className="icon-twitter"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile
                                .instagramProfileUrl &&
                            this.props.loadedProfile.profile
                                .instagramProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.loadedProfile.profile
                                                .instagramProfileUrl
                                        )}
                                    >
                                        <i className="icon-instagram"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile
                                .linkedInProfileUrl &&
                            this.props.loadedProfile.profile
                                .linkedInProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-26 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.loadedProfile.profile
                                                .linkedInProfileUrl
                                        )}
                                    >
                                        <i className="icon-linkedin"></i>
                                    </a>
                                </li>
                            ) : null}
                            {this.props.loadedProfile &&
                            this.props.loadedProfile.profile
                                .youtubeProfileUrl &&
                            this.props.loadedProfile.profile
                                .youtubeProfileUrl !== '' ? (
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="font-35 font-333 opacity-3 o-flex o-flex-middle cursor-pointer"
                                        href={this.formatUrl(
                                            this.props.loadedProfile.profile
                                                .youtubeProfileUrl
                                        )}
                                    >
                                        <i className="icon-youtube"></i>
                                    </a>
                                </li>
                            ) : null}
                        </ul>
                    ) : null}

                    {(!this.props.loadedProfile &&
                        (this.state.isUser || myAura)) ||
                    (this.props.mobileProfileOpen && this.props.user) ? (
                        <div className="c-sidebar__connections">
                            <div className="o-flex o-flex-middle c-sidebar__connections--body">
                                <div className="images">
                                    <img
                                        alt="Connections Placeholder"
                                        src={connectionsPlaceholder}
                                    ></img>
                                </div>
                                <Link
                                    to={`/profile/connections/my-connections/${this.props.userSlug}/connected`}
                                    className="font-family-secondary font-333 font-semibold font-20 ml-20 link-purple-hover"
                                >
                                    Connections{' '}
                                    {this.props.user ? (
                                        <span className="font-orange">
                                            ({this.props.connectionCount})
                                        </span>
                                    ) : null}{' '}
                                </Link>
                            </div>

                            <div className="position-relative">
                                <div
                                    className={`c-tooltip ${
                                        this.props.connectionsTooltipOpened
                                            ? 'c-tooltip--opened'
                                            : ''
                                    }`}
                                >
                                    <button
                                        onClick={this.onConnectionsTooltipClose}
                                        className="c-tooltip__close o-flex o-flex-middle o-flex-center"
                                    >
                                        <i className="icon-times-thin"></i>
                                    </button>
                                    <span className="c-tooltip__content">
                                        You haven't added a Trusted Person to
                                        your account yet. Click Add connections
                                        below to add someone.
                                        <br />
                                        <br />
                                        You can find our more about Trusted
                                        Persons and why they're important{' '}
                                        <Link
                                            className="link-underline-hover-background font-orange"
                                            to="/guides/the-role-of-a-trusted-person"
                                        >
                                            here.
                                        </Link>
                                    </span>
                                </div>

                                <Link
                                    className={[
                                        this.props.connectionsTooltipOpened
                                            ? 'highlighted'
                                            : null,
                                        'c-sidebar__connections--btn width-full btn btn--ghost-purple btn--double-border btn--size-54 o-flex o-flex-middle o-flex-center mt-29',
                                    ].join(' ')}
                                    onClick={() => this.changeState}
                                    to={`/profile/connections/add-connections/${
                                        this.props.user
                                            ? this.props.userSlug
                                            : null
                                    }`}
                                >
                                    Add connections
                                </Link>
                            </div>
                        </div>
                    ) : null}

                    {/* PROFILE SIDEBAR CONNECTION */}
                    {!this.state.isUser &&
                    this.props.loadedProfile &&
                    requested &&
                    !this.props.isUser ? (
                        <div className="o-flex c-user-list__user-status position-relative c-sidebar__connection-requested">
                            <button
                                className="btn-action btn-action--decline btn btn--faded-gray  font-family-secondary btn--no-border btn--size-43"
                                onClick={() => this.handleDecline()}
                            >
                                Decline
                            </button>
                            <div
                                onBlur={($event) =>
                                    this.hideConnectionOptions($event)
                                }
                                tabIndex="0"
                            >
                                <button
                                    onClick={() => this.showConnectionOptions()}
                                    className="btn-action btn-action--connect btn btn--faded-purple  font-family-secondary btn--no-border btn--with-icon-right btn--size-43 o-flex o-flex-middle o-flex-center"
                                >
                                    Connect
                                    <i className="icon-arrow-down-1 font-10"></i>
                                </button>
                                <div
                                    onMouseEnter={this.onDropdownMouseEnter}
                                    onMouseLeave={this.onDropdownMouseLeave}
                                    className={`c-dropdown-menu c-dropdown-menu--left c-dropdown-menu--mobile-button connection-popup ${
                                        this.state.dropdownShown
                                            ? 'open left-alignment width-border-top'
                                            : ''
                                    }`}
                                >
                                    <span className="font-16 font-333 opacity-9 o-d-block c-dropdown-menu__title">
                                        Connect as your:
                                    </span>
                                    <ul className="margin-reset">
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`trusted-person--${this.props.loadedProfile.id}`}
                                                type="radio"
                                                selected={
                                                    this.state.checkedValue ===
                                                    0
                                                }
                                                name="connection-type"
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        1,
                                                        connected,
                                                        notConnected,
                                                        requested,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`trusted-person--${this.props.loadedProfile.id}`}
                                            >
                                                <i className="icon-trusted-person font-orange font-20"></i>
                                                <span className="font-333 font-18">
                                                    Trusted Person
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`family-member--${this.props.loadedProfile.id}`}
                                                type="radio"
                                                name="connection-type"
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        3,
                                                        connected,
                                                        notConnected,
                                                        requested,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`family-member--${this.props.loadedProfile.id}`}
                                            >
                                                <i className="icon-family font-333 font-20"></i>
                                                <span className="font-333 font-18">
                                                    Family
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`friend--${this.props.loadedProfile.id}`}
                                                type="radio"
                                                name="connection-type"
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        2,
                                                        connected,
                                                        notConnected,
                                                        requested,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`friend--${this.props.loadedProfile.id}`}
                                            >
                                                <i className="icon-friends font-333 font-20"></i>
                                                <span className="font-333 font-18">
                                                    Friend
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {!this.state.isUser &&
                    this.props.loadedProfile &&
                    pending &&
                    !this.props.isUser ? (
                        <div className="c-sidebar__connection-pending font-family-secondary font-semibold text-center position-relative">
                            <span className="font-16 o-d-block font-orange opacity-8">
                                Pending{' '}
                                <span className="o-d-none-mobile">
                                    as{' '}
                                    {
                                        this.state.optionNames[
                                            this.props.loadedProfileStore
                                                .profile.connection.type - 1
                                        ]
                                    }
                                </span>
                                <i
                                    onClick={() =>
                                        this.handleRemoveConnectionClick()
                                    }
                                    className="icon-times-thin cursor-pointer o-flex o-flex-middle o-flex-center font-20 position-absolute"
                                >
                                    <span className="font-14 font-white position-absolute btn-status--pending-cancel-text">
                                        Cancel request
                                    </span>
                                </i>
                            </span>
                        </div>
                    ) : null}

                    {!this.state.isUser &&
                    this.props.loadedProfileStore &&
                    notConnected &&
                    !this.props.isUser ? (
                        <div
                            onBlur={($event) =>
                                this.hideConnectionOptions($event)
                            }
                            tabIndex="0"
                            className="position-relative c-sidebar__connect-as"
                        >
                            <button
                                className="btn-action btn-action--connect btn btn--faded-purple  font-family-secondary btn--no-border btn--with-icon-right btn--size-43 btn--size-width-199 o-flex o-flex-middle o-flex-center"
                                onClick={() => this.showConnectionOptions()}
                            >
                                Connect
                                <i className="icon-arrow-down-1 font-10"></i>
                            </button>
                            <div
                                onMouseEnter={this.onDropdownMouseEnter}
                                onMouseLeave={this.onDropdownMouseLeave}
                                className={`c-dropdown-menu c-dropdown-menu--left connection-popup ${
                                    this.state.dropdownShown
                                        ? 'open left-alignment width-border-top'
                                        : ''
                                }`}
                            >
                                <span className="font-16 font-333 opacity-9 o-d-block c-dropdown-menu__title">
                                    Connect as your:
                                </span>
                                <ul className="margin-reset">
                                    {!this.props.friendsFamily ? (
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`trusted-person--${this.props.loadedProfileStore.id}`}
                                                type="radio"
                                                name="connection-type"
                                                selected={
                                                    this.state.checkedValue ===
                                                    0
                                                }
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        1,
                                                        connected,
                                                        notConnected,
                                                        false,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`trusted-person--${this.props.loadedProfileStore.id}`}
                                            >
                                                <i className="icon-trusted-person font-orange font-20"></i>
                                                <span className="font-333 font-18">
                                                    Trusted Person
                                                </span>
                                            </label>
                                        </li>
                                    ) : null}
                                    <li className="c-form-item-radio">
                                        <input
                                            id={`family-member--${this.props.loadedProfileStore.id}`}
                                            type="radio"
                                            name="connection-type"
                                            onChange={($event) =>
                                                this.handleRadioChange(
                                                    3,
                                                    connected,
                                                    notConnected,
                                                    false,
                                                    $event
                                                )
                                            }
                                        />
                                        <label
                                            className="o-flex"
                                            htmlFor={`family-member--${this.props.loadedProfileStore.id}`}
                                        >
                                            <i className="icon-family font-333 font-20"></i>
                                            <span className="font-333 font-18">
                                                Family
                                            </span>
                                        </label>
                                    </li>
                                    <li className="c-form-item-radio">
                                        <input
                                            id={`friend--${this.props.loadedProfileStore.id}`}
                                            type="radio"
                                            name="connection-type"
                                            onChange={($event) =>
                                                this.handleRadioChange(
                                                    2,
                                                    connected,
                                                    notConnected,
                                                    false,
                                                    $event
                                                )
                                            }
                                        />
                                        <label
                                            className="o-flex"
                                            htmlFor={`friend--${this.props.loadedProfileStore.id}`}
                                        >
                                            <i className="icon-Friend font-333 font-20"></i>
                                            <span className="font-333 font-18">
                                                Friend
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) : null}

                    {!this.state.isUser &&
                    this.props.loadedProfileStore &&
                    connected ? (
                        <div className="c-user-list c-user-list__user-status c-sidebar__connected-as">
                            <div
                                onBlur={($event) =>
                                    this.hideConnectionOptions($event)
                                }
                                tabIndex="0"
                                className="position-relative"
                            >
                                <span className="font-18 font-orange  font-semibold mb-10 o-d-block c-sidebar__connected-as--title">
                                    Connected as
                                </span>
                                <span
                                    className="connection-type o-flex o-flex-middle cursor-pointer"
                                    onClick={() => this.showConnectionOptions()}
                                >
                                    <i
                                        className={`font-20 font-333  icon-${
                                            this.state.optionNames[
                                                this.props.loadedProfileStore
                                                    .profile.connection.type - 1
                                            ] === 'Trusted Person'
                                                ? 'trusted-person'
                                                : this.state.optionNames[
                                                      this.props
                                                          .loadedProfileStore
                                                          .profile.connection
                                                          .type - 1
                                                  ] === 'Family'
                                                ? 'family'
                                                : 'friends'
                                        }`}
                                    ></i>
                                    <span className="font-18 font-semibold font-333  ml-10 margin-right-10">
                                        {
                                            this.state.optionNames[
                                                this.props.loadedProfileStore
                                                    .profile.connection.type - 1
                                            ]
                                        }
                                    </span>
                                    <i className="icon-arrow-down-1 font-6 font-333  transform-scale-Y"></i>
                                </span>
                                <div
                                    onMouseEnter={this.onDropdownMouseEnter}
                                    onMouseLeave={this.onDropdownMouseLeave}
                                    className={`c-dropdown-menu ${
                                        this.state.dropdownShown
                                            ? 'open left-alignment width-border-top'
                                            : ''
                                    }`}
                                >
                                    <span className="font-16 font-333 opacity-9 o-d-block c-dropdown-menu__title">
                                        Change Connection to your:
                                    </span>
                                    <ul className="margin-reset">
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`trusted-person--${this.props.loadedProfileStore.id}`}
                                                type="radio"
                                                name="connection-type"
                                                checked={
                                                    this.props
                                                        .loadedProfileStore
                                                        .profile.connection
                                                        .type === 1
                                                }
                                                selected={
                                                    this.state.checkedValue ===
                                                    0
                                                }
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        1,
                                                        connected,
                                                        false,
                                                        false,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`trusted-person--${this.props.loadedProfileStore.id}`}
                                            >
                                                <i className="icon-trusted-person font-orange font-20"></i>
                                                <span className="font-333 font-18">
                                                    Trusted Person
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`family-member--${this.props.loadedProfileStore.id}`}
                                                type="radio"
                                                name="connection-type"
                                                checked={
                                                    this.props
                                                        .loadedProfileStore
                                                        .profile.connection
                                                        .type === 3
                                                }
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        3,
                                                        connected,
                                                        false,
                                                        false,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`family-member--${this.props.loadedProfileStore.id}`}
                                            >
                                                <i className="icon-family font-333 font-20"></i>
                                                <span className="font-333 font-18">
                                                    Family
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio">
                                            <input
                                                id={`friend--${this.props.loadedProfileStore.id}`}
                                                type="radio"
                                                name="connection-type"
                                                checked={
                                                    this.props
                                                        .loadedProfileStore
                                                        .profile.connection
                                                        .type === 2
                                                }
                                                onChange={($event) =>
                                                    this.handleRadioChange(
                                                        2,
                                                        connected,
                                                        false,
                                                        false,
                                                        $event
                                                    )
                                                }
                                            />
                                            <label
                                                className="o-flex"
                                                htmlFor={`friend--${this.props.loadedProfileStore.id}`}
                                            >
                                                <i className="icon-friends font-333 font-20"></i>
                                                <span className="font-333 font-18">
                                                    Friend
                                                </span>
                                            </label>
                                        </li>
                                        <li className="c-form-item-radio c-form-item-radio--red">
                                            <label
                                                onClick={() =>
                                                    this.handleRemoveConnection()
                                                }
                                                className="o-flex"
                                            >
                                                <i className="icon-times-circle font-form-error font-20"></i>
                                                <span className="font-form-error">
                                                    Remove Connection
                                                </span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {(!this.props.loadedProfile &&
                        this.props.user &&
                        (this.props.user.profile.birthday ||
                            this.props.user.profile.country ||
                            this.props.user.profile.city) &&
                        (this.state.isUser || myAura)) ||
                    (this.props.mobileProfileOpen &&
                        this.props.user &&
                        (this.props.user.profile.birthday ||
                            this.props.user.profile.country ||
                            this.props.user.profile.city)) ? (
                        <h2 className="font-22 font-333 opacity-9 font-semibold font-family-secondary">
                            Personal Information
                        </h2>
                    ) : null}

                    <div className="c-sidebar__profile-personal-info">
                        {(!this.props.loadedProfile &&
                            this.props.user &&
                            this.props.user.profile.birthday &&
                            (this.state.isUser || myAura)) ||
                        (this.props.mobileProfileOpen &&
                            this.props.user &&
                            this.props.user.profile.birthday) ? (
                            <p className="font-20 font-333 font-normal  font-family-secondary o-flex o-flex-middle">
                                <i className="font-23 icon-calendar-icon margin-right-30"></i>
                                {DateFormater.formatDate(
                                    this.props.user.profile.birthday.substring(
                                        0,
                                        10
                                    )
                                )}
                            </p>
                        ) : null}

                        {this.props.user &&
                        this.props.user.profile.profileDeceased &&
                        myAura ? (
                            <Link
                                to={`/my-aura/${this.props.user.profile.slug}/deceased`}
                                className="font-20 font-333 font-normal  font-family-secondary o-flex o-flex-middle"
                            >
                                <i className="font-23 icon-deceased margin-right-10 lh-28"></i>
                                {DateFormater.formatDate(
                                    this.props.user.profile.profileDeceased
                                        .dateOfDeath
                                )}
                            </Link>
                        ) : null}

                        {(!this.props.loadedProfile &&
                            this.props.user &&
                            this.props.user.profile.city &&
                            (this.state.isUser || myAura)) ||
                        (this.props.mobileProfileOpen &&
                            this.props.user &&
                            this.props.user &&
                            this.props.user.profile.city) ? (
                            <p className="font-20 font-333 font-normal  font-family-secondary o-flex o-flex-middle">
                                <i className="font-23 icon-location-icon margin-right-30"></i>
                                {this.props.user.profile.city}
                            </p>
                        ) : null}

                        {
                            // my account country
                            (!this.props.loadedProfile &&
                                this.props.user &&
                                this.props.user.profile.country &&
                                this.props.user.profile.country !== null &&
                                this.props.user.profile.country !== '' &&
                                (this.state.isUser || myAura)) ||
                            (this.props.mobileProfileOpen &&
                                this.props.user &&
                                this.props.user.profile.country) ? (
                                <ReactFlagsSelect
                                    defaultCountry={
                                        this.props.user &&
                                        this.props.user.profile.country
                                            ? this.props.user.profile.country
                                            : ''
                                    }
                                    label="Country"
                                    ref={this.countrySelectRef}
                                    name="country"
                                    disabled={true}
                                />
                            ) : null
                        }
                    </div>

                    {this.props.loadedProfile &&
                    window.innerWidth > 767 &&
                    (this.props.loadedProfile.profile.birthday ||
                        this.props.loadedProfile.profile.country ||
                        this.props.loadedProfile.profile.city) ? (
                        <h2 className="font-22 font-333 opacity-9 font-semibold font-family-secondary">
                            Personal Information
                        </h2>
                    ) : null}

                    <div className="c-sidebar__profile-personal-info">
                        {/* birthday */}
                        {this.props.loadedProfile &&
                        window.innerWidth > 767 &&
                        this.props.loadedProfile.profile.birthday ? (
                            <p className="font-20 font-333 font-normal  font-family-secondary o-flex o-flex-middle">
                                <i className="font-23 icon-calendar-icon margin-right-30"></i>
                                {DateFormater.formatDate(
                                    this.props.loadedProfile.profile.birthday.substring(
                                        0,
                                        10
                                    )
                                )}
                            </p>
                        ) : null}

                        {/* date of death */}
                        {this.props.loadedProfileStore &&
                        window.innerWidth > 767 &&
                        this.props.loadedProfileStore.profile.dateOfDeath ? (
                            <p className="font-20 font-333 font-normal  font-family-secondary o-flex o-flex-middle">
                                <i className="font-23 icon-deceased margin-right-30 lh-28"></i>
                                {DateFormater.formatDate(
                                    this.props.loadedProfileStore.profile
                                        .dateOfDeath
                                )}
                            </p>
                        ) : null}

                        {/* city */}
                        {this.props.loadedProfile &&
                        window.innerWidth > 767 &&
                        this.props.loadedProfile.profile.city ? (
                            <p className="font-20 font-333 font-normal  font-family-secondary o-flex o-flex-middle">
                                <i className="font-23 icon-location-icon margin-right-30"></i>
                                {this.props.loadedProfile.profile.city}
                            </p>
                        ) : null}

                        {/* country */}
                        {this.props.loadedProfile &&
                        window.innerWidth > 767 &&
                        this.props.loadedProfile.profile.country &&
                        this.props.loadedProfile.profile.country !== null &&
                        this.props.loadedProfile.profile.country !== '' ? (
                            <ReactFlagsSelect
                                defaultCountry={
                                    this.props.loadedProfile &&
                                    this.props.loadedProfile.profile.country
                                        ? this.props.loadedProfile.profile
                                              .country
                                        : ''
                                }
                                label="Country"
                                ref={this.countrySelectRef}
                                name="country"
                                disabled={true}
                            />
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
    userSlug: state.usersReducer.userSlug,
    userId: state.usersReducer.userId,
    loadedProfileStore: state.usersReducer.loadedProfile,
    connectionsTooltipOpened: state.usersReducer.connectionsTooltipOpened,
    connectionCount: state.connectionsReducer.connectionCount,
    mobileProfileOpen: state.usersReducer.mobileProfileOpen,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserPhoto: (profilePhoto) =>
            dispatch(userActionTypes.onUpdateUserPhoto(profilePhoto)),
        onSidebarConnectionTypeChange: (userId, type) =>
            dispatch(
                userActionTypes.onSidebarConnectionTypeChange({ userId, type })
            ),
        onRemoveConnection: () =>
            dispatch(userActionTypes.onRemoveConnection()),
        onAddConnection: (conn) =>
            dispatch(userActionTypes.onAddConnection(conn)),
        onAcceptRequest: (type) =>
            dispatch(userActionTypes.onAcceptRequest(type)),
        onConnectionsTooltipClose: () =>
            dispatch(userActionTypes.onConnectionsTooltipClose()),
        clearLoadedProfile: () =>
            dispatch(userActionTypes.clearLoadedProfile()),
        onUpdateConnectionNumber: (updateType) =>
            dispatch(connActionTypes.onUpdateConnectionNumber(updateType)),
    };
};

const ProfileSidebarWithRouter = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileSidebar);

export default withRouter(ProfileSidebarWithRouter);
