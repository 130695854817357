import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import articleDefault from '../../../assets/images/article-default.webp';
class BlogListItem extends Component {
    handleReload = () => {
        setTimeout(function () {
            window.location.reload();
        }, 200);
    };

    componentDidMount() {
        console.info('single blog page');
    }

    render() {
        let type;
        if (this.props.type === 'guide') {
            type = 'guide';
        } else if (this.props.type === 'article') {
            type = 'article';
        }
        return (
            <div
                className={`c-knowledge__item ${
                    this.props.onProfilePage ? 'c-knowledge__item--profile' : ''
                }`}
            >
                <Link
                    onClick={this.handleReload}
                    className="o-d-block c-knowledge__item--image position-relative overflow-both-hidden"
                    to={`/${
                        type
                            ? type === 'guide'
                                ? 'guides'
                                : 'articles'
                            : this.props.location &&
                              this.props.location.includes('guides')
                            ? 'guides'
                            : 'articles'
                    }/${this.props.article.slug}`}
                >
                    <img
                        className="position-absolute width-full height-full"
                        src={
                            this.props.article.featuredImage &&
                            this.props.article.featuredImage.mediaDetails
                                ? this.props.article.featuredImage.mediaDetails
                                      .sizes.full.source_url
                                : articleDefault
                        }
                        alt="banner"
                    ></img>
                </Link>
                <div className="c-knowledge__item--info mt-22">
                    <span className="o-flex o-flex-bottom font-18 fw-400">
                        {this.props.article.readTime} min read
                    </span>
                </div>
                <Link
                    onClick={this.handleReload}
                    className="o-d-block link-purple-hover"
                    to={`/${
                        type
                            ? type === 'guide'
                                ? 'guides'
                                : 'articles'
                            : this.props.location &&
                              this.props.location.includes('guides')
                            ? 'guides'
                            : 'articles'
                    }/${this.props.article.slug}`}
                >
                    <h2 className="font-semibold font-family-secondary font-22 margin-reset mt-15 mb-30">
                        {ReactHtmlParser(this.props.article.title)}
                    </h2>
                </Link>

                <Link
                    onClick={this.handleReload}
                    className="c-knowledge__item--read-more o-flex o-flex-middle"
                    to={`/${
                        type
                            ? type === 'guide'
                                ? 'guides'
                                : 'articles'
                            : this.props.location &&
                              this.props.location.includes('guides')
                            ? 'guides'
                            : 'articles'
                    }/${this.props.article.slug}`}
                >
                    Read more
                </Link>
            </div>
        );
    }
}

export default BlogListItem;
