import axios from 'axios';
import config from '../config';

let refreshTokenPromise = null;

axios.defaults.baseURL = `${config.apiGateway.URL}/api`;

// set header token
axios.interceptors.request.use(
    (request) => {
        // Do something before request is sent

        let modifiedRequest = { ...request };
        const token = localStorage.getItem('token');

        if (token) {
            modifiedRequest.headers = {
                ...modifiedRequest.headers,
                xtoken: 'Bearer ' + token,
            };
            localStorage.setItem('tokenRefreshedDate', new Date());
        }

        return modifiedRequest;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// remove token when session is expired
axios.interceptors.response.use(
    (response) => {
        // set token for every reposnse
        return response;
    },
    (err) => {
        const originalRequest = err.config;
        const newAxiosInstance = axios.create();

        if (err.response.status === 401 && !originalRequest._retry) {
            const refreshToken = localStorage.getItem('refreshToken');
            originalRequest._retry = true;

            if (refreshToken) {
                if (!refreshTokenPromise) {
                    // get new token
                    refreshTokenPromise = newAxiosInstance.post(
                        '/token/refresh',
                        { refresh_token: refreshToken }
                    );
                }

                return refreshTokenPromise.then((response) => {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('tokenRefreshedDate', new Date());
                    localStorage.setItem(
                        'refreshToken',
                        response.data.refresh_token
                    );
                    refreshTokenPromise = null;
                    return axios(originalRequest);
                });
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenRefreshedDate');
            }
        }

        return Promise.reject(err);
    }
);

class ApiService {
    static getUser() {
        return axios.get('logged-user');
    }

    static registerUser(data) {
        return axios.post('users', data);
    }

    static confirmUser(data) {
        return axios.post(`users/confirm`, data);
    }

    static login(data) {
        return axios.post('login', data);
    }

    static forgotPassword(data) {
        return axios.post('forgot-password', data);
    }

    static resetPassword(data, token) {
        return axios.put(`reset_passwords/${token}`, data);
    }

    static editProfile(data, userId) {
        return axios.patch(`profiles/${userId}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' },
        });
    }

    static uploadImage(data) {
        return axios.post('/images', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    static userSubsection(data, id) {
        return axios.patch(`/user_subsections/${id}`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static createSubsection(data) {
        return axios.post(`/user_subsections`, data, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    static reorderSubsections(ids) {
        return axios.post(`/user_subsections/reorder`, ids, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    static uploadFile(data) {
        return axios.post('/files', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    static removeFile(id) {
        return axios.delete(`/files/${id}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    static getPracticalinformation(id) {
        return axios.get(`user_practical_informations/${id}`);
    }

    static getWishes(id) {
        return axios.get(`user_wishes/${id}`);
    }

    static infoPages() {
        return axios.get('info_pages');
    }

    static subsectionDelete(data, id) {
        return axios.put(`user_subsections/${id}/inactive`, data);
    }

    static deletedSubsections(id) {
        return axios.get(
            `user_subsections?userSection=/api/user_sections/${id}&active=0`,
            {}
        );
    }

    static updateUserSectionPermissions(sectionId, data) {
        return axios.patch(`user_sections/${sectionId}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        });
    }

    static searchUsers(params) {
        return axios.get('users/search', { params: params });
    }

    static addConnection(data) {
        return axios.post('connections', {
            target: data.target,
            type: parseInt(data.type),
        });
    }

    static invite(data) {
        return axios.post('invitations', data);
    }

    static getRequests() {
        return axios.get('connections/requests');
    }

    static declineRequest(id) {
        return axios.delete(`connections/${id}`);
    }

    static getConnections(id, params) {
        return axios.get(`users/${id}/connections`, { params: params });
    }

    static changeConnectionType(data, id) {
        return axios.patch(`connections/${id}`, data, {
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
        });
    }

    static acceptRequest(data, id) {
        return axios.put(`connections/${id}/accept`, data);
    }

    static getInvite(token) {
        return axios.get(`invitations/${token}`);
    }

    static registerInvitedUser(data) {
        return axios.post('users', data);
    }

    static updateUserPrivacy(userId, data) {
        return axios.patch(`users/${userId}`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static getEmailNotifications(userId) {
        return axios.get(`users/${userId}/notification_settings`);
    }

    static updateEmailNotification(settingId, data) {
        return axios.patch(`notification_settings/${settingId}`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static getNotifications(id) {
        return axios.get(`notifications/${id}`);
    }

    static updateNotification(id, data) {
        return axios.patch(`notifications/${id}`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static usersNotifications(id, params) {
        return axios.get(`users/${id}/notifications`, { params: params });
    }

    static deleteAccount(id, data) {
        return axios.post(`users/${id}/delete-account`, data);
    }

    static changePassword(id, data) {
        return axios.put(`users/${id}/change-password`, data);
    }

    static markAllRead() {
        return axios.get('notifications/mark-all-read');
    }

    static contactUsPage() {
        return axios.get('app_settings/1');
    }

    static contactUs(data) {
        return axios.post('contact_uses', data);
    }

    static getUserBySlug(params) {
        return axios.get('user/show-other', { params: params });
    }

    static getUsersPracticalInformation(id) {
        return axios.get(`users/${id}/user_practical_information`);
    }

    static getUsersWishes(id) {
        return axios.get(`users/${id}/user_wish`);
    }

    static faqCategories() {
        return axios.get('f_a_q_categories');
    }

    static getHelpPageIntro() {
        return axios.get(`help_pages/1`);
    }

    static downloadFile(filename) {
        return axios.get(`download/${filename}`, {
            responseType: 'blob',
        });
    }

    static getFeaturePages() {
        return axios.get('features_pages');
    }

    static getHomepage() {
        return axios.get('homepages/1');
    }

    static getArticles(params) {
        return axios.get('blog_posts', {
            headers: {
                Accept: 'application/ld+json',
            },
            params: params,
        });
    }

    static getGuides(params) {
        return axios.get('blog_guides', {
            headers: {
                Accept: 'application/ld+json',
            },
            params: params,
        });
    }

    static getArticlesCategories() {
        return axios.get('blog_categories');
    }

    static getGuidesCategories() {
        return axios.get('blog_guide_categories');
    }

    static getArticle(id) {
        return axios.get(`blog_posts/${id}`);
    }

    static getGuide(id) {
        return axios.get(`blog_guides/${id}`);
    }

    static getAuthor(slug) {
        return axios.get(`blog_users/${slug}`);
    }

    static getNextArticle(id) {
        return axios.get(`blog_posts/${id}/prev-next`);
    }

    static getNextGuide(id) {
        return axios.get(`blog_guides/${id}/prev-next`);
    }

    static getLatestArticles(params) {
        return axios.get('blog_posts/latest', { params: params });
    }

    static getLatestGuides(params) {
        return axios.get('blog_guides/latest', { params: params });
    }

    static search(params) {
        return axios.get('/searches', { params: params });
    }

    static userLifeEditors() {
        return axios.get('/user_life_story_editors/my_editors');
    }

    static searchLifeStoryEditors(params) {
        return axios.get('/user_life_story_editors/search', { params: params });
    }

    static addLifeStoryEditor(data) {
        return axios.post('/user_life_story_editors', data);
    }

    static reorderLifeStorySubsections(ids) {
        return axios.post(`/user_life_story_sections/reorder`, ids, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    static removeLifeStoryEditor(id) {
        return axios.delete(`/user_life_story_editors/${id}`);
    }

    static uploadAudio(data) {
        return axios.post('audio', data);
    }

    static uploadVideo(data) {
        return axios.post('videos', data);
    }

    static LifeStorySectionUpdate(data) {
        return axios.post('/user_life_story_sections', data);
    }

    static deleteLifeStorySection(id) {
        return axios.delete(`/user_life_story_sections/${id}`);
    }

    static lifeStoryPatch(id, data) {
        return axios.patch(`/user_life_stories/${id}`, data, {
            headers: {
                'content-type': 'application/merge-patch+json',
            },
        });
    }

    static editLifeStorySection(id, data) {
        return axios.patch(`user_life_story_sections/${id}`, data, {
            headers: {
                'content-type': 'application/merge-patch+json',
            },
        });
    }

    static showLifeStoryBySlug(params) {
        return axios.get('user_life_stories/show-other', { params: params });
    }

    static slugUpdate(id, data) {
        return axios.patch(`profiles/${id}/slug-update`, data, {
            headers: {
                'content-type': 'application/merge-patch+json',
            },
        });
    }

    static exportPracticalInformation(id) {
        return axios.get(`user_practical_informations/${id}/pdf-export`, {
            responseType: 'blob',
        });
    }

    static exportWishes(id) {
        return axios.get(`user_wishes/${id}/pdf-export`, {
            responseType: 'blob',
        });
    }

    static stepChange(id, step) {
        return axios.patch(
            `/profiles/${id}/step-update`,
            { step: step },
            {
                headers: {
                    'content-type': 'application/merge-patch+json',
                },
            }
        );
    }

    static aboutPage() {
        return axios.get('abouts/1');
    }

    static resendVerificationEmail(params) {
        return axios.get('user_confirmation/resend', { params: params });
    }

    static getWelcome() {
        return axios.get('/welcomes/1');
    }

    static getPlatforms() {
        return axios.get('/platforms/1');
    }

    static sendScheduledMessage(data) {
        return axios.post('schedule_messages', data);
    }

    static getScheduledThreads(params) {
        return axios.get('schedule_messages/messages', { params: params });
    }

    static deleteScheduledMessage(id) {
        return axios.delete(`schedule_messages/${id}`);
    }

    static editScheduledMessage(id, data) {
        return axios.patch(`schedule_messages/${id}`, data, {
            headers: {
                'content-type': 'application/merge-patch+json',
            },
        });
    }

    static getThreads() {
        return axios.get('threads/list');
    }

    static getThreadMessages(id) {
        return axios.get(`threads/${id}`);
    }

    static sendMessage(data) {
        return axios.post('messages/create', data, {
            headers: {
                'content-type': 'application/json',
            },
        });
    }

    static replyMessage(data, id) {
        return axios.patch(`threads/${id}/reply`, data, {
            headers: {
                'content-type': 'application/merge-patch+json',
            },
        });
    }

    static getMessageConnections(params) {
        return axios.get(`users/participant-autosuggest`, { params: params });
    }

    static readThread(id) {
        return axios.post(`threads/${id}/read`, {
            data: {
                isRead: true,
            },
        });
    }

    static sectionsNotify(id) {
        return axios.post(`user_subsections/${id}/notify`, {});
    }

    static lifeStoryPublishNotify(id) {
        return axios.post(`user_life_stories/${id}/notify`, {});
    }

    static lifeStoryUpdateNotify(id) {
        return axios.post(`user_life_story_sections/${id}/notify`, {});
    }

    static tooltipClose(id) {
        return axios.post(`profiles/${id}/connection-tooltip`, {});
    }

    static howItWorksAddConnections() {
        return axios.get('how_it_work_add_connections/1');
    }

    static howItWorksLifeStory() {
        return axios.get('how_it_work_life_stories/1');
    }

    static howItWorksMessage() {
        return axios.get('how_it_work_messages/1');
    }

    static howItWorksMyConnections() {
        return axios.get('how_it_work_my_connections/1');
    }

    static howItWorksPracticalInformation() {
        return axios.get('how_it_work_pi/1');
    }

    static howItWorksWishes() {
        return axios.get('how_it_work_wishes/1');
    }

    static reorderLifeStoryImages(ids) {
        return axios.post('/images/reorder', ids, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    static imageCaptionUpdate(id, data) {
        return axios.patch(`images/${id}`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static imageDelete(id) {
        return axios.delete(`/images/${id}`);
    }

    static getPartnerships() {
        return axios.get('partnerships/1');
    }

    static getTestimonialsSliders() {
        return axios.get('testimonial_pages/1/testimonial_sliders');
    }

    static getTestimonials(params) {
        return axios.get('testimonial_pages/1/testimonials', {
            params: params,
        });
    }

    static getTeam(params) {
        return axios.get('teams', { params: params });
    }

    static getPressFooter() {
        return axios.get('press_pages/1/footers');
    }

    static getPressSliders() {
        return axios.get('press_pages/1/sliders');
    }

    static getPressArticles(params) {
        return axios.get('press_pages/1/presses', { params: params });
    }

    static deceasedStatusChange(data) {
        return axios.post('profile_deceaseds', data);
    }

    static removeDeceasedStatus(data, id) {
        return axios.post(`profile_deceaseds/${id}/cancel`, data);
    }

    static onBoardingStepOne(data, id) {
        return axios.patch(`profiles/${id}/step-1`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static onBoardingTwoFA(id, data) {
        return axios.patch(`profiles/${id}/two-fa`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static sendTwoFaCode(id, data) {
        return axios.patch(`two_f_as/${id}`, data, {
            headers: { 'Content-Type': 'application/merge-patch+json' },
        });
    }

    static confirmCode(id, data) {
        return axios.post(`two_f_as/${id}/confirm`, data);
    }

    static resendCode(id) {
        return axios.get(`two_f_as/${id}/resend`);
    }

    static crowdCubesForm(data) {
        return axios.post('crowdcubes', data);
    }

    static crowdPage() {
        return axios.get('wp-crowdcube');
    }

    static getNotificationsCount(id) {
        return axios.get(`users/${id}/notifications-count`);
    }

    static getWPLanding() {
        return axios.get('wp-landing');
    }

    static getPlan(data) {
        return axios.post('plans', data);
    }

    static getFuneralPlans(slug) {
        return axios.get(`funeral_plans/${slug}`);
    }

    static getBanners(slug) {
        return axios.get(`funeral_plan_banners?_page=1&plans.slug=${slug}`);
    }

    static getBannersOtherPages(page) {
        return axios.get(`funeral_plan_banners`);
    }

    static getLocations(params) {
        return axios.get(`funeral_plans`, { params: params });
    }

    static funeralPlans(data) {
        return axios.post('funeral_plan_contacts', data, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    static funeralPlanOptions(plan) {
        return axios.get(`funeral_plan_options?plans.slug=${plan}`);
    }

    static emailCheck(data) {
        return axios.post('users/email-check', data);
    }

    static createFuneralPlan(data) {
        return axios.post('user_funeral_plans', data);
    }

    static patchFuneralPlan(data, id) {
        return axios.patch(`user_funeral_plans/${id}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' },
        });
    }

    static registserOnFuneralPlan(data) {
        return axios.post('user_funeral_plans/register', data);
    }

    static getFuneralPlanAddons(id) {
        return axios.get(`funeral_plan_options/${id}/categorized-add-ons`);
    }

    static addAddon(data) {
        return axios.post('user_funeral_plan_add_ons', data);
    }

    static removeAddon(id) {
        return axios.delete(`user_funeral_plan_add_ons/${id}`);
    }

    static changeAmount(id, data) {
        return axios.patch(`user_funeral_plan_add_ons/${id}`, data, {
            headers: { 'Content-type': 'application/merge-patch+json' },
        });
    }

    static getUserFuneralPlan(id) {
        return axios.get(`user_funeral_plans/${id}`);
    }

    static setOption(data, id) {
        return axios.post(`user_funeral_plans/${id}/options`, data, {
            headers: { 'Content-Type': 'application/json' },
        });
    }

    static addCustomAddon(data, id) {
        return axios.post(`user_funeral_plans/${id}/addons`, data);
    }

    static funeralPlanOptionsFiltered(params) {
        return axios.get(`funeral_plan_options`, { params: params });
    }

    static getTotalPrice(id) {
        return axios.get(`user_funeral_plans/${id}/total`);
    }

    static getAddress(postCode) {
        return axios.get(`get-address/${postCode}`);
    }

    static funeralPlanStepUpdate(id, data) {
        return axios.post(`user_funeral_plans/${id}/step`, data);
    }

    static setPersonal(id, data) {
        return axios.post(`user_funeral_plans/${id}/personal`, data);
    }

    static deleteFuneralPlanPayment(id) {
        return axios.delete(`user_funeral_plans/${id}`);
    }

    static getFuneralPlanOption(id) {
        return axios.get(`funeral_plan_options/${id}`);
    }

    static createPaymentIntent(id) {
        return axios.get(`user_funeral_plans/${id}/checkout`);
    }

    static getOrderHistory() {
        return axios.get('order_carts');
    }

    static setInstalment(id, data) {
        return axios.post(`user_funeral_plans/${id}/instalment`, data);
    }

    static getOrderItem(id) {
        return axios.get(`order_carts/${id}`);
    }

    static getInfoPages(slug) {
        return axios.get(`info_pages/${slug}`);
    }

    static scheduleCall(data) {
        return axios.post('schedule_calls', data);
    }

    static getCategories() {
        return axios.get('funeral_plan_categories');
    }

    static decideLater(id) {
        return axios.get(`user_funeral_plans/${id}/add-on-decide-later`);
    }

    static setDeclaration(data, id) {
        return axios.post(`user_funeral_plans/${id}/declaration`, data);
    }

    static stripePay(data) {
        return axios.post('recurring_payments', data);
    }

    static funeralPlanIncludedItems(params) {
        return axios.get(`funeral_plan_included_items`, { params: params });
    }
    static getHomeTestimonials() {
        return axios.get('funeral_plan_testimonials?promoted=true&_page=1');
    }

    static auraCircle() {
        return axios.get('aura_circles');
    }

    static auraAngels() {
        return axios.get('aura_angels/aura-angel');
    }

    static auraCircleHeader() {
        return axios.get('aura_circle_headers');
    }

    static getFuneralPlanExtraAtNeed() {
        return axios.get('funeral_plan_extra_categories/at-need?_page=1');
    }

    static simpleRegister(data) {
        return axios.post('users/simple-register', data);
    }

    static requestBrochure(data) {
        return axios.post('request_brochures', data);
    }

    static postCodeLookup(postcode) {
        return axios.get(`postcode/${postcode}`);
    }

    static getFuneralPlanComparasions() {
        return axios.get('funeral_plan_comparasions?_page=1');
    }

    static getUncompletedPlans(userId) {
        return axios.get(`users/${userId}/uncompleted-plans`);
    }
}

export default ApiService;
