import React, { Component } from 'react';

import SingleItem from './SingleItem/SingleItem';
import ReactHtmlParser from 'react-html-parser';
export default class HowItWorks extends Component {
    render() {
        let items;

        if (this.props.content.length) {
            items = this.props.content.map((item, index) => {
                return (
                    <SingleItem
                        key={`how-it-works-single-${index}`}
                        leftSide={index % 2 === 0 ? true : false}
                        item={item}
                        number={index + 1}
                    />
                );
            });
        }

        return this.props.content.length ? (
            <div className="c-how-it-works-funeral">
                <h2>
                    {ReactHtmlParser(this.props.title ? this.props.title : '')}
                </h2>
                <div className="container">{items}</div>
            </div>
        ) : null;
    }
}
