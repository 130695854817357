import 'react-image-crop/lib/ReactCrop.scss';

import React, { Component } from 'react';

import ApiService from '../../../service/api.service';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import loader from '../../../assets/images/loader.svg';
import { withRouter } from 'react-router-dom';
class ImageUpload extends Component {
    state = {
        src: null,
        crop: {
            unit: '%',
            width: 30,
            aspect: 1 / 1,
        },
        file: null,
        saveInProgress: false,
    };

    componentDidMount() {
        if (this.props.isCover) {
            this.setState({
                crop: {
                    unit: '%',
                    width: 100,
                    aspect: 2.88 / 1,
                },
            });
        }
    }

    onSelectFile = (e, bool) => {
        if (bool) {
            if (e[0]) {
                this.setState({
                    file: e[0],
                });
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    this.setState({ src: reader.result })
                );
                reader.readAsDataURL(e[0]);
            }
        } else {
            if (e.target.files && e.target.files.length > 0) {
                this.setState({
                    file: e.target.files[0],
                });
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    this.setState({ src: reader.result })
                );
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    };

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    handleImageUpload = () => {
        this.setState({ saveInProgress: true }, () => {
            const { crop, file } = this.state;
            const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
            const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('crop[x]', parseInt(crop.x * scaleX));
            formData.append('crop[y]', parseInt(crop.y * scaleY));
            formData.append('crop[width]', parseInt(crop.width * scaleX));
            formData.append('crop[height]', parseInt(crop.height * scaleY));
            if (this.props.isCover) {
                formData.append('namespace', 'profile-cover-photo');
                formData.append('slug', this.props.match.params.slug);
            }
            ApiService.uploadImage(formData)
                .then((r) => {
                    this.setState({ saveInProgress: false }, () => {
                        this.props.handleImageChange(r.data);
                        if (this.props.close) {
                            this.props.close();
                        }
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        });
    };

    simulateClick(e) {
        e.click();
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;
        return (
            <div className="c-popup text-center">
                <div className="c-popup__header">
                    <h2 className="font-family-secondary font-26 font-333  font-semibold text-center">
                        Select and Crop Photo
                    </h2>
                </div>
                <div className="c-image-upload-popup">
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            this.onSelectFile(acceptedFiles, true);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section
                                className={`c-image-upload-popup__input border-radius-3 overflow-both-hidden margin-auto cursor-pointer position-relative ${
                                    src
                                        ? 'c-image-upload-popup__input--image-uploaded'
                                        : null
                                }`}
                            >
                                <div
                                    className="c-image-upload-popup__input--custom"
                                    {...getRootProps()}
                                >
                                    {src ? null : (
                                        <div>
                                            <input
                                                {...getInputProps()}
                                                type="file"
                                                accept="image/*"
                                                onChange={this.onSelectFile}
                                            />
                                            <p className="c-image-upload-popup__input--text">
                                                Click here to select a picture,
                                                or drag ‘n’ drop a picture into
                                                this box.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    {src && (
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    )}
                    {croppedImageUrl && (
                        <img
                            alt="Crop"
                            style={{ maxWidth: '100%' }}
                            src={croppedImageUrl}
                        />
                    )}
                    <div className="o-flex o-flex-middle o-flex-center  o-flex-column-mobile c-image-upload-popup__action">
                        {this.props.closeLink}
                        {this.props.uploadButton}
                        <button
                            className={`btn btn--full btn-loader ${
                                this.state.saveInProgress
                                    ? 'save-in-progress'
                                    : ''
                            } position-relative overflow-both-hidden z-index-1`}
                            onClick={this.handleImageUpload}
                        >
                            Save Photo{' '}
                            <img
                                width="100%"
                                height="100%"
                                src={loader}
                                alt="loaderImage"
                            />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ImageUpload);
