import React, { Component } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactHtmlParser from 'react-html-parser';

class Trustpilot extends Component {
    constructor(props) {
        super(props);
        this.trustBoxRef = React.createRef();
    }
    componentDidMount() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =
            'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'; // Use HTTPS
        script.async = true;

        document.head.appendChild(script);

        script.onload = () => {
            // Check if Trustpilot is available in the window object
            if (window.Trustpilot && this.props.code) {
                window.Trustpilot.loadFromElement(
                    this.trustBoxRef.current,
                    true
                );
            }
        };
    }

    componentWillUnmount() {
        const script = document.querySelector(
            'script[src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"]'
        );
        if (script) {
            document.head.removeChild(script);
        }
    }

    render() {
        const trustpilot = this.props.code;

        return (
            <div className="container">
                <div className="c-plans__trustpilot">
                    <div>
                        {this.props.title && <h2> {this.props.title} </h2>}
                        {this.props.body && (
                            <p className="c-plans__trustpilot-wrapper-desc">
                                {ReactHtmlParser(this.props.body)}
                            </p>
                        )}
                        <div
                            ref={this.trustBoxRef}
                            className="trustpilot-widget"
                            data-businessunit-id={trustpilot.businessID}
                            data-locale={trustpilot.localeLang}
                            data-review-languages={trustpilot.reviewLang}
                            data-reviewnumber={trustpilot.reviewNumb}
                            data-stars={trustpilot.dataStars}
                            data-style-height={trustpilot.styleHeight}
                            data-style-width={trustpilot.styleWidth}
                            data-tags={trustpilot.dataTags}
                            data-template-id={trustpilot.templateID}
                            data-theme={trustpilot.theme}
                        ></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Trustpilot;

