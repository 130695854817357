import React, { Component } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactHtmlParser from 'react-html-parser';
import Slider from 'react-slick';
import TestimonialSingleSlide from './TestimonialSingleSlide/TestimonialSingleSlide';
export default class Testimonials extends Component {
    state = {
        prevButton: 0,
        nextButton: 0,
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.testimonials !== this.props.testimonials) {
            this.setState({
                prevButton: this.props.testimonials.length - 1,
                nextButton: 1,
            });
        }
    }

    handleSlickChange = (slick) => {
        this.setState({
            nextButton:
                slick === this.props.testimonials.length - 1 ? 0 : slick + 1,
            prevButton: slick - 1,
        });
        if (slick === this.props.testimonials.length || slick === 0) {
            this.setState({
                nextButton: 1,
                prevButton: this.props.testimonials.length - 1,
            });
        }
    };

    render() {
        const settings = {
            arrows: true,
            autoplay: false,
            dots: false,
            speed: 350,
            touchThreshold: 40,
            slidesToScroll: 1,
            infinite: true,
            slidesToShow: 1,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        arrows: false,
                    },
                },
            ],

            afterChange: (current) => this.handleSlickChange(current),
        };
        let slides;

        if (this.props.testimonials.length) {
            slides = this.props.testimonials.map((testimonial, index) => {
                return (
                    <TestimonialSingleSlide
                        key={index}
                        testimonial={testimonial}
                    />
                );
            });
        }

        return this.props.testimonials.length ? (
            window.location.href.includes('funeral-at-need') ? (
                <div className="c-plans__testimonials">
                    <h2>
                        {ReactHtmlParser(
                            this.props.title ? this.props.title : ''
                        )}
                    </h2>
                    <p>
                        {ReactHtmlParser(
                            this.props.body ? this.props.body : ''
                        )}
                    </p>
                    <div className="container o-flex o-flex-center">
                        {/* <div
                            className="next-slide c-plans__testimonials--testimonials-img"
                            style={{
                                backgroundImage: `url(${
                                    this.props.testimonials[
                                        this.state.prevButton
                                    ]?.arrowImage
                                })`,
                            }}
                        >
                            <div className="c-plans__testimonials--img-overlay"></div>
                        </div> */}
                        <div>
                            <Slider
                                {...settings}
                                initialSlide={0}
                                className="c-faq"
                                onChange={(slick) =>
                                    this.handleSlickChange(slick)
                                }
                            >
                                {slides}
                            </Slider>
                        </div>
                        {/* <div
                            className="next-slide c-plans__testimonials--testimonials-img"
                            style={{
                                backgroundImage: `url(${
                                    this.props.testimonials[
                                        this.state.nextButton
                                    ]?.arrowImage
                                })`,
                            }}
                        >
                            <div className="c-plans__testimonials--img-overlay"></div>
                        </div> */}
                    </div>
                </div>
            ) : (
                <div className="c-plans__testimonials">
                    <h2>
                        {ReactHtmlParser(
                            this.props.title ? this.props.title : ''
                        )}
                    </h2>
                    <p>
                        {ReactHtmlParser(
                            this.props.body ? this.props.body : ''
                        )}
                    </p>
                    <div className="container o-flex o-flex-center">
                        <div
                            className="next-slide c-plans__testimonials--testimonials-img"
                            style={{
                                backgroundImage: `url(${
                                    this.props.testimonials[
                                        this.state.prevButton
                                    ]?.arrowImage
                                })`,
                            }}
                        >
                            <div className="c-plans__testimonials--img-overlay"></div>
                        </div>
                        <div>
                            <Slider
                                {...settings}
                                initialSlide={0}
                                className="c-faq"
                                onChange={(slick) =>
                                    this.handleSlickChange(slick)
                                }
                            >
                                {slides}
                            </Slider>
                        </div>
                        <div
                            className="next-slide c-plans__testimonials--testimonials-img"
                            style={{
                                backgroundImage: `url(${
                                    this.props.testimonials[
                                        this.state.nextButton
                                    ]?.arrowImage
                                })`,
                            }}
                        >
                            <div className="c-plans__testimonials--img-overlay"></div>
                        </div>
                    </div>
                </div>
            )
        ) : null;
    }
}
