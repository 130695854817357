import React, { Component } from 'react';
import SliderAddon from './SliderAddon/SliderAddon';
import ReactHtmlParser from 'react-html-parser';
export default class SliderCategory extends Component {
    render() {
        let addons;

        if (this.props.categorie.addOns) {
            addons = this.props.categorie.addOns.map((addon) => {
                return <SliderAddon key={`addon-${addon.id}`} addon={addon} />;
            });
        }

        return (
            <div>
                <h4 style={{ textAlign: 'center' }}>
                    {this.props.categorie.title}
                </h4>
                <h5>
                    {ReactHtmlParser(
                        this.props.categorie.shortDescription
                            ? this.props.categorie.shortDescription
                            : ''
                    )}
                </h5>
                <div className="c-funeral__add-ons__slider--slide-card">
                    {addons}
                </div>
            </div>
        );
    }
}
