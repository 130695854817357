import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import SingleItem from './SingleItem/SingleItem';
import Popup from 'reactjs-popup';
import logoAtNeedPopup from '../../../assets/images/logo-revert/requestMoreInfoSvg.svg';
import logoPlans from '../../../assets/images/logo-without-slogan.svg';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import RequiredInput from '../../UI/Input/RequiredInput/RequiredInput';
import RegularInput from '../../UI/Input/RegularInput/RegularInput';
import Button from '../../UI/Button/Button';
import loader from '../../../assets/images/loader.svg';
import phoneIcon from '../../../assets/images/aura2022/icons/call-us-icon.png';
import thankYou from '../../../assets/images/register-thanks.png';
import ApiService from '../../../service/api.service';

export default class HowItWorks extends Component {
    constructor() {
        super();

        this.state = {
            modalIsOpen: false,
            buttonLoading: false,
            formSubmited: false,
        };
    }

    handleRequestbrochure = (e) => {
        this.setState({
            modalIsOpen: true,
        });
    };

    handleFormSubmit = (values) => {
        this.setState(
            {
                buttonLoading: true,
            },
            () => {
                let urlParts = window.location.href.split('/');
                let data = {
                    email: values.email,
                    phone: values.phone,
                    name: values.name,
                    page: urlParts.at(-1),
                };

                ApiService.requestBrochure(data)
                    .then((r) => {
                        this.setState({
                            buttonLoading: false,
                            formSubmited: true,
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            buttonLoading: false,
                        });
                    });
            }
        );
    };

    render() {
        let items;

        if (!window.location.href.includes('funeral-at-need')) {
            const script = document.createElement('script');
            script.src = '//js.hsforms.net/forms/embed/v2.js';
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                // @TS-ignore
                if (window.hbspt) {
                    // @TS-ignore
                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: '20309021',
                        formId: 'f8333296-39ff-47e6-b975-4ef812a12e1a',
                        target: '#hubspotForm',
                    });
                }
            });
        }

        if (this.props.content.length) {
            items = this.props.content.map((item, index) => {
                return (
                    <SingleItem
                        key={`how-it-works-single-${index}`}
                        leftSide={index % 2 === 0 ? true : false}
                        item={item}
                    />
                );
            });
        }

        return this.props.content.length ? (
            <>
                <Popup
                    lockScroll
                    className="request-info-modal-popup"
                    modal
                    open={this.state.modalIsOpen}
                    onClose={() => this.setState({ modalIsOpen: false })}
                >
                    {(close) => (
                        <>
                            {!this.state.formSubmited ? (
                                <div className="request-info-modal">
                                    <div className="request-info-modal__logo-text">
                                        {window.location.href.includes(
                                            'funeral-at-need'
                                        ) && <h2>Aura Digital Brochure</h2>}
                                        <img
                                            src={
                                                window.location.href.includes(
                                                    'funeral-at-need'
                                                )
                                                    ? logoAtNeedPopup
                                                    : logoPlans
                                            }
                                            alt="logo"
                                        ></img>
                                        {window.location.href.includes(
                                            '/funeral-plans'
                                        ) && (
                                            <h2>Aura Funeral Plans Brochure</h2>
                                        )}

                                        {window.location.href.includes(
                                            '/funeral-plans'
                                        ) ? null : (
                                            <p>
                                                Please let us know your contact
                                                details below and we'll send a
                                                digital copy of our Aura
                                                Funerals brochure over to you
                                                straight away via email. If you
                                                can't find it - please check
                                                your promotions or spam and let
                                                us know if you have any issues.
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <div id="hubspotForm"></div>
                                    </div>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            email: this.props.user
                                                ? this.props.user.email
                                                : '',
                                            name: this.props.user
                                                ? this.props.user.name
                                                : '',
                                            phone:
                                                this.props.user &&
                                                this.props.user.profile &&
                                                this.props.user.profile.mobile
                                                    ? this.props.user.profile
                                                          .mobile
                                                    : '',

                                            user_full_name: '',
                                            address: this.props.user
                                                ? this.props.user.address
                                                : '',
                                            postcode: this.props.user
                                                ? this.props.user.postcode
                                                : '',
                                        }}
                                        validationSchema={
                                            this.props.type ===
                                            'funeral-at-need'
                                                ? Yup.object({
                                                      email: Yup.string()
                                                          .email(
                                                              'Please enter a valid E-mail Address'
                                                          )
                                                          .required(
                                                              'This field is required.'
                                                          ),
                                                      name: Yup.string().required(
                                                          'This field is required.'
                                                      ),
                                                      phone: Yup.string(),
                                                  })
                                                : Yup.object({
                                                      email: Yup.string()
                                                          .email(
                                                              'Please enter a valid E-mail Address'
                                                          )
                                                          .required(
                                                              'This field is required.'
                                                          ),
                                                      name: Yup.string().required(
                                                          'This field is required.'
                                                      ),

                                                      phone: Yup.string(),
                                                  })
                                        }
                                        onSubmit={(values) => {
                                            this.handleFormSubmit(values);
                                        }}
                                    >
                                        {(props) => (
                                            <>
                                                {window.location.href.includes(
                                                    'funeral-at-need'
                                                ) && (
                                                    <div className="c-form request-info-modal__form">
                                                        <Form
                                                            noValidate
                                                            className={`o-flex o-flex-column c-contact-form request-info-modal__form-content ${window.location.href
                                                                .split('/')
                                                                .at(-1)}`}
                                                            id={window.location.href
                                                                .split('/')
                                                                .at(-1)}
                                                        >
                                                            <div
                                                                tabIndex="-1"
                                                                className={`required-indicator ${
                                                                    props.errors &&
                                                                    props.touched &&
                                                                    props
                                                                        .touched
                                                                        .email &&
                                                                    props.errors
                                                                        .email
                                                                        ? 'invalid'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <RequiredInput
                                                                    className="form-item--border-gray-light request-info-modal__form-input"
                                                                    something={
                                                                        props
                                                                    }
                                                                    label="Email Address"
                                                                    page="contact"
                                                                    name="email"
                                                                    type="email"
                                                                />
                                                            </div>
                                                            <div
                                                                tabIndex="-1"
                                                                className={`required-indicator ${
                                                                    props.errors &&
                                                                    props.touched &&
                                                                    props
                                                                        .touched
                                                                        .name &&
                                                                    props.errors
                                                                        .name
                                                                        ? 'invalid'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <RequiredInput
                                                                    className="form-item--border-gray-light request-info-modal__form-input"
                                                                    label="Name"
                                                                    name="name"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <div
                                                                tabIndex="-1"
                                                                className={`${
                                                                    props.errors &&
                                                                    props.touched &&
                                                                    props
                                                                        .touched
                                                                        .phone &&
                                                                    props.errors
                                                                        .phone
                                                                        ? 'invalid'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <RegularInput
                                                                    className="form-item--border-gray-light request-info-modal__form-input"
                                                                    label="Phone Number"
                                                                    name="phone"
                                                                    type="text"
                                                                />
                                                            </div>
                                                            {/* <div className="hide"><Input className="form-item--border-gray-light" label="User Full Name"  name="user_full_name" type="text"/></div> */}
                                                            <Button
                                                                disabled={
                                                                    props.values
                                                                        .email ===
                                                                        '' ||
                                                                    (props.errors &&
                                                                        props
                                                                            .errors
                                                                            .email) ||
                                                                    props.values
                                                                        .name ===
                                                                        ''
                                                                }
                                                                className={`request-info-modal__btn btn btn--full btn-loader btn--size-57 ${
                                                                    this.state
                                                                        .buttonLoading
                                                                        ? 'save-in-progress'
                                                                        : ''
                                                                }`}
                                                                type="submit"
                                                            >
                                                                Submit
                                                                <img
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={loader}
                                                                    alt="loaderImage"
                                                                />
                                                            </Button>
                                                        </Form>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Formik>
                                    <div className="request-info-modal__bottom">
                                        {window.location.href.includes(
                                            'funeral-at-need'
                                        ) && (
                                            <p className="max-width-full">
                                                If you can't find your digital
                                                brochure email in your inbox,
                                                please check your promotions or
                                                spam and let us know if you have
                                                any issues.
                                            </p>
                                        )}
                                        <p className="max-width-full">
                                            If you have any questions and would
                                            like to speak to one of our team,
                                            you can call:
                                        </p>
                                        <img src={phoneIcon} alt="phone-icon" />
                                        <a
                                            className="c-pre-header__phone"
                                            href="tel:+448008085723"
                                        >
                                            0800 808 5723
                                        </a>
                                        <p>Monday - Friday 9am-5pm.</p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="c-crowdfunding__form-overlay c-landing__thank-you-modal o-flex o-flex-column o-flex-center o-flex-middle text-center">
                                        {/* <img className="c-landing__thank-you-modal--logo" src={logo} alt="logo"></img> */}
                                        <h4 className="font-family-secondary">
                                            Thank you for submitting your
                                            contact details.
                                        </h4>
                                        <img
                                            className="c-landing__thank-you-modal--mail-image"
                                            src={thankYou}
                                            alt="thankYou"
                                        />
                                        <p className="o-d-block">
                                            Someone will get in touch with you
                                            soon.
                                        </p>
                                    </div>
                                </>
                            )}
                            <button
                                onClick={close}
                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                            >
                                <i className="icon-times font-18 cursor-pointer"></i>
                            </button>
                        </>
                    )}
                </Popup>
                <div className="container c-plans__how-it-works">
                    <h2>{this.props.title} </h2>
                    <p className="text-center">
                        {ReactHtmlParser(this.props.description)}{' '}
                    </p>
                    <div className="c-plans__how-it-works--items">{items}</div>
                    <div className="text-center c-plans__why-aura-ctas">
                        {this.props.btn1Link ? (
                            <a
                                className=" c-plans__why-aura-request-btn o-d-inline-block btn  btn--transparent-orange btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                href={
                                    !this.props.btn1Link
                                        ? 'tel:+448008085723'
                                        : this.props.btn1Link &&
                                          !this.props.btn1Link.includes('tel')
                                        ? this.props.btn1Link
                                        : this.props.btn1Link.includes('tel')
                                        ? `tel:${this.props.btn1Link.replace(
                                              'http://tel:',
                                              ''
                                          )}`
                                        : ''
                                }
                            >
                                {this.props.btn1Title}
                            </a>
                        ) : (
                            <button
                                className="c-plans__why-aura-request-btn o-d-inline-block btn  btn--transparent-orange btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                onClick={() => this.handleRequestbrochure()}
                            >
                                {this.props.btn1Title}
                            </button>
                        )}
                        {this.props.btn2Title && (
                            <a
                                className=" c-plans__why-aura-planning-btn o-d-inline-block btn btn--full btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                href={
                                    !this.props.btn2Link
                                        ? 'tel:+448008085723'
                                        : this.props.btn2Link &&
                                          !this.props.btn2Link.includes('tel')
                                        ? this.props.btn2Link
                                        : this.props.btn2Link.includes('tel')
                                        ? `tel:${this.props.btn2Link.replace(
                                              'http://tel:',
                                              ''
                                          )}`
                                        : ''
                                }
                            >
                                {this.props.btn2Title}
                            </a>
                        )}
                    </div>
                </div>
            </>
        ) : null;
    }
}
