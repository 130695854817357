import React, { useState } from 'react';

import classes from './Input.module.scss';
import { useField } from 'formik';

const Input = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [focused, setFocused] = useState('');

    const onFocus = () => {
        setFocused('focused');
    };

    const onBlur = () => {
        setFocused('');
        if (field.value === '') {
            setFocused(' invalid');
        } else {
            setFocused('');
        }
    };

    let returnValue;

    returnValue = (
        <div
            className={`form-item-wrap position-relative ${
                meta.error && meta.touched ? 'invalid' : ''
            } ${focused}`}
        >
            <div onBlur={onBlur} tabIndex="-1">
                {label ? (
                    <label
                        className="o-flex o-flex-middle"
                        htmlFor={props.id || props.name}
                    >
                        {label}
                        <span>*</span>
                    </label>
                ) : null}
                <input
                    form="novalidatedform"
                    onFocus={onFocus}
                    className={classes.Input}
                    {...field}
                    {...props}
                ></input>
            </div>
            {meta.touched && meta.error ? (
                <div className="required position-absolute font-13 font-semibold font-form-error">
                    {meta.error}
                </div>
            ) : null}
        </div>
    );

    return returnValue;
};

export default Input;
