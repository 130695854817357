import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
// import CookieBot from 'react-cookiebot';
import ReactDOM from 'react-dom';
import connectionsReducer from './store/reducers/connectionReducer';
import lifeStoryReducer from './store/reducers/lifeStoryReducer';
import messagesReducer from './store/reducers/messagesReducer';
import notificationsReducer from './store/reducers/notificationReducer';
import onboardingReducer from './store/reducers/onboardingReducer';
import searchReducer from './store/reducers/searchReducer';
import thunk from 'redux-thunk';
import usersReducer from './store/reducers/usersReducer';
import funeralPaymentReducer from './store/reducers/funeralPaymentReducer';
import routerReducer from './store/reducers/routerReducer';
import { BrowserRouter } from 'react-router-dom';

const rootReducer = combineReducers({
    usersReducer: usersReducer,
    connectionsReducer: connectionsReducer,
    notificationsReducer: notificationsReducer,
    searchReducer: searchReducer,
    lifeStoryReducer: lifeStoryReducer,
    messagesReducer: messagesReducer,
    onboardingReducer: onboardingReducer,
    funeralPaymentReducer: funeralPaymentReducer,
    routerReducer: routerReducer,
});

// const cookieBotDomainGroupId = '4b18dbf1-b436-49f9-819d-df17e1b5c0cc';

const logger = (store) => {
    return (next) => {
        return (action) => {
            const result = next(action);
            return result;
        };
    };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(logger, thunk))
);

ReactDOM.render(
    <>
        <Provider store={store}>
            {/* <CookieBot domainGroupId={cookieBotDomainGroupId} /> */}
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </>,
    document.getElementById('root')
);
