import * as actionTypes from '../actions/connectionActions';

const initialState = {
    connections: [],
    searchedConnections: [],
    requests: [],
    connectionCount: 0,
};

const connectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONNECTIONS:
            const connections = { ...state, connections: action.connections };
            return connections;

        case actionTypes.GET_REQUESTS:
            const requests = { ...state, requests: action.requests };
            return requests;

        case actionTypes.ACCEPT_REQUEST:
            // remove connected person from requests
            let requestsArr = [
                ...state.requests.filter(
                    (request) =>
                        request.source.id !== action.user_connection.target.id
                ),
            ];
            // add connected person to connections
            let connectionsArr = [...state.connections];
            connectionsArr.push(action.user_connection);

            return {
                ...state,
                connections: connectionsArr,
                requests: requestsArr,
            };
        case actionTypes.DECLINE_REQUEST:
            //decline request and remove it from requests array
            let declinedArr = [
                ...state.requests.filter(
                    (request) => request.id !== action.connId
                ),
            ];
            return { ...state, requests: declinedArr };

        case actionTypes.SEARCH_CONNECTIONS:
            return {
                ...state,
                searchedConnections: action.searchedConnections,
            };

        case actionTypes.SEARCH_CONNECTION_PAGINATION:
            const updatedSearchedConnections = [
                ...state.searchedConnections,
                ...action.pagedConnections,
            ];
            return {
                ...state,
                searchedConnections: updatedSearchedConnections,
            };

        case actionTypes.ADD_CONNECTION:
            const conn = [...state.searchedConnections];
            const user = conn.find((sc) => sc.id === action.userId);
            const updatedProfile = {
                ...user.profile,
                connection: action.connection,
            };
            const updatedUser = { ...user, profile: updatedProfile };
            const connectionIndex = conn.findIndex(
                (c) => c.id === action.userId
            );
            conn[connectionIndex] = updatedUser;

            return {
                ...state,
                searchedConnections: conn,
            };

        case actionTypes.REMOVE_CONNECTION:
            const updatedConnections = state.connections.filter(
                (c) => c.id !== action.userId
            );
            const conne = [...state.searchedConnections];
            let user1;
            let updatedProfile1;
            let connectionIndex1;
            let updatedUser1;
            if (conne.length) {
                user1 = conne.find(
                    (sc) =>
                        sc.profile.connection &&
                        sc.profile.connection.id === action.userId
                );
                updatedProfile1 = { ...user1.profile, connection: null };
                updatedUser1 = { ...user1, profile: updatedProfile1 };
                connectionIndex1 = conne.findIndex(
                    (c) =>
                        c.profile.connection &&
                        c.profile.connection.id === action.userId
                );
                conne[connectionIndex1] = updatedUser1;
            }
            return {
                ...state,
                connections: updatedConnections,
                searchedConnections: conne,
            };

        case actionTypes.CONNECTION_TYPE_CHANGE:
            const copiedConnections = [...state.connections];
            const connection = copiedConnections.find(
                (c) => c.id === action.userId
            );
            connection.type = action.connectionType;

            return {
                ...state,
                connections: copiedConnections,
            };
        case actionTypes.REMOVE_PENDING_CONNECTION:
            const copiedWithoutPending = [...state.searchedConnections];
            const pendingConnection = copiedWithoutPending.find(
                (c) => c.profile.connection.id === action.connectionId
            );
            const pendingConnectionProfile = { ...pendingConnection.profile };
            pendingConnectionProfile.connection = null;
            return {
                ...state,
                connections: copiedWithoutPending,
            };
        case actionTypes.SET_PAGED_CONNECTIONS:
            const pagedConnections = [
                ...state.connections,
                ...action.pagedConnections,
            ];
            return { ...state, connections: pagedConnections };

        case actionTypes.SEARCH_CONNECTIONS_CLEAR:
            let searchedConnections = [...state.searchedConnections];
            searchedConnections = [];

            return {
                ...state,
                searchedConnections: searchedConnections,
            };
        case actionTypes.CONNECTION_COUNT_SUBSRCIBE:
            return {
                ...state,
                connectionCount: action.connCount,
            };
        case actionTypes.UPDATE_CONNECTION_NUMBER:
            return {
                ...state,
                connectionCount:
                    action.updateType === 'increase'
                        ? state.connectionCount + 1
                        : state.connectionCount - 1,
            };
        case actionTypes.DECLINE_CONNECTION:
            let declineSearchedConnections = [...state.searchedConnections];
            let declineConn = declineSearchedConnections.find(
                (u) =>
                    u.profile &&
                    u.profile.oppositeConnection &&
                    u.profile.oppositeConnection.id === action.connId
            );
            let declineIndex = declineSearchedConnections.indexOf(declineConn);
            declineConn.profile.oppositeConnection = null;
            declineSearchedConnections[declineIndex] = declineConn;

            return {
                ...state,
                searchedConnections: declineSearchedConnections,
            };
        case actionTypes.ACCEPT_CONNECTION:
            let acceptSearchedConnections = [...state.searchedConnections];
            let acceptConn;
            let acceptIndex;
            acceptConn = acceptSearchedConnections.find(
                (u) =>
                    u.profile &&
                    u.profile.oppositeConnection &&
                    u.profile.oppositeConnection.id === action.connId
            );
            acceptIndex = acceptSearchedConnections.indexOf(acceptConn);
            acceptConn.profile.oppositeConnection = null;
            acceptConn.profile.connection = action.conn;
            acceptSearchedConnections[acceptIndex] = acceptConn;

            return {
                ...state,
                searchedConnections: acceptSearchedConnections,
            };

        default:
            return state;
    }
};

export default connectionsReducer;
