import React, { Component } from 'react';
import Question from '../../../pages/Help/HelpPages/FaqSingle/Question/Question';
import ReactHtmlParser from 'react-html-parser';

export default class PlansFaq extends Component {
    render() {
        let faqs;

        if (this.props.faqs.length) {
            faqs = this.props.faqs.map((faq, index) => {
                return <Question key={`question-${index}`} faq={faq} />;
            });
        }

        return (
            <div className="c-plans__faq">
                <div className="container">
                    <h4>
                        {ReactHtmlParser(
                            this.props.title ? this.props.title : ''
                        )}
                    </h4>
                    <p>
                        {ReactHtmlParser(
                            this.props.body ? this.props.body : ''
                        )}
                    </p>
                    <div className="c-plans__faq--item">
                        <div className="c-plans__angelswrap">{faqs}</div>
                    </div>
                </div>
            </div>
        );
    }
}
