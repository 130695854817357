import React, { Component } from 'react';
import LocationsBlockButton from './LocationsBlockButton';

class LocationsBlock extends Component {
    render() {
        console.log('title', this.props.title);
        console.log('title', this.props.content);

        return (
            <div className="c-location-tabs-bg">
                {this.props.title && <h2>{this.props.title} </h2>}
                <div className="container c-location-tabs">
                    {this.props.content &&
                        this.props.content.map((location, index) => {
                            return (
                                <LocationsBlockButton
                                    key={`location-${index}`}
                                    data={location}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default LocationsBlock;
