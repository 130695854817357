import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApiService from '../../service/api.service';

class PaymentFlowSidebar extends Component {
    state = {
        content: null,
    };

    componentDidMount() {
        let plan = window.location.href.includes('simple')
            ? 'simple'
            : 'imagined';

        console.log('Plan', plan);

        ApiService.getFuneralPlans(plan).then((r) => {
            this.setState({
                content: r.data,
            });
        });
    }
    render() {
        return (
            <div className="c-demands__sidebar">
                <div>
                    <div className="c-demands__sidebar--header">
                        <p>Total single payment</p>
                        <h3>£{this.props.amount} </h3>
                    </div>
                    <div>
                        <p>Monthly payment options</p>
                        <div className="c-demands__sidebar--payment-options two-col">
                            <div>
                                <p> 12 months</p>
                                <h4>
                                    £
                                    {Number.parseFloat(
                                        this.props.amount / 12
                                    ).toFixed(2)}
                                </h4>
                            </div>
                            <div>
                                <p> 24 months</p>
                                <h4>
                                    £
                                    {Number.parseFloat(
                                        this.props.amount / 24
                                    ).toFixed(2)}{' '}
                                </h4>
                            </div>
                        </div>
                    </div>

                    {/* Horizontal image */}
                    <div className="c-demands__sidebar--image desk-hidden">
                        {this.state.content
                            ?.imagesFuneralPaymentPublished?.[0] &&
                        this.state.content?.imagesFuneralPaymentPublished?.[0]
                            .horizontalImage ? (
                            <img
                                alt="horizontal"
                                src={
                                    this.state.content
                                        ?.imagesFuneralPaymentPublished?.[0]
                                        .horizontalImage
                                }
                            />
                        ) : null}
                    </div>
                    {/* Horizontal image */}
                    <div className="c-demands__sidebar--image mob-hidden">
                        {this.state.content
                            ?.imagesFuneralPaymentPublished?.[0] &&
                        this.state.content?.imagesFuneralPaymentPublished?.[0]
                            .verticalImage ? (
                            <img
                                alt="vertical"
                                src={
                                    this.state.content
                                        ?.imagesFuneralPaymentPublished?.[0]
                                        .verticalImage
                                }
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    amount: state.funeralPaymentReducer.total,
    currentStep: state.funeralPaymentReducer.currentStep,
});

export default connect(mapStateToProps)(PaymentFlowSidebar);
