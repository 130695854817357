import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class Location extends Component {
    render() {
        return (
            <div className="light-yellow-background c-location">
                {this.props.title && <h2>{this.props.title} </h2>}
                <div className="container">
                    <div className="o-flex two-col">
                        <div className="c-location--txt">
                            {this.props.content.description && (
                                <p>
                                    {ReactHtmlParser(
                                        this.props.content.description
                                    )}
                                </p>
                            )}{' '}
                        </div>
                        {this.props.content.googleMap && (
                            <div>
                                <iframe
                                    src={this.props.content.googleMap}
                                    width="640"
                                    height="480"
                                    title="all-locations"
                                ></iframe>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Location;
