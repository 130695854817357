import React, { Component } from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/UI/Footer/Footer';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
export default class AuraLanding extends Component {
    render() {
        return (
            <>
                <PageMeta
                    title="Aura Circle | Aura"
                    description="Aura Circle | Aura"
                    canonical={window.location.href}
                />
                <Header />
                <div>[landing page]</div>
                <Footer />
            </>
        );
    }
}
