import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from '../../../../components/FuneralPayment/Navigation/Navigation.js';
import ApiService from '../../../../service/api.service.js';
import Loader from '../../../../components/UI/Loader/Loader.js';
import SliderAddons from '../../../../components/FuneralPayment/SliderAddons/SliderAddons';
import Popup from 'reactjs-popup';
import SliderAddon from '../../../../components/FuneralPayment/SliderAddons/SliderCategory/SliderAddon/SliderAddon.js';
import ReactHtmlParser from 'react-html-parser';
import * as funeralPaymentActionTypes from '../../../../store/actions/funeralPaymentActions';
import NumberFormater from '../../../../service/number_formater.service';
import { debounce } from '../../../../functions/debounce';
import setInputHeight from '../../../../functions/setInputHeight';
import ReactPixel from 'react-facebook-pixel';

class AddOns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addOns: [],
            categories: [],
            subtotal: 0,
            isLoading: true,
            amount: this.props.funeralPayment.addOnCosts
                ? NumberFormater.formatNumber(
                      this.props.funeralPayment.addOnCosts
                  )
                : '0',
            description: this.props.funeralPayment.ownDescription
                ? this.props.funeralPayment.ownDescription
                : '',
        };

        this.debounceAmount = debounce(() => this.handlePatchAmount(), 400);
        this.debounceText = debounce(() => this.handlePatchText(), 400);
    }

    componentDidMount() {
        ReactPixel.init('218942439488342');

        this.setState({
            description: this.props.funeralPayment.ownDescription
                ? this.props.funeralPayment.ownDescription
                : this.props.funeralPayment.ownAddDescription
                ? this.props.funeralPayment.ownAddDescription
                : '',
        });
        window.scrollTo(0, 0);
        let optionId;
        if (
            this.props.funeralPayment &&
            this.props.funeralPayment.selectedOption
        ) {
            optionId = this.props.funeralPayment.selectedOption.split('/')[3];
        }

        ApiService.getFuneralPlanAddons(optionId ? optionId : 1).then(
            (addons) => {
                ApiService.getCategories().then((categories) => {
                    this.setState({
                        addOns: addons.data,
                        categories: categories.data,
                        isLoading: false,
                    });
                });
            }
        );
    }

    handleModalClose = () => {
        this.props.onCloseModal();
    };

    handleTextareaChange = (e) => {
        setInputHeight(e, '40px');
        this.setState(
            {
                description: e.target.value,
            },
            () => {
                this.debounceText();
            }
        );
    };

    handleAmountChange = (e) => {
        let value = e.target.value.replace(',', '');
        let finalVal = value.replace('£', '');
        this.setState(
            {
                // eslint-disable-next-line
                amount:
                    parseInt(finalVal) === 0
                        ? 0
                        : NumberFormater.formatNumber(parseInt(finalVal)),
            },
            () => {
                this.debounceAmount();
            }
        );
    };

    handlePatchText = () => {
        let amount = String(this.state.amount)
            .replace('£', '')
            .replace(',', '');
        const data = {
            ownAddDescription: this.state.description,
            addOnCosts: parseInt(amount),
        };
        const id = this.props.funeralPayment.uuid;
        ApiService.addCustomAddon(data, id).then((r) => {
            ApiService.getTotalPrice(this.props.funeralPayment.uuid).then(
                (res) => {
                    this.props.onSetTotal(res.data.total, res.data.subtotal);
                    this.props.onUpdateCustomAddon(
                        r.data.addOnCosts,
                        r.data.ownAddDescription
                    );
                }
            );
        });
    };

    handlePatchAmount = () => {
        let amount = String(this.state.amount)
            .replace('£', '')
            .replace(',', '');
        const data = {
            addOnCosts: parseInt(amount),
            ownAddDescription: this.state.description,
        };
        const id = this.props.funeralPayment.uuid;
        ApiService.addCustomAddon(data, id).then((r) => {
            ApiService.getTotalPrice(this.props.funeralPayment.uuid).then(
                (res) => {
                    this.props.onSetTotal(res.data.total, res.data.subtotal);
                    this.props.onUpdateCustomAddon(
                        r.data.addOnCosts,
                        r.data.ownAddDescription
                    );
                }
            );
        });
    };

    handleDecideLater = () => {
        // ReactPixel.track('Lead');

        ApiService.decideLater(this.props.funeralPayment.uuid).then((r) => {
            this.props.onDecideLater(
                r.data.userAddOns,
                r.data.subtotal,
                r.data.totalStep
            );
        });
    };

    handleSuggestedCreditClick = () => {
        this.setState(
            {
                amount: NumberFormater.formatNumber(
                    this.props.funeralPayment.subtotal
                ),
            },
            () => {
                this.handlePatchAmount();
            }
        );
    };

    render() {
        let content;
        let categorizedAddOns;

        categorizedAddOns = this.state.categories.map((category) => {
            let filteredAddOns = this.props.funeralPayment.userAddOns.filter(
                (addon) => {
                    return addon.addOn.category?.id === category?.id;
                }
            );

            let content;

            if (filteredAddOns.length) {
                let addons = filteredAddOns.map((addon) => {
                    return (
                        <SliderAddon
                            deletable={true}
                            key={`addon-${addon.addOn.id}`}
                            addon={addon.addOn}
                        />
                    );
                });

                content = (
                    <div className="c-funeral__add-ons__popup--selected">
                        <h4>{category.title}</h4>
                        <div>{addons}</div>
                    </div>
                );
            } else {
                content = null;
            }

            return content;
        });

        if (this.state.isLoading) {
            content = <Loader />;
        } else {
            content = (
                <>
                    <div className="c-funeral__add-ons--headline">
                        {this.props.funeralPayment.forMe ? (
                            <>
                                <h2>
                                    If you’d like to, choose your remembrance
                                    wishes
                                </h2>{' '}
                            </>
                        ) : (
                            <>
                                <h2>
                                    If you’d like to, choose remembrance wishes
                                </h2>
                            </>
                        )}
                        <button
                            onClick={() => this.handleDecideLater()}
                            className="o-d-inline-block btn btn--full btn--size-35 btn--full-shakespeare btn--size-width-118 text-center btn--padding-left-right btn-loader border-radius-2"
                        >
                            Decide Later
                        </button>
                    </div>
                    {this.props.funeralPayment.forMe ? (
                        <>
                            <h6>
                                If there is a particular way that you'd like
                                your family to celebrate your life after you've
                                died, you can detail these wishes below. After
                                you've died, we can then help your family bring
                                these wishes to life at an additional cost.
                                Please note that these wishes are requests, and
                                not guaranteed or included in your plan. Any
                                charges incurred with these wishes requests,
                                including (but not limited to) urns, fireworks
                                and memorial benches, will be discussed with
                                your family and have to be paid for separately
                                after you've died.
                            </h6>{' '}
                        </>
                    ) : (
                        <>
                            <h6>
                                If there is a particular way that the planholder
                                would like their family to celebrate their life
                                after they've died, you can detail their wishes
                                below. After the planholder has died, we can
                                then help their family bring these wishes to
                                life at an additional cost. Please note that
                                these wishes are requests, and not guaranteed or
                                included in the plan. Any charges incurred with
                                these wishes requests, including (but not
                                limited to) urns, fireworks and memorial
                                benches, will be discussed with the planholder's
                                family and have to be paid for separately after
                                they've died.
                            </h6>
                        </>
                    )}
                    <div>
                        <SliderAddons categories={this.state.addOns} />
                        <div className="c-funeral__add-ons--special-request">
                            {this.props.funeralPayment.forMe ? (
                                <>
                                    <textarea
                                        rows={1}
                                        onChange={(e) =>
                                            this.handleTextareaChange(e)
                                        }
                                        placeholder="Your special request"
                                        value={this.state.description}
                                        className="form-control"
                                    />{' '}
                                </>
                            ) : (
                                <>
                                    <textarea
                                        rows={1}
                                        onChange={(e) =>
                                            this.handleTextareaChange(e)
                                        }
                                        placeholder="Special requests"
                                        value={this.state.description}
                                        className="form-control"
                                    />
                                </>
                            )}

                            {this.props.funeralPayment.forMe ? (
                                <>
                                    <p>
                                        Add any special requests here. This
                                        could be to do with a certain place to
                                        scatter ashes, or a custom add on for
                                        example money behind a bar at your wake.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        Add any special requests here. This
                                        could be to do with a certain place to
                                        scatter ashes, or a custom add on for
                                        example money behind a bar at a wake.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="c-funeral__add-ons--selected">
                        <Popup
                            lockScroll
                            className="c-funeral__add-ons__popup c-popup-wrapper"
                            modal
                            trigger={
                                <div>
                                    <h4>Selected wishes:</h4>
                                    <span>
                                        {
                                            this.props.funeralPayment.userAddOns
                                                .length
                                        }
                                    </span>
                                </div>
                            }
                        >
                            {(close) => (
                                <>
                                    <div className="c-popup">
                                        <div className="c-popup__header text-center">
                                            <p className="c-popup__header--subtitle mb-30 o-d-block font-20 font-orange font-bold">
                                                Total Selected{' '}
                                                <span>
                                                    {
                                                        this.props
                                                            .funeralPayment
                                                            .userAddOns.length
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                        {categorizedAddOns}
                                        <button
                                            className="c-popup__close btn btn--full btn--smaller"
                                            onClick={close}
                                        >
                                            Got it, close this
                                        </button>
                                    </div>
                                    <button
                                        onClick={close}
                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                    >
                                        <i className="icon-times font-35 cursor-pointer"></i>
                                    </button>
                                </>
                            )}
                        </Popup>
                    </div>
                    <Navigation backButton={false} continueDisabled={false} />
                    <Popup
                        lockScroll
                        className="c-funeral__add-ons__popup c-funeral__add-ons__popup--sm c-popup-wrapper"
                        modal
                        onClose={() => this.handleModalClose()}
                        open={this.props.funeralPayment.addonModalOpen}
                    >
                        {(close) => (
                            <>
                                <div className="c-popup">
                                    <div className="c-popup__header text-center">
                                        <h1 className="c-popup__header--subtitle mb-30 o-d-block font-20 font-orange font-bold">
                                            {
                                                this.props.funeralPayment
                                                    .addonModalTitle
                                            }
                                        </h1>
                                    </div>
                                    {ReactHtmlParser(
                                        this.props.funeralPayment.addonModalBody
                                    )}
                                    <button
                                        className="c-popup__close btn btn--full btn--smaller"
                                        onClick={close}
                                    >
                                        Got it, close this
                                    </button>
                                </div>
                                <button
                                    onClick={close}
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                            </>
                        )}
                    </Popup>
                </>
            );
        }

        return <div className="c-funeral__add-ons">{content}</div>;
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
    funeralPayment: state.funeralPaymentReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseModal: () => dispatch(funeralPaymentActionTypes.onCloseModal()),
        onOpenModal: (title, body) =>
            dispatch(funeralPaymentActionTypes.onCloseModal(title, body)),
        onSetTotal: (total, subtotal) =>
            dispatch(funeralPaymentActionTypes.onSetTotal(total, subtotal)),
        onUpdateCustomAddon: (cost, desc) =>
            dispatch(funeralPaymentActionTypes.onUpdateCustomAddon(cost, desc)),
        onDecideLater: (addons, subtotal) =>
            dispatch(funeralPaymentActionTypes.onDecideLater(addons, subtotal)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOns);
