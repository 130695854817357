import * as actionTypes from '../actions/searchActions';

import produce from 'immer';

const initialState = {
    searchQuery: '',
    searchResults: [],
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEARCH_QUERY:
            const searchQuery = { ...state, searchQuery: action.searchQuery };
            return searchQuery;

        case actionTypes.SEARCH_RESULTS:
            return {
                ...state,
                searchResults: [...action.searchResults],
            };

        case actionTypes.REMOVE_CONNECTION:
            const searchResultsRemoveConnection = [...state.searchResults];
            let connection;
            let index;
            connection = searchResultsRemoveConnection.find(
                (c) =>
                    c &&
                    c.user &&
                    c.user.profile &&
                    c.user.profile.connection &&
                    c.user.profile.connection.id === action.connectionId
            );
            index = searchResultsRemoveConnection.indexOf(connection);
            let prod;
            if (connection) {
                prod = produce(state.searchResults, (draft) => {
                    connection = {
                        ...connection,
                        user: {
                            ...connection.user,
                            profile: {
                                ...connection.user.profile,
                                connection: null,
                            },
                        },
                    };
                    draft[index] = connection;
                });
            }
            let st = { ...state };
            if (prod) {
                st = { ...st, searchResults: prod };
            }
            return st;
        case actionTypes.CONNECTION_CHANGE:
            const searchResultsAddConnection = [...state.searchResults];
            let addConnection;
            let addIndex;
            addConnection = searchResultsAddConnection.find(
                (c) => c && c.user && c.user.id === action.userId
            );
            addIndex = searchResultsAddConnection.indexOf(addConnection);
            let addProd;
            if (addConnection) {
                addProd = produce(state.searchResults, (draft) => {
                    addConnection = {
                        ...addConnection,
                        user: {
                            ...addConnection.user,
                            profile: {
                                ...addConnection.user.profile,
                                connection: action.connType,
                            },
                        },
                    };
                    draft[addIndex] = addConnection;
                });
            }
            let addSt = { ...state };
            if (addProd) {
                addSt = { ...addSt, searchResults: addProd };
            }
            return addSt;

        case actionTypes.SEARCH_DECLINE_CONNECTION:
            const searchResults = [...state.searchResults];
            let conn;
            let connIndex;
            conn = searchResults.find(
                (c) =>
                    c &&
                    c.user &&
                    c.user.profile &&
                    c.user.profile.oppositeConnection &&
                    c.user.profile.oppositeConnection.id === action.connId
            );
            connIndex = searchResults.indexOf(conn);
            conn.user.profile.oppositeConnection = null;
            searchResults[connIndex] = conn;
            return {
                ...state,
                searchResults: searchResults,
            };
        case actionTypes.SEARCH_ACCEPT_CONNECTION:
            const acceptSearchResults = [...state.searchResults];
            let acceptConn;
            let acceptConnIndex;
            acceptConn = acceptSearchResults.find(
                (c) =>
                    c &&
                    c.user &&
                    c.user.profile &&
                    c.user.profile.oppositeConnection &&
                    c.user.profile.oppositeConnection.id ===
                        action.oppositeConnectionId
            );
            connIndex = acceptSearchResults.indexOf(acceptConnIndex);
            acceptConn.user.profile.connection = action.conn;
            acceptConn.user.profile.oppositeConnection = null;
            acceptSearchResults[connIndex] = acceptConn;
            return {
                ...state,
                searchResults: acceptSearchResults,
            };
        default:
            return state;
    }
};

export default searchReducer;
