export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_FIRST_STEP = 'SET_FIRST_STEP';
export const SET_OPTION = 'SET_OPTION';
export const SET_FUNERAL_CHECKOUT = 'SET_FUNERAL_CHECKOUT';
export const REMOVE_ADDON = 'REMOVE_ADDON';
export const ADD_ADDON = 'ADD_ADDON';
export const ADD_CUSTOM_ADDON = 'ADD_CUSTOM_ADDON';
export const REMOVE_CUSTOM_ADDON = 'REMOVE_CUSTOM_ADDON';
export const SET_TOTAL = 'SET_TOTAL';
export const REMOVE_ADDONS = 'REMOVE_ADDONS';
export const LOGOUT = 'LOGOUT';
export const UPDATE_ADDON = 'UPDATE_ADDON';
export const UPDATE_CUSTOM_ADDON = 'UPDATE_CUSTOM_ADDON';
export const SET_PLAN = 'SET_PLAN';
export const SET_TOTAL_STEP = 'SET_TOTAL_STEP';
export const PATCH_DATA = 'PATCH_DATA';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const DECIDE_LATER = 'DECIDE_LATER';
export const SET_FIRST_STEP_PLAN = 'SET_FIRST_STEP_PLAN';

export const onDecideLater = (addons, subtotal, totalStep) => {
    return (dispatch) => {
        dispatch({
            type: DECIDE_LATER,
            addons: addons,
            subtotal: subtotal,
            totalStep: totalStep,
        });
    };
};

export const onCloseModal = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_MODAL,
        });
    };
};

export const onOpenModal = (title, body) => {
    return (dispatch) => {
        dispatch({
            type: OPEN_MODAL,
            title: title,
            body: body,
        });
    };
};

export const onPatchFirstStep = (payload) => {
    return (dispatch) => {
        dispatch({
            type: PATCH_DATA,
            stepData: payload,
        });
    };
};

export const onSetTotalStep = (step) => {
    return (dispatch) => {
        dispatch({
            type: SET_TOTAL_STEP,
            totalStep: step,
        });
    };
};

export const onSetFirstStepPlan = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_FIRST_STEP_PLAN,
            data: data,
        });
    };
};

export const onSetFuneralPlan = (plan) => {
    return (dispatch) => {
        dispatch({
            type: SET_PLAN,
            plan: plan,
        });
    };
};

export const onUpdateCustomAddon = (amount, description) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_CUSTOM_ADDON,
            amount: amount,
            description: description,
        });
    };
};
export const onUpdateAddon = (uuid, amount) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ADDON,
            uuid: uuid,
            amount: amount,
        });
    };
};

export const onLogoutPaymentUser = () => {
    return (dispatch) => {
        dispatch({
            type: LOGOUT,
        });
    };
};

export const onRemoveAddons = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_ADDONS,
        });
    };
};

export const onSetTotal = (total, subtotal) => {
    return (dispatch) => {
        dispatch({
            type: SET_TOTAL,
            total: total,
            subtotal: subtotal,
        });
    };
};

export const onAddOwnAddon = (ownDesc, ownAmount) => {
    return (dispatch) => {
        dispatch({
            type: ADD_CUSTOM_ADDON,
            ownDesc: ownDesc,
            ownAmount: ownAmount,
        });
    };
};

export const onRemoveOwnAddon = () => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_CUSTOM_ADDON,
        });
    };
};

export const addAddon = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_ADDON,
            addon: payload,
        });
    };
};

export const removeAddon = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_ADDON,
            uuid: payload,
        });
    };
};

export const onSetCurrentStep = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_STEP,
            step: payload,
        });
    };
};

export const onSetFuneralCheckout = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_FUNERAL_CHECKOUT,
            data: payload,
        });
    };
};

export const onSetTotalAmount = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_AMOUNT,
            amount: payload,
        });
    };
};

export const onSetFirstStep = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_FIRST_STEP,
            data: payload,
        });
    };
};

export const onSetOption = (payload, step) => {
    return (dispatch) => {
        dispatch({
            type: SET_OPTION,
            option: payload,
            step: step,
        });
    };
};
