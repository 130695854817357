import * as userActionTypes from '../../store/actions/usersActions';

import React, { Component } from 'react';

import Angels from '../../components/FuneralPlans/Angels/Angels';
import ApiService from '../../service/api.service';
import Carousel from '../../components/FuneralPlans/Carousel/Carousel';
import HowItWorks from '../../components/FuneralPlans/HowItWorks/HowItWorks';
import Loader from '../../components/UI/Loader/Loader';
import PageMeta from '../../components/UI/PageMeta/PageMeta';
import Personal from '../../components/FuneralPlans/Personal/Personal';
import PlansFaq from '../../components/FuneralPlans/PlansFaq/PlansFaq';
import StickyFooter from '../../components/UI/StickyFooter/StickyFooter';
import Testimonials from '../../components/FuneralPlans/Testimonials/Testimonials';
import TrustSection from '../../components/FuneralPlans/TrustSection/TrustSection';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import PlanIncludes from '../../components/FuneralPlans/PlanIncludes/PlanIncludes';
import Footer from '../../components/UI/Footer/Footer';
import SimpleReactLightbox from 'simple-react-lightbox';
import ReactPixel from 'react-facebook-pixel';
import Carousel23 from '../../components/FuneralPlans/Carousel/Carousel23/Carousel23';
import PlanRatings from '../../components/FuneralPlans/PlanRatings/PlanRatings';
import FuneralInspiration from '../../components/FuneralPlans/FuneralInspiration/FuneralInspiration';
import MultipleVideo from '../../components/FuneralPlans/MultipleVideo/MultipleVideo';
import Introduction from '../../components/Blocks/Introduction/Introduction';
import Brochure from '../../components/Blocks/Brochure/Brochure';
import Origin from '../../components/Blocks/Origin/Origin';
import AsSeenIn from '../../components/Blocks/AsSeenIn/AsSeenIn';
import TeamContact from '../../components/Blocks/TeamContact/TeamContact';
import Trustpilot from '../../components/Blocks/Trustpilot/Trustpilot';
import ContactBlock from '../../components/Blocks/ContactBlock/ContactBlock';
import CustomerService from '../../components/Blocks/CustomerService/CustomerService';
import PlanCost from '../../components/Blocks/PlanCost/PlanCost';
import Comparison from '../../components/Blocks/Comparison/Comparison';
import Location from '../../components/Blocks/Location/Location';
import Westerleigh from '../../components/Blocks/Westerleigh/Westerleight';
import HowItWorksNew from '../../components/Blocks/HowItWorksNew/HowItWorksNew';
import WISnipet from '../../components/Blocks/WISnipet/WISnipet';
import Extras from '../../components/FuneralAtNeed/Extras';
import FcaRegulation from '../../components/Blocks/FcaRegulation/FcaRegulation';
import ComparisonAccordion from '../../components/Blocks/ComaprisonAccordion/ComparisonAccordion';
import Plans from '../../components/FuneralPlans/Plans/Plans';
import TrustWorks from '../../components/FuneralPlans/TrustWorks/TrustWorks';
import AmendComponent from '../../components/Blocks/Amends/Amends';

import LocationsBlock from '../../components/Blocks/LocationsBlock/LocationsBlock';
import SchemaOrg from '../../components/SchemaOrg/SchemaOrg';
class FuneralPlans extends Component {
    state = {
        plan: null,
        loading: true,
        funeralPlan: null,
        resultList: [],
        title: '',
        banner: [],
        metaData: [],
    };

    constructor(props) {
        super(props);
        this.trustBoxRef1 = React.createRef();
    }

    componentDidMount() {
        //widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js

        // const aScript1 = document.getElementById('Div1');
        // if (aScript1) {
        //     // (document.getElementById("trustpilotbox1")).parentNode.removeChild();
        //     aScript1.src = '';
        //     aScript1.parentNode.removeChild(aScript1);
        // }
        // var aScript = document.createElement("script");
        // aScript.setAttribute("id", "Div2");
        // aScript.type = "text/javascript";
        // aScript.src =
        //     'widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        // aScript.async = "true";
        // document.head.appendChild(aScript);

        // aScript.onload = function () {

        //     var trustbox = document.getElementById("trustpilotbox1");
        //     window.Trustpilot.loadFromElement(trustbox);

        // };

        //Redirect funeral-plans page to PS
        window.location.pathname === '/funeral-plans' &&
            this.props.history.push('/funerals/funeral-plan');

        window.location.pathname === '/funeral-plans/perfectly-simple' &&
            this.props.history.push('/funerals/funeral-plan');

        const searchParams = new URLSearchParams(
            this.props.history.location.search
        );
        this.setState({
            title: searchParams.getAll('title')[0],
        });

        let plan;
        let loading;
        if (window.location.href.includes('essential')) {
            //set global state
            this.setState({
                plan: 'essential',
            });
            //cdm because setState is async
            plan = 'essential';
        } else if (window.location.href.includes('celebration')) {
            //set global state
            this.setState({
                plan: 'celebration-of-life',
            });
            //cdm because setState is async
            plan = 'celebration-of-life';
        } else if (window.location.href.includes('imagined')) {
            this.setState({
                plan: 'imagined',
            });
            plan = 'imagined';
        } else if (window.location.href.includes('funerals/funeral-plan')) {
            this.setState({
                plan: 'simple',
            });
            plan = 'simple';
        }

        ApiService.getBanners(plan).then((r) => {
            this.setState({
                banner: r.data,
            });
            console.log(plan);
        });

        ApiService.getFuneralPlans(plan, loading)
            .then((r) => {
                this.setState({
                    loading: false,
                    funeralPlan: r.data,
                    metaData: r.data.planMetaPublished,
                });

                console.log(r.data.planMetaPublished[0]);
                // if(window.Trustpilot) {

                //     console.log(trustRef1)

                //     window.Trustpilot.loadFromElement(trustRef1.current, true);

                // }
            })
            .then(() => {
                const list = [
                    {
                        weight: this.state.funeralPlan?.howItWorksWeight,
                        name: 'Name1',
                    },
                    {
                        weight: this.state.funeralPlan?.personalWeight,
                        name: 'Name2',
                    },
                    {
                        weight: this.state.funeralPlan?.inspirationWeight,
                        name: 'Name3',
                    },
                    {
                        weight: this.state.funeralPlan?.testimonialWeight,
                        name: 'Name4',
                    },
                    {
                        weight: this.state.funeralPlan?.planCompareWeight,
                        name: 'Name5',
                    },
                    {
                        weight: this.state.funeralPlan?.faqWeight,
                        name: 'Name6',
                    },
                    {
                        weight: this.state.funeralPlan?.ratingWeight,
                        name: 'Name7',
                    },
                    {
                        weight: this.state.funeralPlan?.trustWeight,
                        name: 'Name8',
                    },
                    {
                        weight: this.state.funeralPlan?.multiStepBlockWeight,
                        name: 'Name9',
                    },
                    {
                        weight: this.state.funeralPlan?.carouselWeight,
                        name: 'Name10',
                    },
                    {
                        weight: this.state.funeralPlan?.includedIntroWeight,
                        name: 'Name11',
                    },
                    {
                        weight: this.state.funeralPlan?.angelsIntroWeight,
                        name: 'Name12',
                    },
                    {
                        weight: this.state.funeralPlan?.introductionWeight,
                        name: 'Name13',
                    },
                    {
                        weight: this.state.funeralPlan?.brochureWeight,
                        name: 'Name14',
                    },
                    {
                        weight: this.state.funeralPlan?.auraOriginWeight,
                        name: 'Name15',
                    },
                    {
                        weight: this.state.funeralPlan?.asSeenInWeight,
                        name: 'Name16',
                    },
                    {
                        weight: this.state.funeralPlan?.contactTeamWeight,
                        name: 'Name17',
                    },
                    {
                        weight: this.state.funeralPlan?.trustpilotWeight,
                        name: 'Name18',
                    },
                    {
                        weight: this.state.funeralPlan?.contactSectionWeight,
                        name: 'Name19',
                    },
                    {
                        weight: this.state.funeralPlan?.customerServiceWeight,
                        name: 'Name20',
                    },
                    {
                        weight: this.state.funeralPlan?.costWeight,
                        name: 'Name21',
                    },
                    {
                        weight: this.state.funeralPlan?.productComparisonWeight,
                        name: 'Name22',
                    },
                    {
                        weight: this.state.funeralPlan?.ourLocationsWeight,
                        name: 'Name23',
                    },
                    {
                        weight: this.state.funeralPlan?.westerleighWeight,
                        name: 'Name24',
                    },
                    {
                        weight: this.state.funeralPlan?.howItWorksWeight2,
                        name: 'Name25',
                    },
                    {
                        weight: this.state.funeralPlan
                            ?.wonderfullyImagineWeight,
                        name: 'Name26',
                    },
                    {
                        weight: this.state.funeralPlan?.extraWeight,
                        name: 'Name27',
                    },
                    {
                        weight: this.state.funeralPlan?.fcaRegulationWeight,
                        name: 'Name28',
                    },
                    {
                        weight: this.state.funeralPlan
                            ?.comparisonAccordionWeight,
                        name: 'Name29',
                    },
                    {
                        weight: this.state.funeralPlan?.keyFeaturesWeight,
                        name: 'Name30',
                    },
                    {
                        weight: this.state.funeralPlan?.peaceOfMindWeight,
                        name: 'Name31',
                    },
                    {
                        weight: this.state.funeralPlan?.locationWeight,
                        name: 'Name32',
                    },
                ];

                const result = list.sort((a, b) => a.weight - b.weight);

                this.setState({
                    resultList: result,
                });
            });

        // if(window.Trustpilot) {

        //     console.log('loaded from funeral plan')

        //         console.log(this.trustBoxRef)
        //         window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);

        //    }
    }
    // componentWillMount() {
    //     if(this.state.funeralPlan) {
    //         console.log('loaded from funeral plan')
    //         console.log(this.state.funeralPlan)
    //         if(window.Trustpilot) {
    //             console.log(this.trustBoxRef)
    //             window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    //         }
    //        }
    // }

    updateBannerVisibilty = () => {
        this.setState({ banner: [] });
    };

    handleSecondButtonClick = () => {
        ReactPixel.init('218942439488342');
        ReactPixel.track('Add to cart');

        if (this.props.user && this.props.user.userFuneralPlan) {
            if (this.props.user.userFuneralPlan.plan.slug === this.state.plan) {
                this.props.history.push(`/funeral-payment/${this.state.plan}`);
            } else {
                ApiService.deleteFuneralPlanPayment(
                    this.props.user.userFuneralPlan.uuid
                ).then((r) => {
                    this.props.onDeleteFuneralPlan();
                    this.props.history.push(
                        `/funeral-payment/${this.state.plan}`
                    );
                });
            }
        } else {
            this.props.history.push(`/funeral-payment/${this.state.plan}`);
        }
    };

    // handleSecondButtonClick = () => {
    //     let requestMoreBtn = document.getElementById('request-more');
    //     requestMoreBtn.click();
    // };
    // componentWillUnmount() {
    //     // var aScript = document.getElementById('Div1');
    //     const aScript = document.getElementById('Div2')

    //     if (aScript) {
    //         // (document.getElementById("trustpilotbox1")).parentNode.removeChild();
    //          aScript.src = '';
    //          aScript.parentNode.removeChild( aScript );

    //     }
    // }

    render() {
        const stickyFooter = this.state.funeralPlan?.stickyFooterPublished[0];

        let content = <Loader />;
        if (!this.state.loading) {
            content = (
                <>
                    <PageMeta
                        title={`${
                            this.state.plan === 'imagined'
                                ? 'Imagined'
                                : 'Simple'
                        } | Aura`}
                    />
                    {this.state.metaData?.length > 0 ? (
                        <SchemaOrg content={this.state.metaData} />
                    ) : null}
                    <Header
                        bannerContent={
                            this.state.banner?.length > 0 &&
                            this.state.banner?.[0]
                        }
                        bannerVisible={this.state.banner?.length > 0}
                        hideBanner={this.updateBannerVisibilty}
                    />
                    <div className="c-plans">
                        <MultipleVideo
                            hasBanner={this.state.banner?.length > 0}
                            horizontalVideo={
                                this.state.funeralPlan?.headersPublished[0]
                                    .horizontalVideo
                            }
                            verticalVideo={
                                this.state.funeralPlan?.headersPublished[0]
                                    .verticalVideo
                            }
                            popupVideo={
                                this.state.funeralPlan?.headersPublished[0]
                                    .videoLink
                            }
                            horizontalImage={
                                this.state.funeralPlan?.headersPublished[0]
                                    .horizontalImage
                            }
                            verticalImage={
                                this.state.funeralPlan?.headersPublished[0]
                                    .verticalImage
                            }
                            // title={
                            //     this.state.funeralPlan?.headersPublished[0]
                            //         .title
                            // }
                            title={
                                window.location.href.includes('?title')
                                    ? this.state.title
                                    : this.state.funeralPlan
                                          ?.headersPublished[0].title
                            }
                            price={
                                this.state.funeralPlan?.headersPublished[0]
                                    .titlePrice
                            }
                            priceInfo={
                                this.state.funeralPlan?.headersPublished[0]
                                    .priceInfo
                            }
                            description={
                                this.state.funeralPlan?.headersPublished[0]
                                    .fullDescription
                            }
                            ctaTitle={
                                this.state.funeralPlan?.headersPublished[0]
                                    .ctaTitle
                            }
                            ctaLink={
                                this.state.funeralPlan?.headersPublished[0]
                                    .ctaLink
                            }
                            ctaPopup={
                                this.state.funeralPlan?.headersPublished[0]
                                    .ctaPopup
                            }
                            requestLink={
                                this.state.funeralPlan?.headersPublished[0]
                                    .requestLink
                            }
                            requestTitle={
                                this.state.funeralPlan?.headersPublished[0]
                                    .requestTitle
                            }
                            ratingImages={
                                this.state.funeralPlan?.headerRatingsPublished
                            }
                            type="plans"
                        />

                        {this.state.resultList.map((item) => {
                            return (
                                <>
                                    {item.name === 'Name1' &&
                                    this.state.funeralPlan.howItWorksPublished
                                        .length > 0 &&
                                    this.state.funeralPlan.howItWorksVisible ? (
                                        <HowItWorks
                                            title={
                                                this.state.funeralPlan
                                                    ?.howItWorksTitle
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.howItWorksPublished
                                            }
                                        />
                                    ) : item.name === 'Name2' &&
                                      this.state.funeralPlan
                                          .personalItemsPublished.length > 0 &&
                                      this.state.funeralPlan
                                          .personalIntroVisible ? (
                                        <Personal
                                            body={
                                                this.state.funeralPlan
                                                    ?.personalIntroBody
                                            }
                                            title={
                                                this.state.funeralPlan
                                                    ?.personalIntroTitle
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.personalItemsPublished
                                            }
                                        />
                                    ) : item.name === 'Name3' &&
                                      this.state.funeralPlan
                                          .inspirationsPublished.length > 0 &&
                                      this.state.funeralPlan
                                          .inspirationVisible ? (
                                        <FuneralInspiration
                                            title={
                                                this.state.funeralPlan
                                                    ?.inspirationTitle
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.inspirationsPublished
                                            }
                                        />
                                    ) : item.name === 'Name4' &&
                                      this.state.funeralPlan
                                          .testimonialsPublished.length > 0 &&
                                      this.state.funeralPlan
                                          .testimonialVisible ? (
                                        <Testimonials
                                            body={
                                                this.state.funeralPlan
                                                    ?.testimonialIntro
                                            }
                                            title={
                                                this.state.funeralPlan
                                                    ?.testimonialTitle
                                            }
                                            testimonials={
                                                this.state.funeralPlan
                                                    ?.testimonialsPublished
                                            }
                                        />
                                    ) : item.name === 'Name5' &&
                                      this.state.funeralPlan
                                          .planCompareVisible &&
                                      this.state.funeralPlan.comparisonPublished
                                          .length > 0 ? (
                                        <Plans
                                            title={
                                                this.state.funeralPlan
                                                    ?.planCompareTitle
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.planCompareDescription
                                            }
                                            tableContent={
                                                this.state.funeralPlan
                                                    ?.comparisonPublished
                                            }
                                            planImage1={
                                                this.state.funeralPlan
                                                    ?.planCompareImage1
                                            }
                                            planImage2={
                                                this.state.funeralPlan
                                                    ?.planCompareImage2
                                            }
                                            planTitle1={
                                                this.state.funeralPlan
                                                    ?.planCompareTitle1
                                            }
                                            planTitle2={
                                                this.state.funeralPlan
                                                    ?.planCompareTitle2
                                            }
                                            planSubtitle1={
                                                this.state.funeralPlan
                                                    ?.planCompareSubtitle1
                                            }
                                            planSubtitle2={
                                                this.state.funeralPlan
                                                    ?.planCompareSubtitle2
                                            }
                                            planDescription1={
                                                this.state.funeralPlan
                                                    ?.planCompareDescription1
                                            }
                                            planDescription2={
                                                this.state.funeralPlan
                                                    ?.planCompareDescription2
                                            }
                                            planPrice1={
                                                this.state.funeralPlan
                                                    ?.planComparePrice1
                                            }
                                            planPrice2={
                                                this.state.funeralPlan
                                                    ?.planComparePrice2
                                            }
                                            planCtaTitle1={
                                                this.state.funeralPlan
                                                    ?.planCompareCtaTitle1
                                            }
                                            planCtaTitle2={
                                                this.state.funeralPlan
                                                    ?.planCompareCtaTitle2
                                            }
                                            planCtaLink1={
                                                this.state.funeralPlan
                                                    ?.planCompareCtaLink1
                                            }
                                            planCtaLink2={
                                                this.state.funeralPlan
                                                    ?.planCompareCtaLink2
                                            }
                                        />
                                    ) : item.name === 'Name6' &&
                                      this.state.funeralPlan.faqsPublished
                                          .length > 0 &&
                                      this.state.funeralPlan.faqVisible ? (
                                        <PlansFaq
                                            title={
                                                this.state.funeralPlan?.faqTitle
                                            }
                                            body={
                                                this.state.funeralPlan?.faqBody
                                            }
                                            faqs={
                                                this.state.funeralPlan
                                                    ?.faqsPublished
                                            }
                                        />
                                    ) : item.name === 'Name7' &&
                                      this.state.funeralPlan.ratingVisible ? (
                                        <PlanRatings
                                            title={
                                                this.state.funeralPlan
                                                    ?.ratingTitle
                                            }
                                            body={
                                                this.state.funeralPlan
                                                    ?.ratingBody
                                            }
                                            images={[
                                                this.state.funeralPlan
                                                    ?.ratingImage1,
                                                this.state.funeralPlan
                                                    ?.ratingImage2,
                                                this.state.funeralPlan
                                                    ?.ratingImage3,
                                            ]}
                                        />
                                    ) : item.name === 'Name8' &&
                                      this.state.funeralPlan.trustsPublished
                                          .length > 0 &&
                                      this.state.funeralPlan.trustVisible ? (
                                        <TrustSection
                                            body={
                                                this.state.funeralPlan
                                                    ?.trustIntro
                                            }
                                            title={
                                                this.state.funeralPlan
                                                    ?.trustTitle
                                            }
                                            trusts={
                                                this.state.funeralPlan
                                                    ?.trustsPublished
                                            }
                                        />
                                    ) : item.name === 'Name9' &&
                                      this.state.funeralPlan.multiStepsPublished
                                          .length > 0 &&
                                      this.state.funeralPlan
                                          .multiStepBlockVisible ? (
                                        <Carousel23
                                            title={
                                                this.state.funeralPlan
                                                    ?.multiStepBlockTitle
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.multiStepsPublished
                                            }
                                        />
                                    ) : item.name === 'Name10' &&
                                      this.state.funeralPlan.carouselsPublished
                                          .length > 0 &&
                                      this.state.funeralPlan
                                          .carouselsVisible ? (
                                        <SimpleReactLightbox>
                                            <Carousel
                                                title={
                                                    this.state.funeralPlan
                                                        ?.carouselTitle
                                                }
                                                content={
                                                    this.state.funeralPlan
                                                        ?.carouselsPublished
                                                }
                                            />
                                        </SimpleReactLightbox>
                                    ) : item.name === 'Name11' &&
                                      this.state.funeralPlan
                                          .includedItemsPublished.length > 0 &&
                                      this.state.funeralPlan
                                          .includedIntroVisible ? (
                                        <PlanIncludes
                                            title={
                                                this.state.funeralPlan
                                                    ?.includedIntroTitle
                                            }
                                            body={
                                                this.state.funeralPlan
                                                    ?.includedIntroBody
                                            }
                                        />
                                    ) : item.name === 'Name12' &&
                                      this.state.funeralPlan
                                          .angelsItemsPublished.length > 0 &&
                                      this.state.funeralPlan
                                          .angelsIntroVisible ? (
                                        <Angels
                                            body={
                                                this.state.funeralPlan
                                                    ?.angelsIntroBody
                                            }
                                            title={
                                                this.state.funeralPlan
                                                    ?.angelsIntroTitle
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.angelsItemsPublished
                                            }
                                        />
                                    ) : item.name === 'Name13' &&
                                      this.state.funeralPlan
                                          ?.introductionVisible &&
                                      this.state.funeralPlan
                                          ?.introductionsPublished.length >
                                          0 ? (
                                        <Introduction
                                            title={
                                                this.state.funeralPlan
                                                    ?.introductionTitle
                                                    ? this.state.funeralPlan
                                                          .introductionTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.introductionsPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name14' &&
                                      this.state.funeralPlan?.brochureVisible &&
                                      this.state.funeralPlan?.brochuresPublished
                                          .length > 0 ? (
                                        <Brochure
                                            title={
                                                this.state.funeralPlan
                                                    ?.brochureTitle
                                                    ? this.state.funeralPlan
                                                          .brochureTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.brochuresPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name15' &&
                                      this.state.funeralPlan
                                          ?.auraOriginVisible &&
                                      this.state.funeralPlan
                                          ?.auraOriginsPublished.length > 0 ? (
                                        <Origin
                                            title={
                                                this.state.funeralPlan
                                                    ?.auraOriginTitle
                                                    ? this.state.funeralPlan
                                                          .auraOriginTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.auraOriginsPublished
                                            }
                                        />
                                    ) : item.name === 'Name16' &&
                                      this.state.funeralPlan?.asSeenInVisible &&
                                      this.state.funeralPlan?.asSeenInsPublished
                                          .length > 0 ? (
                                        <AsSeenIn
                                            title={
                                                this.state.funeralPlan
                                                    ?.asSeenInTitle
                                                    ? this.state.funeralPlan
                                                          .asSeenInTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.asSeenInsPublished
                                            }
                                        />
                                    ) : item.name === 'Name17' &&
                                      this.state.funeralPlan
                                          ?.contactTeamVisible &&
                                      this.state.funeralPlan
                                          ?.contactTeamsPublished.length > 0 ? (
                                        <TeamContact
                                            title={
                                                this.state.funeralPlan
                                                    ?.contactTeamTitle
                                                    ? this.state.funeralPlan
                                                          .contactTeamTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.contactTeamsPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name18' &&
                                      this.state.funeralPlan
                                          ?.trustpilotVisible ? (
                                        <Trustpilot
                                            body={
                                                this.state.funeralPlan
                                                    ?.trustpilotDescription
                                            }
                                            title={
                                                this.state.funeralPlan
                                                    ?.trustpilotTitle
                                            }
                                            testimonials={
                                                this.state.funeralPlan
                                                    ?.trustpilotsPublished
                                            }
                                            ctaLink={
                                                this.state.funeralPlan
                                                    ?.trustpilotCtaLink
                                                    ? this.state.funeralPlan
                                                          .trustpilotCtaLink
                                                    : ''
                                            }
                                            ctaTitle={
                                                this.state.funeralPlan
                                                    ?.trustpilotCtaTitle
                                                    ? this.state.funeralPlan
                                                          .trustpilotCtaTitle
                                                    : ''
                                            }
                                            code={
                                                this.state.funeralPlan
                                                    ? {
                                                          businessID:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataBusinessUnitId,
                                                          localeLang:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataLocale,

                                                          reviewLang:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataReviewLanguages,
                                                          reviewNumb:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataReviewNumber,
                                                          dataStars:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataStars,
                                                          styleHeight:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataStyleHeight,
                                                          styleWidth:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataStyleWidth,
                                                          dataTags:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataTags,
                                                          templateID:
                                                              this.state
                                                                  .funeralPlan
                                                                  .trustpilotDataTemplateId,
                                                          theme: this.state
                                                              .funeralPlan
                                                              .trustpilotDataTheme,
                                                      }
                                                    : ''
                                            }
                                        />
                                    ) : item.name === 'Name19' &&
                                      this.state.funeralPlan
                                          ?.contactSectionVisible &&
                                      this.state.funeralPlan
                                          ?.contactSectionsPublished.length >
                                          0 ? (
                                        <ContactBlock
                                            title={
                                                this.state.funeralPlan
                                                    ?.contactSectionTitle
                                                    ? this.state.funeralPlan
                                                          .contactSectionTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.contactSectionsPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name20' &&
                                      this.state.funeralPlan
                                          ?.customerServiceVisible &&
                                      this.state.funeralPlan
                                          ?.customerServicePublished.length >
                                          0 ? (
                                        <CustomerService
                                            title={
                                                this.state.funeralPlan
                                                    ?.customerServiceTitle
                                                    ? this.state.funeralPlan
                                                          .customerServiceTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.customerServicePublished
                                            }
                                        />
                                    ) : item.name === 'Name21' &&
                                      this.state.funeralPlan?.costVisible &&
                                      this.state.funeralPlan?.costsPublished
                                          .length > 0 ? (
                                        <PlanCost
                                            title={
                                                this.state.funeralPlan
                                                    ?.costTitle
                                                    ? this.state.funeralPlan
                                                          .costTitle
                                                    : null
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.costDescription
                                                    ? this.state.funeralPlan
                                                          .costDescription
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.costsPublished
                                            }
                                        />
                                    ) : item.name === 'Name22' &&
                                      this.state.funeralPlan
                                          ?.productComparisonVisible &&
                                      this.state.funeralPlan
                                          ?.productComparisonPublished.length >
                                          0 ? (
                                        <Comparison
                                            title={
                                                this.state.funeralPlan
                                                    ?.productComparisonTitle
                                                    ? this.state.funeralPlan
                                                          .productComparisonTitle
                                                    : null
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.productComparisonBody
                                                    ? this.state.funeralPlan
                                                          .productComparisonBody
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.productComparisonPublished
                                            }
                                        />
                                    ) : item.name === 'Name23' &&
                                      this.state.funeralPlan
                                          ?.ourLocationsVisible &&
                                      this.state.funeralPlan
                                          ?.ourLocationsPublished.length > 0 ? (
                                        <Location
                                            title={
                                                this.state.funeralPlan
                                                    ?.ourLocationsTitle
                                                    ? this.state.funeralPlan
                                                          .ourLocationsTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.ourLocationsPublished[0]
                                                    ? this.state.funeralPlan
                                                          .ourLocationsPublished[0]
                                                    : null
                                            }
                                        />
                                    ) : item.name === 'Name24' &&
                                      this.state.funeralPlan
                                          ?.westerleighVisible &&
                                      this.state.funeralPlan
                                          ?.westerleighPublished.length > 0 ? (
                                        <Westerleigh
                                            title={
                                                this.state.funeralPlan
                                                    ?.westerleighTitle
                                                    ? this.state.funeralPlan
                                                          .westerleighTitle
                                                    : null
                                            }
                                            subheader={
                                                this.state.funeralPlan
                                                    ?.westerleighSubheader
                                                    ? this.state.funeralPlan
                                                          .westerleighSubheader
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.westerleighPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name25' &&
                                      this.state.funeralPlan
                                          ?.howItWorksVisible2 ? (
                                        <HowItWorksNew
                                            title={
                                                this.state.funeralPlan
                                                    ?.howItWorksTitle2
                                                    ? this.state.funeralPlan
                                                          .howItWorksTitle2
                                                    : null
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.howItWorksDescription2
                                                    ? this.state.funeralPlan
                                                          .howItWorksDescription2
                                                    : null
                                            }
                                        />
                                    ) : item.name === 'Name26' &&
                                      this.state.funeralPlan
                                          ?.wonderfullyImagineVisible &&
                                      this.state.funeralPlan
                                          ?.wonderfullyImaginesPublished
                                          .length > 0 ? (
                                        <WISnipet
                                            horizontalImage={
                                                this.state.funeralPlan
                                                    ?.wonderfullyImaginesPublished[0]
                                                    ? this.state.funeralPlan
                                                          .wonderfullyImaginesPublished[0]
                                                          .horizontalImage
                                                    : null
                                            }
                                            verticalImage={
                                                this.state.funeralPlan
                                                    ?.wonderfullyImaginesPublished[0]
                                                    ? this.state.funeralPlan
                                                          .wonderfullyImaginesPublished[0]
                                                          .verticalImage
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.wonderfullyImaginesPublished[0]
                                                    ? this.state.funeralPlan
                                                          .wonderfullyImaginesPublished[0]
                                                    : null
                                            }
                                        />
                                    ) : item.name === 'Name27' &&
                                      this.state.funeralPlan?.extraVisible &&
                                      this.state.funeralPlan
                                          ?.extraCategoriesPublished.length >
                                          0 ? (
                                        <div className="c-funeral-at-need__extras">
                                            {this.state.funeralPlan
                                                ?.extraTitle && (
                                                <h2>
                                                    {
                                                        this.state.funeralPlan
                                                            .extraTitle
                                                    }{' '}
                                                </h2>
                                            )}
                                            <Extras
                                                data={
                                                    this.state.funeralPlan
                                                        ?.extraCategoriesPublished
                                                }
                                            />
                                        </div>
                                    ) : item.name === 'Name28' &&
                                      this.state.funeralPlan
                                          ?.fcaRegulationVisible &&
                                      this.state.funeralPlan
                                          ?.fcaRegulationPublished.length >
                                          0 ? (
                                        <FcaRegulation
                                            title={
                                                this.state.funeralPlan
                                                    ?.fcaRegulationTitle
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.fcaRegulationDescription
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.fcaRegulationPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name29' &&
                                      this.state.funeralPlan
                                          ?.comparisonAccordionVisible &&
                                      this.state.funeralPlan
                                          ?.comparisonAccordionPublished
                                          .length > 0 ? (
                                        <ComparisonAccordion
                                            title={
                                                this.state.funeralPlan
                                                    ?.comparisonAccordionTitle
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.comparisonAccordionDescription
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.comparisonAccordionPublished
                                            }
                                        />
                                    ) : item.name === 'Name30' &&
                                      this.state.funeralPlan
                                          ?.keyFeaturesVisible &&
                                      this.state.funeralPlan
                                          ?.keyFeaturesPublished.length > 0 ? (
                                        <TrustWorks
                                            title={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesTitle
                                            }
                                            description={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesDescription
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesPublished
                                            }
                                            btn1Title={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesCtaTitle1
                                            }
                                            btn2Title={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesCtaTitle2
                                            }
                                            btn1Link={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesCtaLink1
                                            }
                                            btn2Link={
                                                this.state.funeralPlan
                                                    ?.keyFeaturesCtaLink2
                                            }
                                        />
                                    ) : item.name === 'Name31' &&
                                      this.state.funeralPlan
                                          ?.peaceOfMindVisible &&
                                      this.state.funeralPlan
                                          ?.peaceOfMindPublished.length > 0 ? (
                                        <AmendComponent
                                            title={
                                                this.state.funeralPlan
                                                    ?.peaceOfMindTitle
                                                    ? this.state.funeralPlan
                                                          ?.peaceOfMindTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.peaceOfMindPublished[0]
                                            }
                                        />
                                    ) : item.name === 'Name32' &&
                                      this.state.funeralPlan?.locationVisible &&
                                      this.state.funeralPlan
                                          ?.planLocationsPublished.length >
                                          0 ? (
                                        <LocationsBlock
                                            title={
                                                this.state.funeralPlan
                                                    ?.locationTitle
                                                    ? this.state.funeralPlan
                                                          ?.locationTitle
                                                    : null
                                            }
                                            content={
                                                this.state.funeralPlan
                                                    ?.planLocationsPublished
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            );
                        })}
                    </div>
                    {this.state.funeralPlan?.stickyFooterVisible &&
                    this.state.funeralPlan?.stickyFooterPublished ? (
                        <StickyFooter
                            slug={this.state.funeralPlan.slug}
                            email="customercare@aura.life"
                            //For new footer
                            type={
                                stickyFooter.type1 && !stickyFooter.type2
                                    ? '1'
                                    : stickyFooter.type2 && !stickyFooter.type1
                                    ? '2'
                                    : stickyFooter.type1 && stickyFooter.type2
                                    ? '1'
                                    : null
                            }
                            text={stickyFooter.title}
                            phoneNum={stickyFooter.phoneNumber}
                            //if there is normal link, goes to link, if some number with prefix 'tel:' in CMS, goes to that num, if same as infitiy num, goes to infity num, if link is empty, it opens popup
                            firstBtnTitle={stickyFooter.ctaTitle1}
                            firstBtnLink={stickyFooter.ctaLink1}
                            secondBtnTitle={stickyFooter.ctaTitle2}
                            secondBtnLink={stickyFooter.ctaLink2}
                        />
                    ) : (
                        ''
                    )}

                    <Footer
                        withStickyFooter={
                            this.state.funeralPlan?.stickyFooterVisible &&
                            this.state.funeralPlan?.stickyFooterPublished
                        }
                    />
                </>
            );
        }

        return content;
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteFuneralPlan: () =>
            dispatch(userActionTypes.onDeleteFuneralPlan()),
    };
};

const funeralPlansWithRouter = withRouter(FuneralPlans);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(funeralPlansWithRouter);
