class DateFormater {
    static formatDate(date, isMessages) {
        const d = new Date(date);
        const now = new Date();
        const seconds = Math.round(
            Math.abs((now.getTime() - d.getTime()) / 1000)
        );
        const minutes = Math.round(Math.abs(seconds / 60));
        const hours = Math.round(Math.abs(minutes / 60));
        const days = Math.round(Math.abs(hours / 24));
        const months = Math.round(Math.abs(days / 30.416));
        const years = Math.round(Math.abs(days / 365));
        const today = new Date();

        today.setHours(0, 0, 0, 0);

        if (seconds <= 60) {
            return (
                seconds +
                `${isMessages ? 's' : ' second'}${
                    seconds > 0 && seconds < 2 ? '' : isMessages ? '' : 's'
                } ${isMessages ? '' : 'ago'}`
            );
        } else if (minutes <= 60) {
            return (
                minutes +
                `${isMessages ? 'm' : ' minute'}${
                    minutes > 0 && minutes < 2 ? '' : isMessages ? '' : 's'
                } ${isMessages ? '' : 'ago'}`
            );
        } else if (hours < 24) {
            return (
                hours +
                `${isMessages ? 'h' : ' hour'}${
                    hours > 0 && hours < 2 ? '' : isMessages ? '' : 's'
                } ${isMessages ? '' : 'ago'}`
            );
        } else if (days < 30) {
            return (
                days +
                `${isMessages ? 'd' : ' day'}${
                    days > 0 && days < 2 ? '' : isMessages ? '' : 's'
                } ${isMessages ? '' : 'ago'}`
            );
        } else if (months < 12) {
            return (
                months +
                `${isMessages ? 'M' : ' month'}${
                    months > 0 && months < 2 ? '' : isMessages ? '' : 's'
                } ${isMessages ? '' : 'ago'}`
            );
        } else {
            return (
                years +
                `${isMessages ? 'y' : ' year'}${
                    years > 0 && years < 2 ? '' : isMessages ? '' : 's'
                } ${isMessages ? '' : 'ago'}`
            );
        }
    }
}

export default DateFormater;
