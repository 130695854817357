import React, { Component } from 'react';
import SliderBg from '../../../assets/images/aura2022/redesign/plans-bg-logo-white.svg';
import VideoEmbed from '../../../service/video_embed.service';
import Slider from 'react-slick';
import ReactHtmlParser from 'react-html-parser';
import Popup from 'reactjs-popup';
export default class Carousel extends Component {
    state = {
        popupOpen: false,
        image: null,
        video: null,
    };

    handleImageClick = (e, slide) => {
        this.setState({
            popupOpen: true,
            image: slide.image,
            video: slide.videoLink,
        });
    };

    render() {
        let slides;
        if (this.props.content.length) {
            slides = this.props.content.map((slide, index) => {
                return (
                    <div
                        key={index}
                        onClick={(e) => this.handleImageClick(e, slide)}
                        className="c-plans__inspiration--slide slide carousel-slide"
                    >
                        <div
                            className="c-plans__inspiration--image "
                            style={{ backgroundImage: `url(${slide.image})` }}
                            alt="slide-img"
                        >
                            {slide.videoLink ? (
                                <div
                                    className={`carousel-icon carousel-icon--play`}
                                >
                                    <i className={`icon-play`}></i>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                );
            });
        }

        const settings = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <>
                {this.props.content.length ? (
                    <div className="c-plans__inspiration-slider">
                        <div className="container">
                            <img
                                className="c-plans__inspiration-slider--bg"
                                src={SliderBg}
                                alt="personal-section-background"
                            />
                            <h2>
                                {ReactHtmlParser(
                                    this.props.title ? this.props.title : ''
                                )}
                            </h2>
                            <div>
                                <Slider
                                    {...settings}
                                    initialSlide={0}
                                    className="c-plans__inspiration__slider"
                                >
                                    {slides}
                                </Slider>
                            </div>
                        </div>
                    </div>
                ) : null}
                <Popup
                    lockScroll
                    modal
                    className="lightbox-popup"
                    open={this.state.popupOpen}
                    onClose={() =>
                        this.setState({
                            popupOpen: false,
                            image: null,
                            video: null,
                        })
                    }
                >
                    {(close) => {
                        return (
                            <>
                                <div className="lightbox-content">
                                    {!this.state.video ? (
                                        <div
                                            className="lightbox-img width-full height-full padding-reset border-radius-7"
                                            style={{
                                                backgroundImage: `url(${this.state.image})`,
                                            }}
                                            alt="slide-img"
                                        ></div>
                                    ) : null}
                                    {this.state.video ? (
                                        <iframe
                                            className="lightbox-video width-full height-full padding-reset border-radius-7"
                                            title="embed-title"
                                            allowFullScreen
                                            src={VideoEmbed.parseLink(
                                                this.state.video
                                            )}
                                        ></iframe>
                                    ) : null}
                                </div>
                                <button
                                    onClick={close}
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                            </>
                        );
                    }}
                </Popup>
            </>
        );
    }
}
