import { Form, Formik, Field } from 'formik';
import React, { Component } from 'react';
import logo from '../../../../assets/images/logo-revert/Aura-big-logo.svg';
import Input from '../../../../components/UI/Input/Input';
import * as Yup from 'yup';
import Popup from 'reactjs-popup';
import Button from '../../../../components/UI/Button/Button';
import loader from '../../../../assets/images/loader.svg';
import ApiService from '../../../../service/api.service.js';
import ReactCodeInput from 'react-code-input';
import { connect } from 'react-redux';
import * as actionTypes from '../../../../store/actions/usersActions';
import * as messagesActionTypes from '../../../../store/actions/messagesActions';
import * as connectionActionTypes from '../../../../store/actions/connectionActions';
import * as funeralPaymentActionTypes from '../../../../store/actions/funeralPaymentActions';
import Navigation from '../../../../components/FuneralPayment/Navigation/Navigation';
import forgotImage from '../../../../assets/images/resetPasswordImg.svg';
import imageLogo from '../../../../assets/images/Aura-big-logo.svg';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../../../../components/UI/Loader/Loader';
import RequiredInput from '../../../../components/UI/Input/RequiredInput/RequiredInput';
import planForImage from '../../../../assets/images/funeral-payment/plan-for.svg';
import PasswordInput from '../../../../components/UI/Input/PasswordInput/PasswordInput';

class Contact extends Component {
    state = {
        alreadyHaveAccount: false,
        accountEmail: '',
        saveInProgress: false,
        popupOpen: false,
        popupOpen2: false,
        popupOpen3: false,
        loginData: null,
        authTypeValue: null,
        codeSent: false,
        twoFaId: null,
        loggedIn: false,
        checkboxChecked: true,
        buttonIsLoading: false,
        buttonLoading: false,
        emailSent: false,
        forgotPassword: false,
        plan: null,
        isLoading: true,
        infoTitle: '',
        infoContent: '',
        infoPhoto: '',
        funeralPlan: null,
        showMessage: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (window.location.href.includes('essential')) {
            this.setState({
                plan: 'Essential',
            });
        } else if (window.location.href.includes('celebration')) {
            this.setState({
                plan: 'Celebration of Life',
            });
        } else if (window.location.href.includes('imagined')) {
            this.setState({
                plan: 'Imagine',
            });
        } else if (window.location.href.includes('simple')) {
            this.setState({
                plan: 'Simple',
            });
        }

        let slug = 'privacy-policy';
        ApiService.getInfoPages(slug).then((r) => {
            this.setState({
                infoTitle: r.data.title,
                infoContent: r.data.content,
                infoPhoto: r.data.photo,
                isLoading: false,
            });
        });
        // let slug = 'terms-and-conditions'
        let plan;
        if (window.location.href.includes('essential')) {
            //set global state
            this.setState({
                plan: 'essential',
            });
            //cdm because setState is async
            plan = 'essential';
        } else if (window.location.href.includes('celebration')) {
            //set global state
            this.setState({
                plan: 'celebration-of-life',
            });
            //cdm because setState is async
            plan = 'celebration-of-life';
        } else if (window.location.href.includes('imagined')) {
            this.setState({
                plan: 'imagined',
            });
            plan = 'imagined';
        } else if (window.location.href.includes('simple')) {
            this.setState({
                plan: 'simple',
            });
            plan = 'simple';
        }
        let funeralPlanId;
        if (this.props.user?.userFuneralPlan?.uuid) {
            funeralPlanId = this.props.user?.userFuneralPlan?.uuid;
        } else {
            funeralPlanId = this.props.funeralPayment.uuid;
        }
        ApiService.getUserFuneralPlan(funeralPlanId)
            .then((res) => {
                this.setState(
                    {
                        funeralPlan: res.data,
                    },
                    () => {
                        this.props.onSetFirstStepPlan(res.data);
                        this.props.onSetFuneralPlan(plan);
                    }
                );
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    //submit login
    handleSubmitLogin = (values, { setSubmitting, resetForm }) => {
        this.setState({ saveInProgress: true }, () => {
            ApiService.login(values)
                .then((response) => {
                    if (response.data.twoFa) {
                        this.setState({
                            loginData: response.data,
                            saveInProgress: false,
                            twoFaId: response.data.twoFa.id,
                        });
                    } else {
                        const token = response.data.token;
                        const refreshToken = response.data.refresh_token;

                        localStorage.setItem('token', token);
                        localStorage.setItem('tokenRefreshedDate', new Date());
                        localStorage.setItem('refreshToken', refreshToken);

                        this.props.onSetToken(token);
                        this.props.onSetRefreshToken(refreshToken);

                        ApiService.getUser().then((response) => {
                            this.props.onUnreadThreadCountSubscribe(
                                parseInt(response.data.unreadThreadsCount)
                            );
                            this.setState({ saveInProgress: false });
                            this.props.onSetUser(response.data);
                            this.props.onConnectionCountSubscribe(
                                response.data.profile.connectionNumber
                            );
                            this.setState(
                                {
                                    loggedIn: true,
                                },
                                () => {
                                    this.handlePopupClose();
                                }
                            );
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        loginError: err.response.data.message,
                        saveInProgress: false,
                    });
                });
        });
    };

    //request code
    handleSubmitSendCode = (e) => {
        e.preventDefault();
        this.setState(
            {
                saveInProgress: true,
                error: null,
            },
            () => {
                const data = {
                    transport: parseInt(this.state.authTypeValue),
                };
                ApiService.sendTwoFaCode(this.state.twoFaId, data)
                    .then((r) => {
                        localStorage.setItem(
                            'transport',
                            parseInt(this.state.authTypeValue)
                        );
                        this.setState({
                            saveInProgress: false,
                            codeSent: true,
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            error: e.response.data.detail,
                            saveInProgress: false,
                        });
                    });
            }
        );
    };

    //submit code
    handleSubmitCode = (e) => {
        e.preventDefault();
        this.setState(
            {
                error: false,
                saveInProgress: true,
            },
            () => {
                const data = {
                    confirmationCode: this.state.inputCode,
                };
                ApiService.confirmCode(this.state.twoFaId, data)
                    .then((response) => {
                        const token = response.data.token;
                        const refreshToken = response.data.refresh_token;

                        localStorage.setItem('token', token);
                        localStorage.setItem('tokenRefreshedDate', new Date());
                        localStorage.setItem('refreshToken', refreshToken);

                        this.props.onSetToken(token);
                        this.props.onSetRefreshToken(refreshToken);

                        ApiService.getUser().then((response) => {
                            this.props.onUnreadThreadCountSubscribe(
                                parseInt(response.data.unreadThreadsCount)
                            );
                            this.setState({ saveInProgress: false });
                            this.props.onSetUser(response.data);
                            this.props.onConnectionCountSubscribe(
                                response.data.profile.connectionNumber
                            );
                            this.setState(
                                {
                                    loggedIn: true,
                                },
                                () => {
                                    this.handlePopupClose();
                                }
                            );
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            error: e.response.data.detail,
                            saveInProgress: false,
                        });
                    });
            }
        );
    };

    //check if user already exists
    handleCheckValidEmail = (e, props) => {
        //trigger built in formik blur
        props.handleBlur(e);
        if (props.dirty && !props.errors.email) {
            //test if email is already being used
            this.setState(
                {
                    accountEmail: props.values.email,
                },
                () => {
                    const data = {
                        email: props.values.email,
                    };
                    ApiService.emailCheck(data).then((r) => {
                        this.setState({
                            alreadyHaveAccount: r.data.emailInUse,
                            showMessage: r.data.showMessage,
                        });
                    });
                }
            );
        }
    };

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    handlePopupOpen2 = () => {
        this.setState({
            popupOpen2: true,
        });
    };

    handlePopupClose2 = () => {
        this.setState({
            popupOpen2: false,
        });
    };

    //change autuh type
    handleRadioChange = (e) => {
        this.setState({
            authTypeValue: e.target.value,
        });
    };

    //two fa code change
    handleCodeInputChange = (e) => {
        this.setState({
            inputCode: e,
        });
    };

    //resend two fa  code
    handleResendCode = (e) => {
        e.preventDefault();
        this.setState(
            {
                error: null,
            },
            () => {
                ApiService.resendCode(this.state.twoFaId).then((r) => {
                    this.setState(
                        {
                            resendSuccess: true,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    resendSuccess: false,
                                });
                            }, 2000);
                        }
                    );
                });
            }
        );
    };

    handleSubmit = (values) => {
        let plan;
        if (window.location.href.includes('essential')) {
            plan = 'essential';
        } else if (window.location.href.includes('celebration')) {
            plan = 'celebration-of-life';
        } else if (window.location.href.includes('imagined')) {
            plan = 'imagined';
        } else if (window.location.href.includes('simple')) {
            plan = 'simple';
        }

        this.setState(
            {
                buttonIsLoading: true,
            },
            () => {
                if (1) {
                    this.setState({
                        checkboxChecked: true,
                    });
                    const data = {
                        firstName: values.firstName,
                        email: values.email,
                        forMe: values.forMe === 'true' ? true : false,
                        mobile: values.phone,
                        plan: `/api/funeral_plans/${plan}`,
                        demandsNeeds: true,
                    };

                    const isOnSimplePlan =
                        window.location.href.includes('simple');
                    const stepsToSkip = isOnSimplePlan ? 3 : 1;
                    if (this.props.user) {
                        if (
                            this.props.user.userFuneralPlan ||
                            (this.props.funeralPayment &&
                                this.props.funeralPayment.uuid)
                        ) {
                            const funeralPlanId = this.props.funeralPayment
                                ? this.props.funeralPayment.uuid
                                : this.props.user.userFuneralPlan.split('/')[3];
                            ApiService.patchFuneralPlan(
                                data,
                                funeralPlanId
                            ).then((r) => {
                                if (window.location.href.includes('simple')) {
                                    const data = {
                                        step: 3,
                                    };
                                    ApiService.funeralPlanStepUpdate(
                                        this.props.funeralPayment.uuid,
                                        data
                                    ).then((res) => {
                                        this.props.onPatchFirstStep(r.data);
                                        this.props.onSetFuneralPlan(plan);
                                        this.props.onSetCurrentStep(
                                            this.props.funeralPayment
                                                .currentStep + stepsToSkip
                                        );
                                        this.setState({
                                            buttonIsLoading: false,
                                        });
                                    });
                                } else {
                                    this.props.onPatchFirstStep(r.data);
                                    this.props.onSetFuneralPlan(plan);
                                    this.props.onSetCurrentStep(
                                        this.props.funeralPayment.currentStep +
                                            stepsToSkip
                                    );
                                    this.setState({
                                        buttonIsLoading: false,
                                    });
                                }
                            });
                        } else {
                            ApiService.createFuneralPlan(data).then((r) => {
                                if (window.location.href.includes('simple')) {
                                    const data = {
                                        step: 3,
                                    };
                                    ApiService.funeralPlanStepUpdate(
                                        r.data.uuid,
                                        data
                                    ).then((res) => {
                                        this.props.onSetFirstStep(r.data);
                                        this.props.onSetFuneralPlan(plan);
                                        this.props.onSetCurrentStep(
                                            this.props.funeralPayment
                                                .currentStep + stepsToSkip
                                        );
                                        this.setState({
                                            buttonIsLoading: false,
                                        });
                                    });
                                } else {
                                    this.props.onSetFirstStep(r.data);
                                    this.props.onSetFuneralPlan(plan);
                                    this.props.onSetCurrentStep(
                                        this.props.funeralPayment.currentStep +
                                            stepsToSkip
                                    );
                                    this.setState({
                                        buttonIsLoading: false,
                                    });
                                }
                            });
                        }
                    } else {
                        ApiService.registserOnFuneralPlan(data)
                            .then((r) => {
                                const token = r.data.token;
                                const refreshToken = r.data.refresh_token;

                                localStorage.setItem('token', token);
                                localStorage.setItem(
                                    'tokenRefreshedDate',
                                    new Date()
                                );
                                localStorage.setItem(
                                    'refreshToken',
                                    refreshToken
                                );

                                if (window.location.href.includes('simple')) {
                                    const data = {
                                        step: 3,
                                    };
                                    ApiService.funeralPlanStepUpdate(
                                        r.data.userFuneralPlan.uuid,
                                        data
                                    ).then((res) => {
                                        //login user
                                        this.props.onSetUser(r.data.user);
                                        //set token
                                        this.props.setToken(r.data.token);
                                        //set first step data
                                        this.props.onSetFirstStep(
                                            r.data.userFuneralPlan
                                        );
                                        //set plan
                                        this.props.onSetFuneralPlan(plan);
                                        //make a step up
                                        this.props.onSetCurrentStep(
                                            this.props.funeralPayment
                                                .currentStep + stepsToSkip
                                        );
                                        this.setState({
                                            buttonIsLoading: false,
                                        });
                                    });
                                } else {
                                    //login user
                                    this.props.onSetUser(r.data.user);
                                    //set token
                                    this.props.setToken(r.data.token);
                                    //set first step data
                                    this.props.onSetFirstStep(
                                        r.data.userFuneralPlan
                                    );
                                    //set plan
                                    this.props.onSetFuneralPlan(plan);
                                    //make a step up
                                    this.props.onSetCurrentStep(
                                        this.props.funeralPayment.currentStep +
                                            stepsToSkip
                                    );
                                    this.setState({
                                        buttonIsLoading: false,
                                    });
                                }
                            })
                            .catch((e) => {
                                this.setState({
                                    popupOpen: true,
                                    buttonIsLoading: false,
                                });
                            });
                    }
                } else {
                    this.setState({
                        checkboxChecked: false,
                        buttonIsLoading: false,
                    });
                }
            }
        );
    };

    logOut = (form) => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenRefreshedDate');
        window.location.reload();
    };

    handleBackToLogin = () => {
        this.setState({
            emailSent: false,
            forgotPassword: false,
        });
    };

    render() {
        let introMessage;
        let mobilePhone = this.state.loginData
            ? this.state.loginData.user.mobile
            : null;
        let hiddenPhone = this.state.loginData
            ? this.state.loginData.user.mobile
                ? mobilePhone.replace(mobilePhone.substr(4, 5), '*****')
                : null
            : null;
        if (parseInt(this.state.authTypeValue) === 2) {
            introMessage = (
                <p className="c-auth-page__auth-form--intro-text c-auth-page__auth-form--intro-text-with-span font-18">
                    Please enter the code sent to your email address{' '}
                    <span className="font-semibold">
                        (
                        {this.state.loginData
                            ? this.state.loginData.user.email
                            : null}
                        )
                    </span>
                </p>
            );
        } else {
            introMessage = (
                <p className="c-auth-page__auth-form--intro-text font-18 c-auth-page__auth-form- log in with your email-intro-text-with-span">
                    Please enter the code sent to your mobile device{' '}
                    <span className="font-semibold">(+{hiddenPhone})</span>
                </p>
            );
        }

        let popupContent = (
            <>
                <img
                    className="o-flex-self-center c-funeral__nav--logo"
                    src={logo}
                    alt="logo"
                ></img>
                <p className="font-20">
                    Please log in with your email address and password below
                </p>
                <Formik
                    initialValues={{
                        email: this.state.accountEmail,
                        password: '',
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email('Please enter a valid E-mail Address.')
                            .required('This field is required.'),
                        password: Yup.string().required(
                            'This field is required.'
                        ),
                    })}
                    onSubmit={this.handleSubmitLogin}
                >
                    {(props) => (
                        <>
                            <Form
                                noValidate
                                className="o-flex o-flex-column c-funeral__popup--login-form"
                            >
                                {this.state.loginError ? (
                                    <div className="font-13 font-white font-semibold opacity-9 status-message-error o-flex o-flex-between">
                                        {this.state.loginError}
                                        <i
                                            className="icon-times font-white opacity-9 font-13"
                                            onClick={this.closeError}
                                        ></i>
                                    </div>
                                ) : null}
                                <RequiredInput
                                    name="email"
                                    label="Email"
                                    type="Email"
                                />
                                {this.state.showMessage ? (
                                    <span className="c-funeral__popup--note">
                                        We have sent you an initial password
                                        with welcome email.
                                    </span>
                                ) : null}
                                <div className="c-funeral__popup--pass-box">
                                    <PasswordInput
                                        source="password"
                                        label="Password"
                                        name="password"
                                    />
                                    <span
                                        className="link-underline c-funeral__popup--link"
                                        onClick={() =>
                                            this.setState({
                                                forgotPassword: true,
                                            })
                                        }
                                    >
                                        Forgot Password
                                    </span>
                                </div>
                                <Button
                                    disabled={
                                        props.values.email === '' ||
                                        props.values.password === ''
                                    }
                                    className={`btn btn--full btn-loader ${
                                        this.state.saveInProgress
                                            ? 'save-in-progress'
                                            : ''
                                    } position-relative overflow-both-hidden z-index-1`}
                                    type="submit"
                                >
                                    Log in{' '}
                                    <img
                                        width="100%"
                                        height="100%"
                                        src={loader}
                                        alt="loaderImage"
                                    />
                                </Button>
                                {/* asdfg */}
                                {this.state.accountEmail ? (
                                    <div className="c-funeral__popup--not-email">
                                        Not {this.state.accountEmail}?{' '}
                                        <button
                                            onClick={() =>
                                                this.handlePopupClose()
                                            }
                                            className="link-underline"
                                            href="/"
                                        >
                                            {' '}
                                            Go back
                                        </button>
                                    </div>
                                ) : null}
                            </Form>
                        </>
                    )}
                </Formik>
            </>
        );

        let popupContent2 = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle}
                </h1>

                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent)}
                </div>
                <div>
                    <img src={this.state.infoPhoto} alt="" />
                </div>
            </>
        );

        if (this.state.loginData) {
            popupContent = (
                <form
                    noValidate
                    className="c-funeral__2-factor o-flex o-flex-column"
                >
                    <img
                        className="o-flex-self-center c-funeral__nav--logo"
                        src={logo}
                        alt="logo"
                    ></img>
                    <p>
                        Please choose how you want to receive the Secure Login
                        confirmation code
                    </p>
                    <ul
                        className="o-flex o-flex-center"
                        role="group"
                        aria-labelledby="two-factor-choice"
                    >
                        <li
                            className={`c-form-item-radio o-flex o-flex-column o-flex-middle ${
                                this.state.mobile === 'null' ||
                                this.state.mobile === ''
                                    ? 'disabled'
                                    : null
                            }`}
                        >
                            <input
                                disabled={
                                    this.state.mobile === 'null' ||
                                    this.state.mobile === ''
                                }
                                onChange={(e) => this.handleRadioChange(e)}
                                type="radio"
                                name="authType"
                                id="authTypePhone"
                                value={1}
                            />
                            <label
                                className={`font-bold font-16 o-flex o-flex-column o-flex-middle ${
                                    this.state.mobile === 'null' ||
                                    this.state.mobile === ''
                                        ? 'disabled'
                                        : null
                                }`}
                                htmlFor="authTypePhone"
                            >
                                <i className="c-form-item-radio__icon icon-phone-device"></i>
                                Mobile Phone (SMS)
                            </label>
                            {this.state.mobile === 'null' ||
                            this.state.mobile === '' ? (
                                <span className="font-14 font-333 opacity-8 font-500">
                                    Phone not added on profile
                                </span>
                            ) : null}
                        </li>
                        <li className="c-form-item-radio o-flex o-flex-column o-flex-middle">
                            <input
                                onChange={(e) => this.handleRadioChange(e)}
                                type="radio"
                                name="authType"
                                id="authTypeEmail"
                                value={2}
                            />
                            <label
                                className="font-bold font-16 o-flex o-flex-column o-flex-middle"
                                htmlFor="authTypeEmail"
                            >
                                <i className="c-form-item-radio__icon icon-email-symbol"></i>
                                Email
                            </label>
                        </li>
                    </ul>
                    <Button
                        disabled={!this.state.authTypeValue}
                        className={`btn btn--full btn-loader ${
                            this.state.saveInProgress ? 'save-in-progress' : ''
                        } position-relative overflow-both-hidden z-index-1`}
                        onClick={($event) => this.handleSubmitSendCode($event)}
                    >
                        Send Code{' '}
                        <img
                            width="100%"
                            height="100%"
                            src={loader}
                            alt="loaderImage"
                        />
                    </Button>
                </form>
            );
        }

        if (this.state.forgotPassword) {
            popupContent = (
                <>
                    {this.state.emailSent ? (
                        <div className="o-flex-from-tablet c-funeral__popup__forgot-pass-msg margin-auto z-index-1 o-d-block-tablet o-flex-center o-flex-top o-flex-column width-full text-center">
                            <img
                                className="margin-auto"
                                src={forgotImage}
                                alt="forgotImage"
                            />
                            <p className="text-center">
                                Your Password has been reset.
                                <span className="text-center">
                                    We’ve sent instructions to your email
                                    address that will help you set up a new
                                    password
                                </span>
                            </p>
                            <button
                                className="btn btn--full sub mit-button o-d-block-tablet position-relative overflow-both-hidden z-index-1 width-full text-center"
                                onClick={() => this.handleBackToLogin()}
                            >
                                Back to Login
                            </button>
                        </div>
                    ) : (
                        <Formik
                            initialValues={{
                                email: '',
                                fullUserName: '',
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .email(
                                        'Please enter a valid E-mail Address.'
                                    )
                                    .required('This field is required.'),
                                fullUserName: Yup.string(),
                            })}
                            onSubmit={(values) => {
                                this.setState(
                                    {
                                        buttonLoading: true,
                                    },
                                    () => {
                                        let data;
                                        if (values.fullUserName === '') {
                                            data = {
                                                email: values.email,
                                            };
                                        } else {
                                            data = {
                                                email: values.email,
                                                user_full_name:
                                                    values.fullUserName,
                                            };
                                        }
                                        ApiService.forgotPassword(data)
                                            .then((r) => {
                                                this.setState({
                                                    emailSent: true,
                                                    buttonLoading: false,
                                                });
                                            })
                                            .catch((err) => {
                                                this.setState({
                                                    emailNotFound:
                                                        'Email address does not exist',
                                                    buttonLoading: false,
                                                });
                                            });
                                    }
                                );
                            }}
                        >
                            {(props) => (
                                <>
                                    <div className="c-funeral__popup__forgot-pass z-index-1 o-d-block-tablet o-flex-from-tablet o-flex-center o-flex-top o-flex-column width-full">
                                        <img
                                            className="c-funeral__nav--logo o-flex-self-center"
                                            src={imageLogo}
                                            alt="logo"
                                        />
                                        <p>Enter your email address below...</p>
                                        <Form noValidate>
                                            {this.state.emailNotFound ? (
                                                <div className="font-13 font-white font-semibold opacity-9 status-message-error o-flex o-flex-between">
                                                    {this.state.emailNotFound}
                                                    <i
                                                        className="icon-times font-white opacity-9 font-13"
                                                        onClick={
                                                            this.closeError
                                                        }
                                                    ></i>
                                                </div>
                                            ) : null}
                                            <RequiredInput
                                                allprops={props}
                                                label="Your email"
                                                name="email"
                                                type="Email"
                                            />
                                            <div className="hide">
                                                <Input
                                                    placeholder="Full User Name"
                                                    name="fullUserName"
                                                    type="text"
                                                />
                                            </div>
                                            <Button
                                                disabled={
                                                    props.errors.email ||
                                                    props.values.email
                                                        .length === 0
                                                }
                                                className={`btn--size-54 btn-loader ${
                                                    this.state.buttonLoading
                                                        ? 'save-in-progress'
                                                        : ''
                                                } btn-loader btn btn--full submit-button width-full position-relative overflow-both-hidden z-index-1`}
                                                type="submit"
                                            >
                                                Reset Password{' '}
                                                <img
                                                    width="100%"
                                                    height="100%"
                                                    src={loader}
                                                    alt="loaderImage"
                                                />
                                            </Button>
                                        </Form>
                                    </div>
                                </>
                            )}
                        </Formik>
                    )}
                </>
            );
        }

        if (this.state.codeSent) {
            popupContent = (
                <>
                    <div className="o-flex o-flex-column c-funeral__safe-code">
                        <img
                            className="o-flex-self-center c-funeral__nav--logo"
                            src={logo}
                            alt="logo"
                        ></img>
                        {introMessage}
                        <form
                            noValidate
                            className="o-flex o-flex-column position-relative"
                        >
                            <ReactCodeInput
                                className="o-flex o-flex-wrap safe-code"
                                onChange={(e) => this.handleCodeInputChange(e)}
                                type="numeric"
                                fields={6}
                            />
                            <span
                                className={`savedIndicator o-flex o-flex-middle ${
                                    this.state.resendSuccess
                                        ? 'opacity-1 o-d-flex-mobile'
                                        : 'opacity-0'
                                }`}
                            >
                                <i className="icon-checkmark"></i>
                                <span className="o-d-block font-500 opacity-9">
                                    Code successfully resent
                                </span>
                            </span>
                            <span className="c-auth-page__auth-form--cta-right">
                                <a
                                    onClick={($event) =>
                                        this.handleResendCode($event)
                                    }
                                    className="link-underline font-14 font-semibold"
                                    href="/"
                                >
                                    Resend Code
                                </a>
                            </span>
                            {this.state.error ? (
                                <p className="font-13 font-semibold font-form-error error-message required-icon position-absolute">
                                    Code you've entered is invalid.
                                </p>
                            ) : null}
                            <Button
                                className={`btn btn--full btn-loader o-flex o-flex-middle o-flex-center ${
                                    this.state.saveInProgress
                                        ? 'save-in-progress'
                                        : ''
                                } position-relative overflow-both-hidden z-index-1`}
                                onClick={($event) =>
                                    this.handleSubmitCode($event)
                                }
                            >
                                <i className="icon-lock-secure margin-right-10 font-21 mt-5-negative"></i>
                                Secure Login
                                <img
                                    width="100%"
                                    height="100%"
                                    src={loader}
                                    alt="loaderImage"
                                />
                            </Button>
                        </form>
                    </div>
                </>
            );
        }

        const phoneRegExp =
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

        const validationSchema = Yup.object({
            firstName: Yup.string().required('This field is required.'),
            email: Yup.string()
                .email('Please enter a valid E-mail Address')
                .required('This field is required.'),
            phone: Yup.string()
                .matches(phoneRegExp, 'Phone number is not valid')
                .required('This field is required.'),
            forMe: Yup.boolean().required('This field is required.'),
        });

        const initialValues = {
            firstName: this.state.funeralPlan?.firstName
                ? this.state.funeralPlan?.firstName
                : this.props.user
                ? this.props.user.profile.firstName
                    ? this.props.user.profile.firstName
                    : this.props.user.name
                : this.props.funeralPayment
                ? this.props.funeralPayment.holderFirstName
                : '',
            email: this.props.user
                ? this.props.user.email
                : this.props.funeralPayment.email
                ? this.props.funeralPayment.email
                : '',
            phone: this.state.funeralPlan?.mobile
                ? this.state.funeralPlan?.mobile
                : this.props.user && this.props.user.profile.mobile
                ? this.props.user.profile.mobile
                : this.props.funeralPayment && this.props.funeralPayment.mobile
                ? this.props.funeralPayment.mobile
                : '',
            forMe: this.props.funeralPayment
                ? this.props.funeralPayment.forMe ||
                  this.props.funeralPayment.forMe === null
                    ? 'true'
                    : this.props.user
                    ? 'false'
                    : 'true'
                : 'true',
        };

        let content = <Loader />;

        if (!this.state.isLoading) {
            content = (
                <div className="c-funeral__contact">
                    <h2>
                        Please tell us some brief details
                    </h2>

                    <h6>Please enter your name and contact details.</h6>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => this.handleSubmit(values)}
                    >
                        {(props, values) => {
                            return (
                                <Form className="c-funeral__contact--form">
                                    <RequiredInput
                                        type="text"
                                        name="firstName"
                                        label="First name"
                                    ></RequiredInput>
                                    <RequiredInput
                                        disabled={this.props.user}
                                        onBlur={(e) =>
                                            this.handleCheckValidEmail(e, props)
                                        }
                                        type="email"
                                        name="email"
                                        label="Email address"
                                    ></RequiredInput>
                                    <div className="c-funeral__contact--form-login">
                                        <span>
                                            This is where we’ll send
                                            confirmation of your plan.
                                        </span>
                                        {!this.props.user ? (
                                            <p>
                                                Already have an Account?
                                                <span
                                                    className="link-underline"
                                                    onClick={
                                                        this.handlePopupOpen
                                                    }
                                                >
                                                    Log in
                                                </span>
                                            </p>
                                        ) : (
                                            <p>
                                                <span
                                                    className="link-underline"
                                                    onClick={() =>
                                                        this.logOut(props)
                                                    }
                                                >
                                                    Log out
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                    <RequiredInput
                                        type="text"
                                        name="phone"
                                        label="Phone number"
                                    />
                                    <div className="c-funeral__contact--form-login">
                                        <span>
                                            We’ll use your number if there are
                                            any questions to answer.
                                        </span>
                                    </div>
                                    <h3>
                                        Is this plan for you or someone else?
                                    </h3>
                                    <div className="c-funeral__contact--form-radio">
                                        <Field
                                            id="firstplan"
                                            type="radio"
                                            name="forMe"
                                            value="true"
                                        />
                                        <label htmlFor="firstplan">
                                            <img
                                                src={planForImage}
                                                alt="plan-for"
                                            ></img>
                                            For me
                                        </label>
                                        <Field
                                            id="secondplan"
                                            type="radio"
                                            name="forMe"
                                            value="false"
                                        />
                                        <label htmlFor="secondplan">
                                            <img
                                                src={planForImage}
                                                alt="plan-for"
                                            ></img>
                                            For someone else
                                        </label>
                                    </div>

                                    <h4>How and why we use your information</h4>
                                    <div className="c-funeral__contact--privacy">
                                        We will use the information provided to
                                        process your application and, should
                                        your plan go ahead, to manage your plan
                                        and contact you with information on the
                                        Aura Circle. As part of your application
                                        you get exclusive access to the Aura
                                        Circle - with tools, resources, guides
                                        and community support to help plan your
                                        celebration of life. You can read our{' '}
                                        <span
                                            onClick={this.handlePopupOpen2}
                                            className="link-underline"
                                        >
                                            Privacy Policy
                                        </span>{' '}
                                        here and can opt out at any time.
                                    </div>
                                    <Navigation
                                        buttonLoading={
                                            this.state.buttonIsLoading
                                        }
                                        backHidden={true}
                                        submit={true}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                    <Popup
                        lockScroll
                        open={this.state.popupOpen}
                        onClose={this.handlePopupClose}
                        className=" c-funeral__popup c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                    >
                        {(close) => (
                            <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                <button
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                    onClick={close}
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                                {popupContent}
                            </div>
                        )}
                    </Popup>
                    <Popup
                        lockScroll
                        open={this.state.popupOpen2}
                        onClose={this.handlePopupClose2}
                        className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                    >
                        {(close) => (
                            <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                <button
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                    onClick={close}
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                                {popupContent2}
                            </div>
                        )}
                    </Popup>
                </div>
            );
        }

        return content;
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
    funeralPayment: state.funeralPaymentReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actionTypes.onLogoutUser()),
        onSetUser: (user) => dispatch(actionTypes.onSetUser(user)),
        setToken: (token) => dispatch(actionTypes.setToken(token)),
        onSetToken: (token) => dispatch(actionTypes.onSetToken(token)),
        onSetRefreshToken: (refreshToken) =>
            dispatch(actionTypes.onSetRefreshToken(refreshToken)),
        onUnreadThreadCountSubscribe: (threadCount) =>
            dispatch(
                messagesActionTypes.onUnreadThreadCountSubscribe(threadCount)
            ),
        onConnectionCountSubscribe: (connCount) =>
            dispatch(
                connectionActionTypes.onConnectionCountSubscribe(connCount)
            ),
        onSetCurrentStep: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(currentStep)),
        onSetFirstStep: (firstStepData) =>
            dispatch(funeralPaymentActionTypes.onSetFirstStep(firstStepData)),
        onPatchFirstStep: (stepData) =>
            dispatch(funeralPaymentActionTypes.onPatchFirstStep(stepData)),
        onLogoutPaymentUser: () =>
            dispatch(funeralPaymentActionTypes.onLogoutPaymentUser()),
        onSetFuneralPlan: (plan) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralPlan(plan)),
        onSetFirstStepPlan: (data) =>
            dispatch(funeralPaymentActionTypes.onSetFirstStepPlan(data)),
        onSetTotalAmount: (price) =>
            dispatch(funeralPaymentActionTypes.onSetTotalAmount(price)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
