import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import React, { Component } from 'react';

import noIcon from '../../../assets/images/aura2022/no-icon.svg';
import yesIcon from '../../../assets/images/aura2022/yes-icon.svg';
import bgLogo from '../../../assets/images/aura2022/infinityHpSign.svg';
import Footer from '../../../components/UI/Footer/Footer';
import Header from '../../../components/Header/Header';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
import ApiService from '../../../service/api.service';
import Loader from '../../../components/UI/Loader/Loader';
import ReactHtmlParser from 'react-html-parser';
import Slider from 'react-slick';
import AngelsSingleSlide from '../../../components/FuneralPlans/Angels/AngelsSingleSlide/AngelsSingleSlide';
import BlogListItem from '../../../components/UI/BlogListItem/BlogListItem';
import AngelTestimonial from '../../../components/FuneralPlans/Testimonials/AngelTestimonial/AngelTestimonial';
import Popup from 'reactjs-popup';
import MultipleVideo from '../../../components/FuneralPlans/MultipleVideo/MultipleVideo';
class AuraAngels extends Component {
    state = {
        content: null,
        prevButton: 0,
        nextButton: 0,
        popupOpen: false,
        popupContent: '',
    };

    componentDidMount() {
        ApiService.auraAngels()
            .then((r) => {
                this.setState({
                    content: r.data,
                });
            })
            .then((r) => {
                this.setState({
                    nextButton: 1,
                    prevButton:
                        this.state.content.carouselsPublished.length - 1,
                });
            });
        window.scrollTo(0, 0);
    }

    handleSlickChange = (slick) => {
        this.setState({
            nextButton:
                slick === this.state.content.carouselsPublished.length - 1
                    ? 0
                    : slick + 1,
            prevButton: slick - 1,
        });
        if (
            slick === this.state.content.carouselsPublished.length ||
            slick === 0
        ) {
            this.setState({
                nextButton: 1,
                prevButton: this.state.content.carouselsPublished.length - 1,
            });
        }
    };

    handlePopupOpen = (popupContent) => {
        this.setState({
            popupContent: popupContent,
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
            popupContent: '',
        });
    };

    render() {
        let content = <Loader />;

        const settings = {
            arrows: true,
            autoplay: false,
            dots: false,
            speed: 350,
            touchThreshold: 40,
            slidesToScroll: 1,
            infinite: true,
            slidesToShow: 1,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        dots: true,
                        arrows: false,
                    },
                },
            ],

            afterChange: (current) => this.handleSlickChange(current),
        };

        let slides;
        let article;
        let testimonials;

        if (this.state.content?.testimonialsPublished.length) {
            testimonials = this.state.content.testimonialsPublished.map(
                (testimonial, index) => {
                    return (
                        <AngelTestimonial
                            key={`testimonial-${index}`}
                            body={ReactHtmlParser(
                                testimonial.quote ? testimonial.quote : ''
                            )}
                        />
                    );
                }
            );
        }

        if (this.state.content?.blogPosts.length) {
            article = this.state.content.blogPosts.map((el, index) => {
                return (
                    <BlogListItem
                        key={`latestBlog-${index}`}
                        article={el}
                        location={window.location.pathname}
                        type={'article'}
                    />
                );
            });
        }

        if (this.state.content?.carouselsPublished.length) {
            slides = this.state.content.carouselsPublished.map(
                (slide, index) => (
                    <AngelsSingleSlide
                        key={`angel-single-slide-${index}`}
                        additionalInfo={slide.additionalInfo}
                        body={slide.body}
                        image={slide.image}
                        name={slide.name}
                        title={slide.title}
                        handlePopupOpen={() => this.handlePopupOpen(slide)}
                    />
                )
            );
        }

        if (this.state.content) {
            content = (
                <>
                    {/* header start */}
                    <MultipleVideo
                        horizontalVideo={this.state.content.horizontalVideo}
                        verticalVideo={this.state.content.verticalVideo}
                        popupVideo={this.state.content.video}
                        horizontalImage={this.state.content.horizontalImage}
                        verticalImage={this.state.content.verticalImage}
                        title={this.state.content.headerTitle}
                        description={this.state.content.headerIntro}
                        ctaTitle={this.state.content.headerCTATitle}
                        ctaLink={this.state.content.headerCTAUrl}
                        type="angels"
                    />
                    {/* header end */}
                    <div className="c-angels__highlite container">
                        <div className="c-angels__highlite--bottom">
                            <h2>
                                {ReactHtmlParser(
                                    this.state.content.blockTitle
                                        ? this.state.content.blockTitle
                                        : ''
                                )}
                            </h2>
                            <div className="c-angels__highlite--two-col two-col">
                                <div>
                                    <img
                                        src={this.state.content.block1Image}
                                        alt="block1Image"
                                    />
                                    <h3>{this.state.content.block1Title}</h3>
                                    <div>
                                        {ReactHtmlParser(
                                            this.state.content.block1Body
                                                ? this.state.content.block1Body
                                                : ''
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <img
                                        src={this.state.content.block2Image}
                                        alt="block2Image"
                                    />
                                    <h3>{this.state.content.block2Title}</h3>
                                    <div>
                                        {ReactHtmlParser(
                                            this.state.content.block2Body
                                                ? this.state.content.block2Body
                                                : ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container c-angels__angel-slider">
                        <div className="c-plans__testimonials">
                            <h2>
                                {ReactHtmlParser(
                                    this.state.content.angelsTitle
                                        ? this.state.content.angelsTitle
                                        : ''
                                )}
                            </h2>
                            <div className="container o-flex o-flex-center">
                                <div
                                    className="next-slide c-plans__testimonials--testimonials-img"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.content
                                                .carouselsPublished[
                                                this.state.prevButton
                                            ]?.arrowImage
                                        })`,
                                    }}
                                >
                                    <div className="c-plans__testimonials--img-overlay"></div>
                                </div>
                                <div>
                                    <Slider
                                        {...settings}
                                        initialSlide={0}
                                        className="c-faq"
                                        onChange={(slick) =>
                                            this.handleSlickChange(slick)
                                        }
                                    >
                                        {slides}
                                    </Slider>
                                </div>
                                <div
                                    className="next-slide c-plans__testimonials--testimonials-img"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.content
                                                .carouselsPublished[
                                                this.state.nextButton
                                            ]?.arrowImage
                                        })`,
                                    }}
                                >
                                    <div className="c-plans__testimonials--img-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="c-angels__quotes container">
                        <h2>{this.state.content.testimonialsTitle}</h2>
                        <div className="o-flex">{testimonials}</div>
                    </div>
                    <div className="c-angels__table-section">
                        <div className="container">
                            <div className="c-angels__table-section--head text-center margin-auto">
                                <h2>
                                    What Aura Angel support is available to me?
                                </h2>
                                <p>
                                    Our two plans come with different levels of
                                    Aura Angel support, depending on whether you
                                    would like a no fuss, simple cremation, or a
                                    vibrant celebration of life either at the
                                    crematorium or at a later date.
                                </p>
                            </div>
                            <h3>Now</h3>
                            <div className="c-angels__table-section--plan">
                                <div className="c-angels__table-section--plan-head">
                                    <h4>Angel support:</h4>
                                    <div className="o-flex c-angels__table-section--plan-head-right">
                                        <p>Perfectly Simple</p>
                                        <p>Wonderfully Imagined</p>
                                    </div>
                                </div>
                                <div className="c-angels__table-section--plan-body">
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                One-to-one funeral and
                                                celebration of life planning
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Planning & legacy tools set-up
                                                and assistance
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Introduction to the Aura
                                                communities
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                End-of-life admin and account
                                                closure preparation
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <h3>Ongoing</h3>
                            <div className="c-angels__table-section--plan">
                                <div className="c-angels__table-section--plan-head">
                                    <h4>Angel support:</h4>
                                    <div className="o-flex c-angels__table-section--plan-head-right">
                                        <p>Perfectly Simple</p>
                                        <p>Wonderfully Imagined</p>
                                    </div>
                                </div>
                                <div className="c-angels__table-section--plan-body">
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Changes to your plan at any time
                                            </p>
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>Angel workshops and resources</p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <h3>When the time comes</h3>
                            <div className="c-angels__table-section--plan">
                                <div className="c-angels__table-section--plan-head">
                                    <h4>Angel support:</h4>
                                    <div className="o-flex c-angels__table-section--plan-head-right">
                                        <p>Perfectly Simple</p>
                                        <p>Wonderfully Imagined</p>
                                    </div>
                                </div>
                                <div className="c-angels__table-section--plan-body">
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Direct cremation arrangements
                                                and paperwork
                                            </p>
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>Emotional and grief support</p>
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Crematorium ceremony
                                                arrangements
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Post-cremation celebration of
                                                life support
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Communicate celebration of life
                                                wishes to family
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                    <ul className="o-flex">
                                        <li>
                                            <p>
                                                Celebrant, order of service and
                                                flower arrangements
                                            </p>
                                        </li>
                                        <li>
                                            <img
                                                className="no-icon"
                                                src={noIcon}
                                                alt="no-icon"
                                            />
                                        </li>
                                        <li>
                                            <img src={yesIcon} alt="yes-icon" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.content?.blogPosts.length ? (
                        <div
                            className="c-angels__angel"
                            style={{ backgroundImage: `url(${bgLogo})` }}
                        >
                            <div className="container">
                                <h2>
                                    {ReactHtmlParser(
                                        this.state.content.knowledgeTitle
                                    )}
                                </h2>
                                <div className="c-angels__angel--intro">
                                    {ReactHtmlParser(
                                        this.state.content.knowledgeIntro
                                    )}
                                </div>
                                <div className="o-flex o-flex-between">
                                    {article}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="c-angels__footer-cta text-center">
                        {this.state.content.planWITitle ? (
                            <h2>
                                {this.state.content.planWITitle
                                    ? this.state.content.planWITitle
                                    : ''}
                            </h2>
                        ) : null}
                        {this.state.content.planWIBody ? (
                            <div className="c-angels__footer-cta--intro">
                                {ReactHtmlParser(
                                    this.state.content.planWIBody
                                        ? this.state.content.planWIBody
                                        : ''
                                )}
                            </div>
                        ) : null}
                        {this.state.content.planWICTATitle ? (
                            <a
                                className="o-d-inline-block btn btn--full btn--size-61 btn--size-width-503 text-center btn--padding-left-right"
                                href={this.state.content.planWIURL}
                            >
                                {this.state.content.planWICTATitle}
                            </a>
                        ) : null}
                    </div>
                    <Popup
                        lockScroll
                        modal
                        className="c-help-popup c-popup-wrapper c-popup__angels"
                        open={this.state.popupOpen}
                        onClose={() => this.handlePopupClose()}
                    >
                        {(close) => {
                            return (
                                <>
                                    <div className="c-popup">
                                        <div className="c-popup__angels--head">
                                            <div
                                                className="c-popup__angels--head-img"
                                                style={{
                                                    backgroundImage: `url(${this.state.popupContent.image})`,
                                                }}
                                            ></div>
                                            <div className="c-popup__angels--head-title">
                                                <h3>
                                                    {ReactHtmlParser(
                                                        this.state.popupContent
                                                            .name
                                                    )}
                                                </h3>
                                                <h4>
                                                    {ReactHtmlParser(
                                                        this.state.popupContent
                                                            .title
                                                    )}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="c-popup__body">
                                            {ReactHtmlParser(
                                                this.state.popupContent
                                                    .additionalInfo
                                            )}
                                        </div>
                                    </div>
                                    <button
                                        onClick={close}
                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                    >
                                        <i className="icon-times font-35 cursor-pointer"></i>
                                    </button>
                                </>
                            );
                        }}
                    </Popup>
                </>
            );
        } else {
            content = <Loader />;
        }

        return (
            <>
                <PageMeta
                    title="Aura Angels | Aura"
                    description="Aura Angels | Aura"
                    canonical={window.location.href}
                />
                <div className="c-angels">
                    <Header />
                    {content}
                    <Footer />
                </div>
            </>
        );
    }
}

const auraAngelsWithRouter = withRouter(AuraAngels);

export default auraAngelsWithRouter;
