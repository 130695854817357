import React, { Component } from 'react';
import ComparisonAccordionSingle from './ComaprisonAccordionSingle';
import ReactHtmlParser from 'react-html-parser';

class ComparisonAccordion extends Component {
    render() {
        let comparisonAccordions;

        if (this.props.content.length) {
            comparisonAccordions = this.props.content.map(
                (accordion, index) => {
                    return (
                        <ComparisonAccordionSingle
                            key={`accordion-${index}`}
                            accordion={accordion}
                        />
                    );
                }
            );
        }
        return (
            <div className="light-yellow-background comparison-accordion">
                {this.props.title && <h2>{this.props.title}</h2>}
                {this.props.description && (
                    <p className="text-center">
                        {ReactHtmlParser(this.props.description)}
                    </p>
                )}
                <div className="c-fca-regulation container">
                    {comparisonAccordions}
                </div>
                
            </div>
        );
    }
}

export default ComparisonAccordion;
