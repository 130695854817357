import React, { Component } from 'react';
import dropdownArrow from '../../../assets/images/aura2022/icons/dropdown-arrow.svg';

export default class LocationsBlockButton extends Component {
    state = {
        height: 0,
    };

    handleToggleBox = (e) => {
        const allBtns = document.querySelectorAll('.location-btn');
        const allArrows = document.querySelectorAll(
            '.c-location-tabs__tab--arrow'
        );
        allBtns.forEach((btn) =>
            btn.classList.remove('c-location-tabs__dropdown--show')
        );
        allArrows.forEach((arrow) => arrow.classList.remove('open'));

        this.setState({
            height: this.state.height === 0 ? 'auto' : 0,
        });
    };

    render() {
        return (
            <div className="c-location-tabs__tab">
                {/* Button location */}
                <div className="o-flex c-location-tabs__tab--headline">
                    <a href={this.props.data.link}>{this.props.data.title} </a>
                    {this.props.data.sublocations?.length > 0 && (
                        <div>
                            <img
                                className={`c-location-tabs__tab--arrow ${
                                    this.state.height === 'auto' ? 'open' : ''
                                }`}
                                onClick={this.handleToggleBox}
                                src={dropdownArrow}
                                alt="location-arrow"
                            />
                        </div>
                    )}
                </div>
                {/* Button sublocation */}

                <div
                    className={`c-location-tabs__dropdown location-btn ${
                        this.state.height === 'auto'
                            ? 'c-location-tabs__dropdown--show'
                            : ''
                    }`}
                >
                    {this.props.data.sublocations?.map((sublocation, i) => {
                        return (
                            <div key={i}>
                                <a href={sublocation.link}>
                                    {' '}
                                    {sublocation.title}{' '}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
