import React, { Component } from 'react';
import Footer from '../../../components/UI/Footer/Footer';
import Loader from '../../../components/UI/Loader/Loader';
import OrderHistoryItem from '../../../components/UI/OrderHstory/OrderHistoryItem';
import ApiService from '../../../service/api.service';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
import Header from '../../../components/Header/Header';
export default class OrderHistoryList extends Component {
    state = {
        orderHistory: [],
        isLoading: true,
    };

    componentDidMount() {
      
        document.body.classList.add('white-bg');
        window.scrollTo(0, 0);
        ApiService.getOrderHistory()
            .then((r) => {
                this.setState({
                    orderHistory: r.data,
                    isLoading: false,
                    
                });
             
               
            })
         
            .catch((e) => {
                this.setState({
                    isLoading: false,
                });
                this.props.history.push('/login?redirect=true');
            });
          
     
    }

    componentWillUnmount() {
        document.body.classList.remove('white-bg');
    }

    render() {
     
        let order;
    
        if (this.state.orderHistory.length) {
           
       
            order = this.state.orderHistory.map((item, index) => {
                return <OrderHistoryItem item={item} />;
            });
          
        }

        let content = <Loader />;

        if (!this.state.isLoading) {
            content = (
                <>
                    <div className="c-funeral__form c-funeral__history">
                        <h2>Purchase History</h2>
                        {this.state.orderHistory.length ? (
                            order
                        ) : (
                            <h3 className="text-center c-funeral__history--no-history">
                                You don't have any orders yet.
                            </h3>
                        )}
                    </div>
                </>
            );
        } else {
            content = <Loader />;
        }
        return (
         
            <>
                <Header />
                <div className="c-funeral c-order-history">
                    <PageMeta
                        title="Purchase History | Aura"
                        description="Purchase History | Aura"
                        canonical={window.location.href}
                    />
                    {content}
                </div>
                <Footer />
            </>
        );
    }
}
