import React, { Component } from 'react';
import infinitySign from '../../../assets/images/aura2022/infinityHpSign-gray.svg';
import ReactHtmlParser from 'react-html-parser';

class HowItWorksNew extends Component {
    render() {
        return (
            <div className="light-blue-background c-howitworks-new">
                <img
                    src={infinitySign}
                    alt="Aura Sign"
                    className="c-howitworks-new--infinity"
                />
                {this.props.title && (
                    <h2>{ReactHtmlParser(this.props.title)} </h2>
                )}
                {this.props.description && (
                    <div className="c-howitworks-new__txt container">
                        {ReactHtmlParser(this.props.description)}
                    </div>
                )}
            </div>
        );
    }
}

export default HowItWorksNew;
