import React from 'react';

const ExtraItem = ({ dataItem }) => {
    const listOfExtrasItems = dataItem.map((item) => {
        return (
            <div className="c-funeral-at-need__extras--card" key={item.id}>
                <div
                    className="c-funeral__option--image"
                    style={{ backgroundImage: `url(${item.image})` }}
                >
                    {item.money ? (
                        <span>{item.money}</span>
                    ) : (
                        <span>Included</span>
                    )}
                </div>
                <h4>{item.title}</h4>
            </div>
        );
    });

    return (
        <div className="c-funeral-at-need__extras--full-category">
            {listOfExtrasItems}
        </div>
    );
};

export default ExtraItem;
