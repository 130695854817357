import React, { useState, useRef, useEffect } from 'react';

import { useField } from 'formik';

const RequiredInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [focused, setFocused] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [filled, setFilled] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current.matches(':-internal-autofill-selected')) {
                setFilled(true);
            }
        }, 300);

        setInterval(() => {
            if (
                inputRef &&
                inputRef.current &&
                inputRef.current.matches(':-internal-autofill-selected')
            ) {
                setFilled(true);
                setInvalid(false);
            }
        }, 500);
        // eslint-disable-next-line
    }, []);

    const onFocus = () => {
        inputRef.current.focus();
        setInvalid(false);
        setFocused(true);
    };

    const onBlur = (e) => {
        setFocused(false);
        if (field.value) {
            setInvalid(false);
            setFilled(true);
        } else {
            setInvalid(true);
            setFilled(false);
        }

        if (field.value === '' && !props.notMandatory) {
            setInvalid(true);
        } else {
            setInvalid(false);
        }

        if (e.target.matches(':-internal-autofill-selected')) {
            setInvalid(false);
            setFilled(true);
        }
    };

    const handlePasswordView = () => {
        setPasswordVisible(!passwordVisible);
        inputRef.current.focus();
        setInvalid(false);
        setFocused(true);
    };

    let returnValue = (
        <>
            <div
                onClick={() => onFocus()}
                onBlur={(e) => onBlur(e)}
                className={`c-input c-input__wrap ${
                    (meta.error && meta.touched) || invalid
                        ? 'c-input__invalid'
                        : ''
                } ${focused ? 'c-input__focused' : ''} ${
                    filled ? 'c-input__filled' : ''
                }`}
            >
                {label ? (
                    <label className="c-input__label">
                        {label}
                        <span className="c-input__label--required">*</span>
                    </label>
                ) : null}
                <input
                    onFocus={() => onFocus()}
                    type={passwordVisible ? 'text' : 'password'}
                    ref={inputRef}
                    form="novalidatedform"
                    {...field}
                    {...props}
                ></input>
                <span
                    onClick={() => handlePasswordView()}
                    className="c-input__password-view"
                >
                    <i
                        className={`icon-${passwordVisible ? 'view' : 'hide'}`}
                    ></i>
                </span>
                {meta.touched && meta.error ? (
                    <div className="c-input__error-message">
                        Password is invalid.
                    </div>
                ) : null}
            </div>
        </>
    );

    return returnValue;
};

export default RequiredInput;
