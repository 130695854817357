import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
class Brochure extends Component {
    render() {
        return (
            <div className="light-blue-background">
                <div className="c-brochure container">
                    {this.props.title && <h2>{this.props.title} </h2>}
                    <div className="o-flex two-col">
                        <div>
                            {this.props.content.title && (
                                <h3>{this.props.content.title} </h3>
                            )}
                            {this.props.content.description && (
                                <p>
                                    {ReactHtmlParser(
                                        this.props.content.description
                                    )}
                                </p>
                            )}
                            {this.props.content.ctaTitle && (
                                <a
                                    href={this.props.content.ctaLink}
                                    className="c-btn btn btn--full btn--size-width-241 btn--size-57 text-center o-d-inline-block"
                                >
                                    {this.props.content.ctaTitle}
                                </a>
                            )}
                        </div>
                        <div>
                            <div
                                className="c-brochure--img"
                                style={{
                                    backgroundImage: `url(${this.props.content.image})`,
                                    backgroundPosition: 'left',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Brochure;
