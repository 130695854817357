const local = {
    apiGateway: {
        URL: 'https://dev.aura.etondigital.com',
    },
    JWSToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6W10sInB1Ymxpc2giOlsiKiJdfX0.AWego6RbrYubeWML83xauOfFrqvd2v4ogSnBaisbyr8',
    mercurePort: '9076',
    stripe: {
        STRIPE_PUBLISH_KEY:
            'pk_test_51KLnGSJn5y0Ah0voCGMm7xtSG32ROL1YQPQnhhGc6HpBocfgHEpfM1CEVm0BUbUFlcJReXXqon0zrmno1DcDttH4002eYHQAow',
    },
};

const development = {
    apiGateway: {
        URL: 'https://dev.aura.etondigital.com',
    },
    JWSToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6W10sInB1Ymxpc2giOlsiKiJdfX0.AWego6RbrYubeWML83xauOfFrqvd2v4ogSnBaisbyr8',
    mercurePort: '9076',
    stripe: {
        STRIPE_PUBLISH_KEY:
            'pk_test_51KLnGSJn5y0Ah0voCGMm7xtSG32ROL1YQPQnhhGc6HpBocfgHEpfM1CEVm0BUbUFlcJReXXqon0zrmno1DcDttH4002eYHQAow',
    },
};

const staging = {
    apiGateway: {
        URL: 'https://st.aura.etondigital.com',
    },
    JWSToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6W10sInB1Ymxpc2giOlsiKiJdfX0.AWego6RbrYubeWML83xauOfFrqvd2v4ogSnBaisbyr8',
    mercurePort: '9077',
    stripe: {
        STRIPE_PUBLISH_KEY:
            'pk_test_51KLnGSJn5y0Ah0voCGMm7xtSG32ROL1YQPQnhhGc6HpBocfgHEpfM1CEVm0BUbUFlcJReXXqon0zrmno1DcDttH4002eYHQAow',
    },
};

const production = {
    apiGateway: {
        URL: 'https://www.aura.life',
    },
    JWSToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6W10sInB1Ymxpc2giOlsiKiJdfX0.AWego6RbrYubeWML83xauOfFrqvd2v4ogSnBaisbyr8',
    mercurePort: '9077',
    stripe: {
        STRIPE_PUBLISH_KEY:
            'pk_live_51KLnGSJn5y0Ah0vo65n5H7HgCr6J4qBiQy4GmeMXNe0mtS1dUAxYCaUdvpt4GiNLmIRcY50IhYJ0i3ed56JOrrE400GXcbE9Cv',
    },
};

let config;

switch (process.env.REACT_APP_STAGE) {
    case 'development':
        config = development;
        break;
    case 'staging':
        config = staging;
        break;
    case 'production':
        config = production;
        break;
    default:
        config = local;
        break;
}

export default {
    ...config,
};
