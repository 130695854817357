import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class ContactBlock extends Component {
    render() {
        return (
            <div className="c-contact-block container">
                <div className="o-flex two-col">
                    <div className="position-relative c-contact-block_img-box">
                        {this.props.content.imageStaff && (
                            <div
                                className="c-contact-block--lg-img"
                                style={{
                                    backgroundImage: `url(${this.props.content.imageStaff})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        )}
                        {this.props.content.imageAward && (
                            <div
                                className="c-contact-block--sm-img"
                                style={{
                                    backgroundImage: `url(${this.props.content.imageAward})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        )}
                    </div>
                    <div className="c-contact-block__text-box">
                        {this.props.content.title && (
                            <h2>{this.props.content.title}</h2>
                        )}

                        {this.props.content.description && (
                            <p>
                                {ReactHtmlParser(
                                    this.props.content.description
                                )}
                            </p>
                        )}
                        {this.props.content.ctaTitle && (
                            <a
                                href={
                                    !this.props.content.ctaLink
                                        ? 'tel:+448008085723'
                                        : this.props.content.ctaLink &&
                                          !this.props.content.ctaLink.includes(
                                              'tel'
                                          )
                                        ? this.props.content.ctaLink
                                        : this.props.content.ctaLink.includes(
                                              'tel'
                                          )
                                        ? `tel:${this.props.content.ctaLink.replace(
                                              'http://tel:',
                                              ''
                                          )}`
                                        : ''
                                }
                                className="c-btn btn btn--full btn--size-width-246 btn--size-57 text-center o-d-inline-block"
                            >
                                {this.props.content.ctaTitle}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
// this.props.content.ctaLink.includes('tel')
//                                         ? `tel:+${this.props.content.ctaLink.replace(
//                                               'http://tel:',
//                                               ''
//                                           )}

export default ContactBlock;
