import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class Question extends Component {
    state = {
        isQuestionOpen: false,
    };

    handleQuestionOpen = () => {
        this.setState({ isQuestionOpen: !this.state.isQuestionOpen });
    };

    render() {
        return (
            <>
                <div className="c-faq-question">
                    <div
                        className="c-faq-question__header cursor-pointer position-relative o-flex o-flex-between o-flex-top"
                        onClick={this.handleQuestionOpen}
                    >
                        <span className="c-faq-question__header--title font-20 font-bold margin-right-10">
                            {this.props.faq.question
                                ? this.props.faq.question
                                : this.props.faq.title}
                        </span>
                        <span
                            className={`c-faq-question__header--icon position-relative ${
                                this.state.isQuestionOpen
                                    ? 'c-faq-question__header--icon-body-revealed'
                                    : 'c-faq-question__header--icon-body-hidden'
                            }`}
                        ></span>
                    </div>
                    <div
                        className={`c-faq-question__body font-333 font-18 font-normal lh-28 ${
                            this.state.isQuestionOpen ? 'visible' : ''
                        }`}
                    >
                        {ReactHtmlParser(
                            this.props.faq.answer
                                ? this.props.faq.answer
                                : this.props.faq.body
                        )}
                    </div>
                </div>
            </>
        );
    }
}

export default Question;
