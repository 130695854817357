import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export default class ComparisonAccordionSingle extends Component {
    state = {
        isQuestionOpen: false,
    };

    handleQuestionOpen = () => {
        this.setState({ isQuestionOpen: !this.state.isQuestionOpen });
    };
    render() {
        return (
            <div className="c-faq-question">
                <div
                    className="c-faq-question__header cursor-pointer position-relative o-flex o-flex-between o-flex-top"
                    onClick={this.handleQuestionOpen}
                >
                    {/* {this.props.title && <h2>{this.props.title}</h2>} */}
                    {this.props.accordion.title && (
                        <h3>{this.props.accordion.title}</h3>
                    )}

                    <span
                        onClick={this.handleQuestionOpen}
                        className={`c-faq-question__header--icon position-relative c-faq-question__header--icon-body-${
                            this.state.isQuestionOpen ? 'revealed' : 'hidden'
                        }`}
                    ></span>
                </div>
                <div
                    className={`c-faq-question__body font-333 font-18 font-normal lh-28 ${
                        this.state.isQuestionOpen ? 'visible' : ''
                    }`}
                >
                    <hr />
                    {this.props.accordion.description && (
                        <p>
                            {ReactHtmlParser(this.props.accordion.description)}
                        </p>
                    )}
                    <div className="c-fca-regulation__comparison-accordion-table-wrapper">
                        <div>
                            {ReactHtmlParser(this.props.accordion.tableEditor)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
