import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class PlanCost extends Component {
    render() {
        return (
            <div className="c-plan-cost container">
                {this.props.title && <h2> {this.props.title}</h2>}
                {this.props.description && (
                    <p>{ReactHtmlParser(this.props.description)} </p>
                )}
                <div className="c-plan-cost__box">
                    {this.props.content.map((block, index) => {
                        return (
                            <div
                                key={`plan-${index + 1}`}
                                className="c-plan-cost__box--item"
                            >
                                {block.image && (
                                    <img src={block.image} alt="plan-icon" />
                                )}
                                {block.title && <h4>{block.title} </h4>}
                                {block.price && <span>{block.price} </span>}
                                {block.description && (
                                    <p>{ReactHtmlParser(block.description)}</p>
                                )}
                                {block.ctaLink && (
                                    <a href={block.ctaLink}>{block.ctaTitle}</a>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default PlanCost;
