import React, { useEffect, useState, useRef } from 'react';

import { useField } from 'formik';

const RequiredInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [focused, setFocused] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
        inputRef.current.blur();
        if (field.value) {
            setFocused(false);
        }
        // eslint-disable-next-line
    }, []);

    const onFocus = () => {
        inputRef.current.focus();
        setFocused(true);
    };

    const onBlur = () => {
        setFocused(false);
    };

    let returnValue = (
        <>
            <div
                onClick={() => onFocus()}
                onBlur={() => onBlur()}
                className={`c-input c-input__wrap ${
                    focused ? 'c-input__focused' : ''
                } ${meta.value ? 'c-input__filled' : ''} `}
            >
                {label ? (
                    <label className="c-input__label">{label}</label>
                ) : null}
                <input
                    onFocus={() => onFocus()}
                    ref={inputRef}
                    form="novalidatedform"
                    {...field}
                    {...props}
                ></input>
            </div>
        </>
    );

    return returnValue;
};

export default RequiredInput;
