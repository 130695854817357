import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';
export default class SingleItem extends Component {
    render() {
        return (
            <div className={`c-how-it-works-funeral--items `}>
                <div>
                    <div
                        className="c-how-it-works-funeral--items-img"
                        style={{
                            backgroundImage: `url(${this.props.item.image})`,
                        }}
                    ></div>
                    <h4>{this.props.item.title}</h4>
                    <p>
                        <p>
                            {ReactHtmlParser(
                                this.props.item.body ? this.props.item.body : ''
                            )}
                        </p>
                    </p>
                </div>
            </div>
        );
    }
}
