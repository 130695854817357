export const NEW_MESSAGE = 'NEW_MESSAGE';
export const GET_THREADS = 'GET_THREADS';
export const SET_THREADS = 'SET_THREADS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_THREAD_READ = 'SET_THREAD_READ';
export const UNREAD_THREAD_COUNT = 'UNREAD_THREAD_COUNT';
export const READ_SINGLE_THREAD = 'READ_SINGLE_THREAD';

export const newMessage = (payload) => {
    return {
        type: NEW_MESSAGE,
        message: payload.message,
        thread: payload.thread,
        thread_id: payload.thread_id,
    };
};

export const setThreadRead = (thread) => ({
    type: SET_THREAD_READ,
    thread: thread,
});
export const onSetThreadRead = (payload) => (dispatch) =>
    dispatch(setThreadRead(payload));

export const onNewMessage = (payload) => (dispatch) =>
    dispatch(newMessage(payload));

export const setThreads = (threads) => ({
    type: SET_THREADS,
    threads: threads,
});
export const onSetThreads = (payload) => (dispatch) =>
    dispatch(setThreads(payload));

export const setCurrentThreadsMessages = (payload) => ({
    type: SET_MESSAGES,
    messages: payload.messages,
    threadId: payload.threadId,
});
export const onSetCurrentThreadsMessages = (payload) => (dispatch) =>
    dispatch(setCurrentThreadsMessages(payload));

export const unreadThreadCountSubscribe = (payload) => ({
    type: UNREAD_THREAD_COUNT,
    unreadThreadCount: payload,
});
export const onUnreadThreadCountSubscribe = (payload) => (dispatch) =>
    dispatch(unreadThreadCountSubscribe(payload));
