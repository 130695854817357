import React, { Component } from 'react';

import Footer from '../../../components/UI/Footer/Footer';
import { Link } from 'react-router-dom';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
import { connect } from 'react-redux';
import Header from '../../../components/Header/Header';
import errorIcon from '../../../assets/images/aura2022/icons/404icon.svg';
class NotFound extends Component {
    render() {
        let link = this.props.user
            ? `/my-aura/${this.props.user.profile.slug}/life-story`
            : '/';
        return (
            <>
                <Header />
                <PageMeta
                    title="Page not Found | Aura"
                    description="Page not Found | Aura"
                    canonical={window.location.href}
                />
                <div className="c-error-pages ">
                    <div className="c-error-pages__content">
                        <h1 className="c-error-pages__title">
                            Code 404 - Page not Found
                        </h1>
                        <img src={errorIcon} alt="403 icon" />
                        <p className="c-error-pages__text">
                            The page you were looking for doesn't exist
                        </p>
                        <Link
                            className="c-error-pages__btn  btn btn--full"
                            to={`${link}`}
                        >
                            Take me Home
                        </Link>
                    </div>
                </div>
                <div className="margin-reset">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.usersReducer.user,
});

export default connect(mapStateToProps)(NotFound);
