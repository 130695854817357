import * as funeralPaymentActionTypes from '../../../../store/actions/funeralPaymentActions';

import React, { Component } from 'react';

import ApiService from '../../../../service/api.service';
import NumberFormater from '../../../../service/number_formater.service.js';
import Navigation from '../../../../components/FuneralPayment/Navigation/Navigation';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
class Options extends Component {
    state = {
        options: this.props.options ? this.props.options : [],
        selectedOption: this.props.funeralPayment?.selectedOption
            ? parseInt(this.props.funeralPayment.selectedOption.split('/')[3])
            : this.props.options
            ? this.props.options[0].id
            : 0,
        oldSelectedOption: this.props.funeralPayment?.selectedOption
            ? parseInt(this.props.funeralPayment.selectedOption.split('/')[3])
            : this.props.options
            ? this.props.options[0].id
            : 0,
        buttonIsLoading: false,
        popupOpen: false,
        isLoading: true,
        infoTitle: '',
        infoContent: '',
        infoPhoto: '',
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let slug = 'crematorium-locations';
        ApiService.getInfoPages(slug).then((r) => {
            this.setState({
                infoTitle: r.data.title,
                infoContent: r.data.content,
                infoPhoto: r.data.photo,
                isLoading: false,
            });
        });

        if (
            this.props.funeralPayment?.planOption &&
            !this.props.funeralPayment?.selectedOption
        ) {
            this.setState({
                selectedOption: parseInt(
                    this.props.funeralPayment?.planOption.split('/')[3]
                ),
                oldSelectedOption: parseInt(
                    this.props.funeralPayment?.planOption.split('/')[3]
                ),
            });
        }
    }

    handleSetOption = () => {
        this.setState(
            {
                buttonIsLoading: true,
            },
            () => {
                const data = {
                    planOption: `/api/funeral_plan_options/${this.state.selectedOption}`,
                };
                const id = this.props.funeralPayment.uuid;
                if (
                    this.state.oldSelectedOption !==
                        this.state.selectedOption ||
                    this.props.funeralPayment.totalStep === 1
                ) {
                    ApiService.setOption(data, id).then((r) => {
                        ApiService.getUserFuneralPlan(id)
                            .then((response) => {
                                ApiService.getTotalPrice(
                                    this.props.funeralPayment.uuid
                                ).then((r) => {
                                    this.props.onSetOption(
                                        `/api/funeral_plan_options/${this.state.selectedOption}`,
                                        r.data.totalStep
                                    );
                                    this.props.onRemoveAddons();
                                    this.props.onSetFuneralCheckout(
                                        response.data
                                    );
                                    this.props.onSetTotal(
                                        r.data.total,
                                        r.data.subtotal
                                    );
                                    if (
                                        window.location.href.includes('simple')
                                    ) {
                                        if (
                                            this.props.funeralPayment
                                                .totalStep === 1 ||
                                            !this.props.funeralPayment.totalStep
                                        ) {
                                            const data = {
                                                step: 3,
                                            };
                                            ApiService.funeralPlanStepUpdate(
                                                this.props.funeralPayment.uuid,
                                                data
                                            ).then((r) => {
                                                this.props.onSetCurrentStep(
                                                    this.props.funeralPayment
                                                        .currentStep + 2
                                                );
                                                this.setState({
                                                    buttonIsLoading: false,
                                                });
                                            });
                                        }
                                    } else {
                                        this.props.onSetCurrentStep(
                                            this.props.funeralPayment
                                                .currentStep + 1
                                        );
                                    }
                                    this.setState({
                                        buttonIsLoading: false,
                                    });
                                });
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                } else {
                    this.setState(
                        {
                            buttonIsLoading: true,
                        },
                        () => {
                            let data;
                            let int;
                            if (window.location.href.includes('simple')) {
                                data = {
                                    step: 3,
                                };
                                int = 2;
                            } else {
                                data = {
                                    step: 2,
                                };
                                int = 1;
                            }
                            if (this.props.funeralPayment.totalStep === 1) {
                                ApiService.funeralPlanStepUpdate(
                                    this.props.funeralPayment.uuid,
                                    data
                                );
                            }
                            ApiService.getTotalPrice(
                                this.props.funeralPayment.uuid
                            ).then((r) => {
                                this.props.onSetTotal(
                                    r.data.total,
                                    r.data.subtotal
                                );
                                this.props.onSetCurrentStep(
                                    this.props.funeralPayment.currentStep + int
                                );
                                this.setState({
                                    buttonIsLoading: false,
                                });
                            });
                        }
                    );
                }
            }
        );
    };

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    render() {
        let popupContent = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle}
                </h1>
                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent)}
                </div>
                <div>
                    <img src={this.state.infoPhoto} alt="" />
                </div>
            </>
        );

        return (
            <div className="c-funeral__option">
                <h2>Choose the type of cremation service you would like</h2>
                <h6>
                    All of our plans include collection, care and preparation of
                    the deceased, a simple coffin and the cremation. Ashes can
                    either be returned to the family or scattered at the
                    crematorium. Please select preference below.{' '}
                </h6>

                {this.state.options.map((option, index) => {
                    return (
                        <div
                            className={`c-funeral__option--box o-flex ${
                                option.id ===
                                parseInt(this.state.selectedOption)
                                    ? 'c-funeral__option--chosen'
                                    : 'c-funeral__option--choose'
                            }`}
                            key={index}
                        >
                            <div
                                className="c-funeral__option--image"
                                style={{
                                    backgroundImage: `url(${option.image})`,
                                }}
                            ></div>
                            <div className="c-funeral__option--desc">
                                <h4 className="c-funeral__option--desc--title">
                                    {option.title}
                                </h4>
                                {ReactHtmlParser(option.description)}
                            </div>
                            <div className="c-funeral__option--payment">
                                <div className="c-funeral__option--money">
                                    £{NumberFormater.formatNumber(option.money)}
                                </div>
                                <button
                                    className={`o-d-inline-block btn btn--size-43 btn--size-width-121 text-center ${
                                        option.id ===
                                        parseInt(this.state.selectedOption)
                                            ? 'btn--full'
                                            : 'btn--white-purple'
                                    }`}
                                    onClick={() => {
                                        this.setState({
                                            selectedOption: option.id,
                                        });
                                        this.props.onSetTotalAmount(
                                            option.money
                                        );
                                    }}
                                >
                                    {option.id ===
                                    parseInt(this.state.selectedOption)
                                        ? 'Chosen'
                                        : 'Choose'}
                                </button>
                            </div>
                        </div>
                    );
                })}
                <p className="c-funeral__option--terms">
                    For a full list of crematoria, please{' '}
                    <span
                        onClick={this.handlePopupOpen}
                        className="link-underline"
                    >
                        click here.
                    </span>
                </p>

                <Popup
                    lockScroll
                    open={this.state.popupOpen}
                    onClose={this.handlePopupClose}
                    className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--map c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                >
                    {(close) => (
                        <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                            <button
                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                onClick={close}
                            >
                                <i className="icon-times font-35 cursor-pointer"></i>
                            </button>
                            {popupContent}
                        </div>
                    )}
                </Popup>
                <Navigation
                    buttonLoading={this.state.buttonIsLoading}
                    stepUpWait={true}
                    setOption={this.handleSetOption}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    funeralPayment: state.funeralPaymentReducer,
    user: state.usersReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetTotalAmount: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetTotalAmount(currentStep)),
        onSetOption: (option, step) =>
            dispatch(funeralPaymentActionTypes.onSetOption(option, step)),
        onSetCurrentStep: (step) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(step)),
        onSetTotal: (total, subtotal) =>
            dispatch(funeralPaymentActionTypes.onSetTotal(total, subtotal)),
        onRemoveAddons: () =>
            dispatch(funeralPaymentActionTypes.onRemoveAddons()),
        onSetTotalStep: (step) =>
            dispatch(funeralPaymentActionTypes.onSetTotalStep(step)),
        onSetFuneralCheckout: (data) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralCheckout(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
