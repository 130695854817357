import React, { Component } from 'react';
import infinitySign from '../../../assets/images/aura2022/infinityHpSign.svg';
import ReactHtmlParser from 'react-html-parser';

class CustomerService extends Component {
    render() {
        return (
            <div className="light-blue-background c-customer-service">
                <img
                    src={infinitySign}
                    alt="Aura Sign"
                    className="c-customer-service--infinity"
                />
                {this.props.title && <h2>{this.props.title} </h2>}
                <div className="container">
                    {this.props.content.map((block, index) => {
                        return (
                            <div
                                key={`service-${index}`}
                                className="c-customer-service__block"
                            >
                                <div
                                    className="c-customer-service__block--img"
                                    style={{
                                        backgroundImage: `url(${block.image})`,
                                    }}
                                ></div>
                                <div className="c-customer-service__block--txt">
                                    {block.subtitle && (
                                        <h3>{block.subtitle}</h3>
                                    )}
                                    {block.description && (
                                        <p>
                                            {ReactHtmlParser(block.description)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default CustomerService;
