import React from 'react';

const passwordValidation = (props) => {
    let returnValue = (
        <>
            <div className="c-password-conditions">
                <h3 className="font-16 font-333 opacity-7 font-semibold c-password-conditions__title">
                    Your Password must contain:
                </h3>
                <ul className="c-password-conditions__list bulleted-list">
                    <li
                        className={`validate font-16 font-333 opacity-9 position-relative ${
                            props.pw.length <= 60 && props.pw.length >= 8
                                ? 'valid'
                                : ''
                        }`}
                    >
                        <i className="icon-checkmark-icon position-absolute font-secondary"></i>
                        At least 8 <span>and maximum 60 </span>characters
                    </li>
                    <li
                        className={`validate font-16 font-333 opacity-9 position-relative ${
                            props.pw.match(/[a-z]/) && props.pw.match(/[A-Z]/)
                                ? 'valid'
                                : ''
                        }`}
                    >
                        <i className="icon-checkmark-icon position-absolute font-secondary"></i>
                        Both lower<span> (a-z)</span> and upper case letters
                        <span> (A-Z)</span>
                    </li>
                    <li
                        className={`validate font-16 font-333 opacity-9 position-relative ${
                            props.pw.match(/(?=.*[0-9])/) ? 'valid' : ''
                        }`}
                    >
                        <i className="icon-checkmark-icon position-absolute font-secondary"></i>
                        At least one number<span> (0-9) or a symbol</span>
                    </li>
                </ul>
            </div>
        </>
    );

    if (props.passwordvalidation === 'false') {
        returnValue = null;
    }

    return returnValue;
};

export default passwordValidation;
