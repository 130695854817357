import * as actionTypes from '../actions/usersActions';
const initialState = {
    user: null,
    userId: null,
    userSlug: '',
    token: null,
    refreshToken: null,
    loadedProfile: null,
    connectionsTooltipOpened: false,
    mobileProfileOpen: false,
    funeralPhone: '',
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
                userSlug: action.user.profile.slug,
                userId: action.user.id,
            };
        case actionTypes.UPDATE_USER:
            const updatedUser = {
                ...state.user,
                profile: action.profile,
            };

            return {
                ...state,
                user: updatedUser,
            };
        case actionTypes.UPDATE_USER_PHOTO:
            // copy user and profile
            const copiedUser = { ...state.user };
            const copiedProfile = copiedUser.profile;

            // update profile
            const updatedProfile = {
                ...copiedProfile,
                photo: action.profilePhoto,
            };

            // update user
            const updatedUser1 = {
                ...state.user,
                profile: updatedProfile,
            };

            return {
                ...state,
                user: updatedUser1,
            };
        case actionTypes.UPDATE_COVER_PHOTO:
            // copy user and profile
            const copiedUser1 = action.isUser
                ? { ...state.user }
                : { ...state.loadedProfile };
            const copiedProfile1 = copiedUser1.profile;

            // update profile
            const updatedProfile1 = {
                ...copiedProfile1,
                cover: action.coverPhoto,
            };

            // update user
            const updatedUser2 = {
                ...state.user,
                profile: updatedProfile1,
            };

            if (action.isUser) {
                return {
                    ...state,
                    user: updatedUser2,
                };
            } else {
                return {
                    ...state,
                    loadedProfile: updatedUser2,
                };
            }
        case actionTypes.SET_USER_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case actionTypes.SET_USER_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.refreshToken,
            };
        case actionTypes.UPDATE_USER_PRIVACY:
            const privacyUser = { ...state.user };

            privacyUser.private = action.privacy;

            return {
                ...state,
                user: privacyUser,
            };
        case actionTypes.UPDATE_NOTIF_COUNT:
            const userWithNotifCountUpdated = { ...state.user };
            userWithNotifCountUpdated.notificationNumber =
                userWithNotifCountUpdated.notificationNumber -
                action.notifReadCount;
            return {
                ...state,
                user: userWithNotifCountUpdated,
            };
        case actionTypes.CLEAR_NOTIF_COUNT:
            const userWithClearedNotifNumber = {
                ...state.user,
                notificationNumber: 0,
            };

            return {
                ...state,
                user: userWithClearedNotifNumber,
            };

        case actionTypes.NOTIFICATION_SUBSCRIBE:
            return {
                ...state,
                user: {
                    ...state.user,
                    notificationNumber: action.notifCount,
                },
            };
        case actionTypes.THREAD_SUBSCRIBE:
            const cpdUser = {
                ...state.user,
                unreadThreadsCount: action.threadCount,
            };
            // cpdUser.unreadThreadsCount = action.threadCount

            return {
                ...state,
                user: cpdUser,
            };
        case actionTypes.LOGOUT_USER:
            return {
                ...state,
                user: null,
                userId: null,
                userSlug: '',
                token: null,
                loadedProfile: null,
            };
        case actionTypes.LOADED_PROFILE:
            return {
                ...state,
                loadedProfile: action.loadedprofile,
            };
        case actionTypes.CLEAR_LOADED_PROFILE:
            return {
                ...state,
                loadedProfile: null,
            };
        case actionTypes.STEP_UP:
            // copy user and profile
            const copiedUserStepUp = { ...state.user };
            const copiedProfileStepUp = copiedUserStepUp.profile;

            // update profile
            const updatedProfileStepUp = {
                ...copiedProfileStepUp,
                step: state.user.profile.step + 1,
            };

            // update user
            const updatedUserStepUp = {
                ...state.user,
                profile: updatedProfileStepUp,
            };
            return {
                ...state,
                user: updatedUserStepUp,
            };
        case actionTypes.STEP_DOWN:
            // copy user and profile
            const copiedUserStepDown = { ...state.user };
            const copiedProfileStepDown = copiedUserStepDown.profile;

            // update profile
            const updatedProfileStepDown = {
                ...copiedProfileStepDown,
                step: state.user.profile.step - 1,
            };

            // update user
            const updatedUserStepDown = {
                ...state.user,
                profile: updatedProfileStepDown,
            };
            return {
                ...state,
                user: updatedUserStepDown,
            };
        case actionTypes.SIDEBAR_CONNECTION_TYPE_CHANGE:
            return {
                ...state,
                loadedProfile: {
                    ...state.loadedProfile,
                    profile: {
                        ...state.loadedProfile.profile,
                        connection: {
                            ...state.loadedProfile.profile.connection,
                            type: action.connectionType,
                        },
                    },
                },
            };
        case actionTypes.REMOVE_CONNECTION:
            return {
                ...state,
                loadedProfile: {
                    ...state.loadedProfile,
                    profile: {
                        ...state.profile,
                        connection: null,
                        oppositeConnection: null,
                    },
                },
            };
        case actionTypes.ADD_SIDEBAR_CONNECTION:
            return {
                ...state,
                loadedProfile: {
                    ...state.loadedProfile,
                    profile: {
                        ...state.loadedProfile.profile,
                        connection: {
                            ...action.connection,
                        },
                    },
                },
            };
        case actionTypes.ACCEPT_SIDEBAR_REQUEST:
            return {
                ...state,
                loadedProfile: {
                    ...state.loadedProfile,
                    profile: {
                        ...state.loadedProfile.profile,
                        connection: action.payload,
                    },
                },
            };
        case actionTypes.CONNECTIONS_TOOLTIP_OPEN:
            return {
                ...state,
                connectionsTooltipOpened: true,
            };
        case actionTypes.CONNECTIONS_TOOLTIP_CLOSE:
            return {
                ...state,
                connectionsTooltipOpened: false,
            };
        case actionTypes.USER_DECEASED:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        profileDeceased: action.profileDeceased,
                    },
                },
            };
        case actionTypes.TRUSTED_PERSON_USER_DECEASED:
            return {
                ...state,
                loadedProfile: {
                    ...state.loadedProfile,
                    profile: {
                        ...state.loadedProfile.profile,
                        profileDeceased: action.trustedProfileDeceased,
                    },
                },
            };
        case actionTypes.USER_REVIVED:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        profileDeceased: null,
                    },
                },
            };
        case actionTypes.TWO_FA_PROFILE_UPDATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: action.profile,
                },
            };
        case actionTypes.TOGGLE_MOBILE_PROFILE:
            return {
                ...state,
                mobileProfileOpen: !state.mobileProfileOpen,
            };
        case actionTypes.CLOSE_MOBILE_PROFILE:
            return {
                ...state,
                mobileProfileOpen: false,
            };
        case actionTypes.DELETE_FUNERAL_PLAN:
            return {
                ...state,
                user: {
                    ...state.user,
                    userFuneralPlan: null,
                },
            };
        case actionTypes.SET_FUNERAL_PHONE:
            return {
                ...state,
                funeralPhone: action.funeralPhone,
            };
        default:
            return state;
    }
};

export default usersReducer;
