import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { Component } from 'react';
import Slider from 'react-slick';
import SliderCategory from './SliderCategory/SliderCategory';

export default class SliderAddons extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
        };

        let categories;

        if (this.props.categories.length) {
            categories = this.props.categories.map((categorie, index) => {
                return (
                    <SliderCategory
                        key={`categorie-${index}`}
                        categorie={categorie}
                    />
                );
            });
        }

        return (
            <div className="c-funeral__add-ons__slider">
                <Slider {...settings}>{categories}</Slider>
            </div>
        );
    }
}
