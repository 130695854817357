import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import ApiService from '../../../service/api.service';
import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input/Input';
import loader from '../../../assets/images/loader.svg';
import sentIcon from '../../../assets/images/sentIcon.svg';
import phoneImg from '../../../assets/images/aura2022/icons/call-us-icon.png';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import RequiredInput from '../Input/RequiredInput/RequiredInput';
import RegularInput from '../Input/RegularInput/RegularInput';
import button from '../../../components/UI/Button/Button';

class StickyFooter extends Component {
    endOfEmailForm = React.createRef();

    state = {
        modalOpen: false,
        phoneActive: false,
        emailActive: false,
        onlineChatActive: false,
        phoneButtonLoading: false,
        emailButtonLoading: false,
        isPhoneFocused: false,
        isEmailFocusede: false,
        formSubmited: false,
        plan: '',
        isFocused: false,
    };

    componentDidMount() {
        this.setState({
            plan: window.location.href
                .split('/funeral-plans/')[1]
                ?.includes('simple')
                ? 'simple'
                : 'imagined',
        });
        window.addEventListener('scroll', (e) => this.handleNavigation(e));
        document.body.classList.add('sticky-footer');
    }

    handleFirstButtonClick = () => {
        this.props.firstButtonClick();
    };

    handleSecondButtonClick = () => {
        this.setState({
            modalOpen: true,
        });
    };

    handleOpenModal = () => {
        this.setState({
            modalOpen: true,
        });
    };
    handleNavigeToPayment = () => {
        this.props.secondButtonClick();
    };

    handleNavigation = (e) => {
        const window = e.currentTarget;
        const footer = document.getElementById('sticky-footer');

        if (footer) {
            if (window.pageYOffset > 0) {
                footer.classList.add('c-plans__sticky-footer-onscroll');
            } else {
                footer.classList.remove('c-plans__sticky-footer-onscroll');
            }
        }
    };

    componentWillUnmount() {
        document.body.classList.remove('sticky-footer');
    }

    handleEmailBlur = () => {
        this.setState({
            emailFocused: false,
        });
    };

    handlePhoneClick = (close) => {
        ReactPixel.init('218942439488342');
        ReactPixel.track('Contact');

        close();

        window.open(
            'https://meetings.hubspot.com/tamsin-ferrier/discovery-call-all-team',
            '_blank'
        );
    };

    handleEmailClick = () => {
        setTimeout(() => {
            this.endOfEmailForm.current.scrollIntoView();
        }, 100);
        this.setState({
            phoneActive: false,
            emailActive: true,
            onlineChatActive: false,
        });
    };

    handleOnlineChatClick = (close) => {
        close();
        window.HubSpotConversations.widget.open();
        this.setState({
            phoneActive: false,
            emailActive: false,
            onlineChatActive: false,
        });
    };

    handlePopupClose = () => {
        this.setState({
            phoneActive: false,
            emailActive: false,
            onlineChatActive: false,
            formSubmited: false,
        });
    };

    handlePhoneSubmit = (values) => {
        this.setState(
            {
                phoneButtonLoading: true,
            },
            () => {
                let data;
                if (values.user_full_name.length !== '') {
                    data = {
                        name: values.name,
                        phone: values.phone,
                        preferredTime: values.preferredTime,
                        plan: `api/funeral_plans/${this.props.slug}`,
                        user_full_name: values.user_full_name,
                    };
                } else {
                    data = {
                        name: values.name,
                        phone: values.phone,
                        preferredTime: values.preferredTime,
                        plan: `api/funeral_plans/${this.props.slug}`,
                    };
                }
                ApiService.funeralPlans(data).then((r) => {
                    ReactPixel.init('218942439488342');
                    ReactPixel.track('Lead');
                    ReactGA.initialize('UA-159178529-1');
                    ReactGA.event({
                        category: 'Form',
                        action: 'Submit',
                        label: 'Got a question',
                    });
                    this.setState({
                        phoneButtonLoading: false,
                        formSubmited: true,
                    });
                });
            }
        );
    };

    handleEmailSubmit = (values) => {
        this.setState(
            {
                emailButtonLoading: true,
            },
            () => {
                let data;
                if (values.user_full_name.length !== '') {
                    data = {
                        name: values.name,
                        email: values.email,
                        message: values.message,
                        plan: `api/funeral_plans/${this.props.slug}`,
                        user_full_name: values.user_full_name,
                    };
                } else {
                    data = {
                        name: values.name,
                        email: values.email,
                        message: values.message,
                        plan: `api/funeral_plans/${this.props.slug}`,
                    };
                }
                ApiService.funeralPlans(data).then((r) => {
                    ReactPixel.init('218942439488342');
                    ReactPixel.track('Lead');
                    ReactGA.initialize('UA-159178529-1');
                    ReactGA.event({
                        category: 'Form',
                        action: 'Submit',
                        label: 'Got a question',
                    });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ event: 'form_email_us' });
                    this.setState({
                        emailButtonLoading: false,
                        formSubmited: true,
                    });
                });
            }
        );
    };

    handlePhoneClick2 = () => {
        ReactPixel.init('218942439488342');
        ReactPixel.track('Contact');

        ReactGA.initialize('UA-159178529-1');
        ReactGA.event({
            category: 'CTA',
            action: 'Click',
            label: 'Speak to us',
        });
    };

    handleNavigateToForm = () => {
        window.location.href = 'https://info.aura.life/request-a-quote';
    };

    handleScrollTo = () => {
        let discover = document.getElementById('plans');
        discover.scrollIntoView({ behavior: 'smooth' });
    };

    render() {
        let allActive;

        if (
            !this.state.phoneActive &&
            !this.state.emailActive &&
            !this.state.onlineChatActive
        ) {
            allActive = true;
        } else {
            allActive = false;
        }

        return (
            <>
                <div className="c-sticky-footer">
                    {/* TYPE 2 (text, phone num and one button) */}
                    {/* Text */}
                    <div
                        className={`c-sticky-footer__text-num ${
                            this.props.type === '1'
                                ? 'c-sticky-footer__text-num--type-1'
                                : ''
                        }`}
                    >
                        {this.props.type === '1' && this.props.text ? (
                            <p className="c-sticky-footer__text">
                                {this.props.text}
                            </p>
                        ) : null}

                        {/* Phone number, needed on type 1 and 2 */}
                        {this.props.phoneNum ? (
                            <a
                                className={`c-sticky-footer__num ${
                                    this.props.type === '2'
                                        ? 'c-sticky-footer__num--type-2'
                                        : ''
                                } `}
                                href={`tel:${this.props.phoneNum}`}
                                onClick={() => this.handlePhoneClick2()}
                            >
                                {this.props.phoneNum}
                            </a>
                        ) : null}
                    </div>

                    <div className="c-sticky-footer__buttons">
                        {/* TYPE 1 phone num, first and second button */}

                        {this.props.type === '1' && this.props.phoneNum ? (
                            <a
                                className={`c-sticky-footer__num ${
                                    this.props.type === '1'
                                        ? 'c-sticky-footer__num--type-1'
                                        : ''
                                }`}
                                href={`tel:${this.props.phoneNum}`}
                                onClick={() => this.handlePhoneClick2()}
                            >
                                {this.props.phoneNum}
                            </a>
                        ) : null}

                        {this.props.type === '2' && this.props.firstBtnTitle ? (
                            this.props.firstBtnLink === null ? (
                                <button
                                    className="c-sticky-footer__button c-sticky-footer__button--full"
                                    onClick={() => this.handleOpenModal()}
                                >
                                    {this.props.firstBtnTitle}
                                </button>
                            ) : (
                                <a
                                    className="c-sticky-footer__button c-sticky-footer__button--full"
                                    href={
                                        this.props.firstBtnLink &&
                                        !this.props.firstBtnLink.includes('tel')
                                            ? this.props.firstBtnLink
                                            : this.props.firstBtnLink.includes(
                                                  'tel'
                                              )
                                            ? `tel:${this.props.firstBtnLink.replace(
                                                  'http://tel:',
                                                  ''
                                              )}`
                                            : null
                                    }
                                >
                                    {this.props.firstBtnTitle}
                                </a>
                            )
                        ) : (
                            ''
                        )}

                        {/* TYPE 2 (text, phone num and one button) */}
                        {/* TYPE 2 Button */}
                        {this.props.secondBtnTitle ? (
                            this.props.secondBtnLink === null ? (
                                <button
                                    className="c-sticky-footer__button c-sticky-footer__button--transparent"
                                    onClick={() => this.handleOpenModal()}
                                >
                                    {this.props.secondBtnTitle}
                                </button>
                            ) : (
                                <a
                                    className="c-sticky-footer__button c-sticky-footer__button--transparent"
                                    href={
                                        this.props.secondBtnLink &&
                                        !this.props.secondBtnLink.includes(
                                            'tel'
                                        )
                                            ? this.props.secondBtnLink
                                            : this.props.secondBtnLink.includes(
                                                  'tel'
                                              )
                                            ? `tel:${this.props.secondBtnLink.replace(
                                                  'http://tel:',
                                                  ''
                                              )}`
                                            : null
                                    }
                                >
                                    {this.props.secondBtnTitle}
                                </a>
                            )
                        ) : (
                            ''
                        )}
                        {/* {this.props.firstButton ? (
                            !this.props.firstButtonMobile ? (
                                <button
                                    className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-247 text-center btn--padding-left-right"
                                    onClick={() => this.handleNavigateToForm()}
                                >
                                    {this.props.firstButton}
                                </button>
                            ) : this.props.funeralPhone ? (
                                <a
                                    className="c-btn"
                                    href={`tel:+44${this.props.funeralPhone
                                        .substring(1)
                                        .trim()}`}
                                    onClick={() => this.handlePhoneClick2()}
                                >
                                    {this.props.funeralPhone}
                                </a>
                            ) : (
                                <a
                                    className="c-btn"
                                    href="tel:+448008085723"
                                    onClick={() => this.handlePhoneClick2()}
                                >
                                    {this.props.firstButton}
                                </a>
                            )
                        ) : null} */}
                        {/* {this.props.secondButton &&
                        !this.props.secondButtonLink ? (
                            <button
                                className={`btn btn--ghost-purple btn--double-border btn--size-57 btn--size-width-247 text-center btn--padding-left-right btn--max-height-43 `}
                                onClick={
                                    this.props.firstButtonClick !==
                                    'quote-popup'
                                        ? () => this.handleSecondButtonClick()
                                        : () => this.handleNavigeToPayment()
                                }
                            >
                                {this.props.secondButton}2nd
                            </button>
                        ) : this.props.secondButton &&
                          this.props.secondButtonLink ? (
                            <button
                                className="btn btn--ghost-purple btn--double-border btn--size-57 btn--size-width-247 text-center btn--padding-left-right btn--max-height-43 font-16 "
                                //*Commented out for new sticky footer requirements, this was functionaliy on fp overview to scroll to plan comparison
                                onClick={
                                    window.location.pathname === '/funerals'
                                        ? () => this.handleNavigateToForm()
                                        : null
                                }
                            >
                                {this.props.secondButton}2nd
                            </button>
                        ) : null} */}
                    </div>
                    {/* <div className="c-plans__sticky-footer__btns o-none-from-mobile-sm">
                        {this.props.firstButton ? (
                            <button
                                className="o-d-inline-block btn btn--full btn--size-57 btn--size-width-116 text-center btn--padding-left-right"
                                onClick={() => this.handleFirstButtonClick()}
                            >
                                Select
                            </button>
                        ) : null}
                        {this.props.secondButton ? (
                            <button
                                className="btn btn--ghost-purple btn--double-border btn--size-57 btn--size-width-116 text-center btn--padding-left-right"
                                onClick={() => this.handleSecondButtonClick()}
                            >
                                Contact
                            </button>
                        ) : null}
                    </div> */}
                </div>
                <Popup
                    open={this.state.modalOpen}
                    onClose={() =>
                        this.setState({
                            modalOpen: false,
                            formSubmited: false,
                            emailActive: false,
                            onlineChatActive: false,
                            phoneActive: false,
                        })
                    }
                    lockScroll
                    modal
                    className="funerals-modal-popup"
                >
                    {(close) => (
                        <>
                            {!this.state.formSubmited ? (
                                <div className="c-funerals-modal">
                                    <div className="c-funerals-modal__body o-flex text-center o-flex-column o-flex-middle">
                                        <h4 className="text-center lh-33 font-semibold font-family-secondary">
                                            Speak to our team
                                        </h4>
                                        <img src={phoneImg} alt="phone" />
                                        <div>
                                            {this.props.funeralPhone ? (
                                                <>
                                                    <a
                                                        className="max-width-full font-family-primary font-semibold c-funerals-modal__body--phone"
                                                        href={`tel:+44${this.props.funeralPhone
                                                            .substring(1)
                                                            .trim()}`}
                                                    >
                                                        {
                                                            this.props
                                                                .funeralPhone
                                                        }
                                                    </a>
                                                    <span></span>
                                                </>
                                            ) : (
                                                <>
                                                    <a
                                                        className="max-width-full font-family-primary font-semibold c-funerals-modal__body--phone"
                                                        href="tel:+448008085723"
                                                    >
                                                        0800 808 5723
                                                    </a>
                                                    <span></span>
                                                </>
                                            )}
                                        </div>
                                        {/*<p className="max-width-full font-family-primary font-semibold c-funerals-modal__body--phone">*/}
                                        {/*  0800 808 5723*/}
                                        {/*</p>*/}
                                        <p className="c-funerals-modal__body--time">
                                            Monday - Saturday
                                        </p>
                                        <p className="c-funerals-modal__body--time">
                                            9am - 8pm
                                        </p>
                                        <p className="c-funerals-modal__body--txt max-width-full">
                                            If you’d prefer, you can request a
                                            callback, request for us to send you
                                            an email or chat to our team on Live
                                            Chat using the buttons below.
                                        </p>
                                        <p className="c-funerals-modal__body--txt txt2 max-width-full">
                                            Alternatively, you can email our
                                            team at{' '}
                                            <a
                                                href={`mailto:${this.props.email}`}
                                            >
                                                {this.props.email}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="c-plans__modal__package-modal o-flex">
                                        <div
                                            className={`c-plans__package-modal--phone ${
                                                allActive ||
                                                this.state.phoneActive
                                                    ? 'is-active'
                                                    : ''
                                            }`}
                                        >
                                            <div className="c-plans__modal__package-modal--phone-img"></div>
                                            <p className="padding-reset margin-reset ">
                                                {/*We will call you on your phone number*/}
                                            </p>
                                            <button
                                                onClick={() =>
                                                    this.handlePhoneClick(close)
                                                }
                                                className="o-d-inline-block btn btn--full btn--size-48 btn--size-width-247 text-center btn--padding-left-right"
                                            >
                                                Book a call
                                            </button>
                                        </div>
                                        <div
                                            className={`c-plans__package-modal--email ${
                                                allActive ||
                                                this.state.emailActive
                                                    ? 'is-active'
                                                    : ''
                                            }`}
                                        >
                                            <div className="c-plans__modal__package-modal--email-img"></div>
                                            <p className="padding-reset margin-reset ">
                                                {/*We will call send you more details to your inbox*/}
                                            </p>
                                            <button
                                                onClick={() =>
                                                    this.handleEmailClick()
                                                }
                                                className="o-d-inline-block btn btn--full btn--size-48 btn--size-width-247 text-center btn--padding-left-right"
                                            >
                                                Email us
                                            </button>
                                        </div>
                                        <div
                                            className={`c-plans__package-modal--chat ${
                                                allActive ||
                                                this.state.onlineChatActive
                                                    ? 'is-active'
                                                    : ''
                                            }`}
                                        >
                                            <div className="c-plans__modal__package-modal--chat-img"></div>
                                            <p className="padding-reset margin-reset ">
                                                {/*Have a chat with our staff here on the website*/}
                                            </p>
                                            <button
                                                onClick={() =>
                                                    this.handleOnlineChatClick(
                                                        close
                                                    )
                                                }
                                                className="o-d-inline-block btn btn--full btn--size-48 btn--size-width-247 text-center btn--padding-left-right"
                                            >
                                                Online Chat
                                            </button>
                                        </div>
                                    </div>
                                    {this.state.phoneActive ? (
                                        <Formik
                                            key="phone-form"
                                            enableReinitialize={true}
                                            initialValues={{
                                                phone: '',
                                                name: '',
                                                preferredTime: '',
                                                user_full_name: '',
                                            }}
                                            validationSchema={Yup.object({
                                                phone: Yup.string().required(
                                                    'This field is required.'
                                                ),
                                                name: Yup.string().required(
                                                    'This field is required.'
                                                ),
                                                preferredTime: Yup.string(),
                                                user_full_name: Yup.string(),
                                            })}
                                            onSubmit={(
                                                values,
                                                { resetForm }
                                            ) => {
                                                this.handlePhoneSubmit(
                                                    values,
                                                    resetForm
                                                );
                                            }}
                                        >
                                            {(props) => (
                                                <>
                                                    <div className="c-form c-funerals-modal__form o-flex o-flex-column o-flex-center o-flex-middle">
                                                        <Form
                                                            noValidate
                                                            name="request-a-callback"
                                                            id="request-a-callback"
                                                            className="o-flex c-contact-form c-funerals-modal__form-content c-funerals-modal__form-content--phone"
                                                        >
                                                            {/*<Input*/}
                                                            {/*   validate={true}*/}
                                                            {/*   name="name"*/}
                                                            {/*   label="Name"*/}
                                                            {/*   placeholder="Name"*/}
                                                            {/*   className={`c-funerals-modal__form-input font-bold font-16  ${*/}
                                                            {/*       this.state.isNameFocused ? 'focused' : ''*/}
                                                            {/*   }`}*/}
                                                            {/* />*/}

                                                            <RequiredInput
                                                                label="Name"
                                                                name="name"
                                                                type="text"
                                                            />

                                                            {/*<Input*/}
                                                            {/*    validate={true}*/}
                                                            {/*    name="preferredTime"*/}
                                                            {/*    placeholder="Preferred time to contact you (Optional)"*/}
                                                            {/*    className={`c-funerals-modal__form-input font-bold font-16  ${*/}
                                                            {/*        this.state.isNameFocused ? 'focused' : ''*/}
                                                            {/*    }`}*/}
                                                            {/*/>*/}
                                                            <RegularInput
                                                                label="Preferred time to contact you (Optional)"
                                                                name="preferredTime"
                                                                type="text"
                                                            />

                                                            <RequiredInput
                                                                label="Phone Number"
                                                                name="phone"
                                                                type="text"
                                                            />

                                                            {/* <Input*/}
                                                            {/*    validate={true}*/}
                                                            {/*    name="phone"*/}
                                                            {/*    placeholder="Phone number"*/}
                                                            {/*    className={`c-funerals-modal__form-input font-bold font-16  ${*/}
                                                            {/*        this.state.isPhoneFocused ? 'focused' : ''*/}
                                                            {/*    }`}*/}
                                                            {/*/>*/}

                                                            <div className="hide">
                                                                <Input
                                                                    className="form-item--border-gray-light"
                                                                    label="User Full Name"
                                                                    name="user_full_name"
                                                                    type="text"
                                                                />
                                                            </div>

                                                            <Button
                                                                disabled={
                                                                    props.values
                                                                        .phone ===
                                                                        '' ||
                                                                    props.values
                                                                        .name ===
                                                                        '' ||
                                                                    (props.errors &&
                                                                        props
                                                                            .errors
                                                                            .name) ||
                                                                    (props.errors &&
                                                                        props
                                                                            .errors
                                                                            .phone)
                                                                }
                                                                className={`c-funerals-modal__form-btn btn btn--full-shakespeare btn-loader o-flex o-flex-middle o-flex-center margin-auto ${
                                                                    this.state
                                                                        .phoneButtonLoading
                                                                        ? 'save-in-progress'
                                                                        : ''
                                                                }`}
                                                                type="submit"
                                                            >
                                                                Send
                                                                <img
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={loader}
                                                                    alt="loaderImage"
                                                                />
                                                            </Button>
                                                        </Form>

                                                        <div className="c-form__data c-funerals-modal__data-hint mb-30">
                                                            <p>
                                                                All data
                                                                uploaded to the
                                                                Aura platform is{' '}
                                                                <span>
                                                                    <a
                                                                        href="/aura-security"
                                                                        className="dark-purple"
                                                                    >
                                                                        encrypted.
                                                                    </a>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Formik>
                                    ) : null}

                                    {this.state.emailActive ? (
                                        <Formik
                                            key="email-form"
                                            enableReinitialize={true}
                                            initialValues={{
                                                name: '',
                                                email: '',
                                                message: '',
                                                user_full_name: '',
                                            }}
                                            validationSchema={Yup.object({
                                                name: Yup.string().required(
                                                    'This field is required.'
                                                ),
                                                email: Yup.string()
                                                    .email(
                                                        'Please enter a valid E-mail Address'
                                                    )
                                                    .required(
                                                        'This field is required.'
                                                    ),
                                                message: Yup.string().required(
                                                    'This field is required.'
                                                ),
                                                user_full_name: Yup.string(),
                                            })}
                                            onSubmit={(
                                                values,
                                                { resetForm }
                                            ) => {
                                                this.handleEmailSubmit(
                                                    values,
                                                    resetForm
                                                );
                                            }}
                                        >
                                            {(props) => {
                                                return (
                                                    <>
                                                        <div className="c-form c-funerals-modal__form o-flex o-flex-column o-flex-center o-flex-middle">
                                                            <Form
                                                                noValidate
                                                                name="request-an-email"
                                                                id="request-an-email"
                                                                className="o-flex c-contact-form c-funerals-modal__form-content"
                                                            >
                                                                <RequiredInput
                                                                    label="Name"
                                                                    name="name"
                                                                    type="text"
                                                                />
                                                                <RequiredInput
                                                                    label="Email Address"
                                                                    name="email"
                                                                    type="text"
                                                                />
                                                                <div
                                                                    onFocus={() =>
                                                                        this.setState(
                                                                            {
                                                                                isFocused: true,
                                                                            }
                                                                        )
                                                                    }
                                                                    tabIndex="-1"
                                                                    onBlur={() => {
                                                                        this.setState(
                                                                            {
                                                                                isFocused: false,
                                                                            }
                                                                        );
                                                                        props.setFieldTouched(
                                                                            'message',
                                                                            true
                                                                        );
                                                                    }}
                                                                    className={`c-textarea ${
                                                                        this
                                                                            .state
                                                                            .isFocused
                                                                            ? 'c-textarea__focused'
                                                                            : ''
                                                                    } ${
                                                                        props
                                                                            .errors
                                                                            .message &&
                                                                        props
                                                                            .touched
                                                                            ?.message
                                                                            ? 'c-input__invalid'
                                                                            : ''
                                                                    } ${
                                                                        props
                                                                            .values
                                                                            .message
                                                                            ?.length >
                                                                        0
                                                                            ? 'c-textarea__filled'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <label
                                                                        tabIndex="-1"
                                                                        className="c-textarea__label"
                                                                    >
                                                                        Message...{' '}
                                                                        <span>
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <Field
                                                                        className={`c-textarea__input `}
                                                                        name="message"
                                                                        rows={1}
                                                                        as={
                                                                            'textarea'
                                                                        }
                                                                    />
                                                                    {props.errors &&
                                                                    props.errors
                                                                        .message &&
                                                                    props
                                                                        .touched
                                                                        ?.message ? (
                                                                        <div className="required required-bottom position-absolute font-13 font-semibold font-form-error">
                                                                            {
                                                                                props
                                                                                    .errors
                                                                                    .message
                                                                            }
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="hide">
                                                                    <Input
                                                                        className="form-item--border-gray-light"
                                                                        label="User Full Name"
                                                                        name="user_full_name"
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    disabled={
                                                                        props
                                                                            .values
                                                                            .email ===
                                                                            '' ||
                                                                        props
                                                                            .values
                                                                            .name ===
                                                                            '' ||
                                                                        props
                                                                            .values
                                                                            .message ===
                                                                            '' ||
                                                                        (props.errors &&
                                                                            props
                                                                                .errors
                                                                                .name) ||
                                                                        (props.errors &&
                                                                            props
                                                                                .errors
                                                                                .email) ||
                                                                        (props.errors &&
                                                                            props
                                                                                .errors
                                                                                .message)
                                                                    }
                                                                    className={`c-funerals-modal__form-btn btn btn--full btn-loader o-flex o-flex-middle o-flex-center margin-auto ${
                                                                        this
                                                                            .state
                                                                            .emailButtonLoading
                                                                            ? 'save-in-progress'
                                                                            : ''
                                                                    }`}
                                                                    type="submit"
                                                                >
                                                                    Send
                                                                    <img
                                                                        width="100%"
                                                                        height="100%"
                                                                        src={
                                                                            loader
                                                                        }
                                                                        alt="loaderImage"
                                                                    />
                                                                </Button>
                                                            </Form>
                                                            <div
                                                                ref={
                                                                    this
                                                                        .endOfEmailForm
                                                                }
                                                                className="c-form__data c-funerals-modal__data-hint mb-30"
                                                            >
                                                                <p>
                                                                    All data
                                                                    uploaded to
                                                                    the Aura
                                                                    platform is{' '}
                                                                    <span>
                                                                        <a
                                                                            href="/aura-security"
                                                                            className="dark-purple"
                                                                        >
                                                                            encrypted.
                                                                        </a>
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        </Formik>
                                    ) : null}
                                </div>
                            ) : (
                                <>
                                    <div className="c-plans__thank-you-modal o-flex o-flex-column o-flex-center o-flex-middle text-center">
                                        <h4 className="font-family-secondary">
                                            Successfully sent.
                                        </h4>
                                        <img
                                            className="c-plans__thank-you-modal--mail-image"
                                            src={sentIcon}
                                            alt="success-mark"
                                        />
                                        <p className="o-d-block">
                                            We'll be in touch with you very soon
                                        </p>
                                    </div>
                                </>
                            )}
                            <button
                                onClick={close}
                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                            >
                                <i className="icon-times font-35 cursor-pointer"></i>
                            </button>
                        </>
                    )}
                </Popup>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    funeralPhone: state.usersReducer.funeralPhone,
});

export default connect(mapStateToProps)(StickyFooter);
