import React, { Component } from 'react';

import ApiService from '../../../service/api.service';
import DateFormater from '../../../service/date_formater.service';
import Footer from '../../../components/UI/Footer/Footer';
import Header from '../../../components/Header/Header';
import Loader from '../../../components/UI/Loader/Loader';
import NumberFormater from '../../../service/number_formater.service';
import PageMeta from '../../../components/UI/PageMeta/PageMeta';
import ReactHtmlParser from 'react-html-parser';
export default class OrderHistorySingle extends Component {
    state = {
        orderItem: null,
        isLoading: true,
    };

    componentDidMount() {
        document.body.classList.add('white-bg');
        window.scrollTo(0, 0);
        ApiService.getOrderItem(this.props.match.params.id)
            .then((r) => {
                this.setState({
                    orderItem: r.data,
                    isLoading: false,
                });
            })
            .catch((e) => {
                this.props.history.push('/login?redirect=true');
            });
    }

    componentWillUnmount() {
        document.body.classList.remove('white-bg');
    }

    render() {
        let selectedAddons;
        let content = <Loader />;
        let status;
        if (this.state.orderItem) {
            if (this.state.orderItem.status === 1) {
                status = 'In progress';
            } else if (this.state.orderItem.status === 2) {
                status = 'Pending';
            } else if (this.state.orderItem.status === 3) {
                status = 'Partially paid';
            } else if (this.state.orderItem.status === 4) {
                status = 'Completed';
            }
        }
        if (!this.state.isLoading) {
            if (this.state.orderItem.orderItems[0].userFuneralPlan.userAddOns) {
                selectedAddons =
                    this.state.orderItem.orderItems[0].userFuneralPlan.userAddOns.map(
                        (addon, index) => {
                            return (index ? ', ' : '') + addon.addOn.title;
                        }
                    );
            }
            content = (
                <div className="c-funeral__form c-funeral__history-single">
                    <PageMeta
                        title="Purchase History | Aura"
                        description="Purchase History | Aura"
                        canonical={window.location.href}
                    />
                    <div className="button-with-arrow">
                        <span></span>
                        <button
                            onClick={() =>
                                this.props.history.push('/purchase-history')
                            }
                            className="button"
                        >
                            Purchase History
                        </button>
                    </div>
                    <h2>Transaction Details</h2>
                    <div className="c-funeral__history-single--bill">
                        <h2>
                            {this.state.orderItem.orderItems[0].userFuneralPlan
                                .rate === 1
                                ? 'Amount'
                                : 'Installment amount'}
                            : £
                            {NumberFormater.formatNumber(
                                this.state.orderItem.amount
                            )}
                        </h2>
                        <ul>
                            <li>
                                <p>Date: </p>
                                <span>
                                    {DateFormater.formatDate(
                                        this.state.orderItem.createdAt
                                    )}
                                </span>
                            </li>
                            {this.state.orderItem.transactions &&
                            this.state.orderItem.transactions[0] ? (
                                <li>
                                    <p>Paid with: </p>
                                    <span>
                                        ****{' '}
                                        {
                                            this.state.orderItem.transactions[0]
                                                .last4
                                        }
                                    </span>
                                </li>
                            ) : null}
                            {/*<li><p>Plan ID: </p><span>{this.state.orderItem.orderNumber}</span></li>*/}
                            <li>
                                <p>Policy number: </p>
                                <span>
                                    {
                                        this.state.orderItem.orderItems[0]
                                            .userFuneralPlan.id
                                    }
                                </span>
                            </li>

                            <li>
                                <p>Preferred way to pay:</p>
                                <span>
                                    {this.state.orderItem.orderItems[0]
                                        .userFuneralPlan.rate === 1
                                        ? 'One Payment'
                                        : this.state.orderItem.orderItems[0]
                                              .userFuneralPlan.rate === 24
                                        ? '24 Payments'
                                        : '12 Payments'}
                                </span>
                            </li>
                            <li>
                                <p>Status: </p>
                                <span>
                                    {status}{' '}
                                    {status === 'Partially paid'
                                        ? `(${
                                              this.state.orderItem
                                                  .countPaidTransactions
                                          }/${
                                              this.state.orderItem.orderItems[0]
                                                  .userFuneralPlan.rate === 24
                                                  ? '24'
                                                  : '12'
                                          })`
                                        : null}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <h2>Plan Overview</h2>
                    <div className="c-funeral__history-single--bill--blue">
                        <div className="c-funeral__history-single--bill-headline text-center">
                            <div
                                style={{
                                    backgroundImage: `url(${this.state.orderItem.orderItems[0].userFuneralPlan.planOption.image})`,
                                }}
                            ></div>
                            <h3>
                                {ReactHtmlParser(
                                    this.state.orderItem.orderItems[0]
                                        .userFuneralPlan.plan.title
                                        ? this.state.orderItem.orderItems[0]
                                              .userFuneralPlan.plan.title
                                        : ''
                                )}
                            </h3>
                            <h4>
                                {
                                    this.state.orderItem.orderItems[0]
                                        .userFuneralPlan.planOption.title
                                }
                            </h4>
                            <p>
                                {ReactHtmlParser(
                                    this.state.orderItem.orderItems[0]
                                        .userFuneralPlan.planOption.description
                                )}
                            </p>
                        </div>

                        <div className="c-funeral__history-single--full-bill">
                            <h4>Plan holder details</h4>
                            <ul>
                                <li>
                                    <p>First name: </p>
                                    <span>
                                        {
                                            this.state.orderItem.orderItems[0]
                                                .userFuneralPlan.holderFirstName
                                        }
                                    </span>
                                </li>
                                {this.state.orderItem.orderItems[0]
                                    .userFuneralPlan.holderMiddleName ? (
                                    <li>
                                        <p>Middle name: </p>
                                        <span>
                                            {
                                                this.state.orderItem
                                                    .orderItems[0]
                                                    .userFuneralPlan
                                                    .holderMiddleName
                                            }
                                        </span>
                                    </li>
                                ) : null}
                                <li>
                                    <p>Last name: </p>
                                    <span>
                                        {
                                            this.state.orderItem.orderItems[0]
                                                .userFuneralPlan.holderLastName
                                        }
                                    </span>
                                </li>
                                <li>
                                    <p>Email address: </p>
                                    <span>
                                        {
                                            this.state.orderItem.orderItems[0]
                                                .userFuneralPlan.holderEmail
                                        }
                                    </span>
                                </li>
                                <li>
                                    <p>Phone number: </p>
                                    <span>
                                        {
                                            this.state.orderItem.orderItems[0]
                                                .userFuneralPlan.holderMobile
                                        }
                                    </span>
                                </li>
                                <li>
                                    <p>Full Address: </p>
                                    <span>
                                        {this.state.orderItem.orderItems[0]
                                            .userFuneralPlan
                                            .holderCombinedAddress
                                            ? this.state.orderItem.orderItems[0].userFuneralPlan.holderCombinedAddress
                                                  .replaceAll(',', '')
                                                  .trim()
                                            : this.state.orderItem.orderItems[0].userFuneralPlan.fullAddress
                                                  .replaceAll(',', '')
                                                  .trim()}
                                    </span>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>
                                        {
                                            this.state.orderItem.orderItems[0]
                                                .userFuneralPlan.planOption
                                                .title
                                        }
                                        :
                                    </p>
                                    <span>
                                        £
                                        {NumberFormater.formatNumber(
                                            this.state.orderItem.orderItems[0]
                                                .userFuneralPlan.planOption
                                                .money
                                        )}
                                    </span>
                                </li>
                                {/*{ selectedAddons.length ? <li><p>Wishes:</p><span>{selectedAddons}</span></li> : null }*/}
                                {/*{selectedAddons}*/}
                                {/*{this.state.orderItem.orderItems[0].userFuneralPlan.addOnCosts ? <li><p>{this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription ? this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription : 'Special request'}</p><span>£{NumberFormater.formatNumber(this.state.orderItem.orderItems[0].userFuneralPlan.addOnCosts)}</span></li> : '0'}*/}

                                {/* { this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription ? <li><p>Special request:</p><span> {this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription ? this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription : null}</span></li> : null } */}

                                {/*{this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription ? <li><p>{this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription }</p></li> : null}*/}
                                {/*{this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription ? <li><p>{this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription }</p></li> : <li><p>Special request</p></li>}*/}
                                {/* {this.state.orderItem.orderItems[0].userFuneralPlan.addOnCosts ? <li><p>Add-ons credit: </p><span>£{NumberFormater.formatNumber(this.state.orderItem.orderItems[0].userFuneralPlan.addOnCosts)}</span></li>  : <li><p>Add-ons credit: </p><span>£0</span></li>} */}
                            </ul>
                            {/*<ul>*/}
                            {/*    <li><p>{this.state.orderItem.orderItems[0].userFuneralPlan.planOption.title}</p><span>£{NumberFormater.formatNumber(this.state.orderItem.orderItems[0].userFuneralPlan.planOption.money)}</span></li>*/}
                            {/*    {selectedAddons}*/}
                            {/*    {this.state.orderItem.orderItems[0].userFuneralPlan.ownAddCredit ? <li><p>{this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription ? this.state.orderItem.orderItems[0].userFuneralPlan.ownAddDescription : 'Special request'}</p><span>£{NumberFormater.formatNumber(this.state.orderItem.orderItems[0].userFuneralPlan.ownAddCredit)}</span></li> : null}*/}
                            {/*</ul>*/}
                            <p className="c-funeral__history-single--total">
                                <p>Total cost</p>
                                <span>
                                    £
                                    {NumberFormater.formatNumber(
                                        this.state.orderItem.orderItems[0].price
                                    )}
                                </span>
                            </p>

                            {selectedAddons.length ? (
                                <ul className="c-funeral__wishes-list">
                                    <li>
                                        <p>Wishes:</p>
                                        <span>{selectedAddons}</span>
                                    </li>
                                </ul>
                            ) : null}
                            {this.state.orderItem.orderItems[0].userFuneralPlan
                                .ownAddDescription ? (
                                <ul className="c-funeral__special-request">
                                    <li>
                                        <p>Special request:</p>
                                        <span>
                                            {' '}
                                            {this.state.orderItem.orderItems[0]
                                                .userFuneralPlan
                                                .ownAddDescription
                                                ? this.state.orderItem
                                                      .orderItems[0]
                                                      .userFuneralPlan
                                                      .ownAddDescription
                                                : null}
                                        </span>
                                    </li>
                                </ul>
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        } else {
            content = <Loader />;
        }

        return (
            <>
                <Header />
                <div className="c-funeral">{content}</div>
                <Footer />
            </>
        );
    }
}
