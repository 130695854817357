import * as funeralPaymentActionTypes from '../../store/actions/funeralPaymentActions';

import React, { Component } from 'react';

import ApiService from '../../service/api.service';
import CheckoutForm from '../../components/FuneralPayment/CheckoutForm/CheckoutForm';
import Cookie from 'js-cookie';
import { Elements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import Loader from '../../components/UI/Loader/Loader';
import NumberFormater from '../../service/number_formater.service';
import config from '../../config';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import loader from '../../assets/images/loader.svg';
import ReactHtmlParser from 'react-html-parser';
import Popup from 'reactjs-popup';
import PaymentFlowSidebar from '../../components/PaymentFlowSidebar/PaymentFlowSidebar';
class PaymentSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPaymentOption: '',
            isLoading: true,
            optionTitle: '',
            optionAmount: '',
            optionIncluded: '',
            optionNotincluded: '',
            show: false,
            buttonLoading: false,
            buttonLoading24: false,
            popupOpen: false,
            popupOpen24: false,
            popup24: false,
        };

        this.stripePromise = loadStripe(config.stripe.STRIPE_PUBLISH_KEY);
    }

    componentDidMount() {
        this.getFuneralPlan();
        document.body.classList.add('white-bg');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user?.id !== this.props.user?.id) {
            if (
                prevProps.user !== this.props.user &&
                this.props.user &&
                this.props.user.userFuneralPlan
            ) {
                let plan = 'essential';
                if (window.location.href.includes('essential')) {
                    plan = 'essential';
                } else if (window.location.href.includes('celebration')) {
                    plan = 'celebration-of-life';
                } else if (window.location.href.includes('imagined')) {
                    plan = 'imagined';
                } else if (window.location.href.includes('simple')) {
                    plan = 'simple';
                }
                this.setState(
                    {
                        isLoading: true,
                    },
                    () => {
                        const funeralPlanId =
                            this.props.user.userFuneralPlan.uuid;
                        ApiService.getUserFuneralPlan(funeralPlanId)
                            .then((r) => {
                                this.props.onSetFuneralCheckout(r.data);
                                this.props.onSetFuneralPlan(plan);
                                this.getFuneralPlan();
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                );
            }
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('white-bg');
        window.HubSpotConversations.widget.remove();
    };

    getFuneralPlan = () => {
        let plan = 'essential';
        if (window.location.href.includes('essential')) {
            plan = 'essential';
        } else if (window.location.href.includes('celebration')) {
            plan = 'celebration-of-life';
        } else if (window.location.href.includes('imagined')) {
            plan = 'imagined';
        } else if (window.location.href.includes('simple')) {
            plan = 'simple';
        }
        if (
            this.props.user &&
            this.props.funeralPayment &&
            this.props.funeralPayment.uuid
        ) {
            const funeralPlanId = this.props.funeralPayment.uuid;
            ApiService.getUserFuneralPlan(funeralPlanId)
                .then((r) => {
                    this.props.onSetFuneralCheckout(r.data);
                    this.props.onSetFuneralPlan(plan);
                    let id =
                        this.props.funeralPayment.selectedOption.split('/')[3];
                    ApiService.getFuneralPlanOption(id)
                        .then((r) => {
                            ApiService.getTotalPrice(
                                this.props.funeralPayment.uuid
                            )
                                .then((res) => {
                                    this.props.onSetTotal(
                                        res.data.total,
                                        res.data.subtotal
                                    );
                                    this.setState({
                                        optionTitle: r.data.title,
                                        optionAmount: r.data.money,
                                        optionIncluded: r.data.included,
                                        optionNotincluded: r.data.notIncluded,
                                        isLoading: false,
                                    });
                                })
                                .catch((e) => {
                                    this.setState({
                                        optionTitle: r.data.title,
                                        optionAmount: r.data.money,
                                        optionIncluded: r.data.included,
                                        optionNotincluded: r.data.notIncluded,
                                        isLoading: false,
                                    });
                                });
                        })
                        .catch((e) => {
                            this.setState({
                                isLoading: false,
                            });
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    setSelectedPaymentOption = (option) => {
        this.setState(
            {
                selectedPaymentOption: option,
            },
            () => {
                if (option === 12) {
                    Cookie.set(
                        'funeralPaymentId',
                        this.props.funeralPayment.uuid
                    );
                    this.setState(
                        {
                            buttonLoading: true,
                        },
                        () => {
                            const data = {
                                plan: this.props.funeralPayment
                                    .funeralPaymentId,
                                numOfInstallments: option,
                            };

                            ApiService.stripePay(data)
                                .then((r) => {
                                    this.setState(
                                        {
                                            buttonLoading: false,
                                        },
                                        () => {
                                            window.open(
                                                r.data.stripeCheckoutUrl,
                                                '_self'
                                            );
                                        }
                                    );
                                })
                                .catch((e) => {
                                    this.setState({
                                        buttonLoading: false,
                                    });
                                });
                        }
                    );
                } else if (option === 24) {
                    Cookie.set(
                        'funeralPaymentId',
                        this.props.funeralPayment.uuid
                    );
                    this.setState(
                        {
                            buttonLoading24: true,
                        },
                        () => {
                            const data = {
                                plan: this.props.funeralPayment
                                    .funeralPaymentId,
                                numOfInstallments: option,
                            };

                            ApiService.stripePay(data)
                                .then((r) => {
                                    this.setState(
                                        {
                                            buttonLoading24: false,
                                        },
                                        () => {
                                            window.open(
                                                r.data.stripeCheckoutUrl,
                                                '_self'
                                            );
                                        }
                                    );
                                })
                                .catch((e) => {
                                    this.setState({
                                        buttonLoading24: false,
                                    });
                                });
                        }
                    );
                } else {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth',
                    });
                }
            }
        );
    };

    handleShowBreakdown = () => {
        this.setState({
            show: !this.state.show,
        });
    };

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
            popup24: false,
        });
    };
    handlePopupOpen24 = () => {
        this.setState({
            popupOpen: true,
            popup24: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    render() {
        let popupContent = (
            <>
                <h6>
                    Payment via {this.state.popup24 ? '24' : '12'} monthly
                    instalments is no additional cost.
                </h6>
                <p>
                    If you select to pay by instalments over
                    {this.state.popup24 ? ' 24' : ' 12'} months and the covered
                    individual dies before the full payment has been made of
                    anything other than an accident, then your Next of
                    Kin/Executor will be required to pay any outstanding
                    payments before we can arrange the funeral service,
                    alternatively they could cancel the funeral plan and make
                    alternative arrangements – if they choose to do this all
                    monies paid will be returned to the estate.
                </p>
            </>
        );

        let selectedAddons;
        // let content = <Loader />
        let totalAmount = this.props.funeralPayment.total / 12;

        if (
            this.props.funeralPayment &&
            this.props.funeralPayment.userAddOns &&
            this.props.funeralPayment.userAddOns.length
        ) {
            selectedAddons = this.props.funeralPayment.userAddOns.map(
                (addon, index) => {
                    return (index ? ', ' : '') + addon.addOn.title;
                }
            );
        }

        /** Stripe config */
        const appearance = {
            theme: 'stripe',
        };

        const customFonts = [
            {
                src: '',
                family: '',
                style: 'normal',
                weight: '400',
            },
        ];

        const options = {
            appearance,
            fonts: customFonts,
        };

        //first day of payment to last day of payment (1 year from today)
        let todaysDate = new Date();
        let oneYearFromToday = new Date(
            todaysDate.setMonth(todaysDate.getMonth() + 11)
        );
        let twoYearsFromToday = new Date(
            todaysDate.setMonth(todaysDate.getMonth() + 12)
        );
        todaysDate = new Date();
        let todaysDateString = todaysDate.toString();
        let oneYearFromTodayString = oneYearFromToday.toString();
        let twoYearsFromTodayString = twoYearsFromToday.toString();

        let content = <Loader />;

        if (!this.state.isLoading) {
            content = (
                <>
                    <div class="c-funeral">
                        <div class="c-funeral__form">
                            <div class="c-funeral__payment c-funeral__payment-page">
                                <div className="c-payment-flow-header__progress-wrapper">
                                    <p>
                                        Step{' '}
                                        {window.location.href.includes(
                                            'payment/simple'
                                        )
                                            ? '4'
                                            : '6'}
                                        /
                                        {window.location.href.includes(
                                            'payment/simple'
                                        )
                                            ? '4'
                                            : '6'}
                                    </p>
                                    <div className="c-payment-flow-header__progress-bar-wrapper">
                                        <div
                                            className={`c-payment-flow-header__progress-bar ${
                                                window.location.href.includes(
                                                    'payment/simple'
                                                )
                                                    ? 'c-payment-flow-header__progress-bar--simple-step-4'
                                                    : 'c-payment-flow-header__progress-bar--step-6'
                                            } `}
                                        ></div>
                                    </div>
                                </div>
                                <h2>
                                    Please tell us how you'd like to pay and
                                    confirm your purchase
                                </h2>
                                <div className="c-funeral__form--wrapper">
                                    <h5>Plan Overview</h5>
                                    <ul className="c-funeral__thankyou--bill">
                                        <li>
                                            <p>Plan is for:</p>
                                            <span>
                                                {
                                                    this.props.funeralPayment
                                                        .holderTitle
                                                }{' '}
                                                {
                                                    this.props.funeralPayment
                                                        .holderFirstName
                                                }{' '}
                                                {
                                                    this.props.funeralPayment
                                                        .holderMiddleName
                                                }{' '}
                                                {
                                                    this.props.funeralPayment
                                                        .holderLastName
                                                }
                                            </span>
                                        </li>
                                        <li>
                                            <p>Plan name:</p>
                                            <span>
                                                {this.props.funeralPayment
                                                    .currentPlan === 'essential'
                                                    ? 'Essential'
                                                    : this.props.funeralPayment
                                                          .currentPlan ===
                                                      'celebration-of-life'
                                                    ? 'Celebration of Life'
                                                    : this.props.funeralPayment
                                                          .currentPlan ===
                                                      'imagined'
                                                    ? 'Wonderfully Imagined'
                                                    : this.props.funeralPayment
                                                          .currentPlan ===
                                                      'simple'
                                                    ? 'Perfectly Simple'
                                                    : ''}
                                            </span>
                                        </li>
                                        <li>
                                            <p>{this.state.optionTitle}:</p>
                                            <span>
                                                £
                                                {NumberFormater.formatNumber(
                                                    this.state.optionAmount
                                                )}
                                            </span>
                                        </li>
                                        <li className="c-funeral__thankyou--bill-total">
                                            <p>Total cost</p>
                                            <span>
                                                £
                                                {NumberFormater.formatNumber(
                                                    this.props.funeralPayment
                                                        .total
                                                )}
                                            </span>
                                        </li>
                                        {selectedAddons ? (
                                            <ul className="c-funeral__wishes-list-payment">
                                                <li>
                                                    <p>Wishes:</p>
                                                    <span>
                                                        {selectedAddons}
                                                    </span>
                                                </li>
                                            </ul>
                                        ) : null}
                                        {this.props.funeralPayment
                                            .ownDescription ? (
                                            <ul className="c-funeral__special-request">
                                                <li>
                                                    <p>Special request:</p>
                                                    <span>
                                                        {' '}
                                                        {
                                                            this.props
                                                                .funeralPayment
                                                                .ownDescription
                                                        }
                                                    </span>
                                                </li>
                                            </ul>
                                        ) : null}
                                    </ul>
                                </div>
                                <h4
                                    className={
                                        this.state.show
                                            ? 'c-funeral__thankyou__breakdown--title toggle-open'
                                            : 'c-funeral__thankyou__breakdown--title'
                                    }
                                    onClick={this.handleShowBreakdown}
                                >
                                    Plan breakdown
                                </h4>
                                {this.state.show ? (
                                    <div className="c-funeral__thankyou__breakdown--select">
                                        <div>
                                            <p className="c-funeral__thankyou__breakdown--selected">
                                                {ReactHtmlParser(
                                                    this.state.optionIncluded
                                                )}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="c-funeral__thankyou__breakdown--selected">
                                                {ReactHtmlParser(
                                                    this.state.optionNotincluded
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="c-funeral__thankyou__breakdown--txt-box">
                                    {this.props.funeralPayment.forMe ? (
                                        <>
                                            <p>
                                                Based on the information you
                                                have provided, we feel this plan
                                                meets your demands and needs
                                                because you have told us:
                                            </p>
                                            <ul>
                                                <li>
                                                    <i className="icon-checkmark"></i>
                                                    You do not have existing
                                                    plans/arrangements in place
                                                    for your funeral.
                                                </li>
                                                <li>
                                                    <i className="icon-checkmark"></i>
                                                    You have not been diagnosed
                                                    with an illness or condition
                                                    which means you are likely
                                                    to pass away within the next
                                                    24 months.
                                                </li>
                                                <li>
                                                    <i className="icon-checkmark"></i>
                                                    You understand the
                                                    information we have
                                                    presented to you about your
                                                    funeral plan.
                                                </li>
                                            </ul>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                Based on the information you
                                                have provided, we feel this plan
                                                meets the demands and needs of
                                                the planholder because you have
                                                told us:
                                            </p>
                                            <ul>
                                                <li>
                                                    <i className="icon-checkmark"></i>
                                                    They do not have existing
                                                    plans/arrangements in place
                                                    for their funeral.
                                                </li>
                                                <li>
                                                    <i className="icon-checkmark"></i>
                                                    They have not been diagnosed
                                                    with an illness or condition
                                                    which means they are likely
                                                    to pass away within the next
                                                    24 months.
                                                </li>
                                                <li>
                                                    <i className="icon-checkmark"></i>
                                                    You understand the
                                                    information we have
                                                    presented to you about the
                                                    funeral plan.
                                                </li>
                                            </ul>
                                        </>
                                    )}
                                </div>
                                <h6>Would you prefer to pay</h6>
                                <div className="c-funeral__payment--options">
                                    <div
                                        className={`one-payment-box ${
                                            this.state.selectedPaymentOption ===
                                            1
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <p>
                                            £
                                            {NumberFormater.formatNumber(
                                                this.props.funeralPayment.total
                                            )}
                                        </p>
                                        <span>Payment in full</span>
                                        <button
                                            className="o-d-inline-block btn btn--full btn--size-59 btn--size-width-246 text-center btn--padding-left-right btn-loader one-payment-btn"
                                            onClick={() => {
                                                this.setSelectedPaymentOption(
                                                    1
                                                );
                                            }}
                                        >
                                            One Payment
                                        </button>
                                    </div>
                                    <div
                                        className={`${
                                            this.state.selectedPaymentOption ===
                                            12
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <p className="c-funeral__instalments-heading">
                                            12 x £
                                            {NumberFormater.formatNumber(
                                                totalAmount.toFixed(2)
                                            )}{' '}
                                            <span
                                                onClick={this.handlePopupOpen}
                                            >
                                                <i className="icon-question font-15"></i>
                                            </span>
                                        </p>
                                        <span className="c-funeral__instalments-total">
                                            (Total cost £
                                            {NumberFormater.formatNumber(
                                                this.props.funeralPayment.total
                                            )}
                                            )
                                        </span>
                                        <span className="c-funeral__instalments-date">
                                            {todaysDateString.split(' ')[2]}{' '}
                                            {todaysDateString.split(' ')[1]}{' '}
                                            {todaysDateString.split(' ')[3]} -{' '}
                                            {
                                                oneYearFromTodayString.split(
                                                    ' '
                                                )[2]
                                            }{' '}
                                            {
                                                oneYearFromTodayString.split(
                                                    ' '
                                                )[1]
                                            }{' '}
                                            {
                                                oneYearFromTodayString.split(
                                                    ' '
                                                )[3]
                                            }
                                        </span>
                                        <button
                                            className={`o-d-inline-block btn btn--full btn--size-57 btn--size-width-242 text-center btn--padding-left-right btn-loader ${
                                                this.state.buttonLoading
                                                    ? 'save-in-progress'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                this.setSelectedPaymentOption(
                                                    12
                                                );
                                            }}
                                        >
                                            12 Payments{' '}
                                            <img
                                                width="100%"
                                                height="100%"
                                                src={loader}
                                                alt="loaderImage"
                                            />
                                        </button>
                                    </div>
                                    <div
                                        className={`${
                                            this.state.selectedPaymentOption ===
                                            24
                                                ? 'active'
                                                : ''
                                        }`}
                                    >
                                        <p className="c-funeral__instalments-heading">
                                            24 x £
                                            {NumberFormater.formatNumber(
                                                (totalAmount / 2).toFixed(2)
                                            )}{' '}
                                            <span
                                                onClick={this.handlePopupOpen24}
                                            >
                                                <i className="icon-question font-15"></i>
                                            </span>
                                        </p>
                                        <span className="c-funeral__instalments-total">
                                            (Total cost £
                                            {NumberFormater.formatNumber(
                                                this.props.funeralPayment.total
                                            )}
                                            )
                                        </span>
                                        <span className="c-funeral__instalments-date">
                                            {todaysDateString.split(' ')[2]}{' '}
                                            {todaysDateString.split(' ')[1]}{' '}
                                            {todaysDateString.split(' ')[3]} -{' '}
                                            {
                                                twoYearsFromTodayString.split(
                                                    ' '
                                                )[2]
                                            }{' '}
                                            {
                                                twoYearsFromTodayString.split(
                                                    ' '
                                                )[1]
                                            }{' '}
                                            {
                                                twoYearsFromTodayString.split(
                                                    ' '
                                                )[3]
                                            }
                                        </span>
                                        <button
                                            className={`o-d-inline-block btn btn--full btn--size-57 btn--size-width-242 text-center btn--padding-left-right btn-loader ${
                                                this.state.buttonLoading24
                                                    ? 'save-in-progress'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                this.setSelectedPaymentOption(
                                                    24
                                                );
                                            }}
                                        >
                                            24 Payments{' '}
                                            <img
                                                width="100%"
                                                height="100%"
                                                src={loader}
                                                alt="loaderImage"
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="c-funeral__thankyou__breakdown--txt-box2">
                                    <p>
                                        If you decide to pay by instalments and
                                        stop making payments, we may cancel your
                                        plan.
                                        <br />
                                        If this happens, you’ll get a full
                                        refund and will no longer benefit from
                                        the plan.
                                    </p>
                                    <p>
                                        If you change your mind or circumstances
                                        change, you can cancel your plan at any
                                        time at no cost. We will issue a full
                                        refund within 14 days of receiving your
                                        cancellation request.
                                    </p>
                                </div>
                                {this.state.selectedPaymentOption ? (
                                    <Elements
                                        options={options}
                                        stripe={this.stripePromise}
                                    >
                                        <CheckoutForm
                                            instalmentAmount={`£${NumberFormater.formatNumber(
                                                totalAmount.toFixed(2)
                                            )}`}
                                            uuid={
                                                this.props.funeralPayment.uuid
                                            }
                                            paymentOption={
                                                this.state.selectedPaymentOption
                                            }
                                        />
                                    </Elements>
                                ) : null}
                                <div class="c-funeral__bottom-nav ">
                                    <Link
                                        className="c-funeral__bottom-nav--back o-d-inline-block btn text-center btn--padding-left-right"
                                        to={{
                                            pathname: `/funeral-payment/${
                                                window.location.href.includes(
                                                    'essential'
                                                )
                                                    ? 'essential'
                                                    : window.location.href.includes(
                                                          'celebration-of-life'
                                                      )
                                                    ? 'celebration-of-life'
                                                    : window.location.href.includes(
                                                          'imagined'
                                                      )
                                                    ? 'imagined'
                                                    : 'simple'
                                            }`,
                                            state: {
                                                prevPath:
                                                    window.location.pathname,
                                            },
                                        }}
                                    >
                                        Back
                                    </Link>
                                </div>
                                <Popup
                                    lockScroll
                                    open={this.state.popupOpen}
                                    onClose={this.handlePopupClose}
                                    className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                                >
                                    {(close) => (
                                        <div className="c-popup o-flex o-flex-column text-center c-funeral__popup--olduser">
                                            <button
                                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                                onClick={close}
                                            >
                                                <i className="icon-times font-35 cursor-pointer"></i>
                                            </button>
                                            {popupContent}

                                            <button
                                                className="o-d-inline-block margin-auto c-popup__close btn btn--full btn--smaller"
                                                onClick={close}
                                            >
                                                Got it, close this
                                            </button>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        </div>
                        <PaymentFlowSidebar />
                    </div>
                </>
            );
        }
        return content;
    }
}

const mapStateToProps = (state) => ({
    funeralPayment: state.funeralPaymentReducer,
    funeralPlan: state.usersReducer,
    user: state.usersReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetTotalAmount: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetTotalAmount(currentStep)),
        onSetOption: (option, step) =>
            dispatch(funeralPaymentActionTypes.onSetOption(option, step)),
        onSetCurrentStep: (step) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(step)),
        onSetTotal: (total, subtotal) =>
            dispatch(funeralPaymentActionTypes.onSetTotal(total, subtotal)),
        onRemoveAddons: () =>
            dispatch(funeralPaymentActionTypes.onRemoveAddons()),
        onSetFuneralCheckout: (data) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralCheckout(data)),
        onSetFuneralPlan: (plan) =>
            dispatch(funeralPaymentActionTypes.onSetFuneralPlan(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSelection);
