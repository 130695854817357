import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class TeamContact extends Component {
    render() {
        return (
            <div className="c-team-contact container">
                <div className="o-flex two-col">
                    <div className="position-relative c-team-contact__img-box">
                        {this.props.content.imageStaff && (
                            <div
                                className="c-team-contact--lg-img"
                                style={{
                                    backgroundImage: `url(${this.props.content.imageStaff})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        )}
                        {this.props.content.imageAward && (
                            <div
                                className="c-team-contact--sm-img"
                                style={{
                                    backgroundImage: `url(${this.props.content.imageAward})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        )}
                    </div>
                    <div className="c-team-contact__text-box">
                        {this.props.content.title && (
                            <h2>{this.props.content.title}</h2>
                        )}
                        {this.props.content.description && (
                            <p>
                                {ReactHtmlParser(
                                    this.props.content.description
                                )}
                            </p>
                        )}{' '}
                        {this.props.content.phoneNumber ? (
                            <a href={`tel:${this.props.content.phoneNumber}`}>
                                {this.props.content.phoneNumber}
                            </a>
                        ) : (
                            <a
                                className="c-pre-header__phone"
                                href="tel:+448008085723"
                                onClick={() => this.handlePhoneClick()}
                            >
                                0800 808 5723
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamContact;
