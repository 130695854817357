import React, { Component } from 'react';

import Cookie from 'js-cookie';
import { Link } from 'react-router-dom';
import PaymentFlowHeader from '../../../../../components/PayentFlowHeader/PaymentFlowHeader';
import PaymentFlowSidebar from '../../../../../components/PaymentFlowSidebar/PaymentFlowSidebar';
export default class Demands extends Component {
    state = {
        disagree: false,
    };

    handleAgree = () => {
        Cookie.set('demands', 'true');
        this.props.setDemands();
    };

    handleChatOpen = () => {
        window.HubSpotConversations.widget.open();
    };

    render() {
        return (
            <div className="c-demands">
                <PaymentFlowHeader showStepTrack={false} />

                {/* <div className="c-demands--logo">
                    <Link to="/">
                        <img src={imageLogo} alt="logo" />
                    </Link>
                </div> */}
                <div className="two-col-demands">
                    <div className="c-demands--content">
                        <h1>
                            Before we continue, please read and confirm your
                            understanding of the following:
                        </h1>
                        <ul>
                            <li>
                                Aura do not offer advice on their funeral plans
                                and I will select a plan which best suits the
                                demands and needs of who the plan is for
                            </li>
                            <li>
                                I will only purchase a funeral plan from Aura
                                which is affordable to me and won't cause me any
                                financial difficulties
                            </li>
                            <li>
                                The person the plan is for does not have any
                                existing arrangements in place
                            </li>
                            <li>
                                The person this plan is for does not usually
                                have someone else help them with decisions to do
                                with their finances, for example how their money
                                is spent or invested. This could be a friend or
                                financial advisor
                            </li>
                            <li>
                                The person this plan is for has not faced recent
                                challenges which may impair their judgement and
                                decision making.
                            </li>
                            <li>
                                The person the plan is for does not have a
                                diagnosed illness or condition which means they
                                are likely to pass away within the next 24
                                months
                            </li>
                        </ul>
                        <h2 className="font-20">More information</h2>
                        <p>
                            If the person the plan is for is on end-of-life care
                            or has a terminal condition, you can still set up a
                            funeral plan in advance if you pay via lump sum.
                        </p>
                        <p>
                            However, before proceeding, we must inform you about
                            our At-Need service. This is for arranging a direct
                            cremation immediately, either for someone who has
                            passed or is on end-of-life care, and is not a
                            prepaid funeral plan. It would be for the Next of
                            Kin to use when needed, if you decide to wait.
                        </p>
                        <p>
                            Using our At-Need service may sometimes cost less
                            than setting up a prepaid plan, depending on the
                            timing and circumstances.
                        </p>
                        <p>
                            If you wish to prepay and plan ahead, you can accept
                            this statement and continue. Alternatively, you can
                            contact us with any queries: 0800 014 8225.
                        </p>
                        <div className="c-demands--button o-flex o-flex-between mb-30">
                            <button
                                onClick={() =>
                                    this.setState({ disagree: true })
                                }
                                className="btn btn--ghost-purple btn--size-width-199"
                            >
                                No, disagree
                            </button>
                            <button
                                onClick={() => this.handleAgree()}
                                className="btn btn--full btn--size-width-199"
                            >
                                Yes, agree
                            </button>
                        </div>
                        {this.state.disagree ? (
                            <>
                                <p className="c-demands--error">
                                    Based on your answers, our products may not
                                    meet your demands and needs or you may need
                                    some additional support. You’ll need to
                                    speak to us. Please call us on{' '}
                                    <a
                                        className="font-bold font-form-error"
                                        href="tel:08008085723"
                                    >
                                        0800 808 5723
                                    </a>
                                    , email us at{' '}
                                    <a
                                        className="font-bold font-form-error"
                                        href="mailto:hello@aura.life"
                                    >
                                        hello@aura.life
                                    </a>{' '}
                                    or chat with us{' '}
                                    {/* <span
                                        className="link-underline"
                                        onClick={() => this.handleChatOpen()}
                                    >
                                        here
                                    </span> */}
                                    <a
                                        className="link-underline"
                                        href="https://www.aura.life/help/contact-us"
                                    >
                                        here
                                    </a>
                                </p>
                                <div className="c-demands--error-link">
                                    <Link
                                        className="arrow-link"
                                        to={`/funerals`}
                                    >
                                        <i className="icon-nav-arrow-smaller"></i>
                                        Go back to funeral
                                    </Link>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <PaymentFlowSidebar />
                </div>
            </div>
        );
    }
}
