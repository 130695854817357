import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';

export default class Personal extends Component {
    render() {
        let item;

        if (this.props.content.length) {
            item = this.props.content.map((item, index) => {
                return (
                    <div
                        key={`key-feature-${index}`}
                        className="c-plans__personal--items"
                    >
                        <div
                            className="c-plans__personal--item-img"
                            style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                        <h4>{ReactHtmlParser(item.title ? item.title : '')}</h4>
                        <p>{ReactHtmlParser(item.body ? item.body : '')}</p>
                    </div>
                );
            });
        }

        return this.props.content.length ? (
            <div className="c-plans__personal c-personal container">
                <h3>
                    {ReactHtmlParser(this.props.title ? this.props.title : '')}
                </h3>
                <p>{ReactHtmlParser(this.props.body ? this.props.body : '')}</p>
                <div className="c-plans__personal--item">{item}</div>
            </div>
        ) : null;
    }
}
