import * as funeralPaymentActionTypes from '../../../../store/actions/funeralPaymentActions';
import React, { Component } from 'react';
import Navigation from '../../../../components/FuneralPayment/Navigation/Navigation';
import { connect } from 'react-redux';
import ApiService from '../../../../service/api.service';
import Loader from '../../../../components/UI/Loader/Loader';
import NumberFormater from '../../../../service/number_formater.service';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Popup from 'reactjs-popup';
import ReactHtmlParser from 'react-html-parser';

class Payment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPaymentOption: '',
            isLoading: true,
            optionTitle: '',
            optionAmount: '',
            optionIncluded: '',
            optionNotincluded: '',
            show: false,
            buttonLoading: false,
            popupOpen1: false,
            infoTitle1: '',
            infoContent1: '',
            infoPhoto1: '',
            popupOpen2: false,
            infoTitle2: '',
            infoContent2: '',
            infoPhoto2: '',
            popupOpen3: false,
            infoTitle3: '',
            infoContent3: '',
            infoPhoto3: '',
        };
    }

    componentDidMount() {
        let id = this.props.funeralPayment.selectedOption.split('/')[3];
        ApiService.getFuneralPlanOption(id)
            .then((r) => {
                ApiService.getTotalPrice(this.props.funeralPayment.uuid)
                    .then((res) => {
                        this.props.onSetTotal(
                            res.data.total,
                            res.data.subtotal
                        );
                        this.setState({
                            optionTitle: r.data.title,
                            optionAmount: r.data.money,
                            optionIncluded: r.data.included,
                            optionNotincluded: r.data.notIncluded,
                            isLoading: false,
                        });
                    })
                    .catch((e) => {
                        this.setState({
                            optionTitle: r.data.title,
                            optionAmount: r.data.money,
                            optionIncluded: r.data.included,
                            optionNotincluded: r.data.notIncluded,
                            isLoading: false,
                        });
                    });
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                });
            });

        let slug1 = 'funeral-plan-summary';
        ApiService.getInfoPages(slug1).then((r) => {
            this.setState({
                infoTitle1: r.data.title,
                infoContent1: r.data.content,
                infoPhoto1: r.data.photo,
                isLoading: false,
            });
        });

        let slug2 = 'funeral-plan-schedule';
        ApiService.getInfoPages(slug2).then((r) => {
            this.setState({
                infoTitle2: r.data.title,
                infoContent2: r.data.content,
                infoPhoto2: r.data.photo,
                isLoading: false,
            });
        });
        let slug3 = 'terms-and-conditions';
        ApiService.getInfoPages(slug3).then((r) => {
            this.setState({
                infoTitle3: r.data.title,
                infoContent3: r.data.content,
                infoPhoto3: r.data.photo,
                isLoading: false,
            });
        });
    }

    setSelectedPaymentOption = (option) => {
        this.setState({
            selectedPaymentOption: option,
        });
    };

    handleSubmit = (values) => {
        this.setState(
            {
                buttonLoading: true,
            },
            () => {
                ApiService.setDeclaration(
                    values,
                    this.props.funeralPayment.uuid
                )
                    .then((r) => {
                        console.log(
                            'I DID THIS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!'
                        );
                        let currentPlan = 'essential';
                        if (
                            this.props.funeralPayment.currentPlan ===
                            'celebration-of-life'
                        ) {
                            currentPlan = 'celebration-of-life';
                        } else if (
                            this.props.funeralPayment.currentPlan === 'imagined'
                        ) {
                            currentPlan = 'imagined';
                        } else if (
                            this.props.funeralPayment.currentPlan === 'simple'
                        ) {
                            currentPlan = 'simple';
                        }
                        this.setState({
                            buttonLoading: false,
                        });
                        this.props.history.push(`/payment/${currentPlan}`);
                    })
                    .catch((e) => {
                        this.props.history.push('/aml-check');
                    });
            }
        ).catch((e) => {
            this.setState({
                buttonLoading: false,
            });
        });
    };

    handlePopupOpen1 = () => {
        this.setState({
            popupOpen1: true,
        });
    };

    handlePopupClose1 = () => {
        this.setState({
            popupOpen1: false,
        });
    };

    handlePopupOpen2 = () => {
        this.setState({
            popupOpen2: true,
        });
    };

    handlePopupClose2 = () => {
        this.setState({
            popupOpen2: false,
        });
    };
    handlePopupOpen3 = () => {
        this.setState({
            popupOpen3: true,
        });
    };

    handlePopupClose3 = () => {
        this.setState({
            popupOpen3: false,
        });
    };

    handleShowBreakdown = () => {
        this.setState({
            show: !this.state.show,
        });
    };

    render() {
        let popupContent1 = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle1}
                </h1>
                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent1)}
                </div>
                <div>
                    <img src={this.state.infoPhoto1} alt="" />
                </div>
            </>
        );
        let popupContent2 = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle2}
                </h1>
                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent2)}
                </div>
                <div>
                    <img src={this.state.infoPhoto2} alt="" />
                </div>
            </>
        );
        let popupContent3 = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle3}
                </h1>
                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent3)}
                </div>
                <div>
                    <img src={this.state.infoPhoto3} alt="" />
                </div>
            </>
        );

        let selectedAddons;
        let content = <Loader />;

        if (
            this.props.funeralPayment &&
            this.props.funeralPayment.userAddOns &&
            this.props.funeralPayment.userAddOns.length
        ) {
            selectedAddons = this.props.funeralPayment.userAddOns.map(
                (addon, index) => {
                    return (index ? ', ' : '') + addon.addOn.title;
                }
            );
        }

        if (!this.state.isLoading) {
            content = (
                <>
                    <h2>
                        Please review your plan details and confirm the
                        following statements
                    </h2>
                    <div className="c-funeral__form--wrapper">
                        <h5>Plan Overview</h5>
                        <ul className="c-funeral__thankyou--bill">
                            <li>
                                <p>Plan is for:</p>
                                <span>
                                    {this.props.funeralPayment.holderTitle}{' '}
                                    {this.props.funeralPayment.holderFirstName}{' '}
                                    {this.props.funeralPayment.holderMiddleName}{' '}
                                    {this.props.funeralPayment.holderLastName}
                                </span>
                            </li>
                            <li>
                                <p>Plan name:</p>
                                <span>
                                    {this.props.funeralPayment.currentPlan ===
                                    'essential'
                                        ? 'Essential'
                                        : this.props.funeralPayment
                                              .currentPlan ===
                                          'celebration-of-life'
                                        ? 'Celebration of Life'
                                        : this.props.funeralPayment
                                              .currentPlan === 'imagined'
                                        ? 'Wonderfully Imagined'
                                        : this.props.funeralPayment
                                              .currentPlan === 'simple'
                                        ? 'Perfectly Simple'
                                        : ''}
                                </span>
                            </li>
                            <li>
                                <p>{this.state.optionTitle}:</p>
                                <span>
                                    £
                                    {NumberFormater.formatNumber(
                                        this.state.optionAmount
                                    )}
                                </span>
                            </li>
                            <li className="c-funeral__thankyou--bill-total">
                                <p>Total cost</p>
                                <span>
                                    £
                                    {NumberFormater.formatNumber(
                                        this.props.funeralPayment.total
                                    )}
                                </span>
                            </li>
                            {selectedAddons ? (
                                <ul className="c-funeral__wishes-list-payment">
                                    <li>
                                        <p>Wishes:</p>
                                        <span>{selectedAddons}</span>
                                    </li>
                                </ul>
                            ) : null}
                            {this.props.funeralPayment.ownDescription ? (
                                <ul className="c-funeral__special-request">
                                    <li>
                                        <p>Special request:</p>
                                        <span>
                                            {' '}
                                            {
                                                this.props.funeralPayment
                                                    .ownDescription
                                            }
                                        </span>
                                    </li>
                                </ul>
                            ) : null}
                        </ul>
                    </div>
                    <h4
                        className={
                            this.state.show
                                ? 'c-funeral__thankyou__breakdown--title toggle-open'
                                : 'c-funeral__thankyou__breakdown--title'
                        }
                        onClick={this.handleShowBreakdown}
                    >
                        Plan breakdown
                    </h4>
                    {this.state.show ? (
                        <div className="c-funeral__thankyou__breakdown--select">
                            <div>
                                <p className="c-funeral__thankyou__breakdown--selected">
                                    {ReactHtmlParser(this.state.optionIncluded)}
                                </p>
                            </div>

                            <div>
                                <p className="c-funeral__thankyou__breakdown--selected">
                                    {ReactHtmlParser(
                                        this.state.optionNotincluded
                                    )}
                                </p>
                            </div>
                        </div>
                    ) : null}

                    <div className="c-funeral__thankyou__breakdown--txt-box">
                        {this.props.funeralPayment.forMe ? (
                            <>
                                <strong>
                                    Important - please read before proceeding:
                                </strong>
                                <p>
                                    We’ve sent your Funeral Plan Summary
                                    document to you by email. This document
                                    explains what is and is not included in your
                                    funeral plan, how you can pay for your plan
                                    and information on cancelling, making a
                                    complaint and your entitlement to
                                    compensation.
                                </p>
                            </>
                        ) : (
                            <>
                                <strong>
                                    Important - please read before proceeding:
                                </strong>
                                <p>
                                    We’ve sent the Funeral Plan Summary document
                                    to you and the planholder by email. This
                                    document explains what is and is not
                                    included in the funeral plan, how you can
                                    pay for your plan and information on
                                    cancelling, making a complaint and your
                                    entitlement to compensation.{' '}
                                </p>
                            </>
                        )}
                    </div>

                    <Formik
                        initialValues={{
                            declaration1: false,
                            declaration2: false,
                            declaration3: false,
                            terms: false,
                        }}
                        validationSchema={Yup.object({
                            declaration1: Yup.bool().oneOf(
                                [true],
                                'Field must be checked'
                            ),
                            declaration2: Yup.bool().oneOf(
                                [true],
                                'Field must be checked'
                            ),
                            declaration3: Yup.bool().oneOf(
                                [true],
                                'Field must be checked'
                            ),
                            terms: Yup.bool().oneOf(
                                [true],
                                'Field must be checked'
                            ),
                        })}
                        onSubmit={(values) => this.handleSubmit(values)}
                    >
                        {(props) => {
                            return (
                                <>
                                    <Form
                                        noValidate
                                        className="o-flex o-flex-column"
                                    >
                                        <div className="c-funeral__payment--checkboxes c-field-checkbox o-flex o-flex-middle position-relative o-flex-column">
                                            <div>
                                                <Field
                                                    name="declaration1"
                                                    className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset hide"
                                                    id="declaration1-checkbox"
                                                    type="checkbox"
                                                />

                                                {this.props.funeralPayment
                                                    .forMe ? (
                                                    <>
                                                        <label
                                                            htmlFor="declaration1-checkbox"
                                                            className="c-field-checkbox__label position-relative font-500 font-16 lh-30"
                                                        >
                                                            I am happy that the
                                                            chosen plan meets my
                                                            demands and needs
                                                            from the factual
                                                            information provided
                                                            by Aura which does
                                                            not constitute
                                                            financial advice.
                                                        </label>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label
                                                            htmlFor="declaration1-checkbox"
                                                            className="c-field-checkbox__label c-field-checkbox__label--simple position-relative font-500 font-16 lh-30"
                                                        >
                                                            I am happy that the
                                                            chosen plan meets
                                                            the demands and
                                                            needs of the
                                                            planholder from the
                                                            factual information
                                                            provided by Aura
                                                            which does not
                                                            constitute financial
                                                            advice. I agree I
                                                            have been given
                                                            consent by the
                                                            planholder and they
                                                            are aware I will
                                                            purchase this plan
                                                            on their behalf.
                                                        </label>
                                                    </>
                                                )}

                                                {props.errors.declaration1 &&
                                                props.touched.declaration1 ? (
                                                    <p className="font-13 font-semibold font-form-error error-message required-icon position-relative error-msg-top-1">
                                                        Required
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div>
                                                <Field
                                                    name="declaration2"
                                                    className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset hide"
                                                    id="declaration2-checkbox"
                                                    type="checkbox"
                                                />

                                                <label
                                                    htmlFor="declaration2-checkbox"
                                                    className="c-field-checkbox__label position-relative font-500 font-16 lh-30"
                                                >
                                                    Making financial decisions
                                                    can be difficult. If you
                                                    need us to explain anything
                                                    further, or have any
                                                    questions, please contact
                                                    us. If you have fully
                                                    understood the information
                                                    provided and don’t need any
                                                    more help please tick to
                                                    proceed.
                                                </label>

                                                {props.errors.declaration2 &&
                                                props.touched.declaration2 ? (
                                                    <p className="font-13 font-semibold font-form-error error-message required-icon position-relative error-msg-top-2">
                                                        Required
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div>
                                                <Field
                                                    name="declaration3"
                                                    className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset hide"
                                                    id="declaration3"
                                                    type="checkbox"
                                                />
                                                <label
                                                    htmlFor="declaration3"
                                                    className="c-field-checkbox__label position-relative font-500 font-16 lh-30"
                                                >
                                                    I will only proceed with
                                                    payment of this funeral plan
                                                    as long as it is affordable
                                                    to me and won't cause me any
                                                    financial difficulties
                                                </label>
                                                {props.errors.declaration3 &&
                                                props.touched.declaration3 ? (
                                                    <p className="font-13 font-semibold font-form-error error-message required-icon position-relative error-msg-top-3">
                                                        Required
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div>
                                                <Field
                                                    name="terms"
                                                    className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset hide"
                                                    id="terms-checkbox"
                                                    type="checkbox"
                                                />
                                                <label
                                                    htmlFor="terms-checkbox"
                                                    className="c-field-checkbox__label position-relative font-500 font-16 lh-30"
                                                >
                                                    I have read and agree to the{' '}
                                                    <span
                                                        onClick={
                                                            this
                                                                .handlePopupOpen1
                                                        }
                                                        className="link-underline span-privacy-after"
                                                    >
                                                        Funeral Plan Summary
                                                    </span>{' '}
                                                    and{' '}
                                                    <span
                                                        onClick={
                                                            this
                                                                .handlePopupOpen3
                                                        }
                                                        className="link-underline span-privacy-after"
                                                    >
                                                        Terms and Conditions
                                                    </span>{' '}
                                                </label>
                                                {props.errors.terms &&
                                                props.touched.terms ? (
                                                    <p className="font-13 font-semibold font-form-error error-message required-icon position-relative error-msg-top-4">
                                                        Required
                                                    </p>
                                                ) : null}
                                            </div>
                                            {/*<div>*/}
                                            {/*    <Field name="terms" className="c-field-checkbox__input position-absolute z-index-1 position-alignment margin-reset hide" id="terms-checkbox" type="checkbox" />*/}
                                            {/*    <label htmlFor="terms-checkbox" className="c-field-checkbox__label position-relative font-500 font-16">I have read and agree to the </label><span onClick={this.handlePopupOpen1} className='link-underline' > Funeral Plan Summary</span> , <span onClick={this.handlePopupOpen2} className='link-underline' >Funeral Plan Schedule</span> and <span onClick={this.handlePopupOpen3} className='link-underline' >Terms and Conditions</span>*/}
                                            {/*    {props.errors.terms && props.touched.terms? <p className="font-13 font-semibold font-form-error error-message required-icon position-relative error-msg-top-3">Required</p> : null }*/}
                                            {/*</div>*/}
                                        </div>
                                        <Popup
                                            lockScroll
                                            open={this.state.popupOpen1}
                                            onClose={this.handlePopupClose1}
                                            className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                                        >
                                            {(close) => (
                                                <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                                    <button
                                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                                        onClick={close}
                                                    >
                                                        <i className="icon-times font-35 cursor-pointer"></i>
                                                    </button>
                                                    {popupContent1}
                                                </div>
                                            )}
                                        </Popup>
                                        <Popup
                                            lockScroll
                                            open={this.state.popupOpen2}
                                            onClose={this.handlePopupClose2}
                                            className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                                        >
                                            {(close) => (
                                                <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                                    <button
                                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                                        onClick={close}
                                                    >
                                                        <i className="icon-times font-35 cursor-pointer"></i>
                                                    </button>
                                                    {popupContent2}
                                                </div>
                                            )}
                                        </Popup>
                                        <Popup
                                            lockScroll
                                            open={this.state.popupOpen3}
                                            onClose={this.handlePopupClose3}
                                            className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                                        >
                                            {(close) => (
                                                <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                                                    <button
                                                        className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                                        onClick={close}
                                                    >
                                                        <i className="icon-times font-35 cursor-pointer"></i>
                                                    </button>
                                                    {popupContent3}
                                                </div>
                                            )}
                                        </Popup>

                                        <Navigation
                                            buttonLoading={
                                                this.state.buttonLoading
                                            }
                                            submit={true}
                                        />
                                    </Form>
                                </>
                            );
                        }}
                    </Formik>
                </>
            );
        }

        return <div className="c-funeral__payment">{content}</div>;
    }
}

const mapStateToProps = (state) => ({
    funeralPayment: state.funeralPaymentReducer,
    funeralPlan: state.usersReducer,
    user: state.usersReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetTotalAmount: (currentStep) =>
            dispatch(funeralPaymentActionTypes.onSetTotalAmount(currentStep)),
        onSetOption: (option, step) =>
            dispatch(funeralPaymentActionTypes.onSetOption(option, step)),
        onSetCurrentStep: (step) =>
            dispatch(funeralPaymentActionTypes.onSetCurrentStep(step)),
        onSetTotal: (total, subtotal) =>
            dispatch(funeralPaymentActionTypes.onSetTotal(total, subtotal)),
        onRemoveAddons: () =>
            dispatch(funeralPaymentActionTypes.onRemoveAddons()),
    };
};

const paymentWithRouter = withRouter(Payment);

export default connect(mapStateToProps, mapDispatchToProps)(paymentWithRouter);
