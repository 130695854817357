import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Popup from 'reactjs-popup';

export default class OriginSingle extends Component {
    state = {
        popupOpen: false,
    };

    handleTogglePopup = () => {
        this.setState({
            popupOpen: !this.state.popupOpen,
        });
    };

    render() {
        return (
            <>
                <div
                    className={`o-flex two-col ${
                        this.props.origin.imageRight ? 'origin-image-right' : ''
                    } ${
                        !this.props.origin.image2
                            ? 'no-rating-img'
                            : ''
                    }`}
                >
                    <div className="position-relative c-origin__img-box">
                        {/* big image and video (NEED TO CHECK WITH MILOS WHICH FILES ARE SUPPORTED, NOT WORKING CURRENTLY) */}
                        {this.props.origin.video ? (
                            <div>
                                <video
                                    className="c-origin__video"
                                    autoPlay
                                    loop
                                    muted
                                    playsinline="true"
                                    src={this.props.origin.video}
                                ></video>
                                <div
                                    className={`c-video-header__play-button c-video-header__play-button--custom-page`}
                                    onClick={() => this.handleTogglePopup()}
                                >
                                    <div className="rings">
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <div className="circle">
                                        <div className="triangle">
                                            <i class="icon-video-play"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="c-origin--lg-img"
                                style={{
                                    backgroundImage: `url(${this.props.origin.image})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        )}
                        {/* small image */}
                        {this.props.origin.image2 && (
                            <div
                                className="c-origin--sm-img"
                                style={{
                                    backgroundImage: `url(${this.props.origin.image2})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                        )}
                    </div>
                    <div className="c-origin__txt-box">
                        {this.props.origin.title && (
                            <h3>{this.props.origin.title} </h3>
                        )}
                        {this.props.origin.description && (
                            <p>
                                {ReactHtmlParser(this.props.origin.description)}
                            </p>
                        )}

                        {this.props.origin.ctaTitle && (
                            <a
                                href={this.props.origin.ctaLink}
                                className="c-btn btn btn--full btn--size-width-246 btn--size-57 text-center o-d-inline-block"
                            >
                                {this.props.origin.ctaTitle}
                            </a>
                        )}
                    </div>
                </div>

                <Popup
                    lockScroll
                    modal
                    className="lightbox-popup"
                    open={this.state.popupOpen}
                    onClose={() =>
                        this.setState({
                            popupOpen: false,
                        })
                    }
                >
                    {(close) => {
                        return (
                            <>
                                <div className="lightbox-content">
                                    {this.props.origin.videoPopup ? (
                                        <iframe
                                            className="lightbox-video width-full height-full padding-reset border-radius-7"
                                            title="embed-title"
                                            allowFullScreen
                                            src={this.props.origin.videoPopup}
                                        ></iframe>
                                    ) : null}
                                </div>
                                <button
                                    onClick={close}
                                    className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                >
                                    <i className="icon-times font-35 cursor-pointer"></i>
                                </button>
                            </>
                        );
                    }}
                </Popup>
            </>
        );
    }
}
