import React, { Component } from 'react';

import ApiService from '../../../service/api.service';
import ReactHtmlParser from 'react-html-parser';
import NumberFormater from '../../../service/number_formater.service';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
// import ReactPixel from 'react-facebook-pixel';
import Popup from 'reactjs-popup';
export default class Plans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [],
            plan: null,
            plan_simple: {},
            plan_imagined: {},
            popupOpen: false,
            isLoading: true,
            infoTitle: '',
            infoContent: '',
            infoPhoto: '',
        };
        this.leftSideRef = React.createRef();
        this.rightSideRef = React.createRef();
    }

    componentDidMount() {
        ApiService.getFuneralPlanComparasions().then((r) => {
            this.setState(
                {
                    content: r.data,
                },
                () => {
                    setTimeout(() => {
                        let leftHeight = this.leftSideRef.current.offsetHeight;
                        let rightHeight =
                            this.rightSideRef.current.offsetHeight;
                        if (leftHeight > rightHeight) {
                            //set right height to left height
                            this.rightSideRef.current.style.height = `${leftHeight}px`;
                        } else {
                            //set left height to right height
                            this.leftSideRef.current.style.height = `${rightHeight}px`;
                        }
                    }, 100);
                }
            );
        });

        let slug = 'crematorium-locations';
        ApiService.getInfoPages(slug).then((r) => {
            this.setState({
                infoTitle: r.data.title,
                infoContent: r.data.content,
                infoPhoto: r.data.photo,
                isLoading: false,
            });
        });

        var plan = 'simple';
        ApiService.getFuneralPlans(plan).then((r) => {
            this.setState({
                plan_simple: r.data,
            });
        });

        plan = 'imagined';
        ApiService.getFuneralPlans(plan).then((r) => {
            this.setState({
                plan_imagined: r.data,
            });
        });
    }

    //*Commented out to remove fb pixel from this page, task 38 on website launch
    // handleLearnMore = () => {
    //     ReactPixel.init('218942439488342');
    //     ReactPixel.track('View content');
    // };

    handlePopupOpen = () => {
        this.setState({
            popupOpen: true,
        });
    };

    handlePopupClose = () => {
        this.setState({
            popupOpen: false,
        });
    };

    render() {
        let popupContent = (
            <>
                <h1 className="font-family-secondary font-42 font-semibold font-333 opacity-9">
                    {this.state.infoTitle}
                </h1>
                <div className="parsed-body">
                    {ReactHtmlParser(this.state.infoContent)}
                </div>
                <div>
                    <img src={this.state.infoPhoto} alt="" />
                </div>
            </>
        );

        let leftContent;
        let rightContent;

        if (this.state.content) {
            // leftContent
            leftContent = this.props.tableContent.map((item, index) => {
                let content;
                if (item.title1) {
                    content = (
                        <EqualHeightElement name={`item=${index}`}>
                            <div className="c-funerals__plan-details-spec">
                                <div className="c-funerals__plan-spec-content">
                                    {item.icon1 ? (
                                        <img
                                            src={item.icon1}
                                            alt="details icon"
                                            className="c-funerals__plan-details-icon"
                                        />
                                    ) : null}
                                    <p className="c-funerals__plan-details-text">
                                        {item.title1}
                                    </p>
                                </div>
                            </div>
                        </EqualHeightElement>
                    );
                } else {
                    content = (
                        <EqualHeightElement name={`item=${index}`}>
                            <div className="c-funerals__plan-details-spec c-funerals__plan-details-spec--empty">
                                <div className="c-funerals__plan-details-empty">
                                    <span></span>
                                </div>
                            </div>
                        </EqualHeightElement>
                    );
                }
                return content;
            });

            rightContent = this.props.tableContent.map((item, index) => {
                let content;
                if (item.title2) {
                    content = (
                        <EqualHeightElement name={`item=${index}`}>
                            <div className="c-funerals__plan-details-spec">
                                <div className="c-funerals__plan-spec-content">
                                    {item.icon2 ? (
                                        <img
                                            src={item.icon2}
                                            alt="details icon"
                                            className="c-funerals__plan-details-icon"
                                        />
                                    ) : null}
                                    <p className="c-funerals__plan-details-text">
                                        {item.title2}
                                    </p>
                                </div>
                            </div>
                        </EqualHeightElement>
                    );
                } else {
                    content = (
                        <EqualHeightElement name={`item=${index}`}>
                            <div className="c-funerals__plan-details-spec c-funerals__plan-details-spec--empty">
                                <div className="c-funerals__plan-details-empty">
                                    <span></span>
                                </div>
                            </div>
                        </EqualHeightElement>
                    );
                }
                return content;
            });
        }
        return (
            <div className="c-funerals__plans" id="plans">
                <div className="container">
                    <h2>{this.props.title}</h2>
                    <p className="text-center">
                        {ReactHtmlParser(this.props.description)}
                    </p>
                </div>
                <div className="c-funerals__plans--two-col">
                    <EqualHeight>
                        <div className="c-funerals__plans--wrapper">
                            <div
                                className="c-funerals__plans--two-col-img"
                                style={{
                                    backgroundImage: `url(${
                                        this.props.planImage1
                                            ? this.props.planImage1
                                            : ''
                                    })`,
                                }}
                            ></div>
                            <div className="c-funerals__plans--sticky-wrapper">
                                <h3>
                                    {this.props.planTitle1
                                        ? ReactHtmlParser(this.props.planTitle1)
                                        : ''}
                                    <br />
                                    {this.props.planSubtitle1
                                        ? this.props.planSubtitle1
                                        : ''}
                                </h3>
                            </div>
                            <p ref={this.leftSideRef}>
                                {this.props.planDescription1
                                    ? ReactHtmlParser(
                                          this.props.planDescription1
                                      )
                                    : ''}
                            </p>
                            <span className="c-funerals__plans--two-col-price">
                                {/* Commented this FROM, maybe needs to be back sometimes */}
                                From £
                                {this.props.planPrice1
                                    ? NumberFormater.formatNumber(
                                          this.props.planPrice1 / 100
                                      )
                                    : ''}
                            </span>
                            <div className="button-wrapper">
                                {this.props.planCtaLink1 && (
                                    <a
                                        className="o-d-inline-block btn btn--full btn--size-69 btn--size-width-300 text-center btn--padding-left-right"
                                        // onClick={() => this.handleLearnMore()}
                                        href={this.props.planCtaLink1}
                                    >
                                        {this.props.planCtaTitle1}
                                    </a>
                                )}
                            </div>
                            <div className="c-funerals__plan-details">
                                {leftContent}
                            </div>
                        </div>
                        <div className="c-funerals__plans--wrapper">
                            <div
                                className="c-funerals__plans--two-col-img"
                                style={{
                                    backgroundImage: `url(${
                                        this.props.planImage2
                                            ? this.props.planImage2
                                            : ''
                                    })`,
                                }}
                            ></div>
                            <div className="c-funerals__plans--sticky-wrapper">
                                <h3>
                                    {this.props.planTitle2
                                        ? ReactHtmlParser(this.props.planTitle2)
                                        : ''}
                                    <br />
                                    {this.props.planSubtitle2
                                        ? this.props.planSubtitle2
                                        : ''}
                                </h3>
                            </div>
                            <p ref={this.rightSideRef}>
                                {this.props.planDescription2
                                    ? ReactHtmlParser(
                                          this.props.planDescription2
                                      )
                                    : ''}
                            </p>
                            <span className="c-funerals__plans--two-col-price">
                                From £
                                {this.props.planPrice2
                                    ? NumberFormater.formatNumber(
                                          this.props.planPrice2 / 100
                                      )
                                    : ''}
                            </span>
                            <div className="button-wrapper">
                                {this.props.planCtaLink2 && (
                                    <a
                                        className="o-d-inline-block btn btn--full btn--size-69 btn--size-width-300 text-center btn--padding-left-right"
                                        // onClick={() => this.handleLearnMore()}
                                        href={this.props.planCtaLink2}
                                    >
                                        {this.props.planCtaTitle2}
                                    </a>
                                )}
                            </div>

                            <div className="c-funerals__plan-details">
                                {rightContent}
                            </div>
                        </div>
                    </EqualHeight>
                </div>

                <p className="c-funeral__option--terms crematoria-maps text-center">
                    {' '}
                    <span
                        onClick={this.handlePopupOpen}
                        className="link-underline"
                    >
                        Click here
                    </span>{' '}
                    to see our list of crematoria locations available with our
                    Wonderfully Imagined plan.
                </p>
                <Popup
                    lockScroll
                    open={this.state.popupOpen}
                    onClose={this.handlePopupClose}
                    className=" c-funeral__popup c-funeral__popup--classic c-funeral__popup--map c-funeral__popup--olduser-wrap c-help-popup c-popup-wrapper"
                >
                    {(close) => (
                        <div className="c-popup o-flex o-flex-column c-funeral__popup--olduser">
                            <button
                                className="c-popup-wrapper-overlay__close btn-icon position-absolute"
                                onClick={close}
                            >
                                <i className="icon-times font-35 cursor-pointer"></i>
                            </button>
                            {popupContent}
                        </div>
                    )}
                </Popup>
            </div>
        );
    }
}
