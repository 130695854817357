import React, { Component } from 'react';
import OriginSingle from './OriginSingle';
class Origin extends Component {
    render() {
        let origins;

        if (this.props.content.length) {
            origins = this.props.content.map((origin, index) => {
                return <OriginSingle key={`origin-${index}`} origin={origin} />;
            });
        }

        return (
            <div className="c-origin container">
                {this.props.title && <h2>{this.props.title} </h2>}
                {origins}
            </div>
        );
    }
}

export default Origin;
