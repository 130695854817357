export const SEARCH_QUERY = 'SEARCH_QUERY';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const CONNECTION_CHANGE = 'CONNECTION_CHANGE';
export const SEARCH_DECLINE_CONNECTION = 'SEARCH_DECLINE_CONNECTION';
export const SEARCH_ACCEPT_CONNECTION = 'SEARCH_ACCEPT_CONNECTION';

//dispatch changes
export const setSearchQuery = (searchQuery) => {
    return {
        type: SEARCH_QUERY,
        searchQuery: searchQuery,
    };
};

export const setSearchResults = (searchResults) => {
    return {
        type: SEARCH_RESULTS,
        searchResults: searchResults,
    };
};

export const searchRemoveConnection = (connectionId) => {
    return {
        type: REMOVE_CONNECTION,
        connectionId: connectionId,
    };
};

export const connectionTypeChangeResults = (userId, connection) => {
    return {
        type: CONNECTION_CHANGE,
        userId: userId,
        connType: connection,
    };
};

export const declineSearchConnection = (connId) => {
    return {
        type: SEARCH_DECLINE_CONNECTION,
        connId: connId,
    };
};

export const searchAcceptRequest = (conn, oppositeConnectionId) => {
    return {
        type: SEARCH_ACCEPT_CONNECTION,
        conn: conn,
        oppositeConnectionId: oppositeConnectionId,
    };
};

//dispatch actions
export const onSetSearchQuery = (payload) => {
    return (dispatch) => {
        dispatch(setSearchQuery(payload));
    };
};

export const onSetSearchResults = (payload) => {
    return (dispatch) => {
        dispatch(setSearchResults(payload));
    };
};

export const onSearchRemoveConnection = (payload) => {
    return (dispatch) => {
        dispatch(searchRemoveConnection(payload));
    };
};

export const onConnectionTypeChangeResults = (userId, connection) => {
    return (dispatch) => {
        dispatch(connectionTypeChangeResults(userId, connection));
    };
};

export const onSearchDeclineRequest = (connId) => {
    return (dispatch) => {
        dispatch(declineSearchConnection(connId));
    };
};

export const onSearchAcceptRequest = (conn, oppositeConnectionId) => {
    return (dispatch) => {
        dispatch(searchAcceptRequest(conn, oppositeConnectionId));
    };
};
