import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class AmendComponent extends Component {
    render() {
        return (
            <div>
                <div className="c-amends container text-center">
                    {this.props.title && <h2>{this.props.title} </h2>}
                    {/* {!this.props.title && this.props.content.title && (
                        <h2>{this.props.content.title} </h2>
                    )}
                    {this.props.title && this.props.content.title && (
                        <h2>{this.props.title} </h2>
                    )} */}
                    {this.props.content.description && (
                        <p>{ReactHtmlParser(this.props.content.description)}</p>
                    )}{' '}
                    <div className="c-amends__images two-col">
                        <div>
                            <div
                                className="c-amends__images--img"
                                style={{
                                    backgroundImage: `url(${this.props.content.image1})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            {this.props.content.ctaLink1 && (
                                <a
                                    class=" c-plans__why-aura-planning-btn o-d-inline-block btn btn--full btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                    href={this.props.content.ctaLink1}
                                >
                                    {this.props.content.ctaTitle1}
                                </a>
                            )}
                        </div>
                        <div>
                            <div
                                className="c-amends__images--img"
                                style={{
                                    backgroundImage: `url(${this.props.content.image2})`,
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            {this.props.content.ctaLink2 && (
                                <a
                                    class=" c-plans__why-aura-planning-btn o-d-inline-block btn btn--full btn--size-57 btn--size-width-246 text-center btn--padding-left-right"
                                    href={this.props.content.ctaLink2}
                                >
                                    {this.props.content.ctaTitle2}
                                </a>
                            )}
                        </div>
                    </div>
                    <span>
                        <span>
                            {ReactHtmlParser(this.props.content.source)}
                        </span>
                    </span>
                </div>
            </div>
        );
    }
}

export default AmendComponent;
