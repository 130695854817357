import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import SlickSliderComparison from '../../UI/SlickSlider/SlickSliderComparison';
class Comparison extends Component {
    render() {
        return (
            <div className="c-plans c-funeral-at-need">
                <div className="container">
                    <div className="c-homepage__options">
                        <div className="c-homepage__options__box">
                            {this.props.title && <h2>{this.props.title}</h2>}
                            {this.props.description && (
                                <p>{ReactHtmlParser(this.props.description)}</p>
                            )}

                            <div className="c-homepage__options__img-box o-flex overflow-x-hidden">
                                {this.props.content?.map((block, index) => {
                                    return (
                                        <div
                                            key={`comaprison-${index}`}
                                            className="o-flex o-flex-column  c-homepage__options__card"
                                        >
                                            <SlickSliderComparison
                                                img1={block.image1}
                                                img2={block.image2}
                                                img3={block.image3}
                                            />
                                            <div className="c-homepage__options__card-info option1">
                                                <div className="c-homepage__options__card-plan-info">
                                                    {block.title && (
                                                        <h3 className="c-homepage__options__card-plan-name">
                                                            {block.title}
                                                        </h3>
                                                    )}

                                                    <div className="c-homepage__options__card-desc-price">
                                                        {block.description && (
                                                            <p className="c-homepage__options__card-plan-desc">
                                                                {ReactHtmlParser(
                                                                    block.description
                                                                )}
                                                            </p>
                                                        )}
                                                        {block.price && (
                                                            <div className="c-homepage__options__card-plan-price">
                                                                <h6>
                                                                    Costs from
                                                                </h6>
                                                                <h5>
                                                                    £
                                                                    {
                                                                        block.price
                                                                    }
                                                                </h5>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {block.ctaLink && (
                                                    <a
                                                        href={block.ctaLink}
                                                        className="c-btn btn btn--full btn--size-width-246 btn--size-57 text-center o-d-inline-block"
                                                    >
                                                        {block.ctaTitle}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Comparison;
