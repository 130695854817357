import { Helmet } from 'react-helmet';
import React from 'react';

export default function PageMeta(props) {
    return (
        <div>
            <Helmet>
                {props.title ? <title>{props.title}</title> : null}
                {props.canonical ? (
                    <link rel="canonical" href={props.canonical} />
                ) : null}
                {props.keywords ? (
                    <meta name="keywords" content={props.keywords} />
                ) : null}
                {props.description ? (
                    <meta name="description" content={props.description} />
                ) : null}
                {props.photo ? (
                    <meta name="og:image" content={props.photo} />
                ) : null}
                {props.noIndex ? (
                    <meta name="robots" content="noindex"></meta>
                ) : null}
            </Helmet>
        </div>
    );
}
