import * as actionTypes from '../actions/funeralPaymentActions';

const initialState = {
    amount: 1000,
    currentStep: 0,
    email: null,
    forMe: null,
    mobile: null,
    plan: null,
    selectedOption: null,
    userAddOns: [],
    uuid: null,
    addOnCosts: null,
    ownDescription: null,
    total: 0,
    subtotal: 0,
    currentPlan: null,
    totalStep: 0,
    addonModalOpen: false,
    addonModalTitle: '',
    addonModalBody: '',
    funeralPaymentId: null,
    nominated: false,
    firstName: null,
};

const funeralPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AMOUNT:
            return {
                ...state,
                amount: action.amount,
                total: action.amount,
            };
        case actionTypes.SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.step,
            };
        case actionTypes.SET_FIRST_STEP:
            return {
                ...state,
                currentStep: action.data.step,
                firstName: action.data.firstName,
                email: action.data.email,
                forMe: action.data.forMe,
                mobile: action.data.mobile,
                uuid: action.data.uuid,
                plan: action.data.plan,
                totalStep: action.data.step,
                holderMobile: action.data.holderMobile,
                holderFirstName: action.data.holderFirstName,
                holderEmail: action.data.holderEmail,
                funeralPaymentId: action.data.id,
            };
        case actionTypes.SET_OPTION:
            return {
                ...state,
                selectedOption: action.option,
                totalStep: action.step,
            };
        case actionTypes.SET_FUNERAL_CHECKOUT:
            return {
                ...state,
                nominated: action.data.nominated,
                firstName: action.data.firstName,
                currentStep: action.data.totalStep,
                email: action.data.email,
                forMe: action.data.forMe,
                mobile: action.data.mobile,
                uuid: action.data.uuid,
                plan: action.data.plan,
                selectedOption: action.data.planOption,
                userAddOns: action.data.userAddOns,
                addOnCosts: action.data.addOnCosts,
                ownDescription: action.data.ownAddDescription,
                total: action.data.total,
                subtotal: action.data.subtotal,
                totalStep: action.data.totalStep,
                funeralPaymentId: action.data.id,
                //ovde ide ostatak
                createdByAddress: action.data.createdByAddress,
                createdByEmail: action.data.createdByEmail,
                createdByFirstName: action.data.createdByFirstName,
                createdByLastName: action.data.createdByLastName,
                createdByMiddleName: action.data.createdByMiddleName,
                createdByMobile: action.data.createdByMobile,
                createdByRelationship: action.data.createdByRelationship,
                fullAddress: action.data.fullAddress,
                holderEmail: action.data.holderEmail,
                holderFirstName: action.data.holderFirstName,
                holderLastName: action.data.holderLastName,
                holderMiddleName: action.data.holderMiddleName,
                holderMobile: action.data.holderMobile,
                holderTitle: action.data.holderTitle,
                kinEmail: action.data.kinEmail,
                kinFirstName: action.data.kinFirstName,
                kinFullAddress: action.data.kinFullAddress,
                kinLastName: action.data.kinLastName,
                kinMiddleName: action.data.kinMiddleName,
                kinMobile: action.data.kinMobile,
                kinRelationship: action.data.kinRelationship,
                birthday: action.data.birthday,
                //adressess
                //created by
                createdByAddressLine1: action.data.createdByAddressLine1,
                createdByAddressLine2: action.data.createdByAddressLine2,
                createdByCountry: action.data.createdByCountry,
                createdByCounty: action.data.createdByCounty,
                createdByPostcode: action.data.createdByPostcode,
                createdByTown: action.data.createdByTown,
                //holder
                holderAddressLine1: action.data.holderAddressLine1,
                holderAddressLine2: action.data.holderAddressLine2,
                holderCountry: action.data.holderCountry,
                holderCounty: action.data.holderCounty,
                holderPostcode: action.data.holderPostcode,
                holderTown: action.data.holderTown,
                //kin
                kinAddressLine1: action.data.kinAddressLine1,
                kinAddressLine2: action.data.kinAddressLine2,
                kinCountry: action.data.kinCountry,
                kinCounty: action.data.kinCounty,
                kinPostcode: action.data.kinPostcode,
                kinTown: action.data.kinTown,
            };
        case actionTypes.SET_FIRST_STEP_PLAN:
            return {
                ...state,
                nominated: action.data.nominated,
                // currentStep: action.data.totalStep,
                firstName: action.data.firstNae,
                email: action.data.email,
                forMe: action.data.forMe,
                mobile: action.data.mobile,
                uuid: action.data.uuid,
                plan: action.data.plan,
                selectedOption: action.data.planOption,
                userAddOns: action.data.userAddOns,
                addOnCosts: action.data.addOnCosts,
                ownDescription: action.data.ownAddDescription,
                total: action.data.total,
                subtotal: action.data.subtotal,
                totalStep: action.data.totalStep,
                funeralPaymentId: action.data.id,
                //ovde ide ostatak
                createdByAddress: action.data.createdByAddress,
                createdByEmail: action.data.createdByEmail,
                createdByFirstName: action.data.createdByFirstName,
                createdByLastName: action.data.createdByLastName,
                createdByMiddleName: action.data.createdByMiddleName,
                createdByMobile: action.data.createdByMobile,
                createdByRelationship: action.data.createdByRelationship,
                fullAddress: action.data.fullAddress,
                holderEmail: action.data.holderEmail,
                holderFirstName: action.data.holderFirstName,
                holderLastName: action.data.holderLastName,
                holderMiddleName: action.data.holderMiddleName,
                holderMobile: action.data.holderMobile,
                holderTitle: action.data.holderTitle,
                kinEmail: action.data.kinEmail,
                kinFirstName: action.data.kinFirstName,
                kinFullAddress: action.data.kinFullAddress,
                kinLastName: action.data.kinLastName,
                kinMiddleName: action.data.kinMiddleName,
                kinMobile: action.data.kinMobile,
                kinRelationship: action.data.kinRelationship,
                birthday: action.data.birthday,
                //adressess
                //created by
                createdByAddressLine1: action.data.createdByAddressLine1,
                createdByAddressLine2: action.data.createdByAddressLine2,
                createdByCountry: action.data.createdByCountry,
                createdByCounty: action.data.createdByCounty,
                createdByPostcode: action.data.createdByPostcode,
                createdByTown: action.data.createdByTown,
                //holder
                holderAddressLine1: action.data.holderAddressLine1,
                holderAddressLine2: action.data.holderAddressLine2,
                holderCountry: action.data.holderCountry,
                holderCounty: action.data.holderCounty,
                holderPostcode: action.data.holderPostcode,
                holderTown: action.data.holderTown,
                //kin
                kinAddressLine1: action.data.kinAddressLine1,
                kinAddressLine2: action.data.kinAddressLine2,
                kinCountry: action.data.kinCountry,
                kinCounty: action.data.kinCounty,
                kinPostcode: action.data.kinPostcode,
                kinTown: action.data.kinTown,
            };
        case actionTypes.PATCH_DATA:
            return {
                ...action.stepData,
            };
        case actionTypes.REMOVE_ADDON:
            const copiedAddons = state.userAddOns.filter(
                (a) => a.uuid !== action.uuid
            );

            return {
                ...state,
                userAddOns: copiedAddons,
            };
        case actionTypes.ADD_ADDON:
            const addCopiedAddons = state.userAddOns;
            addCopiedAddons.push(action.addon);
            return {
                ...state,
                userAddOns: addCopiedAddons,
            };
        case actionTypes.ADD_CUSTOM_ADDON:
            return {
                ...state,
                addOnCosts: action.ownAmount,
                ownDescription: action.ownDesc,
            };
        case actionTypes.REMOVE_CUSTOM_ADDON:
            return {
                ...state,
                addOnCosts: null,
                ownDescription: null,
            };
        case actionTypes.SET_TOTAL:
            return {
                ...state,
                total: action.total,
                subtotal: action.subtotal,
            };
        case actionTypes.REMOVE_ADDONS:
            return {
                ...state,
                userAddOns: [],
            };
        case actionTypes.LOGOUT:
            return initialState;
        case actionTypes.UPDATE_ADDON:
            let copiedUserAddons = [...state.userAddOns];
            let addon = copiedUserAddons.find(
                (addon) => addon.uuid === action.uuid
            );
            let updatedAddon = { ...addon, amount: action.amount };
            let index = copiedUserAddons.indexOf(addon);
            copiedUserAddons[index] = updatedAddon;
            return {
                ...state,
                userAddOns: copiedUserAddons,
            };
        case actionTypes.UPDATE_CUSTOM_ADDON:
            return {
                ...state,
                addOnCosts: action.amount,
                ownDescription: action.description,
            };
        case actionTypes.SET_PLAN:
            return {
                ...state,
                currentPlan: action.plan,
            };
        case actionTypes.SET_TOTAL_STEP:
            return {
                ...state,
                totalStep: action.totalStep,
            };
        case actionTypes.OPEN_MODAL:
            return {
                ...state,
                addonModalOpen: true,
                addonModalTitle: action.title,
                addonModalBody: action.body,
            };
        case actionTypes.CLOSE_MODAL:
            return {
                ...state,
                addonModalOpen: false,
                addonModalTitle: '',
                addonModalBody: '',
            };
        case actionTypes.DECIDE_LATER:
            return {
                ...state,
                subtotal: action.subtotal,
                userAddOns: action.addons,
                currentStep: state.currentStep + 1,
                totalStep: state.totalStep,
            };
        default:
            return state;
    }
};

export default funeralPaymentReducer;
