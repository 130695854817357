import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import ApiService from '../../../service/api.service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import loader from '../../../assets/images/loader.svg';
import cardImage from '../../../assets/images/cards.png';
import lock from '../../../assets/images/funeral-payment/lock.svg';

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [state, setState] = useState({
        cardHolder: '',
        debitAccountName: '',
        debitAccountNumber: '',
        debitSortCode: '',
    });
    const [validationState, setValidationState] = useState({
        cardHolder: true,
        debitAccountName: true,
        debitAccountNumber: true,
        debitSortCode: true,
    });

    const [cardElementsComplete, setCardElementsComplete] = useState({
        number: false,
        expiry: false,
        cvc: false,
    });

    useEffect(
        () => {
            ApiService.getOrderHistory();
            if (!stripe) {
                return;
            }
        },
        [stripe],
        []
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const cardElement = elements.getElement(CardNumberElement);

        const paymentMethodReq = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: state.cardHolder,
            },
        });

        const debitAccountName = state.debitAccountName;
        const debitAccountNumber = state.debitAccountNumber;
        const debitSortCode = state.debitSortCode;

        const data = {
            debitAccountName: debitAccountName.length ? debitAccountName : null,
            debitAccountNumber: debitAccountNumber.length
                ? debitAccountNumber
                : null,
            debitSortCode: debitSortCode.length ? debitSortCode : null,
            rate: props.paymentOption,
        };
        ApiService.setInstalment(props.uuid, data).then((r) => {
            ApiService.createPaymentIntent(props.uuid).then((r) => {
                const paymentIntent = r.data.clientSecret;

                if (paymentMethodReq.paymentMethod) {
                    stripe
                        .confirmCardPayment(paymentIntent, {
                            payment_method: paymentMethodReq.paymentMethod.id,
                        })
                        .then((r) => {
                            if (r.error) {
                                history.push('/payment-problem');
                                setIsLoading(false);
                            } else {
                                history.push('/payment-success');
                                setIsLoading(false);
                            }
                        });
                }
            });
        });
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value,
        });

        if (e.target.name === 'password' && e.target.value.length > 7) {
            document.querySelector('#password-length').style.visibility =
                'hidden';
        }
    };

    const handleBlur = (e) => {
        const value = e.target.value;
        setValidationState({
            ...validationState,
            [e.target.name]: value.length > 1 ? true : false,
        });
    };

    const handleChangeCardElements = (e, type) => {
        setCardElementsComplete({
            ...cardElementsComplete,
            [type]: e.complete,
        });
    };

    const cardStyle = {
        hideIcon: false,
        base: {
            fontFamily: 'sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            letterSpacing: '0.02em',
            color: 'rgba(0, 0, 0, 0.9)',
            fontWeight: 400,
            borderRadius: '4px',
            borderColor: '#e9d6c1',
            borderWidth: '1px',
            borderStyle: 'solid',
            '::placeholder': {
                color: 'rgba(0, 0, 0, 0.4)',
                padding: '15px 0 0 15px',
            },
        },
        invalid: {
            color: '#c6363c',
        },
    };

    const cardNumberOptions = {
        placeholder: 'Card number',
        style: cardStyle,
    };

    const cardExpiryOptions = {
        placeholder: 'Expiry date',
        style: cardStyle,
    };

    const cardCvcOptions = {
        placeholder: 'Security code',
        style: cardStyle,
    };

    let paymntForm = (
        <>
            <div className="c-funeral__payment--first-instalment">
                <h2>One Payment - Credit or Debit card</h2>
            </div>
            <div className="form-item-wrap">
                <input
                    className={`bg-white mb-30 ${
                        !validationState.cardHolder ? 'invalid' : ''
                    }`}
                    type="text"
                    placeholder="Card holder"
                    name="cardHolder"
                    id="cardHolder"
                    value={state.cardHolder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>

            <CardNumberElement
                onChange={(e) => handleChangeCardElements(e, 'number')}
                options={cardNumberOptions}
                id="payment-element"
                className="stripeInput mb-30"
            />
            <img
                src={cardImage}
                alt="Visa, MasterCard, American Express, Discover"
            />
            <div className="col2">
                <CardExpiryElement
                    onChange={(e) => handleChangeCardElements(e, 'expiry')}
                    options={cardExpiryOptions}
                    className="stripeInput col2__input"
                />
                <CardCvcElement
                    onChange={(e) => handleChangeCardElements(e, 'cvc')}
                    options={cardCvcOptions}
                    className="stripeInput col2__input"
                />
                <p className="o-flex flex-center c-funeral__payment-page--powered">
                    <img src={lock} alt="icon-lock"></img>
                    <span>Powered by </span>
                    <span>Stripe</span>{' '}
                </p>
            </div>

            <button
                onClick={handleSubmit}
                disabled={
                    isLoading ||
                    !stripe ||
                    !elements ||
                    state.cardHolder === '' ||
                    !cardElementsComplete.number ||
                    !cardElementsComplete.expiry ||
                    !cardElementsComplete.cvc
                }
                className={`pay-btn o-d-inline-block btn btn--full btn--size-59 btn--size-width-166 text-center btn--padding-left-right btn-loader ${
                    isLoading ? 'save-in-progress' : ''
                }`}
            >
                Confirm and pay
                <img
                    width="100%"
                    height="100%"
                    src={loader}
                    alt="loaderImage"
                />
            </button>
        </>
    );

    return props.paymentOption === 1 ? paymntForm : null;
}
