import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import VideoEmbed from '../../../../service/video_embed.service';
export default class TestimonialSingleSlide extends Component {
    render() {
        return (
            <div
                className={`c-plans__testimonials--slide ${
                    !this.props.testimonial.videoLink &&
                    !this.props.testimonial.image
                        ? 'only-text-slide'
                        : ''
                }`}
            >
                {this.props.testimonial.videoLink ? (
                    <div className="c-plans__testimonials--slide-video embeddedContent embeddedContent--big">
                        <iframe
                            title="plan-video"
                            allowFullScreen
                            src={VideoEmbed.parseLink(
                                this.props.testimonial.videoLink
                            )}
                        ></iframe>
                    </div>
                ) : !this.props.testimonial.videoLink &&
                  !this.props.testimonial.image ? null : (
                    <div
                        className="c-plans__testimonials--slide-img"
                        style={{
                            backgroundImage: `url(${this.props.testimonial.image})`,
                        }}
                    ></div>
                )}

                <div className="c-plans__testimonials--txt-title">
                    <div className="c-plans__testimonials--slide-txt">
                        {ReactHtmlParser(this.props.testimonial.body)}
                    </div>
                    <span className="c-plans__testimonials--slide-title">
                        {this.props.testimonial.title}
                    </span>
                </div>
            </div>
        );
    }
}
