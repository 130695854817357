import React, { useState } from 'react';

import PasswordValidation from '../PasswordValidation/PasswordValidation';
import classes from './Input.module.scss';
import { useField } from 'formik';

const Input = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [focusedName, setFocusedName] = useState('');
    const [focusedEmail, setFocusedEmail] = useState('');
    const [focusedPassword, setFocusedPassword] = useState('');
    const [inputType, setInputType] = useState('password');
    const [validName, setValidName] = useState('');
    const [validEmail, setValidEmail] = useState('');
    const [validPassword, setValidPassword] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [iconReveal, setIconReveal] = useState('icon-hide');
    const [submitOnEnter, setSubmitOnEnter] = useState(false);
    /* eslint-disable-next-line */
    if (
        meta.error ===
        'password must match the following: "/[a-zA-Z]+[^a-zA-Zs]+/"'
    ) {
        meta.error = '';
    } else if (meta.error === 'password must match the following: "/[A-Z]/"') {
        meta.error = '';
    } else if (meta.error === 'password must match the following: "/[a-z]/"') {
        meta.error = '';
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            props.allprops.submitForm();
            setSubmitOnEnter(true);
        }
    };

    const onFocus = () => {
        if (props.name === 'name') {
            setFocusedName(' focused');
        } else if (props.name === 'email') {
            setFocusedEmail(' focused');
        } else {
            setFocusedPassword(' focused');
        }
    };

    const onBlur = () => {
        if (props.name === 'name') {
            setFocusedName('');
            if (field.value === '' && props.edit !== 'true') {
                setValidName(' invalid');
            } else {
                setValidName('');
            }
        } else if (props.name === 'email' && props.edit !== 'true') {
            setFocusedEmail('');
            if (
                field.value === '' ||
                (props.allprops &&
                    props.allprops.errors &&
                    props.allprops.errors.email)
            ) {
                setValidEmail(' invalid');
            } else {
                setValidEmail('');
            }
        } else if (
            props.name === 'oldPassword' ||
            props.name === 'newPassword' ||
            props.name === 'delPassword'
        ) {
            setFocusedPassword('');
            if (meta.value === '') {
                setInvalidPassword(true);
            } else {
                setInvalidPassword(false);
            }
        } else if (props.name === 'phone') {
            if (meta.value === '' && props.validate) {
                setFocusedPassword(' invalid');
            } else {
                setFocusedPassword('');
            }
        } else if (
            props.name === 'holderCounty' ||
            props.name === 'holderAddressLine2'
        ) {
            setFocusedPassword('');
        } else {
            setFocusedPassword('');
            if (field.value === '' && !props.notMandatory && !submitOnEnter) {
                setValidPassword(' invalid');
            } else {
                setValidPassword('');
            }
        }
    };

    const changeInputType = () => {
        if (inputType === 'password') {
            setInputType('text');
            setIconReveal('icon-view');
        } else {
            setInputType('password');
            setIconReveal('icon-hide');
        }
    };

    let returnValue;
    let error = meta.error;
    if (props.type === 'password') {
        returnValue = (
            <div
                className={`form-item-wrap position-relative ${
                    props.passwordIncorrect || invalidPassword ? 'invalid' : ''
                }`}
            >
                {meta.touched && meta.value === '' ? (
                    <div
                        onBlur={onBlur}
                        tabIndex="-1"
                        className={`validate ${
                            meta.error === 'WrongPassword' ? ' invalid' : ''
                        } ${
                            focusedEmail +
                            focusedPassword +
                            focusedName +
                            validName +
                            validPassword +
                            validEmail
                        }`}
                    >
                        <label
                            className="o-flex o-flex-middle"
                            htmlFor={props.id || props.name}
                        >
                            {label}
                            {props.type === 'password' ? (
                                <i
                                    onClick={changeInputType}
                                    className={`${iconReveal} c-pass-reveal o-flex o-flex-center o-flex-middle font-8 font-333  border-radius-50 cursor-pointer`}
                                ></i>
                            ) : null}
                        </label>
                        <input
                            form="novalidatedform"
                            onFocus={onFocus}
                            className={classes.Input}
                            {...field}
                            {...props}
                        ></input>
                    </div>
                ) : (
                    <div
                        onBlur={onBlur}
                        tabIndex="-1"
                        className={`${
                            meta.error === 'WrongPassword' ? ' invalid' : ''
                        } ${
                            focusedEmail +
                            focusedPassword +
                            focusedName +
                            validName +
                            validPassword +
                            validEmail
                        }`}
                    >
                        <label
                            className="o-flex o-flex-middle"
                            htmlFor={props.id || props.name}
                        >
                            {label}
                            {props.type === 'password' ? (
                                <i
                                    onClick={changeInputType}
                                    className={`${iconReveal} c-pass-reveal o-flex o-flex-center o-flex-middle font-10 font-333  border-radius-50 cursor-pointer`}
                                ></i>
                            ) : null}
                        </label>
                        <input
                            form="novalidatedform"
                            onKeyDown={onKeyDown}
                            onFocus={onFocus}
                            className={classes.Input}
                            {...field}
                            {...props}
                            type={inputType}
                        ></input>
                    </div>
                )}

                {props.errormessage !== '' ? (
                    <div className="error-message position-absolute font-13 font-semibold font-form-error">
                        {props.errormessage}
                    </div>
                ) : null}
                {meta.error === 'Required' &&
                props.type === 'password' &&
                meta.touched &&
                meta.value === '' ? (
                    <div className="error-message required-icon position-absolute font-13 font-semibold font-form-error">
                        Required
                    </div>
                ) : null}
                {meta.error === 'WrongPassword' &&
                props.type === 'password' &&
                meta.touched ? (
                    <div className="error-message required-icon position-absolute font-13 font-semibold font-form-error">
                        Wrong password
                    </div>
                ) : null}
                {error === '' ? (
                    <div className="required hide position-absolute font-13 font-semibold font-form-error">
                        {error}
                    </div>
                ) : null}
                {meta.value !== '' &&
                props.type === 'password' &&
                props.passwordvalidation === 'true' ? (
                    <PasswordValidation
                        passwordvalidation={props.passwordvalidation}
                        pw={meta.value}
                    />
                ) : null}
            </div>
        );
    } else {
        returnValue = (
            <div
                className={`form-item-wrap position-relative ${
                    props.type === 'email' &&
                    meta.error &&
                    meta.touched &&
                    props.page === 'contact' &&
                    props.noValidate
                        ? 'invalid'
                        : ''
                } ${props.dayvalid === 'invalid' ? 'invalid' : ''} ${
                    props.yearvalid === 'invalid' ? 'invalid' : ''
                } ${
                    props.name === 'phone' && props.phoneError ? 'invalid' : ''
                }`}
            >
                {meta.touched && meta.value === '' ? (
                    <div
                        className={`validate ${
                            focusedEmail +
                            focusedPassword +
                            focusedName +
                            validName +
                            validPassword +
                            validEmail
                        }`}
                        tabIndex="-1"
                        onBlur={onBlur}
                    >
                        <label
                            className="o-flex o-flex-middle"
                            htmlFor={props.id || props.name}
                        >
                            {label}
                            {props.type === 'password' ? (
                                <i
                                    onClick={changeInputType}
                                    className={`${iconReveal} c-pass-reveal o-flex o-flex-center o-flex-middle font-14 font-333  border-radius-50 cursor-pointer`}
                                ></i>
                            ) : null}
                        </label>
                        <input
                            form="novalidatedform"
                            onFocus={onFocus}
                            className={classes.Input}
                            {...field}
                            {...props}
                        ></input>
                    </div>
                ) : (
                    <div
                        onBlur={onBlur}
                        tabIndex="-1"
                        className={`${
                            focusedEmail +
                            focusedPassword +
                            focusedName +
                            validName +
                            validPassword +
                            validEmail
                        }`}
                    >
                        <label
                            className="o-flex o-flex-middle"
                            htmlFor={props.id || props.name}
                        >
                            {label}
                            {props.type === 'password' ? (
                                <i
                                    onClick={changeInputType}
                                    className={`${iconReveal} c-pass-reveal o-flex o-flex-center o-flex-middle font-10 font-333  border-radius-50 cursor-pointer`}
                                ></i>
                            ) : null}
                        </label>
                        <input
                            form="novalidatedform"
                            onFocus={onFocus}
                            className={classes.Input}
                            onKeyDown={onKeyDown}
                            {...field}
                            {...props}
                        ></input>
                    </div>
                )}
                {props.errormessage === 'Email address already in use!' &&
                meta.value !== '' &&
                !meta.error &&
                props.edit !== 'true' ? (
                    <div className="error-message required-icon position-absolute font-13 font-semibold font-form-error">
                        {props.errormessage}
                    </div>
                ) : null}
                {meta.touched && meta.error ? (
                    <div className="required position-absolute font-13 font-semibold font-form-error">
                        {meta.error}
                    </div>
                ) : null}
            </div>
        );
    }

    return returnValue;
};

export default Input;
